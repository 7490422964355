<div class="p-dialog-header">
    <span class="p-dialog-title">{{'Map.PleaseSelectCoordinates' | transloco }}</span>
    <div class="justify-content-end">
        <p-button styleClass="btn" type="button" (onClick)="OnCancel()" label="{{'Map.Cancel' | transloco }}"></p-button>
        <p-button [disabled]="!MapLoaded" styleClass="btn btn-secondary" type="button" (onClick)="OnAcceptChanges()" label="{{'Map.SaveSelection' | transloco }}"></p-button>
    </div>
</div>
<div class="trimble-map">
    <span #mapContent></span>
</div>
