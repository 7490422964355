import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { PropertyConfiguration } from '../../../../../models/configuration/widgets/properties/property-configuration';

@Component({
    selector: 'fw-control-group-wrapper',
    templateUrl: './control-group-wrapper.component.html',
    host: { 'class': 'inline-edit' }
})
export class ControlGroupWrapperComponent implements OnInit {
    public ChildFormGroup: FormGroup;
    public Display: boolean = false;
    public FormEditStyle: FormEditStyle = FormEditStyle.Form;
    public Properties: PropertyConfiguration[] = [];

    @Input()
    public AcceptChanges: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public CustomFormGroup: FormGroup;

    @Input()
    public DiscardChanges: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public Property: PropertyConfiguration;

    constructor() { }

    public discardClicked(): void {
        this.Display = false;
        this.DiscardChanges.emit();
    }

    public editClicked(): void {
        this.Display = true;
    }

    public getReadOnlyControl(): boolean {
        const readOnlyControl = this.ChildFormGroup.controls[this.Property.PropertyName];

        if (readOnlyControl) {
            return true;
        } else {
            return false;
        }

    }

    ngOnInit(): void {
        this.Properties = this.Property.Properties;
        this.ChildFormGroup = this.CustomFormGroup.controls[this.Property.PropertyName] as FormGroup;
    }

    public onKeyDownEnter(): void {
        this.Display = true;
    }

    public submitClicked(): void {
        this.Display = false;
        this.AcceptChanges.emit();
    }
}
