import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListState, MapReferenceListWidgetState } from 'src/app/AppState';
import { ListFieldConfigurationViewDTO } from 'src/app/data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { PaginationModel } from 'src/app/models/pagination-models';
import { SearchModel } from 'src/app/models/search-model';
import { MapReferenceQueryDefinitionConfigurationViewDTO } from '../../data-transfer-objects/configuration/list-configuration/query-definitions/map-reference-query-definition-configuration-view-dto';
import { AbstractListFacade } from './abstract-list.facade';

@Injectable()
export abstract class AbstractMapReferenceListFacade extends AbstractListFacade<MapReferenceQueryDefinitionConfigurationViewDTO, MapReferenceListWidgetState> {

    public abstract AssetId: string;
    public abstract WidgetId: string;

    public abstract GetListItems(): Observable<ListState<MapReferenceListWidgetState>[]>;
    public abstract LoadListItems(fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, searchModel: SearchModel, queryDefinition: MapReferenceQueryDefinitionConfigurationViewDTO, dataSourceId: string): void;
}
