import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { FilterMatchMode, SelectItem } from 'primeng/api';
import { DateHelper } from '../../../../../../helpers/date-helper';
import { FilterMatchModeHelper } from '../../../../../../helpers/filter-match-mode-helper';
import { SiteSettingsService } from '../../../../../../services/deprecated/site-settings.service';

@Component({
    selector: 'fw-date-column-filter',
    templateUrl: './date-column-filter.component.html',
})
export class DateColumnFilterComponent implements OnInit {

    public selectedValue: string = FilterMatchMode.LESS_THAN_OR_EQUAL_TO;
    public startDate: Date;
    public endDate: Date;
    public dateMatchModeOptions: SelectItem[];

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public InputFilter: any;

    @Output()
    public OnFilter: EventEmitter<{ value: Date, matchMode: string }[] | { value: Date, matchMode: string }> = new EventEmitter<{ value: Date, matchMode: string }[] | { value: Date, matchMode: string }>();

    public get dateFormat(): string {
        return this.siteSettingsService.dateFormat;
    }

    constructor(private siteSettingsService: SiteSettingsService,
        private transloco: TranslocoService) { }

    ngOnInit() {
        this.SetFilterMatchModes();
        this.RebindControls();
    }

    public mapDateFormat(dateFormat: string): string {
        return DateHelper.mapCalendarDateFormat(dateFormat);
    }

    public ClearInputDates() {
        this.startDate = undefined;
        this.endDate = undefined;
    }

    public ApplyFilter() {
        if (this.selectedValue === FilterMatchMode.BETWEEN && (this.startDate || this.endDate)) {

            const dateFilterValues: { value: Date, matchMode: string }[] = [];

            if (this.startDate) {
                dateFilterValues.push({ value: this.startDate, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO });
            }

            if (this.endDate) {
                dateFilterValues.push({ value: this.endDate, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO });
            }

            this.OnFilter.emit(dateFilterValues);
        }
        else if (this.selectedValue === FilterMatchModeHelper.NULL || this.selectedValue === FilterMatchModeHelper.NOT_NULL) {
            this.OnFilter.emit([{ value: new Date(), matchMode: this.selectedValue }]);
        }
        else if (this.selectedValue !== FilterMatchMode.BETWEEN && this.startDate) {

            this.OnFilter.emit([{ value: this.startDate, matchMode: this.selectedValue }]);
        }
    }

    public ClearFilter() {
        this.ClearInputDates();
        this.OnFilter.emit(undefined);
    }

    private RebindControls() {
        if (this.InputFilter && this.InputFilter[0]) {
            this.selectedValue = this.InputFilter.length === 2 ? FilterMatchMode.BETWEEN : this.InputFilter[0].matchMode;
            this.startDate = this.InputFilter[0].value;
            if (this.selectedValue === FilterMatchMode.BETWEEN)
                this.endDate = this.InputFilter[1].value;
        }
    }

    private SetFilterMatchModes() {
        this.dateMatchModeOptions = FilterMatchModeHelper.GetDateMatchModes(this.transloco);
    }
}