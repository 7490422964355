import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserHttpService } from '../../services/http/user-http.service';
import { RepresentativesHttpService } from '../../services/http/representatives-http.service';
import { of } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';

import { PaginationResultModel } from '../../models/pagination-models';
import { RepresentativeOfListViewDTO } from '../../data-transfer-objects/representative/representative-list-view-dto';
import { RepresentativeListWidgetDataModel } from '../../data-transfer-objects/representative/representative-list-widget-data-model';
import { CompanyHttpService } from '../../services/http/company-http.service';
import { getCompaniesDone, getCompaniesError, getCompaniesRequest } from './company.actions';
import { ErrorDetails } from 'src/app/models/error-details';

@Injectable()
export class CompanyEffects {

    getCompaniesFromSearch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getCompaniesRequest),
            switchMap((action) => {
                return this.companyHttpService
                    .GetCompaniesBySearch(action.UserRepresentationListWidgetConfiguration)
                    .pipe(
                        map((representativepaginationResultModel: PaginationResultModel<RepresentativeOfListViewDTO>) => {
                            const representativeListWidgetDataModel: RepresentativeListWidgetDataModel = {

                                representatives: representativepaginationResultModel.Models.map((representative) => {

                                    return {
                                        Id: representative.Id,
                                        FullName: representative.FullName,
                                        Email: representative.Email,
                                        Code: representative.Code,
                                    };
                                }),
                                totalRecords: representativepaginationResultModel.TotalRecords,
                                FormEditStyle: action.FormEditStyle
                            };
                            return getCompaniesDone({
                                CompanyListState: {
                                    Companies: representativeListWidgetDataModel,                                    
                                    GetError: null
                                   
                                }
                            });
                        }),
                        catchError((errorDetails: ErrorDetails) => {
                            return of(
                                getCompaniesError({
                                    CompanyListState: {
                                        Companies: null,                                        
                                        GetError: errorDetails
                                    }
                                })
                            );
                        })
                    );
            })
        )
    );

    constructor(
        private actions$: Actions,        
        private companyHttpService: CompanyHttpService
    ) { }
}
