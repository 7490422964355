import { createReducer, on } from '@ngrx/store';
import { RepresentativeWidgetState } from '../../AppState';
import { RepresentativeOfListViewDTO } from '../../data-transfer-objects/representative/representative-list-view-dto';
import { ObjectHelper } from '../../helpers/object-helper';
import { getRepresentativeDone, getRepresentativeError, addRepresentativeRequest, addRepresentativeError, removeRepresentativeRequest, removeRepresentativeError, resetRepresentative } from './representative.actions'

export const initialRepresentativesState: RepresentativeWidgetState = { Representatives: null, GetError: null, AddError: null, RemoveError: null };

export const RepresentativeReducer = createReducer<RepresentativeWidgetState>(initialRepresentativesState,

    on(getRepresentativeDone, (state, action) => action.RepresentativeWidgetState),
    on(getRepresentativeError, (state, action) => action.RepresentativeWidgetState),

    on(addRepresentativeRequest, (state, action) => {
        const stateToReturn: RepresentativeWidgetState = ObjectHelper.deepCopyJsonParse(state);
        stateToReturn.Representatives.representatives.push({ Code: action.representativeOfDTO.Code, Email: action.representativeOfDTO.Email, FullName: action.representativeOfDTO.FullName, Id: action.representativeOfDTO.Id });
        return stateToReturn;
    }),
    on(addRepresentativeError, (state, action) => {
        const stateToReturn: RepresentativeWidgetState = ObjectHelper.deepCopyJsonParse(state);
        stateToReturn.AddError = action.addError;

        const index = stateToReturn.Representatives.representatives.findIndex((item: RepresentativeOfListViewDTO) => item.Id === action.RepresentativeId);
        stateToReturn.Representatives.representatives.splice(index, 1);
        return stateToReturn;
    }),

    on(removeRepresentativeRequest, (state, action) => {
        const stateToReturn: RepresentativeWidgetState = ObjectHelper.deepCopyJsonParse(state);

        const index = stateToReturn.Representatives.representatives.findIndex((item: RepresentativeOfListViewDTO) => item.Id === action.representativeOfDTO.Id);
        stateToReturn.Representatives.representatives.splice(index, 1);
        return stateToReturn;
    }),
    on(removeRepresentativeError, (state, action) => {
        const stateToReturn: RepresentativeWidgetState = ObjectHelper.deepCopyJsonParse(state);
        stateToReturn.RemoveError = action.removeError;
        stateToReturn.Representatives.representatives.push({ Code: action.representativeOfDTO.Code, Email: action.representativeOfDTO.Email, FullName: action.representativeOfDTO.FullName, Id: action.representativeOfDTO.Id });
        return stateToReturn;
    }),
    on(resetRepresentative, () => initialRepresentativesState)
);
