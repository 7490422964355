import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CalendarConfiguration } from '../../../../../models/configuration/widgets/properties/controls/calendar/calendar-configuration';
import { ValidatableInputControlBase } from '../../input-controls/validatable-input-control-base';
import { SiteSettingsService } from 'src/app/services/deprecated/site-settings.service';
import { NgbCalendar, NgbCalendarIslamicUmalqura, NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbDatepickerI18n, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { HijriCalendar18n } from '../../../../../helpers/hijiri-calendar-I18n';
import { HijriCalendareDateFormatter } from '../../../../../helpers/hijiri-calendar-date-formatter';
import { clear } from 'console';
import { HijriDateHelper } from '../../../../../helpers/date-helper';

@Component({
    selector: 'fw-form-edit-uaq-calendar',
    templateUrl: './form-edit-uaq-calendar.component.html',
    host: { 'class': 'form-field' },
    providers: [
        {
            provide: NgbCalendar,
            useClass: NgbCalendarIslamicUmalqura
        },
        {
            provide: NgbDatepickerI18n,
            useClass: HijriCalendar18n
        },
        {
            provide: NgbDateParserFormatter,
            useClass: HijriCalendareDateFormatter
        }
    ]
})
export class FormEditUAQCalendarComponent extends ValidatableInputControlBase<NgbDate | null> implements OnInit {

    @Input()
    public Calendar: CalendarConfiguration;

    @ViewChild('datePicker') datePicker: NgbInputDatepicker;

    public get dateFormat(): string {
        return this.siteSettingsService.dateFormat;
    }

    constructor(
        protected transloco: TranslocoService,
        private siteSettingsService: SiteSettingsService
    ) {
        super(transloco);
    }    

    ngOnInit(): void {
        if (this.Calendar) {
            this.ConfigureDateTimeValidation(this.Calendar.Validation);
        }
    }

    toggleDatePicker() {

        this.datePicker.toggle();

        if (this.datePicker.isOpen()) {
            const datepicker = document.querySelector('ngb-datepicker');
            if (datepicker) {


                const buttonBarDiv = document.createElement('div');
                buttonBarDiv.id = 'buttonBar';

                const todayButton = document.createElement('button');
                todayButton.textContent = this.transloco.translate('Today');
                todayButton.className = 'today-btn';
                todayButton.addEventListener('click', () => {
                    this.selectToday();
                    this.datePicker.toggle();
                });

                buttonBarDiv.appendChild(todayButton);


                const clearButton = document.createElement('button');
                clearButton.textContent = this.transloco.translate('Clear');
                clearButton.className = 'clear-btn';
                clearButton.addEventListener('click', () => {
                    this.clearDate();
                    this.datePicker.toggle();
                });

                buttonBarDiv.appendChild(clearButton);
                datepicker.appendChild(buttonBarDiv);
            }
        }
    }

    clearDate() {
        this.CustomFormGroup.controls[this.PropertyName].setValue('');
        this.onAcceptChanges();
    }

    selectToday() {
        this.CustomFormGroup.controls[this.PropertyName].setValue(HijriDateHelper.GetToday());
        this.onAcceptChanges();
    }

    public onAcceptChanges() {

        this.editMode = false;
        this.Data = this.CustomFormGroup.controls[this.PropertyName].value;
        this.CustomFormGroup.controls[this.PropertyName].markAsPristine();
        this.AcceptChanges.emit();
        this.CustomFormGroup.controls[this.PropertyName].setValue(this.Data); //update UI immediately
    }
}