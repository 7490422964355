import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { BASE_URL } from '../../helpers/base-url';
import { HttpServiceBase } from '../class-definitions/http-service-base';

@Injectable({
    providedIn: 'root'
})
export class DocumentHttpService {

    constructor(private httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string) {
    }

    public getDocumentBlob(documentId: string, pageId: string): Observable<Blob> {

        let headers = new HttpHeaders({'Accept': 'application/json', 'Content-Type': 'application/json'});
        headers = headers.append(HttpServiceBase.PageIdHeader, pageId);

        return new Observable<Blob>((observer: Subscriber<Blob>) => {
            HttpServiceBase.RequestCount++;

            this.httpClient.get(`${this.baseUrl}api/documents/${documentId}`, {
                responseType: 'blob',
                headers: headers
            },).subscribe(
                (data: Blob) => {
                    observer.next(data);
                    observer.complete();

                    HttpServiceBase.RequestCount--;
                }, error => {
                    observer.error(error);
                    observer.complete();

                    HttpServiceBase.RequestCount--;
                });
        })
    }
}
