import { Injectable } from '@angular/core';
import { ShapeUpdateDTO } from '@landadmin/ng-mapping-library/lib/data-transfer-objects/shape-update-dto';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { ErrorDetails } from 'src/app/models/error-details';
import { HttpFieldModel } from 'src/app/models/field-list-model';
import { ActionHttpService } from 'src/app/services/http/action-http.service';
import { DocumentWidgetState, ShapeWidgetState } from '../../../AppState';
import { DocumentCreateDto } from '../../../data-transfer-objects/document/document-create-dto';
import { DocumentListViewDTO } from '../../../data-transfer-objects/document/document-list-view-dto';
import { DocumentReplaceDto } from '../../../data-transfer-objects/document/document-replace-dto';
import { OrderByDirection } from '../../../enums/comparator-enum';
import { EntityType } from '../../../enums/entity-type';
import { ShapeType } from '../../../enums/shape-type';
import { OrderByModel, PaginationModel } from '../../../models/pagination-models';
import { DocumentService } from '../../../services/deprecated/document.service';
import { ShapeHttpService } from '../../../services/http/shape-http.service';
import {
    addAssetDocumentDone,
    addAssetDocumentError,
    addAssetDocumentRequest,
    getActionsBySearchDone,
    getActionsBySearchRequest,
    getActionsBySearchRequestError,
    getAssetDocumentsDone,
    getAssetDocumentsError,
    getAssetDocumentsRequest,
    getAssetShapeDone,
    getAssetShapeRequest,
    removeAssetDocumentDone,
    removeAssetDocumentError,
    removeAssetDocumentRequest,
    updateAssetDocumentDone,
    updateAssetDocumentError,
    updateAssetDocumentRequest,
    updateAssetShapeDone,
    updateAssetShapeError,
    updateAssetShapeRequest
} from './asset-details-inline.actions';

@Injectable()
export class AssetDetailsPageEffects {
    addDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addAssetDocumentRequest),
            concatMap((action) => {
                const documentViewDTO = action.documentViewDTO;

                const documentCreateDto: DocumentCreateDto = new DocumentCreateDto(
                    documentViewDTO.Id,
                    documentViewDTO.DocumentTypeId,
                    documentViewDTO.SensitivityId,
                    action.parentEntityId,
                    documentViewDTO.DateUploaded,
                    action.documentBlob,
                    documentViewDTO.FileName
                );

                return this.documentService
                    .addDocument(documentCreateDto, action.pageId, action.widgetId)
                    .pipe(
                        map(() => {
                            const documentList: DocumentListViewDTO[] = Object.assign(
                                [],
                                action.documentListViewDTO
                            );

                            const documentDraftWdigetState: DocumentWidgetState = {
                                AddDocumentsError: null,
                                DocumentDrafts: documentList,
                                GetDocumentsError: null,
                                UpdateDocumentsError: null,
                                DocumentAdded: true,
                                DocumentReplaced: false,
                                DocumentRemoved: false
                            };

                            return addAssetDocumentDone({
                                documentDraftWidgetState: documentDraftWdigetState,
                                dataSourceId: action.dataSourceId,
                                widgetId: action.widgetId,
                            });
                        }),
                        catchError((errorDetails: ErrorDetails) => {
                            return of(
                                addAssetDocumentError({
                                    dataSourceId: action.dataSourceId,
                                    widgetId: action.widgetId,
                                    documentDraftWidgetState: {
                                        AddDocumentsError: errorDetails,
                                        DocumentDrafts: null,
                                        GetDocumentsError: null,
                                        UpdateDocumentsError: null,
                                        DocumentAdded: false,
                                        DocumentReplaced: false,
                                        DocumentRemoved: false
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    removeDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(removeAssetDocumentRequest),
            concatMap((action) => {

                return this.documentService
                    .removeDocument(action.parentEntityId, EntityType.Asset, action.documentId, action.pageId, action.widgetId)
                    .pipe(
                        map(() => {

                            return removeAssetDocumentDone({
                                documentId: action.documentId,
                                dataSourceId: action.dataSourceId,
                                widgetId: action.widgetId,
                            });
                        }),
                        catchError((errorDetails: ErrorDetails) => {
                            return of(
                                removeAssetDocumentError({
                                    dataSourceId: action.dataSourceId,
                                    widgetId: action.widgetId,
                                    removeError: errorDetails,
                                    documentId: action.documentId,
                                })
                            );
                        })
                    );
            })
        )
    );

    getActionBySearch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getActionsBySearchRequest),
            mergeMap((action) => {
                const httpFieldModel = new HttpFieldModel();
                httpFieldModel.Fields = new Array<string>();

                action.fields.forEach((field) => {
                    httpFieldModel.Fields.push(field.PropertyName);
                });

                return this.actionHttpService
                    .getActionBySearch(
                        action.paginationModel,
                        action.actionSearchDTO,
                        httpFieldModel
                    )
                    .pipe(
                        map((paginationResultModel) =>
                            getActionsBySearchDone({
                                dataSourceId: action.dataSourceId,
                                paginationResult: paginationResultModel,
                            })
                        ),
                        catchError((errorDetails: ErrorDetails) => {
                            return of(
                                getActionsBySearchRequestError({
                                    dataSourceId: action.dataSourceId,
                                    error: errorDetails,
                                })
                            );
                        })
                    );
            })
        )
    );

    getAssetShape$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAssetShapeRequest),
            switchMap((action) => {

                return this.shapeHttpService.getShape(action.pageId, action.assetId, action.assetTypeId).pipe(
                    map((shapeFromServer) => {
                        const shapeWidgetState: ShapeWidgetState = {
                            ListState: null,
                            GetShapeError: null,
                            UpdateShapeError: null
                        };

                        shapeWidgetState.ListState = [{
                            StateModel: shapeFromServer.NewShape,
                            dataSourceId: ShapeType.Unspecified.toString()
                        }];

                        return getAssetShapeDone({
                            shapeWidgetState: shapeWidgetState,
                        });
                    })
                );
            })
        )
    );

    getDocuments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAssetDocumentsRequest),
            mergeMap((action) => {
                const orderByModels: OrderByModel[] = [];

                const orderByModel: OrderByModel = {
                    Field: 'Uploadeddate',
                    OrderByDirection: OrderByDirection.Ascending,
                };

                orderByModels.push(orderByModel);

                const paginationModel: PaginationModel = {
                    Limit: 100,
                    OrderByList: orderByModels,
                    Page: 1,
                    ShowAll: false
                };

                return this.documentService
                    .getDocumentsByWidgetContextAndSearch(
                        action.entityId,
                        paginationModel,
                        action.pageId,
                        action.widgetId,
                        action.filters
                    )
                    .pipe(
                        map((documents) => {
                            return getAssetDocumentsDone({
                                dataSourceId: action.dataSourceId,
                                widgetId: action.widgetId,
                                documentDraftWidgetState: {
                                    DocumentDrafts: documents.Models,
                                    GetDocumentsError: null,
                                    UpdateDocumentsError: null,
                                    AddDocumentsError: null,
                                    DocumentAdded: false,
                                    DocumentReplaced: false,
                                    DocumentRemoved: false,
                                },
                            });
                        }),
                        catchError((errorDetails: ErrorDetails) => {
                            return of(
                                getAssetDocumentsError({
                                    dataSourceId: action.dataSourceId,
                                    widgetId: action.widgetId,
                                    documentDraftWidgetState: {
                                        DocumentDrafts: null,
                                        GetDocumentsError: errorDetails,
                                        UpdateDocumentsError: null,
                                        AddDocumentsError: null,
                                        DocumentReplaced: false,
                                        DocumentAdded: false,
                                        DocumentRemoved: false,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    updateAssetShape$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateAssetShapeRequest),
            switchMap((action) => {
                let updateShapeDTO: ShapeUpdateDTO;
                // eslint-disable-next-line prefer-const
                updateShapeDTO = { ...updateShapeDTO, ...action.shapeViewDTO };

                return this.shapeHttpService
                    .updateShape(action.pageId, action.assetId, action.assetTypeId, updateShapeDTO)
                    .pipe(
                        map(() => {
                            const shapeWidgetState: ShapeWidgetState = {
                                GetShapeError: null,
                                ListState: [{
                                    StateModel: action.shapeViewDTO,
                                    dataSourceId: ShapeType.Unspecified.toString()
                                }],
                                UpdateShapeError: null
                            };

                            return updateAssetShapeDone({
                                shapeWidgetState: shapeWidgetState,
                            });
                        }),
                        catchError((errorDetails: ErrorDetails) => {
                            return of(
                                updateAssetShapeError({
                                    shapeWidgetState: {
                                        GetShapeError: null,
                                        ListState: null,
                                        UpdateShapeError: errorDetails
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    updateDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateAssetDocumentRequest),
            concatMap((action) => {
                const documentViewDTO = action.documentViewDTO;

                const documentReplaceDto: DocumentReplaceDto = new DocumentReplaceDto(
                    documentViewDTO.Id,
                    documentViewDTO.DocumentTypeId,
                    documentViewDTO.SensitivityId,
                    action.parentEntityId,
                    documentViewDTO.DateUploaded,
                    action.documentBlob,
                    documentViewDTO.FileName
                );

                return this.documentService.replaceDocument(documentReplaceDto).pipe(
                    map(() => {
                        const documentList: DocumentListViewDTO[] = Object.assign(
                            [],
                            action.documentListViewDTO
                        );

                        const documentDraftWdigetState: DocumentWidgetState = {
                            AddDocumentsError: null,
                            DocumentDrafts: documentList,
                            GetDocumentsError: null,
                            UpdateDocumentsError: null,
                            DocumentReplaced: true,
                            DocumentAdded: false,
                            DocumentRemoved: false,
                        };

                        return updateAssetDocumentDone({
                            documentDraftWidgetState: documentDraftWdigetState,
                            dataSourceId: action.dataSourceId,
                            widgetId: action.widgetId,
                        });
                    }),
                    catchError((errorDetails: ErrorDetails) => {
                        return of(
                            updateAssetDocumentError({
                                dataSourceId: action.dataSourceId,
                                widgetId: action.widgetId,
                                documentDraftWidgetState: {
                                    AddDocumentsError: null,
                                    DocumentDrafts: null,
                                    GetDocumentsError: null,
                                    DocumentAdded: false,
                                    DocumentReplaced: false,
                                    DocumentRemoved: false,
                                    UpdateDocumentsError: errorDetails,
                                },
                            })
                        );
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private shapeHttpService: ShapeHttpService,
        private documentService: DocumentService,
        private actionHttpService: ActionHttpService
    ) { }
}
