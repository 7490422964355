import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { DocumentWidgetState, ListState } from '../../AppState';
import { DocumentQueryDefinitionConfigurationViewDTO } from '../../data-transfer-objects/configuration/list-configuration/query-definitions/document-query-definition-configuration-view-dto';
import { DocumentListViewDTO } from '../../data-transfer-objects/document/document-list-view-dto';
import { DocumentViewDTO } from '../../data-transfer-objects/document/document-view-dto';
import { FormEditStyle } from '../../enums/configuration/form-edit-style';
import { AllowedDocumentTypeViewDTO } from '../../models/configuration/allowed-document-type';
import { FacadeValidation } from '../../modules/dynamic/widgets/types/validation/facade-validation';

@Injectable()
export abstract class AbstractDocumentListFacade extends FacadeValidation {
    public abstract FormEditStyle: FormEditStyle;
    public abstract ReadyToLoadSubject: BehaviorSubject<boolean>;
    public abstract _isPageLoaded: boolean;
    public abstract _isReadOnly: boolean;
    public abstract _pageId: string;
    public abstract _parentEntityId: string;
    public _personId: string;

    public abstract AddDocument(documentListViewDTO: DocumentListViewDTO[], documentViewDTO: DocumentViewDTO, documentBlob: File, dataSourceId: string, widgetConfigurationId: string): void;
    public abstract RemoveDocument(documentId: string, dataSourceId: string, widgetConfigurationId: string): void;
    public abstract GetDocuments(): Observable<{ listState: ListState<DocumentWidgetState>[], triggeredDataSourceId: string, widgetConfigurationId: string }>;
    public abstract LoadDocuments(dataSourceId: string, widgetConfigurationId: string, filters: DocumentQueryDefinitionConfigurationViewDTO): void;
    public abstract LoadRequiredDocuments(allowedDocumentTypes: AllowedDocumentTypeViewDTO[], documentListViewDTO: DocumentListViewDTO[]): DocumentListViewDTO[];
    public abstract Reset(): void;
    public abstract UpdateDocument(documentListViewDTO: DocumentListViewDTO[], documentViewDTO: DocumentViewDTO, documentBlob: File, dataSourceId: string, widgetConfigurationId: string): void;

}
