/* eslint-disable */

import {
  Component,
  OnInit,
  OnChanges,
  ElementRef,
  Input,
  forwardRef,
} from '@angular/core';
import { ValidationComponentBase } from '../../classes/validation-component-base';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'portal-password-field',
  templateUrl: './portal-password-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PortalPasswordFieldComponent),
      multi: true,
    },
  ],
  host: {
    class: 'form-field',
  },
})
export class PortalPasswordFieldComponent
  extends ValidationComponentBase
  implements OnInit, ControlValueAccessor, OnChanges
{
  @Input('value') public _value: string;

  @Input('password-togglable')
  public PasswordTogglable: boolean = false;

  public PasswordToggleClass: string = PortalPasswordFieldComponent.ShowClass;
  public PasswordToggleText: string = PortalPasswordFieldComponent.ShowText;

  public PasswordToggle(): void {
    const inputElement: HTMLInputElement = (
      this._elementReference.nativeElement as HTMLElement
    ).querySelector('input');

    if (this.PasswordToggleClass === PortalPasswordFieldComponent.ShowClass) {
      this.PasswordToggleClass = PortalPasswordFieldComponent.HideClass;
      this.PasswordToggleText = PortalPasswordFieldComponent.HideText;

      inputElement.type = 'text';
    } else {
      this.PasswordToggleClass = PortalPasswordFieldComponent.ShowClass;
      this.PasswordToggleText = PortalPasswordFieldComponent.ShowText;

      inputElement.type = 'password';
    }
  }

  constructor(private _elementReference: ElementRef) {
    super(_elementReference);
  }

  ngOnInit() {
    this.SetDefaults();
  }

  public onChange(): void {
    this._propagateChange(this._value);
  }

  public onTouch(): void {
    this._propagateTouch();
  }

  private _propagateChange: any = () => {};
  private _propagateTouch: any = () => {};

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    this._propagateChange(this._value);
  }

  writeValue(obj: any): void {
    this._value = obj;
  }
  registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._propagateTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  private static ShowClass: string = 'pi pi-eye';
  private static HideClass: string = 'pi pi-eye-slash';
  private static ShowText: string = 'Show Password';
  private static HideText: string = 'Hide Password';
}
