/* eslint-disable @angular-eslint/component-selector */
import { Component } from '@angular/core';

@Component({
    selector: 'a[portal-link]',
    templateUrl: './portal-link.component.html',
    host: {
        'href': 'javascript:void(0)'
    }
})
export class PortalLinkComponent {

  constructor() {}
}
