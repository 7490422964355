import { Component, DoCheck, ElementRef, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ToastService } from 'src/app/services/deprecated/toast.service';
import { ActionViewDTO } from '../../../../../data-transfer-objects/action/action-view-dto';
import { ActionDetailsWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/widgets/action-details-widget-configuration-view-dto';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { ConfigurableControlsHelper } from '../../../../../helpers/configurable-controls-helper';
import { AbstractActionDetailsFacade } from '../../../../../facade/abstract/abstract-action-details.facade';
import { PropertyConfiguration } from 'src/app/models/configuration/widgets/properties/property-configuration';
import { HttpStatusCode } from '../../../../../enums/http-status-codes';
import { UIStylingHelper } from '../../../../../helpers/ui-styling-helper';
import { BaseDetailsWidget } from '../base-details-widget';

@Component({
    selector: 'fw-action-details-widget',
    templateUrl: './action-details-widget.component.html',
})
export class ActionDetailsWidgetComponent extends BaseDetailsWidget<ActionViewDTO> implements OnInit, OnDestroy, DoCheck {
    public loaded: boolean = false;

    @Input()
    public AcceptChanges: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public ActionDetailsWidgetConfiguration: ActionDetailsWidgetConfigurationViewDTO;

    @Input()
    public FormEditStyle: FormEditStyle;

    constructor(
        private actionDetailsFacade: AbstractActionDetailsFacade,
        public toastService: ToastService,
        private transloco: TranslocoService,
        public elementRef: ElementRef) {

        super(elementRef);
    }

    ngDoCheck(): void {
        this.actionDetailsFacade.SetValidity(this.dataSourceId, this.FormGroup.valid);
    }

    ngOnInit(): void {

        super.ngOnInit();

        this.actionDetailsFacade.LoadActionDetails();

        const getActionDetailsSubscription = this.actionDetailsFacade.ActionSelector().subscribe((actionWidgetState) => {

            if (actionWidgetState.GetActionError && actionWidgetState.GetActionError.status !== HttpStatusCode.NOT_FOUND) {
                this.toastService.ShowErrorToast(actionWidgetState.GetActionError, [{
                    Message: 'ActionDetails.Form.ActionRetrievedUnsuccessfully', //todo: transloco params here e.g. `ActionDetails.Form.ActionRetrievedUnsuccessfully, ${error.Message}`
                    RouterLink: '',
                    RouterText: '',
                    QueryParameters: null,
                    MessageParameters: null
                }], false);
            }
            else if (actionWidgetState.UpdateActionError && actionWidgetState.UpdateActionError.status !== HttpStatusCode.NOT_FOUND) {
                this.toastService.ShowErrorToast(actionWidgetState.UpdateActionError, [{
                    Message: 'ActionDetails.Form.ActionUpdatedUnsuccessfully', //todo: transloco params here e.g. `ActionDetails.Form.ActionUpdatedUnsuccessfully, ${error.Message}`
                    RouterLink: '',
                    RouterText: '',
                    QueryParameters: null,
                    MessageParameters: null
                }], false);
            }
            else if (actionWidgetState.ActionViewDTO) {

                this.loaded = true;
                this.actionDetailsFacade.PrimaryWidgetCompletedLoading.next(actionWidgetState.ActionViewDTO);

                this.DataModel = actionWidgetState.ActionViewDTO;

                const decisionResultIdPropertyName = 'DecisionResultId';
                const decisionResultIdProperty: PropertyConfiguration = this.ActionDetailsWidgetConfiguration.Properties?.find(property => property.PropertyName == decisionResultIdPropertyName);

                if (decisionResultIdProperty && decisionResultIdProperty.Dropdown) {

                    if (actionWidgetState.ActionViewDTO.TypeId) {
                        decisionResultIdProperty.Dropdown.Filter = actionWidgetState.ActionViewDTO.TypeId;
                    }
                    else {
                        decisionResultIdProperty.Dropdown.Filter = this.actionDetailsFacade._typeId;
                    }
                }

                ConfigurableControlsHelper.PopulateFormControl(this.DataModel, this.ActionDetailsWidgetConfiguration.Properties, this.FormGroup);
            }
        });

        const acceptChangesSubscription = this.AcceptChanges.subscribe(() => {

            const clonedAction = { ...this.DataModel, ...this.FormGroup.value };
            this.DataModel = clonedAction;
            this.convertHijriDatesToGregorian();
            this.actionDetailsFacade.UpdateAction(this.DataModel);
        });


        this.Subscriptions.push(getActionDetailsSubscription);
        this.Subscriptions.push(acceptChangesSubscription);
    }

    public getLayoutMode(): string {
        return UIStylingHelper.getLayoutMode(this.ActionDetailsWidgetConfiguration.LayoutMode)
    }

    public isReadOnly(): boolean {
        return this.isWidgetReadOnly(this.ActionDetailsWidgetConfiguration);
     }
}
