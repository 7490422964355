import { createAction, props } from '@ngrx/store'
import { StructuredDataNodeConfigurationState } from '../../../../AppState';


//LOAD STRUCTURED DATA
export const loadStructuredDataNodesRequest = createAction('[StructuredDataNodes] Load Structured Data Nodes Request',
 props<{
        pageId: string,
        entityTypeId: string
}>());

export const loadStructuredDataNodesDone = createAction('[StructuredDataNodes] Load Structured Data Nodes Done',
    props<{
        entityTypeId: string,
        structuredDataNodeConfigurationState: StructuredDataNodeConfigurationState
    }>());

export const loadStructuredDataNodesError = createAction('[StructuredDataNodes] Load Structured Data Nodes Error',
    props<{
        entityTypeId: string,
        structuredDataNodeConfigurationState: StructuredDataNodeConfigurationState
    }>());

//RESET STATE
export const resetStructuredDataNodes = createAction('[StructuredDataNodes] Reset Structured Data Nodes');
