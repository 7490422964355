import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'fw-number-column-filter',
    templateUrl: './number-column-filter.component.html',
})
export class NumberColumnFilterComponent implements OnInit {

    public inputNumber: number;

    @Input()
    public InputFilter: number;

    @Output()
    public OnFilter: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    public OnClearFilter: EventEmitter<void> = new EventEmitter<void>();

    constructor(public transloco: TranslocoService) { }

    ngOnInit(): void {
        if (this.InputFilter) {
            this.inputNumber = this.InputFilter;
        }
    }

    public ApplyFilter() {
        this.OnFilter.emit(this.inputNumber);
    }

    public ClearFilter() {
        this.inputNumber = null;;
        this.OnClearFilter.emit();
    }
}