import { Component, ComponentFactoryResolver, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { StructuredDataLibraryService, ValueType } from '@landadmin/structured-data';
import { IDisplayMapper } from '@landadmin/structured-data/lib/interfaces/i-display-mapper';
import { IEditOverride } from '@landadmin/structured-data/lib/interfaces/i-edit-override';
import { LookupConfigurationModel } from '@landadmin/structured-data/lib/models/lookup-configuration-model';
import { StructuredDataEntryModel } from '@landadmin/structured-data/lib/models/structured-data-entry-model';
import { StructuredDataModel } from '@landadmin/structured-data/lib/models/structured-data-model';
import { PaginatorPageChange } from '@landadmin/structured-data/lib/models/structured-data-pagination-override';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { StructuredDataLookupFacade } from '../../../../../facade/configuration/structured-data-lookup.facade';
import { StructuredDataMapperHelper } from '../../../../../configuration/structured-data-configuration-mapper-helper';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { GuidHelper } from '../../../../../helpers/guid-helper';
import { ObjectHelper } from '../../../../../helpers/object-helper';
import { StructuredDataHelper } from '../../../../../helpers/structured-data-helper';
import { ToastApplicationService } from '../../../../../services/application/toast-application.service';
import { LanguageHttpService } from '../../../../../services/http/language-http.service';
import { SiteSettingsService } from '../../../../../services/deprecated/site-settings.service';
import { BaseWidget } from '../base-widget';
import { TranslocoService } from '@ngneat/transloco';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogApplicationService } from '../../../../../services/application/dialog-application.service';
import { CustomDialogConfig } from '../../../../../models/custom-dialog-config';
import { Dialog } from '../../../../../data-transfer-objects/configuration/widgets/editing-configuration-view-dto';
import { SessionApplicationService } from '../../../../../services/application/session-application.service';

@Component({
    selector: 'fw-structured-data-widget-dialog',
    templateUrl: './structured-data-widget-dialog.component.html'
})
export class StructuredDataWidgetDialogComponent extends BaseWidget implements OnInit, OnDestroy {
    public addEnabled: boolean = false;
    public editEnabled: boolean = false;
    public deleteEnabled: boolean = false;
    public displayMappers: IDisplayMapper[] = [];
    public displayNextPage: boolean = false;
    public editMode: boolean = true;
    public instanceId: string = null;
    public loaded: boolean = false;
    public lookups: LookupConfigurationModel = null;
    public paginationEnabled: boolean = false;
    public setPageSubject: Subject<number> = new Subject<number>();
    public setTotalRecordsSubject: Subject<number> = new Subject<number>();
    public structuredDataClass: string;
    public structuredDataModel: StructuredDataModel;
    public totalRecords: number = -1;
    private disableAddButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private subscriptions: Subscription[] = [];
    public saveButtonText: string;
    public cancelButtonText: string;

    constructor(
        public _structuredDataLibraryService: StructuredDataLibraryService,
        public _componentFactoryResolver: ComponentFactoryResolver,
        public structuredDataLookupFacade: StructuredDataLookupFacade,
        public elementRef: ElementRef,
        private dynamicDialogReference: DynamicDialogRef,
        private dynamicDialogConfig: DynamicDialogConfig,
        private sessionApplicationService: SessionApplicationService) {
        super(elementRef);
    }

    public Add(structuredDataModel: StructuredDataEntryModel): void {
    }

    public Delete(structuredDataModel: StructuredDataEntryModel): void {
    }

    public Paginate(pageChange: PaginatorPageChange) {
    }

    public Update(structuredDataModel: StructuredDataEntryModel): void {
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach(subscription => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            });
        }
        this._structuredDataLibraryService.DiscardInstance(this.instanceId);

        this.close();

    }

    close() {
        if (this.dynamicDialogReference) {
            this.dynamicDialogReference.close(null);
        }
    }

    proceedClicked() {
        this.dynamicDialogReference.close(this.structuredDataModel.Entries[this.structuredDataModel.Entries.length - 1]);
    }

    ngOnInit(): void {
        this.instanceId = GuidHelper.NewGuid();
        this.setPageSubject = new Subject<number>();
        if (this.dynamicDialogConfig instanceof CustomDialogConfig) {
            const additionalConfigurationData: StructuredDataDialogConfiguration =
                (
                    this.dynamicDialogConfig as CustomDialogConfig<
                        StructuredDataDialogConfiguration>
                ).configuration;
            this.structuredDataModel = additionalConfigurationData.structuredDataModel;
            this.saveButtonText = additionalConfigurationData.dialogConfiguration?.ConfirmResourceId;
            this.cancelButtonText = additionalConfigurationData.dialogConfiguration?.CancelResourceId;
            this.loaded = false;
        }
        this.PopulateStructuredData();

        this.subscriptions.push(this.sessionApplicationService.authenticationChanged.subscribe(
            (isAuthenticated: boolean) => {
                if (!isAuthenticated) {
                    this.close();
                }
            }));
    }  

    private GetEditMappers(): Array<IEditOverride> {
        return StructuredDataMapperHelper.GetStructuredDataEditMappers(this._componentFactoryResolver, FormEditStyle.Form, this.subscriptions);
    }

    public get isEntryEmpty(): boolean {
        return StructuredDataHelper.IsStructuredDataModelEmpty(ObjectHelper.deepCopy(this.structuredDataModel.Entries[0]));
    }

    private PopulateStructuredData(): void {
        this.structuredDataClass = 'structured-data lm-details';
        this.structuredDataClass += ' sd-form-edit';
        if (this._structuredDataLibraryService.IsLookupConfigurationRegistered() === false) {
            this._structuredDataLibraryService.RegisterLookupConfiguration(this.lookups);
        }

        this._structuredDataLibraryService.RegisterInstance(
            this.instanceId,
            this.displayMappers,
            this.GetEditMappers(),
            null,
            StructuredDataMapperHelper.GetAddButtonOverride(this._componentFactoryResolver, this.subscriptions, this.disableAddButtonSubject, ''),
            StructuredDataMapperHelper.GetPaginateOverride(this._componentFactoryResolver,
                this.subscriptions,
                () => 1,
                () => 1,
                () => this.totalRecords,
                this.setPageSubject,
                this.setTotalRecordsSubject),
            false);
        this.loaded = true;
    }
}

export interface StructuredDataDialogConfiguration {
    structuredDataModel: StructuredDataModel;
    dialogConfiguration: Dialog<any>;
}
