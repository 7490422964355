import { Component } from '@angular/core';
import { ControlBaseWrapper } from '../control-wrapper-base';

@Component({
  selector: 'fw-input-number-wrapper',
  templateUrl: './input-number-wrapper.component.html',
})
export class InputNumberWrapperComponent extends ControlBaseWrapper {
  constructor() {
    super();
  }
}
