import { HttpStatusCode } from '../enums/http-status-codes';
import { LandfolioFrameworkApiException } from './landfolio-framework-api-exception';

export class ErrorDetails {
    public title: string;
    public type: string;
    public detail: string;
    public instance: string;
    public exceptionId: string;
    public status: HttpStatusCode;
    public timestamp: Date;
    public errorMessageResourceId: string;
    public landfolioFrameworkApi: LandfolioFrameworkApiException;
}