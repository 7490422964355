<form novalidate [formGroup]="FormGroup" [ngClass]="getLayoutMode()">
    <ng-container>
        <fw-control-wrapper [Properties]="UserDetailsWidgetConfiguration.Properties"
                            [CustomFormGroup]="FormGroup"
                            [AcceptChanges]="AcceptChanges"
                            [AcceptGroupChanges]="AcceptGroupChanges"
                            [DiscardChanges]="DiscardChanges"
                            [FormEditStyle]="FormEditStyle"
                            [class.is-readonly]="isReadOnly()"
                            [IsHijriChanged]="IsHijriChanged"></fw-control-wrapper>
    </ng-container>

</form>