<div>
    <div [ngClass]="{'view-mode': editMode === false, 'edit-mode': editMode === true }" [attr.tabindex]="FormEditStyle === FormEditStyles.ReadOnly ? -1 : 0" (keydown.enter)="onKeyDownEnter($event)" (keydown.escape)="onCancelChanges()">
        <div [formGroup]="CustomFormGroup">
            <div *ngIf="!editMode" (click)="editMode = FormEditStyle !== FormEditStyles.ReadOnly" class="view-fields">
                {{StorePreviousValue()}}
                {{GetDisplayValue()}} {{GetDropDownDisplayValue()}}
                <button tabindex="-1" class="pi pi-pencil"></button>
            </div>
            <div class="edit-fields" *ngIf="editMode">
                <p-inputNumber [formControlName]="unitValue" [showButtons]="true">
                </p-inputNumber>
                <p-dropdown [formControlName]="unitDropDownValueId"
                            [options]="Options"
                            optionLabel="Value"
                            optionValue="Id"
                            placeholder="">
                </p-dropdown>
                <div class="buttons">
                    <button class="pi pi-check" *ngIf="editMode" (click)="onAcceptChanges()"></button>
                    <button class="pi pi-times" *ngIf="editMode" (click)="onCancelChanges()"></button>
                </div>
            </div>
        </div>
    </div>
</div>
