import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../AppState';
import { Observable } from 'rxjs';
import { getWidgetStateCache } from '../../state/widgets.selectors';
import { map } from 'rxjs/operators';
import { resetStateCache, setStateCache } from '../../state/state-cache/state-cache.actions';

@Injectable({
    providedIn: 'root'
})
export class StateCacheFacade {
    constructor(private store: Store<AppState>) {

    }

    public GetCache<T>(key: string): Observable<any> {
        return this.store.pipe(
            select(getWidgetStateCache),
            map(widgetStateCache => {
                if (widgetStateCache[key]) {
                    return widgetStateCache[key] as T;
                } else {
                    return null;
                }
            }));
    }

    public SetCache<T>(key: string, data: T): void {
        this.store.dispatch(setStateCache({key: key, data: data}));
    }

    public ResetCache(): void {
        this.store.dispatch(resetStateCache());
    }
}