import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { BasicBehaviourContextModel } from 'src/app/models/behaviours/context-models/basic/basic-behaviour-context-model';
import { ErrorDetails } from 'src/app/models/error-details';
import { BehaviourContextModel } from '../../../models/behaviours/context-models/task/task-behaviour-context-model';
import { HttpFieldModel } from '../../../models/field-list-model';
import { ActionHttpService } from '../../../services/http/action-http.service';
import { AssetHttpService } from '../../../services/http/asset-http.service';
import { BasicHttpService } from '../../../services/http/basic-http';
import { UserHttpService } from '../../../services/http/user-http.service';
import {
    executeBasicBehaviourDone, executeBasicBehaviourRequest, executeListBehaviourDone, executeListBehaviourRequest, getBasicActionsBySearchDone, getBasicActionsBySearchRequest, getBasicActionsBySearchRequestError, getBasicAssetsBySearchDone, getBasicAssetsBySearchRequest, getBasicAssetsBySearchRequestError, getBasicUsersBySearchDone, getBasicUsersBySearchRequest, getBasicUsersBySearchRequestError
} from './basic-page.actions';

@Injectable()
export class BasicPageEffects {

    executeBasicBehaviourRequest$ = createEffect(() => this.actions$.pipe(
        ofType(executeBasicBehaviourRequest),
        switchMap((action) => {

            const basicBehaviourContext: BehaviourContextModel = {
                TaskContext: {
                    TaskId: action.taskId,
                    PageId: action.pageId,
                    ClientId: action.clientId
                }
            };

            return this.basicHttpService.executeBasicBehaviour(basicBehaviourContext, action.widgetId, action.pageId).pipe(
                map(() => {
                    return executeBasicBehaviourDone();
                })
            );
        })
    ));

    executeListBehaviourRequest$ = createEffect(() => this.actions$.pipe(
        ofType(executeListBehaviourRequest),
        switchMap((action) => {

            const basicBehaviourContext: BehaviourContextModel = {
                AssetId: action.listWidgetAssetId,
                ActionId: action.listWidgetActionId,
                UserId: action.listWidgetUserId,
                ListContext: {

                    ClientId: action.clientId,
                    PageId: action.pageId,
                    PropertyName: action.propertyName,
                    WidgetId: action.widgetId
                }
            };

            return this.basicHttpService.executeBasicBehaviour(basicBehaviourContext, action.widgetId, action.pageId).pipe(
                map(() => {
                    return executeListBehaviourDone();
                })
            );
        })
    ));

    getActionBySearch$ = createEffect(() => this.actions$.pipe(
        ofType(getBasicActionsBySearchRequest),
        mergeMap((action) => {
            const httpFieldModel = new HttpFieldModel();
            httpFieldModel.Fields = new Array<string>();

            action.fields.forEach(field => {
                httpFieldModel.Fields.push(field.PropertyName);
            })

            return this.actionHttpService.getActionBySearch(action.paginationModel, action.actionSearchDTO, httpFieldModel).pipe(

                map(paginationResultModel => getBasicActionsBySearchDone({
                    dataSourceId: action.dataSourceId,
                    paginationResult: paginationResultModel
                })),
                catchError((errorDetails: ErrorDetails) => {
                    return of(getBasicActionsBySearchRequestError({
                        dataSourceId: action.dataSourceId,
                        error: errorDetails
                    }))
                })
            );
        })
    ));

    getAssetBySearch$ = createEffect(() => this.actions$.pipe(
        ofType(getBasicAssetsBySearchRequest),
        mergeMap((action) => {
            const httpFieldModel = new HttpFieldModel();
            httpFieldModel.Fields = new Array<string>();

            action.fields.forEach(field => {
                httpFieldModel.Fields.push(field.PropertyName);
            })

            return this.assetHttpService.getAssetBySearch(action.paginationModel, action.assetSearchDTO, httpFieldModel).pipe(
                map(paginationResultModel => getBasicAssetsBySearchDone({
                    dataSourceId: action.dataSourceId,
                    paginationResult: paginationResultModel
                })),
                catchError((errorDetails: ErrorDetails) => {
                    return of(getBasicAssetsBySearchRequestError({
                        dataSourceId: action.dataSourceId,
                        error: errorDetails
                    }))
                })
            );
        })
    ));

    getUserBySearch$ = createEffect(() => this.actions$.pipe(
        ofType(getBasicUsersBySearchRequest),
        mergeMap((action) => {

            //TODO: to be removed as part of LFF-1320
            return this.userHttpService.userSearch(action.userSearchDTO, action.paginationModel, action.pageId, action.widgetId).pipe(

                map(paginationResultModel => getBasicUsersBySearchDone({
                    dataSourceId: action.dataSourceId,
                    paginationResult: paginationResultModel
                })),
                catchError((errorDetails: ErrorDetails) => {
                    return of(getBasicUsersBySearchRequestError({
                        dataSourceId: action.dataSourceId,
                        error: errorDetails
                    }))
                })
            );
        })
    ));

    constructor(private actions$: Actions,
        public assetHttpService: AssetHttpService,
        public basicHttpService: BasicHttpService,
        public actionHttpService: ActionHttpService,
        public userHttpService: UserHttpService) {

    }
}
