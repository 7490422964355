<div>
    <div (focusout)="onFocusOut($event)" [ngClass]="{'view-mode': editMode === false, 'edit-mode': editMode === true }" [attr.tabindex]="FormEditStyle === FormEditStyles.ReadOnly ? -1 : 0" (keydown.enter)="onKeyDownEnter($event)" (keydown.escape)="onCancelChanges()">
        <ng-template [ngIf]="ShowLabel()">
            <label [ngClass]="GetLabelStylingClass()">
                {{Label | transloco}}
                <span *ngIf="Calendar.Validation.Required && editMode">*</span>
            </label>
        </ng-template>
        <div [formGroup]="CustomFormGroup">
            <div *ngIf="!editMode" (click)="editMode = FormEditStyle !== FormEditStyles.ReadOnly" class="view-fields" [ngClass]="GetValueStylingClass()">
                {{displayValue() }}{{StorePreviousValue()}}<button tabindex="-1" class="pi pi-pencil"></button>
            </div>
            <div class="edit-fields" *ngIf="editMode">
                <span class="uaq-calendar">
                    <input readonly class="p-inputtext" [placeholder]="dateFormat" (click)="toggleDatePicker()"
                           name="dp" [formControlName]="PropertyName" ngbDatepicker #datePicker="ngbDatepicker">
                    <button class="p-button p-button-icon-only" (click)="toggleDatePicker()" type="button">
                        <span class="p-button-icon pi pi-calendar"></span>
                    </button>
                </span>
                <div class="buttons">
                    <button class="pi pi-check" [disabled]="IsAcceptDisabled()" *ngIf="editMode" (click)="onAcceptChanges()"></button>
                    <button class="pi pi-times" *ngIf="editMode" (click)="onCancelChanges()"></button>
                </div>
                <fw-general-validator [PropertyName]="PropertyName"
                                      [(CustomFormGroup)]="CustomFormGroup"
                                      [(ValidationDictionary)]="ValidationDictionary"></fw-general-validator>
            </div>
        </div>
    </div>
</div>
