import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserRoleWidgetState } from '../../../../../AppState';
import { UserRolesWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/user-roles-widget-configuration-view-dto';
import { RoleViewDTO } from '../../../../../data-transfer-objects/role/role-view-dto';
import { UserRoleUpdateDTO } from '../../../../../data-transfer-objects/role/user-role-update-dto';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { LookupModel } from '../../../../../models';
import { AbstractUserRolesFacade } from '../../../../../facade/abstract/abstract-user-roles.facade';

@Component({
    selector: 'fw-user-roles-widget',
    templateUrl: './user-roles-widget.component.html',
})
export class UserRolesWidgetComponent implements OnInit, OnDestroy {

    public AcceptChanges: EventEmitter<void> = new EventEmitter<void>();

    public CurrentUserRoles: LookupModel[] = [];
    public FormEditStyles = FormEditStyle;
    public FormGroup: FormGroup;
    @Input()
    public FormEditStyle: FormEditStyle;

    @Input()
    UserRolesWidgetConfigurationViewDTO: UserRolesWidgetConfigurationViewDTO;



    private readyToStorePreviousValue: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private PropertyName: string = 'UserRoles';
    private _subcriptions: Subscription[] = [];

    constructor(protected userRolesFacade: AbstractUserRolesFacade) { }

    ngOnDestroy(): void {
        if (this._subcriptions) {
            this._subcriptions.forEach((subcription) => {
                if (subcription) {
                    subcription.unsubscribe();
                }
            });
        }
        this.userRolesFacade.ResetState();
    }
    ngOnInit(): void {

        this.FormGroup = new FormGroup({});

        this._subcriptions.push(this.userRolesFacade.ReadyToLoadSubject.subscribe((ready) => {

            if (ready) {
                this.userRolesFacade.LoadRolesForUser();
            }
        }));

        this._subcriptions.push(this.userRolesFacade.UserRolesSelector().subscribe((userRolesWidgetState: UserRoleWidgetState) => {

            if (userRolesWidgetState.Roles) {
                this.CurrentUserRoles = userRolesWidgetState.Roles.map(r => { return { CommonlyUsed: false, Id: r.Id, Value: null } });
                this.initialiseFormControl();
            }
        }));

        this._subcriptions.push(this.AcceptChanges.subscribe(() => {

            const userRoleIds: string[] = this.FormGroup.value[this.PropertyName];

            const roleUpdateDTO: UserRoleUpdateDTO = { RoleIds: userRoleIds, UserId: this.userRolesFacade._userId };

            this.userRolesFacade.UpdateUserRoles(roleUpdateDTO);
        }));
    }

    private initialiseFormControl(): void {

        const data = this.CurrentUserRoles.map((option) => option.Id);

        const abstractControl = this.FormGroup.controls[this.PropertyName];
        if (!abstractControl) {
            this.FormGroup.addControl(this.PropertyName, new FormControl(data));
            this.readyToStorePreviousValue.next(true);
        } else {
            abstractControl.setValue(data);
            this.readyToStorePreviousValue.next(true);
        }
    }
}
