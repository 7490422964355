import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActionListWidgetStateModel, ListState } from '../../../../../../AppState';
import { ActionListViewDTO } from '../../../../../../data-transfer-objects/action/action-list-view-dto';
import { ActionQueryDefinitionSearchViewDTO } from '../../../../../../data-transfer-objects/configuration/list-configuration/query-definitions/action-query-definition-search-view-dto';
import { ActionListWidgetConfigurationViewDTO } from '../../../../../../data-transfer-objects/configuration/widgets/action-list-widget-configuration-view-dto';
import { PaginationResultModel } from '../../../../../../models/pagination-models';
import { ToastService } from '../../../../../../services/deprecated/toast.service';
import { IListWidget, NavigateWrapper, OnLoadWrapper } from '../list-widget-interface';
import { AbstractActionListFacade } from '../../../../../../facade/abstract/abstract-action-list.facade';
import { GuidHelper } from '../../../../../../helpers/guid-helper';
import { HttpStatusCode } from '../../../../../../enums/http-status-codes';
import { FilterMetadataHelper } from '../../../../../../helpers/filter-metadata-helper';

@Component({
    selector: 'fw-action-list-widget',
    templateUrl: './action-list-widget.component.html'
})
export class ActionListWidgetComponent implements OnInit, OnDestroy, IListWidget<ActionListViewDTO> {
    public PaginationResultModel: PaginationResultModel<ActionListViewDTO>;
    public DataSourceId: string;
    public errorMessageResourceId: string = null;

    @Input()
    public ActionListWidgetConfigurationViewDTO: ActionListWidgetConfigurationViewDTO;

    private GetActionListSubscription: Subscription;

    constructor(
        private abstractActionListFacade: AbstractActionListFacade,
        private toastService: ToastService
    ) { }

    public NavigateByContext(navigateWrapper: NavigateWrapper): void {

        this.abstractActionListFacade.ExecuteBehaviour(navigateWrapper.fieldConfiguration.PropertyName, this.ActionListWidgetConfigurationViewDTO.Id, navigateWrapper.model.AssetId, navigateWrapper.model.Id);
    }

    public OnLoad(onLoadWrapper: OnLoadWrapper): void {
        const actionQueryDefinitionSearchViewDTO: ActionQueryDefinitionSearchViewDTO = {
            SavedSearchId: this.ActionListWidgetConfigurationViewDTO.ActionQueryDefinition.SavedSearchId,
            Assets: this.ActionListWidgetConfigurationViewDTO.ActionQueryDefinition.Assets
        };

        this.abstractActionListFacade.PageAsset = this.ActionListWidgetConfigurationViewDTO.ActionQueryDefinition.PageAsset;

        this.abstractActionListFacade.LoadListItems(
            this.ActionListWidgetConfigurationViewDTO.Fields,
            onLoadWrapper.paginationModel,
            onLoadWrapper.searchModel,
            actionQueryDefinitionSearchViewDTO,
            this.DataSourceId,
            FilterMetadataHelper.GetParsedFilters(onLoadWrapper.event.filters));
    }

    ngOnDestroy() {
        this.GetActionListSubscription.unsubscribe();
    }

    ngOnInit(): void {

        this.DataSourceId = GuidHelper.NewGuid();

        this.GetActionListSubscription = this.abstractActionListFacade.GetListItems().subscribe((actionListWidget: ListState<ActionListWidgetStateModel>[]) => {

            if (!actionListWidget || actionListWidget.length == 0) {
                return;
            }

            const stateEntry = actionListWidget.find((alw) => alw.dataSourceId == this.DataSourceId);

            if (stateEntry) {

                if (stateEntry.StateModel.error && stateEntry.StateModel.error.status !== HttpStatusCode.NOT_FOUND) {

                    this.errorMessageResourceId = 'Lists.GenericGetError';
                    this.toastService.ShowErrorToast(stateEntry.StateModel.error, [{
                        Message: this.errorMessageResourceId,
                        RouterLink: null,
                        RouterText: null,
                        QueryParameters: null,
                        MessageParameters: null
                    }], true);

                    this.PaginationResultModel = stateEntry.StateModel.paginationResult;

                } else {
                    this.errorMessageResourceId = null;
                    this.PaginationResultModel = stateEntry.StateModel.paginationResult;
                }
            }
        });
    }
}
