<fw-list (OnLoad)="OnLoad($event)"
         (RoutingFieldClicked)="NavigateByContext($event)"      
         (SelectableFieldClicked)="itemSelected($event)"
         (ClearSelectedItems)="clearSelectedItems()"
         [ListConfigurationViewDTO]="AssetListWidgetConfigurationViewDTO"
         [ErrorMessageResourceId]="errorMessageResourceId"
         [PaginationResultModel]="paginationResultModelCopy"
         [IsUserList]="false"         
         [AdditionalColumnsBodyTemplate]="CustomBodyTemplate"
         [selectMode]="selectMode"
         [IsSelectable]="true"
         [showOptionItems]="showOptionItems">
</fw-list>

<ng-template #CustomBodyTemplate let-data>    
    <p-dropdown *ngIf="showOptionItems" [options]="relationshipTypes" optionLabel="Value" optionValue="Id" fwAutoFocus [(ngModel)]="data.SelectedRelationshipTypeId" appendTo="body" (onChange)="onChange(data['Id'], data['SelectedRelationshipTypeId'])" >
    </p-dropdown>
</ng-template>

<div class="p-dialog-footer justify-content-end">
    <p-button styleClass="btn btn-lg" type="button" (onClick)="cancelClicked()" label="{{'SelectDocumentType.Form.Cancel' | transloco }}"></p-button>
    <p-button [disabled]="!canProceed" styleClass="btn btn-primary btn-lg" type="button" (onClick)="proceedClicked()" label="{{'SelectDocumentType.Form.Proceed' | transloco }}"></p-button>
</div>

