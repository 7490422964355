import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ListState, MapReferenceListWidgetState } from 'src/app/AppState';
import { MapReferenceListWidgetConfigurationViewDTO } from 'src/app/data-transfer-objects/configuration/widgets/map-reference-list-widget-configuration-view-dto';
import { MapReferenceViewDTO } from 'src/app/data-transfer-objects/map-reference/map-reference-view-dto';
import { StyleVariants } from 'src/app/enums/bootstrap/style-variants';
import { HttpStatusCode } from 'src/app/enums/http-status-codes';
import { AbstractMapReferenceListFacade } from 'src/app/facade/abstract/abstract-map-reference-list.facade';
import { GuidHelper } from 'src/app/helpers/guid-helper';
import { PaginationResultModel } from 'src/app/models/pagination-models';
import { ToastService } from 'src/app/services/deprecated/toast.service';
import { IListWidget, OnLoadWrapper } from '../list-widget-interface';

@Component({
    selector: 'fw-map-reference-list-widget',
    templateUrl: './map-reference-list-widget.component.html'
})
export class MapReferenceListWidgetComponent implements OnInit, OnDestroy, IListWidget<MapReferenceViewDTO> {

    public PaginationResultModel: PaginationResultModel<MapReferenceViewDTO>;
    public DataSourceId: string;
    public ErrorMessageResourceId: string = '';

    @Input()
    public MapReferenceListWidgetConfigurationViewDTO: MapReferenceListWidgetConfigurationViewDTO;

    private subcriptions: Subscription[] = [];
    private onLoadWrapper: OnLoadWrapper;
    private stateEntry: ListState<MapReferenceListWidgetState>;

    constructor(
        private toastService: ToastService,
        private router: Router,
        private abstractMapReferenceListFacade: AbstractMapReferenceListFacade
    ) { }

    public OnLoad(onLoadWrapper: OnLoadWrapper): void {
        this.onLoadWrapper = onLoadWrapper;
        this.abstractMapReferenceListFacade.WidgetId = this.MapReferenceListWidgetConfigurationViewDTO.Id;
        this.abstractMapReferenceListFacade.LoadListItems(this.MapReferenceListWidgetConfigurationViewDTO.Fields, onLoadWrapper.paginationModel, onLoadWrapper.searchModel, this.MapReferenceListWidgetConfigurationViewDTO.Filters, this.DataSourceId);
    }

    ngOnDestroy(): void {
        if (this.subcriptions) {
            this.subcriptions.forEach((subcription) => {
                if (subcription) {
                    subcription.unsubscribe();
                }
            });
        }
    }

    ngOnInit(): void {

        this.DataSourceId = GuidHelper.NewGuid();

        const getMapReferenceListSubscription = this.abstractMapReferenceListFacade.GetListItems().subscribe((MapReferenceListWidget: ListState<MapReferenceListWidgetState>[]) => {

            if (!MapReferenceListWidget || MapReferenceListWidget.length == 0) {
                return;
            }

            this.stateEntry = MapReferenceListWidget.find((listState) => listState.dataSourceId == this.DataSourceId);

            if (this.stateEntry) {

                if (this.stateEntry.StateModel.GetError && this.stateEntry.StateModel.GetError.status !== HttpStatusCode.NOT_FOUND) {

                    this.ErrorMessageResourceId = 'Lists.GenericGetError';
                    this.toastService.ShowToast([{
                        Message: this.ErrorMessageResourceId,
                        RouterLink: null,
                        RouterText: null,
                        QueryParameters: null,
                        MessageParameters: null
                    }], StyleVariants.Danger, true);

                    this.PaginationResultModel = this.stateEntry.StateModel.paginationResult;

                } else {

                    this.ErrorMessageResourceId = null;
                    this.PaginationResultModel = this.stateEntry.StateModel.paginationResult;

                }
            }
        });

        this.subcriptions.push(getMapReferenceListSubscription);
    }

}
