import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SystemSettingViewDTO } from '../../data-transfer-objects/system-setting/system-setting-view-dto';
import { SystemSetting } from '../../enums/system-setting-enum';
import { BASE_URL } from '../../helpers/base-url';

import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from './error-manager.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class SystemSettingsService extends AuthenticatedHttpServiceBase  {

    constructor(httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, errorManager: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorManager, toastService);
    }

    public GetById(systemSettingId: SystemSetting): Observable<SystemSettingViewDTO> {
        return this.GetAuthenticated<SystemSettingViewDTO>(`${this.baseUrl}api/SystemSetting/${systemSettingId}`, this.GetStandardHeaders());
    }
}
