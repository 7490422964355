import { createAction, props } from '@ngrx/store'
import { DocumentWidgetState, UserDetailsWidgetState } from '../../../AppState';
import { DocumentQueryDefinitionConfigurationViewDTO } from '../../../data-transfer-objects/configuration/list-configuration/query-definitions/document-query-definition-configuration-view-dto';
import { DocumentListViewDTO } from '../../../data-transfer-objects/document/document-list-view-dto';
import { DocumentViewDTO } from '../../../data-transfer-objects/document/document-view-dto';
import { UserPersonViewDTO } from '../../../data-transfer-objects/user/user-person-view-dto';
import { ErrorDetails } from '../../../models/error-details';

//User Details
export const getUserDetailsRequest = createAction('[UserDetailsInline] Get User Details Request', props<{ userId: string }>());
export const getUserDetailsDone = createAction('[UserDetailsInline] Get User Details Done', props<{ userDetailsWidgetState: UserDetailsWidgetState }>());
export const getUserDetailsError = createAction('[UserDetailsInline] Get User Details Error', props<{ userDetailsWidgetState: UserDetailsWidgetState }>());

export const updateUserDetailsRequest = createAction('[UserDetailsInline] Update User Details Request', props<{ userDetails: UserPersonViewDTO, userId: string }>());
export const updateAndGetUserDetailsRequest = createAction('[UserDetailsInline] Update And Get User Details Request', props<{ userDetails: UserPersonViewDTO, userId: string }>());
export const updateUserDetailsDone = createAction('[UserDetailsInline] Update User Details Done', props<{ userDetailsWidgetState: UserDetailsWidgetState }>());
export const updateUserDetailsError = createAction('[UserDetailsInline] Update User Details Error', props<{ userDetailsWidgetState: UserDetailsWidgetState }>());

export const getUserDetailsResetState = createAction('[UserDetailsInline] User Details Reset State');

//Documents
export const updateDocumentDone = createAction('[UserDetailsInline] Update Document Done', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());
export const updateDocumentRequest = createAction('[UserDetailsInline] Update Document Request', props<{ documentListViewDTO: DocumentListViewDTO[], documentViewDTO: DocumentViewDTO, parentEntityId: string, documentBlob: File, dataSourceId: string, widgetId: string }>());
export const updateDocumentError = createAction('[UserDetailsInline] Update Document Error', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());

export const addDocumentDone = createAction('[UserDetailsInline] Add Document Done', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());
export const addDocumentRequest = createAction('[UserDetailsInline] Add Document Request', props<{ documentListViewDTO: DocumentListViewDTO[], documentViewDTO: DocumentViewDTO, parentEntityId: string, documentBlob: File, pageId: string, widgetId: string, dataSourceId: string }>());
export const addDocumentError = createAction('[UserDetailsInline] Add Document Error', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());

export const getDocumentsRequest = createAction('[UserDetailsInline] Get Document Request', props<{ entityId: string, pageId: string, widgetId: string, dataSourceId: string, filters: DocumentQueryDefinitionConfigurationViewDTO }>());
export const getDocumentsDone = createAction('[UserDetailsInline] Get Document Done', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());
export const getDocumentsError = createAction('[UserDetailsInline] Get Document Error', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());

export const removeUserDocumentDone = createAction('[UserDetailsInline] Remove User Document Done', props<{ documentId: string, dataSourceId: string, widgetId: string }>());
export const removeUserDocumentRequest = createAction('[UserDetailsInline] Remove User Document Request', props<{ documentId: string, parentEntityId: string, pageId: string, widgetId: string, dataSourceId: string }>());
export const removeUserDocumentError = createAction('[UserDetailsInline] Remove User Document Error', props<{
    removeError: ErrorDetails,
    documentId: string, dataSourceId: string,
    widgetId: string
}>());

export const getUserDocumentsResetState = createAction('[UserDetailsInline] User Documents Reset State');
