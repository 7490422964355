import { Injectable, Inject } from '@angular/core';
import { Observable, of, Subscriber } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BASE_URL } from 'src/app/helpers/base-url';
import { SetPasswordModel, ResetPasswordModel } from 'src/app/models/set-password-model';
import { ClientUserModel } from 'src/app/models/client-user-model';
import { HttpStatusCode } from 'src/app/enums/http-status-codes';
import { PaginationModel, PaginationResultModel } from 'src/app/models/pagination-models';
import { RegisterUserDto } from 'src/app/data-transfer-objects/users/register-user-dto';
import { ToastNotificationSetting } from 'src/app/models/toast-notfication-specfication';
import { StyleVariants } from 'src/app/enums/bootstrap/style-variants';
import { CurrentUserRepresentativeUpdateDTO } from 'src/app/data-transfer-objects/users/current-user-representative-update-dto';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';
import { UpdateEmailModel } from '../../models/update-email-model';
import { UserViewDTO } from '../../data-transfer-objects/user/user-view-dto';
import { UserPersonViewDTO } from '../../data-transfer-objects/user/user-person-view-dto';
import { RoleModel } from '../../models/role-model';
import { SearchModel } from 'src/app/models/search-model';
import { UserListViewDTO } from 'src/app/data-transfer-objects/users/user-list-view-dto';
import { UserUpdateDTO } from '../../data-transfer-objects/user/user-update-dto';
import { RepresentativeOfListViewDTO } from '../../data-transfer-objects/representative/representative-list-view-dto';
import { UserRepresentationListWidetConfigurationViewDTO } from '../../data-transfer-objects/configuration/user-representation-list-widget-configuration-view-dto';
import { UserIdPViewDTO } from '../../data-transfer-objects/user/user-idp-view-dto';
import { BearerTokenViewDTO } from "../../data-transfer-objects/authentication/bearer-token-view-dto";

@Injectable({
    providedIn: 'root'
})
export class UserHttpService extends AuthenticatedHttpServiceBase {

    constructor(httpClient: HttpClient, @Inject(BASE_URL) private _baseUrl: string, errorManager: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorManager, toastService);
    }

    public ForgotPassword(email: string): Observable<null> {
        return this.Post<null>(`${this._baseUrl}api/users/forgotpassword/${email}`, null, this.GetStandardHeaders());
    }

    public GetCurrentUser(): Observable<ClientUserModel> {
        return this.GetAuthenticated<ClientUserModel>(`${this._baseUrl}api/Users/Current`, this.GetStandardHeaders(), [], null, [HttpStatusCode.UNAUTHORIZED], null);
    }

    public GetCurrentUserRoles(): Observable<RoleModel[]> {
        return this.GetAuthenticated<RoleModel[]>(`${this._baseUrl}api/Roles/Current`, this.GetStandardHeaders());
    }

    public Logout(): Observable<null> {
        return this.PostAuthenticated<null>(`${this._baseUrl}api/authentication/logout`, null, this.GetStandardHeaders());
    }

    public RefreshToken(): Observable<string> {
        return this.PostAuthenticated<string>(`${this._baseUrl}api/authentication/refreshToken`, null, this.GetStandardHeaders(), [], null, [HttpStatusCode.UNAUTHORIZED]);
    }

    public ResendConfirmationEmail(userId: string): Observable<null> {
        return this.PostAuthenticated<null>(`${this._baseUrl}api/users/ResendSetPasswordEmail/${userId}`, null, this.GetStandardHeaders(), [], null, null);
    }

    public ResetPassword(resetPasswordModel: ResetPasswordModel): Observable<null> {
        return this.PostAuthenticated<null>(`${this._baseUrl}api/users/ResetPassword`, resetPasswordModel, this.GetStandardHeaders(), [], null, [HttpStatusCode.CONFLICT]);
    }

    public SendEmail(userId: string, newEmail: string): Observable<null> {
        return this.PostAuthenticated<null>(`${this._baseUrl}api/users/${userId}/sendAuthenticationEmail/${newEmail}`, null, this.GetStandardHeaders(), [], null);
    }

    public SetPassword(setPasswordModel: SetPasswordModel): Observable<null> {
        return this.Post<null>(`${this._baseUrl}api/users/CreatePassword`, setPasswordModel, this.GetStandardHeaders(), [], null, [HttpStatusCode.UNAUTHORIZED]);
    }

    public UpdateEmail(updateEmailModel: UpdateEmailModel): Observable<null> {
        return this.Put<null>(`${this._baseUrl}api/users/updateEmail`, updateEmailModel, this.GetStandardHeaders());
    }

    public UpdateRepresentation(roleId: string, representationId: string): Observable<null> {
        let path = '';

        if (representationId == null) {
            path = `${this._baseUrl}api/users/representation?roleId=${roleId}`
        }
        else {
            path = `${this._baseUrl}api/users/representation?roleId=${roleId}&representeeId=${representationId}`
        }

        return this.PutAuthenticated<null>(path, null, this.GetStandardHeaders());

    }

    public add(clientUserModel: ClientUserModel): Observable<null> {
        return this.PostAuthenticated<null>(`${this._baseUrl}api/users`, clientUserModel, this.GetStandardHeaders(), [], null, [HttpStatusCode.BAD_REQUEST, HttpStatusCode.INTERNAL_SERVER_ERROR, HttpStatusCode.NOT_FOUND]);
    }

    public getLoggedInUser(): Observable<UserViewDTO> {
        return this.GetAuthenticated<UserViewDTO>(`${this._baseUrl}api/Users/loggedInUser`, this.GetStandardHeaders(), [], null, [HttpStatusCode.UNAUTHORIZED], null);
    }

    public getPeopleIds(): Observable<string[]> {
        return this.GetAuthenticated<string[]>(`${this._baseUrl}api/users/peopleIds`, this.GetStandardHeaders());
    }

    public getRepresentations(userRepresenatativeListWidgetConfigurationViewDTO: UserRepresentationListWidetConfigurationViewDTO): Observable<PaginationResultModel<RepresentativeOfListViewDTO>> {
        const headers: HttpHeaders = this.AddWidgetContextHeader(this.AddWidgetContextHeader(this.GetStandardHeaders(), userRepresenatativeListWidgetConfigurationViewDTO.WidgetId), userRepresenatativeListWidgetConfigurationViewDTO.PageId);
        return this.PostAuthenticated<PaginationResultModel<RepresentativeOfListViewDTO>>(`${this._baseUrl}api/users/userRepresentation`, userRepresenatativeListWidgetConfigurationViewDTO, headers);
    }

    public getUser(id: string): Observable<ClientUserModel> {
        return this.GetAuthenticated<ClientUserModel>(`${this._baseUrl}api/users/client/${id}`, this.GetStandardHeaders(), []);
    }

    public getUserDetails(userId: string): Observable<UserPersonViewDTO> {
        const notify: ToastNotificationSetting[] = [
            new ToastNotificationSetting(HttpStatusCode.BAD_REQUEST, StyleVariants.Danger, null)
        ];

        return this.GetAuthenticated<UserPersonViewDTO>(`${this._baseUrl}api/users/${userId}`, this.GetStandardHeaders(), notify, null, [HttpStatusCode.BAD_REQUEST], null);
    }

    public getUsers(paginationModel: PaginationModel, context: string): Observable<PaginationResultModel<ClientUserModel>> {

        return this.GetAuthenticated<PaginationResultModel<ClientUserModel>>(`${this._baseUrl}api/users`, this.AddSearchAuthorizedContextHeader(this.GetStandardHeaders(), context), [], paginationModel);
    }

    public register(registerUserDto: RegisterUserDto): Observable<string> {
        const notify: ToastNotificationSetting[] = [
            new ToastNotificationSetting(HttpStatusCode.OK, StyleVariants.Success, null),
            new ToastNotificationSetting(HttpStatusCode.BAD_REQUEST, StyleVariants.Danger, null)
        ];
        return this.Post<string>(`${this._baseUrl}api/users/register`, registerUserDto, this.GetStandardHeaders(), notify, null, [HttpStatusCode.BAD_REQUEST,HttpStatusCode.NOT_FOUND]);
    }

    public updateRepresentation(currentUserRepresentativeUpdateDTO: CurrentUserRepresentativeUpdateDTO): Observable<BearerTokenViewDTO> {
        return this.PutAuthenticated<BearerTokenViewDTO>(`${this._baseUrl}api/users/personRepresentation`, currentUserRepresentativeUpdateDTO, this.GetStandardHeaders());
    }

    // Update user details service
    public updateUser(userUpdateDTO: UserUpdateDTO): Observable<null> {
        return this.PutAuthenticated<null>(`${this._baseUrl}api/users/updateUser`, userUpdateDTO, this.GetStandardHeaders(), [], [HttpStatusCode.BAD_REQUEST]);
    }

    public userSearch(searchModel: SearchModel, paginationModel: PaginationModel, pageId: string, widgetId: string): Observable<PaginationResultModel<UserListViewDTO>> {
        const headers: HttpHeaders = this.AddSearchAuthorizedContextHeader(this.AddWidgetContextHeader(this.GetStandardHeaders(), widgetId), pageId);
        return this.PostAuthenticated<PaginationResultModel<UserListViewDTO>>(`${this._baseUrl}api/users`, searchModel, headers, [], paginationModel);
    }

    public getUserIdPRedirectUrl(username: string, returnUrl: string): Observable<UserIdPViewDTO> {
        return this.Get<UserIdPViewDTO>(`${this._baseUrl}api/users/useridp?username=${username}&returnUrl=${returnUrl}`, this.GetStandardHeaders());
    }
}
