import { Component, Input } from '@angular/core';
import { ContentConfigurationViewDTO } from 'src/app/data-transfer-objects/configuration/content-configuration-view-dto';
import { UIStylingHelper } from 'src/app/helpers/ui-styling-helper';
import { ConfigurationHelper } from 'src/app/helpers/configuration-helper';
import { ContainerConfigurationViewDTO } from '../../../../data-transfer-objects/configuration/container-configuration-view-dto';
import { ContainerContentType } from '../../../../enums/configuration/container-content-type';
import { FormEditStyle } from '../../../../enums/configuration/form-edit-style';

@Component({
    selector: 'fw-container',
    templateUrl: './container.component.html',
})
export class ContainerComponent {
    // necessary to access the properties on the template
    public containerContentType = ContainerContentType;

    @Input()
    FormEditStyle: FormEditStyle;

    @Input()
    public containerConfiguration: ContainerConfigurationViewDTO;

    constructor() { }

    public getBackground(): object {

        let background: object = null;

        if (this.containerConfiguration && this.containerConfiguration.DisplayStyle && this.containerConfiguration?.DisplayStyle.Background != '') {
            background = { 'background': `${this.containerConfiguration.DisplayStyle.Background}` };
        }

        return background;
    }

    public getContainerStyling(): string {

        let containerStyling: string = '';

        if (this.containerConfiguration && this.containerConfiguration.DisplayStyle) {
            const borderStyle: string = UIStylingHelper.GetBorderStyling(this.containerConfiguration.DisplayStyle.BorderStyle);
            const horizontalAlignmentStyle: string = UIStylingHelper.GetHorizontalAlignmentStyling(this.containerConfiguration.DisplayStyle.HorizontalAlignment);
            const verticalAlignmentStyle: string = UIStylingHelper.GetVerticalAlignmentStyling(this.containerConfiguration.DisplayStyle.VerticalAlignment);
            const cssClass: string = UIStylingHelper.GetCssClass(this.containerConfiguration.DisplayStyle);

            containerStyling = `${borderStyle} ${horizontalAlignmentStyle} ${verticalAlignmentStyle} ${cssClass}`;
        }

        return containerStyling;
    }

    public getWidgetStyling(contentConfiguration: ContentConfigurationViewDTO): string {

        let widgetStyling: string = '';

        if (contentConfiguration.ContainerContentType === ContainerContentType.Widget) {
            const baseWidgetConfiguration = ConfigurationHelper.GetBaseWidgetFromConfiguration(contentConfiguration.Widget);

            if (baseWidgetConfiguration && baseWidgetConfiguration.DisplayStyle) {

                const borderStyle: string = UIStylingHelper.GetBorderStyling(baseWidgetConfiguration.DisplayStyle.BorderStyle);
                const selfAlignmentStyle: string = UIStylingHelper.GetSelfAlignmentStyling(baseWidgetConfiguration.DisplayStyle.SelfAlignment);
                const cssClass: string = UIStylingHelper.GetCssClass(baseWidgetConfiguration.DisplayStyle);

                widgetStyling = `${borderStyle} ${selfAlignmentStyle} ${cssClass}`;
            }
        }

        return widgetStyling;
    }
}