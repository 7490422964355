import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LookupTables } from '../../../../../enums/lookup-table-enum';
import { LookupCacheService } from '../../../../../services/deprecated/lookup-cache.service';
import { ListBoxBaseComponent } from '../../listbox/listbox-base.component';

@Component({
    selector: 'fw-form-edit-user-roles',
    templateUrl: './form-user-role.component.html',
    host: { 'class': 'form-edit item-list' } //class to be updated, chat to Alex
})
export class FormEditUserRoleComponent extends ListBoxBaseComponent {

    public PropertyName: string = 'UserRoles';
    public LFFLookupTable: LookupTables = LookupTables.Roles;

    constructor(
        protected translationService: TranslocoService,
        protected lookupService: LookupCacheService
    ) {
        super(translationService, lookupService);
    }   
}
