<fw-inline-edit-input-number *ngIf="FormEditStyle === FormEditStyles.Inline || FormEditStyle === FormEditStyles.ReadOnly"
                             [CustomFormGroup]="CustomFormGroup"
                             [PropertyName]="Property.PropertyName"
                             [Label]="Property.LabelResourceId"
                             [LabelStyle]="Property.LabelStyle"
                             [ValueStyle]="Property.ValueStyle"
                             [InputNumber]="Property.InputNumber"
                             [AcceptChanges]="AcceptChanges"
                             [FormEditStyle]="FormEditStyle">
</fw-inline-edit-input-number>
<fw-form-edit-input-number *ngIf="FormEditStyle === FormEditStyles.Form"
                             [CustomFormGroup]="CustomFormGroup"
                             [PropertyName]="Property.PropertyName"
                             [Label]="Property.LabelResourceId"
                             [LabelStyle]="Property.LabelStyle"
                             [InputNumber]="Property.InputNumber"
                             [AcceptChanges]="AcceptChanges">
</fw-form-edit-input-number>