import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentCreateDto } from 'src/app/data-transfer-objects/document/document-create-dto';
import { DocumentListViewDTO } from 'src/app/data-transfer-objects/document/document-list-view-dto';
import { BASE_URL } from 'src/app/helpers/base-url';
import { PaginationModel } from '../../models/pagination-models';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';
import { DocumentDraftHttpService } from './document-draft-http.service';

@Injectable({
    providedIn: 'root'
})
export class ActionDocumentDraftHttpService extends DocumentDraftHttpService {

    constructor(httpClient: HttpClient, @Inject(BASE_URL) baseUrl: string, errorService: ErrorManagerService, toastService: ToastService) {
        super(httpClient, baseUrl, errorService, toastService);
    }    

    public addDocument(documentCreateDto: DocumentCreateDto, pageId: string, entityId: string, stateGroupId: string): Observable<null> {
        return this.AddDocument(documentCreateDto, `action/${pageId}/entityId/${entityId}?stateGroupId=${stateGroupId}`);
    }

    public getActionDocuments(pageId: string, actionId: string, widgetId: string, paginationModel: PaginationModel): Observable<DocumentListViewDTO[]> {
        return this.GetDocumentsByEntityDraftWithHeaders(`action/${pageId}/actionId/${actionId}`, widgetId, paginationModel);
    }

    public getActionDraftDocuments(pageId: string, assetId: string, stateGroupId: string): Observable<DocumentListViewDTO[]> {
        return this.GetDocumentsByEntityDraft(`action/${pageId}/assetId/${assetId}?stateGroupId=${stateGroupId}`);
    }

    public removeDocumentDraft(pageId: string, assetId: string, stateGroupId: string): Observable<null> {
        return this.RemoveEntityDraft(`action/${pageId}/assetId/${assetId}?stateGroupId=${stateGroupId}`);
    }
}
