import { Injectable } from '@angular/core';
import { ErrorDetails } from 'src/app/models/error-details';

@Injectable({
  providedIn: 'root'
})
export class ErrorApplicationService {

  constructor() { }

  handleError(errorDetails: ErrorDetails) {

  }
}
