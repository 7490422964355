import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionListWidgetStateModel, ListState } from '../../AppState';
import { ListFieldConfigurationViewDTO } from '../../data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { ActionQueryDefinitionSearchViewDTO } from '../../data-transfer-objects/configuration/list-configuration/query-definitions/action-query-definition-search-view-dto';
import { ListFilterViewDTO } from '../../data-transfer-objects/filter/list-filter-view-dto';
import { PaginationModel } from '../../models/pagination-models';
import { SearchModel } from '../../models/search-model';
import { AbstractListFacade } from './abstract-list.facade';

@Injectable()
export abstract class AbstractActionListFacade extends AbstractListFacade<ActionQueryDefinitionSearchViewDTO, ActionListWidgetStateModel> {

    public abstract PageAsset: boolean;

    public abstract GetListItems(): Observable<ListState<ActionListWidgetStateModel>[]>;
    public abstract LoadListItems(fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, searchModel: SearchModel, actionQueryDefinition: ActionQueryDefinitionSearchViewDTO, dataSourceId: string,
        filters?: ListFilterViewDTO): void;
}
