import { ErrorManagerService } from 'src/app/services/deprecated/error-manager.service';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';
import { ErrorDetails } from 'src/app/models/error-details';

@Injectable({
    providedIn: 'root'
})
export class ToastApplicationService {

    constructor(private messageService: MessageService, private translocoService: TranslocoService,
        private errorManagerService: ErrorManagerService
    ) { }

    showToast(summary: string, detail: string|ErrorDetails, severity?: string, sticky?: boolean) {
        const errorDetails = detail as ErrorDetails;

        const detailString = !!errorDetails
            ? this.errorManagerService.translateErrorDetails(errorDetails)
            : this.translocoService.translate(detail as string);
            
        this.messageService.add({
            summary: this.translocoService.translate(summary),
            detail: detailString,
            life: 3000,
            severity: severity,
            sticky: sticky
        });
    }
}
