/* eslint-disable */

import {
    Component, Input
} from '@angular/core';
import { ButtonAction } from 'src/app/enums/button-action';

@Component({
    selector: 'portal-button',
    templateUrl: './portal-button.component.html',
})
export class PortalButtonComponent {
    @Input('is-disabled') public _disabled: boolean;

    @Input('variant') public _buttonAction: ButtonAction = ButtonAction.Button;

    constructor() { }
}
