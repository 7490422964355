import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { PreNavigationHandlerService } from '../services/pre-navigation-handler.service';

@Injectable({
    providedIn: 'root'
})
export class PreNavigationGuard implements CanActivate {
    constructor(private _preNavigationService: PreNavigationHandlerService) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this._preNavigationService.PreNavigation();
    }
}
