import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AttachmentCreateDTO } from '../../../../data-transfer-objects/discussion-comment/discussion-comment-create-dto';
import { DiscussionCommentViewDTO } from '../../../../data-transfer-objects/discussion-comment/discussion-comment-view-dto';
import { DocumentListViewDTO } from '../../../../data-transfer-objects/document/document-list-view-dto';
import { FormEditStyle } from '../../../../enums/configuration/form-edit-style';
import { EditingOperation } from '../../../../enums/editing-operation';
import { ObjectHelper } from '../../../../helpers/object-helper';
import { SessionApplicationService } from '../../../../services/application/session-application.service';
import { DocumentRowOptions } from '../document-row/document-row-options';
import { AbstractDiscussionCommentsListFacade } from '../../../../facade/abstract/abstract-discussion-comments-list.facade';

@Component({
    selector: 'fw-discussion-comments-list',
    templateUrl: './discussion-comments-list.component.html'
})
export class DiscussionCommentsListComponent implements OnInit, OnDestroy {

    public pageId: string;
    public discussionComments: DiscussionCommentViewDTO[];
    public totalRecords: number = 100;
    public rowOptions: DocumentRowOptions;

    private subscriptions: Subscription[] = [];

    constructor(
        private commentsListFacade: AbstractDiscussionCommentsListFacade,
        private sessionApplicationService: SessionApplicationService) {
        this.InitialiseRowOptions();
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach(subscription => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            });
        }

        this.commentsListFacade.ResetState();
    }

    ngOnInit(): void {
        const setLoadSub = this.commentsListFacade._setLoadSubject.subscribe(readyToLoad => {
            if (readyToLoad === true) {
                this.pageId = this.commentsListFacade._pageId;
                this.commentsListFacade.LoadDiscussionComments();
            }
        });

        this.subscriptions.push(setLoadSub);

        const selectorSub = this.commentsListFacade.LoadDiscussionCommentsSelector().subscribe((comments) => {
            if (comments) {
                this.discussionComments = ObjectHelper.deepCopyJsonParse(comments.DiscussionComments);
            }
        });

        const addCommentSub = this.commentsListFacade._addCommentToUi.subscribe((discussionComment) => {

            const newDisussionComment: DiscussionCommentViewDTO = {
                Attachments: this.mapAttachmentCreateDtoToViewDto(discussionComment.Attachments),
                Comment: discussionComment.Comment,
                Commenter: this.sessionApplicationService.currentUser.Fullname,
                CommenterId: null,
                CommenterTypeId: null,
                Id: null,
                Timestamp: new Date()
            };

            const discussionCommentsCloned = ObjectHelper.deepCopyJsonParse(this.discussionComments);
            discussionCommentsCloned.unshift(newDisussionComment);

            this.discussionComments = ObjectHelper.deepCopyJsonParse(discussionCommentsCloned);
        });

        this.subscriptions.push(selectorSub);
        this.subscriptions.push(addCommentSub);
    }

    mapAttachmentCreateDtoToViewDto(attachments: AttachmentCreateDTO[]): DocumentListViewDTO[] {
        const documentListViewDto: DocumentListViewDTO[] = [];

        attachments.forEach((a) => {
            documentListViewDto.push({
                FileName: a.FileName,
                DocumentType: null,
                DocumentTypeDescription: null,
                DocumentTypeId: null,
                SensitivityId: null,
                Id: null,
                IsDownloadable: true,
                LastUpdatedDate: null,
                Size: null
            })
        });

        return documentListViewDto;
    }


    private InitialiseRowOptions(): void {
        this.rowOptions = {
            documentOperations: [EditingOperation.Get],
            fields: ['FileName', 'Size'],
            formEditStyle: FormEditStyle.Inline
        };
    }


}
