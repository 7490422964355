import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReferenceCodeViewDTO } from 'src/app/data-transfer-objects/reference-code/reference-code-view-dto';
import { AssetListViewDTO } from '../../data-transfer-objects/asset/asset-list-view-dto';
import { AssetSearchModel } from '../../data-transfer-objects/asset/asset-search-dto';
import { AssetUpdateDTO } from '../../data-transfer-objects/asset/asset-update-dto';
import { AssetViewDTO } from '../../data-transfer-objects/asset/asset-view-dto';
import { MapReferenceViewDTO } from '../../data-transfer-objects/map-reference/map-reference-view-dto';
import { HttpStatusCode } from '../../enums/http-status-codes';
import { BASE_URL } from '../../helpers/base-url';
import { AssetBehaviourContextModel } from '../../models/behaviours/context-models/asset/asset-draft-behaviour-context-model';
import { HttpFieldModel } from '../../models/field-list-model';
import { PaginationModel, PaginationResultModel } from '../../models/pagination-models';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class AssetHttpService extends AuthenticatedHttpServiceBase {
    constructor(httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, errorService: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorService, toastService);
    }

    public getAssetById(assetId: string): Observable<AssetViewDTO> {
        return this.GetAuthenticated<AssetViewDTO>(`${this.baseUrl}api/asset/${assetId}`, this.GetStandardHeaders());
    }

    public getAsset(pageId: string, assetId: string): Observable<AssetViewDTO> {
        return this.GetAuthenticated<AssetViewDTO>(`${this.baseUrl}api/asset/${assetId}/page/${pageId}`, this.GetStandardHeaders());
    }

    public getAssetBySearch(paginationModel: PaginationModel, assetSearchDTO: AssetSearchModel, fieldModel: HttpFieldModel): Observable<PaginationResultModel<AssetListViewDTO>> {
        return this.PostAuthenticated<PaginationResultModel<AssetListViewDTO>>(`${this.baseUrl}api/asset/search`, assetSearchDTO, this.GetStandardHeaders(), [], paginationModel, [HttpStatusCode.BAD_REQUEST, HttpStatusCode.NOT_FOUND], fieldModel);
    }

    public updateAsset(pageId: string, assetId: string, assetUpdateDTO: AssetUpdateDTO): Observable<string> {
        return this.PutAuthenticated<string>(`${this.baseUrl}api/asset/${assetId}/page/${pageId}`, assetUpdateDTO, this.GetStandardHeaders(), [], null);
    }

    public executeAssetBehaviour(assetDraftBehaviourContextModel: AssetBehaviourContextModel, widgetId: string, pageId: string): Observable<null> {
        const headers: HttpHeaders = this.AddWidgetContextHeader(this.AddPageIdHeader(this.GetStandardHeaders(), pageId), widgetId);
        return this.PostAuthenticated<null>(`${this.baseUrl}api/asset/ExecuteBehaviour`, assetDraftBehaviourContextModel, headers);
    }

    public getAssetReferenceCodes(assetId: string, fieldModel: HttpFieldModel, paginationModel: PaginationModel, pageId: string, widgetId: string): Observable<PaginationResultModel<ReferenceCodeViewDTO>> {
        const headers: HttpHeaders = this.AddWidgetContextHeader(this.AddPageIdHeader(this.GetStandardHeaders(), pageId), widgetId);
        return this.GetAuthenticated<PaginationResultModel<ReferenceCodeViewDTO>>(`${this.baseUrl}api/asset/${assetId}/referenceCodes`, headers, [], paginationModel, [], fieldModel);
    }

    public getAssetMapReference(assetId: string, fieldModel: HttpFieldModel, paginationModel: PaginationModel, pageId: string, widgetId: string): Observable<PaginationResultModel<MapReferenceViewDTO>> {
        const headers: HttpHeaders = this.AddWidgetContextHeader(this.AddPageIdHeader(this.GetStandardHeaders(), pageId), widgetId);
        return this.GetAuthenticated<PaginationResultModel<MapReferenceViewDTO>>(`${this.baseUrl}api/asset/${assetId}/mapReferences`, headers, [], paginationModel, [], fieldModel);
    }
}
