import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/AppState';
import { Calendars } from '../../data-transfer-objects/configuration/site-settings-configuration-view-dto';
import { getSiteSettingsConfigurationRequest } from '../../state/site-settings/site-settings.actions';
import { getCalendars, getDateFormat } from '../../state/site-settings/site-settings.selectors';

@Injectable({
    providedIn: 'root'
})
export class SiteSettingsFacade {

    constructor(private store: Store<AppState>) {
    }

    public getDateFormat(): Observable<string> {
        return this.store.pipe(select(getDateFormat));
    }

    public getCalendars(): Observable<Calendars> {
        return this.store.pipe(select(getCalendars));
    }

    public loadConfiguration(): void {
        this.store.dispatch(getSiteSettingsConfigurationRequest());
    }
}
