import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from 'primeng/calendar';
import { StyleClassModule } from 'primeng/styleclass';
import { PipeModule } from 'src/app/pipes/pipe-module';
import { TranslocoRootModule } from 'src/app/transloco/transloco-root.module';
import { DirectivesModule } from '../../directives/directives.module';
import { NavigationLinksComponent } from './components/navigation-links/navigation-links.component';
import { PortalButtonComponent } from './components/portal-button/portal-button.component';
import { PortalCheckboxComponent } from './components/portal-checkbox/portal-checkbox.component';
import { PortalDateFieldComponent } from './components/portal-date-field/portal-date-field.component';
import { PortalDropDownComponent } from './components/portal-drop-down/portal-drop-down.component';
import { PortalHeaderRowComponent } from './components/portal-header-row/portal-header-row.component';
import { PortalLabelTagComponent } from './components/portal-label-tag/portal-label-tag.component';
import { PortalLinkComponent } from './components/portal-link/portal-link.component';
import { PortalLoadingComponent } from './components/portal-loading/portal-loading.component';
import { PortalNumberFieldComponent } from './components/portal-number-field/portal-number-field.component';
import { PortalOverlayComponent } from './components/portal-overlay/portal-overlay.component';
import { PortalPasswordFieldComponent } from './components/portal-password-field/portal-password-field.component';
import { PortalProgressBarComponent } from './components/portal-progress-bar/portal-progress-bar.component';
import { PortalRadioGroupComponent } from './components/portal-radio-group/portal-radio-group.component';
import { PortalRadioComponent } from './components/portal-radio/portal-radio.component';
import { PortalRowComponent } from './components/portal-row/portal-row.component';
import { PortalSearchFilterComponent } from './components/portal-search-filter/portal-search-filter.component';
import { PortalSmallButtonComponent } from './components/portal-small-button/portal-small-button.component';
import { PortalTextAreaComponent } from './components/portal-text-area/portal-text-area.component';
import { PortalTextFieldComponent } from './components/portal-text-field/portal-text-field.component';
import { SavedStampComponent } from './components/saved-stamp/saved-stamp.component';
import { StructuredDataDateFieldComponent } from './components/structured-data-date-field/structured-data-date-field.component';
import { ToastComponent } from './components/toast/toast.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
    declarations: [
        PortalNumberFieldComponent,
        PortalTextAreaComponent,
        PortalTextFieldComponent,
        PortalDateFieldComponent,
        PortalCheckboxComponent,
        PortalDropDownComponent,
        PortalButtonComponent,
        NavigationLinksComponent,
        PortalSearchFilterComponent,
        PortalOverlayComponent,
        PortalLoadingComponent,
        PortalPasswordFieldComponent,
        PortalSmallButtonComponent,
        PortalRadioComponent,
        PortalRadioGroupComponent,
        PortalRowComponent,
        PortalHeaderRowComponent,
        PortalLabelTagComponent,
        PortalLinkComponent,
        SavedStampComponent,
        PortalProgressBarComponent,
        StructuredDataDateFieldComponent,
        ToastComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        PipeModule,
        ReactiveFormsModule,
        NgbDatepickerModule,
        DirectivesModule,
        TranslocoRootModule,
        StyleClassModule,
        CalendarModule,
        ProgressSpinnerModule
    ],
    exports: [
        PortalNumberFieldComponent,
        PortalTextAreaComponent,
        PortalTextFieldComponent,
        PortalDateFieldComponent,
        PortalCheckboxComponent,
        PortalDropDownComponent,
        PortalButtonComponent,
        NavigationLinksComponent,
        PortalSearchFilterComponent,
        PortalOverlayComponent,
        PortalLoadingComponent,
        PortalPasswordFieldComponent,
        PortalSmallButtonComponent,
        PortalRadioComponent,
        PortalRadioGroupComponent,
        PortalRowComponent,
        PortalHeaderRowComponent,
        PortalLabelTagComponent,
        PortalLinkComponent,
        SavedStampComponent,
        PortalProgressBarComponent,
        StructuredDataDateFieldComponent,
        ToastComponent
    ]
})
export class GenericControlsModule { }
