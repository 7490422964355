<div>
    <h5 *ngIf="HeaderLabel">
        {{HeaderLabel}}
        <span class="required" *ngIf="Configuration.Required">*</span>
    </h5>
    <div [formGroup]="CustomFormGroup">
        <div class="selected-items">
            <label *ngIf="ChipsetLabel">
                {{ChipsetLabel}}
            </label>
            <p-chips [disabled]="isReadOnly" [formControlName]="PropertyName" [placeholder]="('FormEditCommodity.Placeholder' | transloco)"
                     (onRemove)="RemoveChip()">
                <ng-template let-item pTemplate="item">
                    {{GetCommodityName(item)}}
                </ng-template>
            </p-chips>
        </div>
        <p-listbox *ngIf="!isReadOnly" [formControlName]="PropertyName" [options]="FilteredOptions" [checkbox]="true" [multiple]="true" [showToggleAll]="false"
                   optionLabel="Value" optionValue="Id" (onChange)="onChange($event)" ></p-listbox>
        <a *ngIf="!isReadOnly" href="javascript:void(0)" (click)="ViewMore = !ViewMore">
            {{ ViewMore ? ('FormEditCommodity.ViewLess' | transloco) : ('FormEditCommodity.ViewMore' | transloco) }}
        </a>
        <fw-general-validator [PropertyName]="PropertyName" [(CustomFormGroup)]="CustomFormGroup"
                              [(ValidationDictionary)]="ValidationDictionary"></fw-general-validator>
    </div>
</div>