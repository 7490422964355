import { Component, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { ConfigurableControlsHelper } from "../../../../helpers/configurable-controls-helper";
import { PropertyConfiguration } from "../../../../models/configuration/widgets/properties/property-configuration";
import { BaseWidget } from "./base-widget";
import { BaseWidgetConfigurationViewDTO } from "src/app/data-transfer-objects/configuration/base-widget-configuration-view-dto";

@Component({
    template: '',
})
export abstract class BaseDetailsWidget<T> extends BaseWidget {
    public IsHijriChanged: EventEmitter<PropertyConfiguration> = new EventEmitter<PropertyConfiguration>();
    public FormGroup: FormGroup = new FormGroup({});
    protected DataModel: T | any;
    protected Subscriptions: Subscription[] = [];

    ngOnInit(): void {
        const IsHijriChangesSubscription = this.IsHijriChanged.subscribe((property) => {
            //reload form group
            ConfigurableControlsHelper.UpdateCalendarFormControls(this.DataModel, property, this.FormGroup)
        });

        this.Subscriptions.push(IsHijriChangesSubscription);
    }

    ngOnDestroy() {
        if (this.Subscriptions) {
            this.Subscriptions.forEach(subscription => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            });
        }
    }
    
    protected convertHijriDatesToGregorian() {
        for (var prop in this.DataModel) {
            if ((this.DataModel[prop] as NgbDate)?.year) {
                this.DataModel[prop] = ConfigurableControlsHelper.ConvertHijriToGregorian(this.DataModel[prop]);
            }
        }
    }
     
    public isWidgetReadOnly(widgetConfiguraion:BaseWidgetConfigurationViewDTO): boolean {
       return  widgetConfiguraion.Editing?.ReadOnly ?? false;
    }
}