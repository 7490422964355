import { ErrorManagerService } from '../deprecated/error-manager.service';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ToastService } from '../deprecated/toast.service';
import { Observable, Subscriber } from 'rxjs';
import { HttpStatusCode } from 'src/app/enums/http-status-codes';
import { ErrorDetails } from 'src/app/models/error-details';
import { PaginationModel, OrderByModel } from 'src/app/models/pagination-models';
import { ToastNotificationSetting } from '../../models/toast-notfication-specfication';
import { ErrorModel } from '../../models/error-model';
import { HttpFieldModel } from '../../models/field-list-model';
import { GuidHelper } from '../../helpers/guid-helper';

export abstract class HttpServiceBase {
    // TODO: This was implemented as a temporary fix for LFF-1924
    // Usage is specifically for Draft discard and commit tasks 
    public static RequestCount: number = 0;

    public static PageIdHeader: string = 'PageIdHeader';
    public static SignalRClientIdHeader: string = 'SignalRClientIdHeader';
    private static ClientId = GuidHelper.NewGuid();
    private static FilterRequestConfigurationHeader: string = 'FilterRequestConfigurationHeader';
    private static PageHeader: string = 'PageHeader';
    private static RequestContextHeader: string = 'RequestConfigurationHeader';
    private static WidgetRequestConfigurationHeader: string = 'WidgetRequestConfigurationHeader';
    private static ClientIdHeader: string = 'ClientIdHeader';

    constructor(protected _httpClient: HttpClient,
        protected _errorManager: ErrorManagerService,        
        protected _toastService: ToastService

    ) { }

    protected AddSearchAuthorizedContextHeader(headers: HttpHeaders, context: string): HttpHeaders {
        return headers.append(HttpServiceBase.RequestContextHeader, context);
    }

    protected GetMultipartFormDataHeaders(): HttpHeaders {
        const multiPartHeaders: HttpHeaders = new HttpHeaders();
        multiPartHeaders.set('Content-Type', null);
        multiPartHeaders.set('Accept', 'multipart/form-data');
        return multiPartHeaders;
    }

    protected AddWidgetContextHeader(headers: HttpHeaders, context: string): HttpHeaders {
        return headers.append(HttpServiceBase.WidgetRequestConfigurationHeader, context);
    }

    protected AddPageIdHeader(headers: HttpHeaders, pageId: string): HttpHeaders {
        return headers.append(HttpServiceBase.PageIdHeader, pageId);
    }

    protected AddSignalRClientIdHeader(headers: HttpHeaders, signalRclientId: string): HttpHeaders {
        return headers.append(HttpServiceBase.SignalRClientIdHeader, signalRclientId);
    }

    protected Delete<TModel>(path: string, headers: HttpHeaders): Observable<TModel>;
    protected Delete<TModel>(path: string, headers: HttpHeaders, notify: ToastNotificationSetting[]): Observable<TModel>;
    protected Delete<TModel>(path: string, headers: HttpHeaders, notify: ToastNotificationSetting[], ignoreHttpStatusCode: HttpStatusCode[]): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected Delete<TModel>(path: string, headers: HttpHeaders, notify: ToastNotificationSetting[], ignoreHttpStatusCode: HttpStatusCode[], body: any);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected Delete<TModel>(path: string, headers: HttpHeaders, notify: ToastNotificationSetting[] = [], ignoreHttpStatusCode: HttpStatusCode[] = [], body: any = undefined): Observable<TModel> {
        return new Observable<TModel>((observer: Subscriber<TModel>) => {

            HttpServiceBase.RequestCount++;

            this._httpClient.delete<TModel | ErrorDetails>(path, {
                headers: headers,
                observe: 'response',
                body
            }).subscribe((response: HttpResponse<TModel | ErrorDetails>) => {
                this.handleResponse<TModel>(response, observer, notify, ignoreHttpStatusCode);
                HttpServiceBase.RequestCount--;
            }, (error: HttpErrorResponse) => {
                this.handleError<TModel>(error, observer, notify, ignoreHttpStatusCode);
                HttpServiceBase.RequestCount--;
            });

        });
    }

    protected Get<TModel>(path: string, headers: HttpHeaders): Observable<TModel>;
    protected Get<TModel>(path: string, headers: HttpHeaders, notify: ToastNotificationSetting[]): Observable<TModel>;
    protected Get<TModel>(path: string, headers: HttpHeaders, notify: ToastNotificationSetting[], paginationModel: PaginationModel): Observable<TModel>;
    protected Get<TModel>(path: string, headers: HttpHeaders, notify: ToastNotificationSetting[], paginationModel: PaginationModel, ignoreHttpStatusCode: HttpStatusCode[]): Observable<TModel>;
    protected Get<TModel>(path: string, headers: HttpHeaders, notify: ToastNotificationSetting[], paginationModel: PaginationModel, ignoreHttpStatusCode: HttpStatusCode[], fieldModel: HttpFieldModel): Observable<TModel>;
    protected Get<TModel>(path: string, headers: HttpHeaders, notify: ToastNotificationSetting[] = [], paginationModel: PaginationModel = null, ignoreHttpStatusCode: HttpStatusCode[] = [], fieldModel: HttpFieldModel = null): Observable<TModel> {

        return new Observable<TModel>((observer: Subscriber<TModel>) => {

            HttpServiceBase.RequestCount++;

            this._httpClient.get<TModel | ErrorDetails>(path, {
                headers: headers,
                observe: 'response',
                params: this.BuildParams(paginationModel, fieldModel)
            }).subscribe((response: HttpResponse<TModel | ErrorDetails>) => {
                this.handleResponse<TModel>(response, observer, notify, ignoreHttpStatusCode);
                HttpServiceBase.RequestCount--;
                observer.complete();
            }, (error: HttpErrorResponse) => {
                this.handleError<TModel>(error, observer, notify, ignoreHttpStatusCode);
                HttpServiceBase.RequestCount--;
                observer.complete();
            });

        });
    }

    protected GetStandardHeaders(): HttpHeaders {
        let headers = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });
        headers = headers.append(HttpServiceBase.ClientIdHeader, HttpServiceBase.ClientId);

        return headers;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected Post<TModel>(path: string, body: any, headers: HttpHeaders): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected Post<TModel>(path: string, body: any, headers: HttpHeaders, notify: ToastNotificationSetting[]): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected Post<TModel>(path: string, body: any, headers: HttpHeaders, notify: ToastNotificationSetting[], paginationModel: PaginationModel): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected Post<TModel>(path: string, body: any, headers: HttpHeaders, notify: ToastNotificationSetting[], paginationModel: PaginationModel, ignoreHttpStatusCode: HttpStatusCode[]): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected Post<TModel>(path: string, body: any, headers: HttpHeaders, notify: ToastNotificationSetting[], paginationModel: PaginationModel, ignoreHttpStatusCode: HttpStatusCode[], FieldModel: HttpFieldModel): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected Post<TModel>(path: string, body: any, headers: HttpHeaders, notify: ToastNotificationSetting[] = [], paginationModel: PaginationModel = null, ignoreHttpStatusCode: HttpStatusCode[] = [], FieldModel: HttpFieldModel = null): Observable<TModel> {

        return new Observable<TModel>((observer: Subscriber<TModel>) => {

            HttpServiceBase.RequestCount++;

            this._httpClient.post<TModel | ErrorDetails>(path, body, {
                headers: headers,
                observe: 'response',
                params: this.BuildParams(paginationModel, FieldModel)
            }).subscribe((response: HttpResponse<TModel | ErrorDetails>) => {
                this.handleResponse<TModel>(response, observer, notify, ignoreHttpStatusCode);
                HttpServiceBase.RequestCount--;
            }, (error: HttpErrorResponse) => {
                this.handleError<TModel>(error, observer, notify, ignoreHttpStatusCode);
                HttpServiceBase.RequestCount--;
            });

        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected Put<TModel>(path: string, body: any, headers: HttpHeaders): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected Put<TModel>(path: string, body: any, headers: HttpHeaders, notify: ToastNotificationSetting[]): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected Put<TModel>(path: string, body: any, headers: HttpHeaders, notify: ToastNotificationSetting[], ignoreHttpStatusCode: HttpStatusCode[]): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected Put<TModel>(path: string, body: any, headers: HttpHeaders, notify: ToastNotificationSetting[], ignoreHttpStatusCode: HttpStatusCode[], paginationModel: PaginationModel): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected Put<TModel>(path: string, body: any, headers: HttpHeaders, notify: ToastNotificationSetting[] = [], ignoreHttpStatusCode: HttpStatusCode[] = [], paginationModel: PaginationModel = null): Observable<TModel> {

        return new Observable<TModel>((observer: Subscriber<TModel>) => {

            HttpServiceBase.RequestCount++;

            this._httpClient.put<TModel | ErrorDetails>(path, body, {
                headers: headers,
                observe: 'response',
                params: this.BuildParams(paginationModel, null)
            }).subscribe((response: HttpResponse<TModel | ErrorDetails>) => {
                this.handleResponse<TModel | ErrorDetails>(response, observer, notify, ignoreHttpStatusCode);
                HttpServiceBase.RequestCount--;
            }, (error: HttpErrorResponse) => {
                this.handleError<TModel>(error, observer, notify, ignoreHttpStatusCode);
                HttpServiceBase.RequestCount--;
            });
        });

    }

    private BuildParams(paginationModel: PaginationModel, fieldModel: HttpFieldModel): HttpParams {

        let params = this.GetHttpParamsFromPaginationModel(paginationModel);

        if (fieldModel) {
            params = params.append('Fields', fieldModel.Fields.join(','));
        }

        return params;
    }

    private GetHttpParamsFromPaginationModel(paginationModel: PaginationModel): HttpParams {
        let params = new HttpParams();

        for (const item in paginationModel) {

            if (item == 'OrderByList' && paginationModel.OrderByList) {
                // Object.keys(this).forEach
                paginationModel.OrderByList.forEach((model: OrderByModel, index: number) => {
                    params = params.append('OrderByList[' + index + '].Field', model.Field);
                    params = params.append('OrderByList[' + index + '].OrderByDirection', model.OrderByDirection.toString());
                })
            }
            else {
                params = params.append(item, paginationModel[item]);
            }
        }

        return params;
    }

    private extractErrorFeedback(response: HttpErrorResponse): string {

        return response.headers.get('feedback') ? response.headers.get('feedback') : '';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private extractFeedback(response: HttpResponse<any>): string {

        return response.headers.get('feedback') ? response.headers.get('feedback') : '';
    }

    private handleError<TModel>(response: HttpErrorResponse, observer: Subscriber<TModel>, notify: ToastNotificationSetting[], ignoreHttpStatusCode: HttpStatusCode[]): void {

        const status: HttpStatusCode = response.status;

        const errorDetails: ErrorDetails = response.error as ErrorDetails;

        if (ignoreHttpStatusCode?.indexOf(status) ?? -1 === -1) {
            this._errorManager.HandleError(new ErrorModel(errorDetails));
        }

        const notifyIndex = notify.findIndex((toastSetting: ToastNotificationSetting) => { return toastSetting.StatusCode === status; });

        if (notifyIndex > -1) {

            const translate = response.headers.get('feedback') ? true : false;
            const message = response.headers.get('feedback') ? response.headers.get('feedback') : errorDetails.detail;

            this._toastService.ShowToast([{
                Message: message,
                RouterLink: null,
                RouterText: null,
                MessageParameters: null,
                QueryParameters: null,
            }], notify[notifyIndex].StyleVariant, true, translate);
        }

        observer.error(errorDetails);

    }

    private handleResponse<TModel>(response: HttpResponse<TModel | ErrorDetails>, observer: Subscriber<TModel>, notify: ToastNotificationSetting[], ignoreHttpStatusCode: HttpStatusCode[]): void {

        const status: HttpStatusCode = response.status;

        if (status >= 200 && status < 300) {

            const notifyIndex = notify.findIndex((toastSetting: ToastNotificationSetting) => { return toastSetting.StatusCode === status; });

            if (notifyIndex > -1)
                this._toastService.ShowToast([{
                    Message: this.extractFeedback(response),
                    RouterLink: null,
                    RouterText: null,
                    QueryParameters: null,

                    MessageParameters: null
                }], notify[notifyIndex].StyleVariant);

            observer.next(response.body as TModel);
        }
        else {
            const errorDetails: ErrorDetails = response.body as ErrorDetails;

            if (ignoreHttpStatusCode?.indexOf(status) ?? -1 === -1) {
                this._errorManager.HandleError(new ErrorModel(errorDetails));
            }

            const notifyIndex = notify.findIndex((toastSetting: ToastNotificationSetting) => { return toastSetting.StatusCode === status; });

            if (notifyIndex > -1) {

                this._toastService.ShowToast([{
                    Message: this.extractFeedback(response),
                    RouterLink: null,
                    RouterText: null,
                    QueryParameters: null,

                    MessageParameters: null
                }], notify[notifyIndex].StyleVariant);

            }

            observer.error(errorDetails);
        }
    }
}
