<fw-form-edit-commodity [ChipsetLabel]="('SelectCommodities.ChipsetLabel' | transloco)"
    [Configuration]="Configuration"
    [CustomFormGroup]="FormGroup"
    [Commodities]="Commodities"
    [SubmitDraft]="false">
</fw-form-edit-commodity>
<div class="p-dialog-footer justify-content-end">
    <p-button styleClass="btn btn-lg" type="button" (onClick)="cancelClicked()"
        label="{{'SelectCommodities.Cancel' | transloco }}">
    </p-button>
    <p-button styleClass="btn btn-primary btn-lg" type="button" [disabled]="FormGroup.controls['Commodities'].invalid"
        (onClick)="confirmClicked()" label="{{'SelectCommodities.Confirm' | transloco }}">
    </p-button>
</div>