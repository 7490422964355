import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorDetails } from '../models/error-details';
import { ErrorApplicationService } from '../services/application/error-application.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private errorApplicationService: ErrorApplicationService) {
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.error instanceof ErrorEvent) {
                        //client side error

                    }
                    else {
                        //server side error
                        this.errorApplicationService.handleError(error.error);
                    }
                    return throwError(error);
                })
            )
    }
}
