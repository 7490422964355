<div [formGroup]="CustomFormGroup">
    <fw-general-validator [PropertyName]="PropertyName"
                          [(CustomFormGroup)]="CustomFormGroup"
                          [(ValidationDictionary)]="ValidationDictionary"></fw-general-validator>
    <span class="uaq-calendar">
        <input readonly class="p-inputtext p-component" [placeholder]="dateFormat" (click)="toggleDatePicker()"
               name="dp" [formControlName]="PropertyName" (dateSelect)="onAcceptChanges()" ngbDatepicker #datePicker="ngbDatepicker">
        <ng-template [ngIf]="ShowLabel()">
            <label [ngClass]="GetLabelStylingClass()">
                {{Label | transloco}}
                <span *ngIf="Calendar.Validation.Required">*</span>
            </label>
        </ng-template>
        <button class="p-button p-button-icon-only" (click)="toggleDatePicker()" type="button">
            <span class="p-button-icon pi pi-calendar"></span>
        </button>
    </span>
</div>