<p-table 
    [value]="RepresentativeListWidgetDataModel?.representatives" 
    selectionMode="single"
    [(selection)]="SelectedRepresentative" 
    [lazy]="true" 
    (onLazyLoad)="loadRepresentatives($event)"
    [paginator]="RepresentativeListWidgetDataModel?.representatives?.length > 0" 
    [showCurrentPageReport]="true"
    [currentPageReportTemplate]="currentPageReportTemplate"
    [showPageLinks]="RepresentativeListWidgetDataModel?.totalRecords > RepresentativeListWidgetConfiguration.PageSize"
    [showFirstLastIcon]="RepresentativeListWidgetDataModel?.totalRecords > RepresentativeListWidgetConfiguration.PageSize"
    [rows]="RepresentativeListWidgetConfiguration.PageSize"
    [totalRecords]="RepresentativeListWidgetDataModel?.totalRecords"
    [ngClass]="getTableStyleClass()"
    [loading]="loading" 
    dataKey="Id" 
    [first]="first">

    <ng-template pTemplate="header">
        <tr>
            <th style="width:30px"></th>
            <th>
                {{"Representation.List.Name" | transloco}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-representative>
        <tr [pSelectableRow]="representative">
            <td>
                <p-tableRadioButton [value]="representative"></p-tableRadioButton>
            </td>
            <td>{{representative.FullName}}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="2">
                {{RepresentativeListWidgetConfiguration.EmptyListResourceId | transloco}}
            </td>
        </tr>
    </ng-template>
</p-table>
<div class="p-dialog-footer justify-content-end">
    <p-button styleClass="btn btn-lg" type="button" label="{{'AddRepresentation.Form.Cancel' | transloco }}"
        (onClick)="cancelClicked()"></p-button>
    <p-button [disabled]="!SelectedRepresentative" styleClass="btn btn-primary btn-lg" type="button"
        label="{{'AddRepresentation.Form.Confirm' | transloco }}" (onClick)="confirmClicked()"></p-button>
</div>