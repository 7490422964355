import { createReducer, on } from '@ngrx/store';
import { ListState, StructuredDataListWidgetState } from '../../AppState';
import { ListStateReducerHelper } from '../../helpers/list-state-reducer-helper';
import { loadStructuredDataEntryDone, resetStructuredDataEntry } from './structured-data.actions';


export const initialStructuredDataState: ListState<StructuredDataListWidgetState>[] = [];

export const StructuredDataListReducer = createReducer<ListState<StructuredDataListWidgetState>[]>(initialStructuredDataState,
    
    on(loadStructuredDataEntryDone, (state, action) => {
        const currentState: StructuredDataListWidgetState = ListStateReducerHelper.GetListState<StructuredDataListWidgetState>(state, action.rootNodeId) ?? {
            viewModel: null,
            getError: null,
            updateError: null
        };
        
        return ListStateReducerHelper.GetUpdatedListState<StructuredDataListWidgetState>(state, action.rootNodeId, {
            ...currentState,
            viewModel: action.data
        });
    }),
    
    on(resetStructuredDataEntry, () => initialStructuredDataState),
);
