import { Injectable } from '@angular/core';
import { StructuredDataLibraryService } from '@landadmin/structured-data';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getPageConfigurationState } from '../../state/configuration/configuration.selectors';
import { loadPageConfigurationRequest, resetPageConfiguration } from '../../state/configuration/site/site-configuration.actions';
import { AppState, ListState, PageConfigurationState } from '../../AppState';

@Injectable({
    providedIn: 'root'
})
export class SiteConfigurationFacade {

    constructor(private store: Store<AppState>, public _structuredDataLibraryService: StructuredDataLibraryService) {
    }

    public LoadPageConfiguration(pageId: string): void {

        this.store.dispatch(loadPageConfigurationRequest({ pageId: pageId}));
    }

    public LoadPageConfigurationSelector(): Observable<ListState<PageConfigurationState>[]> {

        const structuredDataWidgetState = this.store.pipe(select(getPageConfigurationState));
        return structuredDataWidgetState;
    }

    public ResetState(): void {
        this.store.dispatch(resetPageConfiguration());
    }
}
