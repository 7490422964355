import { Component, Directive, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';
import { FormEditStyle } from '../../../../enums/configuration/form-edit-style';
import { LookupTables } from '../../../../enums/lookup-table-enum';
import { GuidHelper } from '../../../../helpers/guid-helper';
import { LookupModel } from '../../../../models';
import { LookupCacheService } from '../../../../services/deprecated/lookup-cache.service';
import { ValidatableInputControlBase } from '../input-controls/validatable-input-control-base';

@Component({
    selector: 'fw-listbox-base',
    templateUrl: './listbox-base.component.html'
})
export class ListBoxBaseComponent extends ValidatableInputControlBase<string[]> implements OnInit {

    public FormEditStyles = FormEditStyle;

    public Options: LookupModel[] = [];

    @Input()
    SelectedOptions: LookupModel[] = [];

    @Input()
    public PropertyName: string;
    @Input()
    public LFFLookupTable: LookupTables;
    @Input()
    public FormEditStyle: FormEditStyle;
    @Input()
    public ReadyToStorePreviousValue: BehaviorSubject<boolean>;
    @Input()
    public HeaderLabel: string = '';

    constructor(protected translocoService: TranslocoService,
        protected lookupCacheService: LookupCacheService) {
        super(translocoService);
    }
    ngOnInit(): void {
        this.InitialiseLookup();
    }

    InitialiseLookup(): void {

        if (this.LFFLookupTable) {
            this.lookupCacheService
                .GetLookupTable(this.LFFLookupTable)
                .subscribe((lookupModels: LookupModel[]) => {
                    this.Options = lookupModels;
                });
        }
    }

    onChange(event: AbstractControl) {

        this.CustomFormGroup.controls[this.PropertyName].setValue(event.value);
        this.onAcceptChanges();
    }
}
