import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ShapeEntity } from 'src/app/enums/shape-entity';
import { ShapeValidationResultsState, RunShapeValidationState, ShapeWidgetState } from '../../AppState';
import { ShapeValidationCreateDTO } from '../../data-transfer-objects/shape/shape-validation-create-dto';
import { ShapeViewDTO } from '../../data-transfer-objects/shape/shape-view-dto';
import { FacadeValidation } from '../../modules/dynamic/widgets/types/validation/facade-validation';

@Injectable()
export abstract class AbstractMapFacade extends FacadeValidation {
    public _isEditable: boolean = false;
    public _isReadOnly: boolean = false;
    public abstract _pageId: string;
    public abstract _typeId: string;
    public abstract _entityId: string;
    public abstract _setLoadSubject: BehaviorSubject<boolean>;

    public abstract DeleteJob(jobId: number): void;
    public abstract GetShape(): Observable<ShapeWidgetState>;
    public abstract GetShapeValidationJobDetails(): Observable<RunShapeValidationState>;
    public abstract GetShapeValidationResults(): Observable<ShapeValidationResultsState>;
    public abstract LoadShape(): void;
    public abstract LoadShapeValidationResults(jobId: number): void;
    public abstract RunShapeValidation(businessRuleId: string, validationShape: ShapeViewDTO, comparisonShape: ShapeViewDTO): void;
    public abstract SetIsSaving(isSaving: boolean): void;
    public abstract UpdateShape(shapeViewDTO: ShapeViewDTO);
}
