import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from 'src/app/helpers/date-helper';
import { LanguageHttpService } from 'src/app/services/http/language-http.service';
import { SiteSettingsService } from 'src/app/services/deprecated/site-settings.service';

@Pipe({
    name: 'localeDateFormat'
})
export class LocaleDateFormatPipe implements PipeTransform {
    constructor(
        private languageHttpService: LanguageHttpService,
        private siteSettingsService: SiteSettingsService
    ) { }

    transform(value: Date | string, props?: { stringDate: boolean }): string {
        if (value == null) {
            value = '';
        }
        let returnValue = value.toString();

        if (props && props.stringDate && value !== '') {
            value = new Date(value);
        }

        if (value && value instanceof Date) {
            const locale: string = this.languageHttpService.getCurrentLanguageLocale() ?? 'en-US';
            
            const dateFormat: string = DateHelper.mapDatePipeDateFormat(this.siteSettingsService.dateFormat);

            returnValue = new DatePipe(locale)
                .transform(value, dateFormat);

            // Some angular locales return month short names with 
            // a . to indicate it has been shortened, irrespective 
            // of the date format specified. Here we remove that
            // if it exists, but only if no . is specifically 
            // included in the date format.
            if (!dateFormat.includes('.')) {
                // Replace only does the first instance, this does all.
                returnValue = returnValue.split('.').join('');
            }
        }

        return returnValue;
    }

}
