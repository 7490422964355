import { Injectable, ViewContainerRef, ComponentFactory, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalSubmitHandler } from 'src/app/models/modal-submit-handler';
import { ModalSize } from '../../enums/modal-size-enum';
import { BaseFormComponent } from '../../modules/static/classes/base-form-component';
import { ModalContainerComponent } from '../../modules/static/components/form-modal-container/modal-container.component';
import { PortalLoadingComponent } from '../../modules/static/generics/components/portal-loading/portal-loading.component';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    private _domReference: ViewContainerRef;
    private _initialized: boolean = false;
    private _modalContainerFactory: ComponentFactory<ModalContainerComponent>;

    constructor(private _factoryResolver: ComponentFactoryResolver) {

    }

    public Initialize(domReference: ViewContainerRef): void {

        if (!this._initialized) {
            this._initialized = true;
            this._domReference = domReference
        }

    }

    public ShowFormModal<TModel, TForm extends BaseFormComponent<TModel>>(
        formFactory: ComponentFactory<TForm>,
        title: string,
        formSubmitDelegate: (createdModel: TModel) => void,
        model: TModel,
        closeObservable: Observable<null>,
        submitHandlers: ModalSubmitHandler[]
    )
    public ShowFormModal<TModel, TForm extends BaseFormComponent<TModel>>(
        formFactory: ComponentFactory<TForm>,
        title: string,
        formSubmitDelegate: (createdModel: TModel) => void,
        model: TModel,
        closeObservable: Observable<null>,
        submitHandlers: ModalSubmitHandler[],
        showClose: boolean
    )
    public ShowFormModal<TModel, TForm extends BaseFormComponent<TModel>>(
        formFactory: ComponentFactory<TForm>,
        title: string,
        formSubmitDelegate: (createdModel: TModel) => void,
        model: TModel,
        closeObservable: Observable<null>,
        submitHandlers: ModalSubmitHandler[],
        showClose: boolean,
        configurationDelegate: (form: BaseFormComponent<TModel>) => void
    )
    public ShowFormModal<TModel, TForm extends BaseFormComponent<TModel>>(
        formFactory: ComponentFactory<TForm>,
        title: string,
        formSubmitDelegate: (createdModel: TModel) => void,
        model: TModel,
        closeObservable: Observable<null>,
        submitHandlers: ModalSubmitHandler[],
        showClose: boolean,
        configurationDelegate: (form: BaseFormComponent<TModel>) => void,
        modalSize: ModalSize 
    )
    public ShowFormModal<TModel, TForm extends BaseFormComponent<TModel>>(
        formFactory: ComponentFactory<TForm>,
        title: string,
        formSubmitDelegate: (createdModel: TModel) => void,
        model: TModel,
        closeObservable: Observable<null>,
        submitHandlers: ModalSubmitHandler[],
        showClose: boolean = true,
        configurationDelegate: (form: BaseFormComponent<TModel>) => void = null,
        modalSize: ModalSize = ModalSize.Medium
    ): void {

        this._modalContainerFactory = this._factoryResolver.resolveComponentFactory(ModalContainerComponent);

        const _formModal: ComponentRef<ModalContainerComponent> = this._domReference.createComponent(this._modalContainerFactory);

        _formModal.instance._showClose = showClose;
        _formModal.instance.InitializeFormModal<TModel, TForm>(formFactory, _formModal, title, formSubmitDelegate, model, closeObservable, submitHandlers, configurationDelegate, modalSize);

    }

    public ShowPreloaderModal(
        portalLoadingFactory: ComponentFactory<PortalLoadingComponent>,
        title: string,
        closeObservable: Observable<null>,
        modalSize: ModalSize = ModalSize.Medium
    ): void {

        this._modalContainerFactory = this._factoryResolver.resolveComponentFactory(ModalContainerComponent);
        const _formModal: ComponentRef<ModalContainerComponent> = this._domReference.createComponent(this._modalContainerFactory);
        _formModal.instance._showClose = false;
        _formModal.instance._isPreloader = true;
        _formModal.instance.InitializePreloaderModal(portalLoadingFactory, _formModal, title, closeObservable, modalSize);

    }

}
