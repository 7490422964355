export enum LayoutMode {
    Details = 1,
    List = 2,
    Card = 3,
    Inline = 4,
    Title = 5,
    Brief = 6,
    Stacked = 7,
    Justified = 8,
    Dialog = 9
}