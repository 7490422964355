import { createReducer, on } from '@ngrx/store';
import { ListState, RelatedAssetListWidgetStateModel } from '../../AppState';
import { ListStateReducerHelper } from '../../helpers/list-state-reducer-helper';
import { endOperation, getRelatedAssetsBySearchDone, removeRelatedAssets, startOperation } from './related-assets.actions';
import { PaginationResultModel } from '../../models/pagination-models';
import { RelatedAssetListViewDTO } from '../../data-transfer-objects/related-asset/related-asset-list-view-dto';

export const initialRelatedAssetListState: ListState<RelatedAssetListWidgetStateModel>[] = [];

export const RelatedAssetListReducer = createReducer<ListState<RelatedAssetListWidgetStateModel>[]>(initialRelatedAssetListState,
    on(getRelatedAssetsBySearchDone, (state, action) => {
        const listState: RelatedAssetListWidgetStateModel = Object.assign({}, ListStateReducerHelper.GetListState<RelatedAssetListWidgetStateModel>(state, action.dataSourceId)) ?? {
            reloadList: false,
            operations: 0,
            paginationResult: null,
            error: null
        };
        
        listState.paginationResult = action.result;
        listState.reloadList = false;

        return ListStateReducerHelper.GetUpdatedListState<RelatedAssetListWidgetStateModel>(state, action.dataSourceId, listState);
    }),

    on(removeRelatedAssets, (state, action) => {
        const listState: RelatedAssetListWidgetStateModel = Object.assign({}, ListStateReducerHelper.GetListState<RelatedAssetListWidgetStateModel>(state, action.dataSourceId));
        
        if (!listState) {
            return state;
        }
        
        const currentPaginationModel: PaginationResultModel<RelatedAssetListViewDTO> = listState.paginationResult;
        
        let models: RelatedAssetListViewDTO[] = [];
        
        for (const m of listState.paginationResult.Models) {
            if (!action.relatedAssetIds.includes(m.Id)) {
                models.push(m);
            } else {
                models.push(Object.assign({}, m, { Id: '00000000-0000-0000-0000-000000000000' } ));
            }
        }
        
        listState.paginationResult = Object.assign({}, currentPaginationModel, {
            Models: models
        });

        return ListStateReducerHelper.GetUpdatedListState(state, action.dataSourceId, listState);
    }),
    
    on(startOperation, (state, action) => {
        const listState: RelatedAssetListWidgetStateModel = Object.assign({}, ListStateReducerHelper.GetListState<RelatedAssetListWidgetStateModel>(state, action.dataSourceId));

        if (!listState) {
            return state;
        }
        
        listState.operations = (listState.operations ?? 0) + 1;

        return ListStateReducerHelper.GetUpdatedListState(state, action.dataSourceId, listState);
    }),

    on(endOperation, (state, action) => {
        const listState: RelatedAssetListWidgetStateModel = Object.assign({}, ListStateReducerHelper.GetListState<RelatedAssetListWidgetStateModel>(state, action.dataSourceId));

        if (!listState) {
            return state;
        }

        listState.operations = (listState.operations ?? 0) - 1;
        listState.reloadList = true;

        return ListStateReducerHelper.GetUpdatedListState(state, action.dataSourceId, listState);
    })
);
