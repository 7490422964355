import { ActionReducerMap } from '@ngrx/store';
import { ActionDetailsPageState } from '../../../AppState';
import { ActionDocumentsReducer, ActionShapeReducer } from './action-details-inline.reducers';


export const ActionDetailsInlineReducerMapper: ActionReducerMap<ActionDetailsPageState> = {
    DocumentGroupWidgetState: ActionDocumentsReducer,
    ShapeWidgetState: ActionShapeReducer,
    RunShapeValidationState: null,
    ShapeValidationResultsState: null,
    DeleteJobState: null,
    IsSaving: null,
    ActionDraftLoaded: null,
};
