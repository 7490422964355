import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { executeProcessPaymentResponseRequest } from '../../state/post-payment/post-payment.actions';
import { AppState } from '../../AppState';
import { PaymentConnectorResponseInputViewDTO } from '../../data-transfer-objects/payment/payment-connector-response-input-view-dto';
import { BASE_URL } from '../../helpers/base-url';
import { SignalRApplicationService } from '../../services/application/signalr-base-application-service';
import {take} from 'rxjs/operators';

@Injectable()
export class PostPaymentFacade {

    constructor(private store: Store<AppState>,
        @Inject(BASE_URL)
        private baseUrl: string,
        private router: Router,
        private signalRApplicationService: SignalRApplicationService) {
    }

    public ProcessResponse(successful: boolean): void {
        const fullUrl = this.baseUrl.slice(0, -1) + this.router.url;

        const paymentConnectorResponseInputViewDTO: PaymentConnectorResponseInputViewDTO = {
            Success: successful,
            ResponseUrl: fullUrl
        };

        this.signalRApplicationService.ClientId.pipe(take(1)).subscribe(clientId => {
            this.store.dispatch(executeProcessPaymentResponseRequest({
                postPaymentRedirectResponse: {
                    ClientId: clientId,
                    PaymentConnectorResponseInputModel: paymentConnectorResponseInputViewDTO
                }
            }));
        });
    }
}
