import { HostListener, ViewChild, DoCheck } from '@angular/core';
import { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { UIStylingHelper } from 'src/app/helpers/ui-styling-helper';
import { TabSectionConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/tab-section-configuration-view-dto';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';

@Component({
    selector: 'fw-tab-section',
    templateUrl: './tab-section.component.html'
})
export class TabSectionComponent implements OnInit, DoCheck {

    activeTabIndex: number = 0;
    public isScrollable: boolean = false;

    @Input()
    public FormEditStyle: FormEditStyle;

    @Input()
    public TabSectionConfiguration: TabSectionConfigurationViewDTO = null;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @ViewChild('tabView') tabView: any;

    constructor() {
    }

    IsFormEditableStyle(): boolean {
        return this.FormEditStyle == FormEditStyle.Form;
    }

    ngOnInit(): void {

        if (this.IsFormEditableStyle()) {
            setTimeout(() => {
                for (let index = 0; index < this.TabSectionConfiguration.Containers.length; index++) {
                    this.activeTabIndex = index;
                }
                this.activeTabIndex = 0;
            }, 500);
        }
    }

    ngDoCheck(): void {
        this.onResize();
    }

    public getBackground(): object {

        return UIStylingHelper.getSectionBackground(this.TabSectionConfiguration);
    }

    public getBackgroundWidth(): string {

        return UIStylingHelper.getSectionBackgroundWidth(this.TabSectionConfiguration);
    }

    public getSectionStyling(): string {

        return UIStylingHelper.getSectionStyling(this.TabSectionConfiguration);
    }

    public getTabOrientation(): string {

        return UIStylingHelper.getMenuOrientation(this.TabSectionConfiguration.TabOrientation);
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (this.tabView) {
            this.isScrollable = this.tabView.navbar.nativeElement.scrollWidth > this.tabView.navbar.nativeElement.clientWidth;
        }
    }

    scrollNext(e) {
        this.tabView.navbar.nativeElement.scrollLeft += 150;
    }

    scrollPrevious(e) {
        this.tabView.navbar.nativeElement.scrollLeft -= 150;
    }
}
