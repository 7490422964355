import { ViewContainerRef, ComponentFactory, ComponentRef } from '@angular/core';
import { ReplaySubject, timer } from 'rxjs';
import { PortalLoadingComponent } from '../modules/static/generics/components/portal-loading/portal-loading.component';
import { SavedStampComponent } from '../modules/static/generics/components/saved-stamp/saved-stamp.component';
import { LocaleDateFormatPipe } from '../pipes/locale-date-format.pipe';
import { LanguageHttpService } from '../services/http/language-http.service';
import { SiteSettingsService } from '../services/deprecated/site-settings.service';


export class LoadingManager {
    private _loadCount: number = 0;
    private _started: boolean = false;
    private _stoppable: ReplaySubject<null>;

    constructor(private _viewContainers: ViewContainerRef[],
        private _loadingOffset: number,
        private _loadingFactory: ComponentFactory<PortalLoadingComponent>,
        private _savedStampFactory: ComponentFactory<SavedStampComponent>,
        private languageHttpService: LanguageHttpService,
        private siteSettingsService: SiteSettingsService
    ) { }

    public ShowLoading(): void {

        this._loadCount++;

        if (!this._started) {

            this._started = true;

            this._stoppable = new ReplaySubject<null>(1);

            this._viewContainers.forEach((container: ViewContainerRef) => {
                container.clear();
                container.createComponent(this._loadingFactory);
            });

            timer(this._loadingOffset).subscribe(() => {

                this._stoppable.next();

            })

        }

    }

    public StopLoading(): void {

        if (this._loadCount > 0) {
            this._loadCount--;
        }

        if (this._loadCount === 0 && this._stoppable) {

            this._stoppable.subscribe(() => {

                this._viewContainers.forEach((container: ViewContainerRef) => {
                    container.clear();

                    if (this._savedStampFactory) {

                        const savedStampInstance: ComponentRef<SavedStampComponent> = container.createComponent(this._savedStampFactory);

                        const localeDateFormatPipe = new LocaleDateFormatPipe(this.languageHttpService, this.siteSettingsService);
                        const date = new Date();
                        const displayDate = localeDateFormatPipe.transform(date);

                        savedStampInstance.instance._timeStamp = `${displayDate}, ${date.toLocaleTimeString()}`
                        savedStampInstance.instance._errorOccurred = false;
                    }
                });

                this._started = false;

            });

        }

    }

    public StopLoadingOnError(errorMessage: string): void {

        if (this._loadCount > 0) {
            this._loadCount--;
        }

        if (this._loadCount === 0 && this._stoppable) {

            this._stoppable.subscribe(() => {

                this._viewContainers.forEach((container: ViewContainerRef) => {
                    container.clear();

                    const savedStampInstance: ComponentRef<SavedStampComponent> = container.createComponent(this._savedStampFactory);

                    savedStampInstance.instance._errorOccurred = true;
                    savedStampInstance.instance._errorMessage = errorMessage;
                });

                this._started = false;

            });

        }

    }

    public isLoading(): boolean {
        return this._started === true && this._loadCount === 0;
    }
}
