import { AfterContentInit, Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, DoCheck, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MapConfigurationHelper, NgMapComponent } from '@landadmin/ng-mapping-library';
import { ShapeUpdateDTO } from '@landadmin/ng-mapping-library/lib/data-transfer-objects/shape-update-dto';
import { AreaUnit } from '@landadmin/ng-mapping-library/lib/models/area-unit';
import { DistanceUnit } from '@landadmin/ng-mapping-library/lib/models/distance-unit';
import { MapModule as NgMapModule } from '@landadmin/ng-mapping-library/lib/models/enums/map-module.enum';
import { MapShapeImporter as NgMapShapeImporter } from '@landadmin/ng-mapping-library/lib/models/enums/map-shapeimporter.enum';
import { MapConfiguration } from '@landadmin/ng-mapping-library/lib/models/map-configuration';
import { ShapeAndSymbology } from '@landadmin/ng-mapping-library/lib/models/shape-and-symbology';
import { ShapeOverlap } from '@landadmin/ng-mapping-library/lib/models/shape-overlap';
import { ShapePart } from '@landadmin/ng-mapping-library/lib/models/shape-part';
import { SpatialReferenceInformation } from '@landadmin/ng-mapping-library/lib/models/spatial-reference-information';
import { StandardMapState } from '@landadmin/ng-mapping-library/lib/models/standard-map-state';
import { TranslocoService } from '@ngneat/transloco';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AsyncSubject, forkJoin, fromEvent, interval, Observable, Subscriber, Subscription } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { MapReferenceOverlapTopology } from 'src/app/data-transfer-objects/shape/map-reference-overlap-topology';
import { ShapeSymbology } from 'src/app/models/configuration/widgets/map-widget-configuration models/shape-symbology-model';
import { ValidationResult } from 'src/app/models/validation/validation-result';
import { LanguageHttpService } from 'src/app/services/http/language-http.service';
import { SiteSettingsService } from 'src/app/services/deprecated/site-settings.service';
import { LoadingManager } from '../../../../../class-definitions/loading-manager';
import { severity_error, severity_info, severity_success, severity_warning } from '../../../../../consts/severity-options';
import { ListFieldConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { MapWidgetConfiguration as MapWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/map-widget-configuration-view-dto';
import { TcpfConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/tcpf-configuration-view-dto';
import { JobSchedulerViewDTO } from '../../../../../data-transfer-objects/job/job-scheduler-view-dto';
import { JobStatus } from '../../../../../data-transfer-objects/job/job-status';
import { AreaUnitViewDTO } from '../../../../../data-transfer-objects/mapping/area-unit-view-dto';
import { CoordinateViewDTO } from '../../../../../data-transfer-objects/mapping/coordinate-view-dto';
import { MapModule } from '../../../../../data-transfer-objects/mapping/enums/map-module.enum';
import { MapShapeImporter } from '../../../../../data-transfer-objects/mapping/enums/map-shapeimporter.enum';
import { MapConfigurationViewDTO } from '../../../../../data-transfer-objects/mapping/map-configuration-view-dto';
import { StandardMapStateViewDTO } from '../../../../../data-transfer-objects/mapping/standard-map-state-view-dto';
import { ClippedShapePartViewDTO } from '../../../../../data-transfer-objects/shape/clipped-shape-part-view-dto';
import { ShapePartViewDTO } from '../../../../../data-transfer-objects/shape/shape-part-view-dto';
import { ShapeValidationCheck } from '../../../../../data-transfer-objects/shape/shape-validation-check';
import { ShapeValidationResultViewDTO } from '../../../../../data-transfer-objects/shape/shape-validation-result-view-dto';
import { ShapeViewDTO } from '../../../../../data-transfer-objects/shape/shape-view-dto';
import { GeometryType } from '../../../../../enums/geometry-type';
import { GuidHelper } from '../../../../../helpers/guid-helper';
import { ShapeHelper, ShapeValidResult } from '../../../../../helpers/shape-helper';
import { CoordinatesListEntryModel } from '../../../../../models/coordinates-list-entry-model';
import { ModalMapDataModel } from '../../../../../models/modal-map-data-model';
import { DialogApplicationService, DialogOptions } from '../../../../../services/application/dialog-application.service';
import { ToastApplicationService } from '../../../../../services/application/toast-application.service';
import { MapService } from '../../../../../services/deprecated/map.service';
import { PortalLoadingComponent } from '../../../../static/generics/components/portal-loading/portal-loading.component';
import { BaseWidget } from '../base-widget';
import { AbstractMapFacade } from '../../../../../facade/abstract/abstract-map.facade';
import { ModalMapComponent } from './modal-map/modal-map.component';
import { HttpStatusCode } from '../../../../../enums/http-status-codes';
import { SpatialReferenceType } from '../../../../../data-transfer-objects/mapping/enums/spatial-reference-type-view-dto';

@Component({
    selector: 'fw-map-widget',
    templateUrl: './map-widget.component.html'
})

export class MapWidgetComponent extends BaseWidget implements OnInit, OnDestroy, AfterContentInit, DoCheck {

    public static SpatialReferenceType = { Geographic: 'GEOGRAPHIC', Projected: 'PROJECTED' };
    public static SquareKilometreAreaUnitName: string = 'Square Kilometre';

    public CalculatedArea: string;
    public CoordinateSystem: string;
    public CoordinatesListColumns: ListFieldConfigurationViewDTO[];
    public CoordinatesListEntries: CoordinatesListEntryModel[];
    public EditableState: boolean = false;
    public ExecutingShapeValidation: boolean = false;
    public GeneralRestrictions: Map<string, ShapeValidationResultViewDTO>;
    public GeneralWarnings: Map<string, ShapeValidationResultViewDTO>;
    public MapConfiguration: MapConfiguration;
    public MapLoaded: boolean;
    public OfficialArea: string;
    public OfficialLength: string;
    public OldShapeViewDTO: ShapeViewDTO;
    public OverlapRestrictions: Map<string, ShapeValidationResultViewDTO>;
    public OverlapWarnings: Map<string, ShapeValidationResultViewDTO>;
    public PollShapeValidationResultsCounter: number;
    public ResizeObservable: Observable<unknown>;

    public ShapeValid: ShapeValidResult;
    public ShapeValidationJobSchedulerViewDTO: JobSchedulerViewDTO;
    public ShapeValidationMessage: string;
    public ShapeValidationMessageLink: string;
    public ShapeValidationResultsVisible: boolean;
    public ShapeValidationSeverity: string;
    public ShapeViewDTO: ShapeViewDTO;
    public ShowCalculatedArea: boolean;
    public ShowCoordinateSystem: boolean;
    public ShowCoordinatesList: boolean;
    public ShowOfficialArea: boolean;
    public ShowOfficialLength: boolean;

    @Input()
    public MapWidgetConfigurationViewDTO: MapWidgetConfigurationViewDTO;

    private _dialogRef: DynamicDialogRef;
    private _factory: ComponentFactory<NgMapComponent>;
    private _getShapeValidationJobDetailsRequestSubscription: Subscription;
    private _getShapeValidationResultsRequestSubscription: Subscription;
    private _loadingManager: LoadingManager;
    private _mapRef: ComponentRef<NgMapComponent>;
    private _shapeAsyncSubject: AsyncSubject<null> = new AsyncSubject<null>();
    private _shapeClippingOverlapId: string;
    private _shapeOverlaps: ShapeAndSymbology[] = [];
    private _standardMapStateAsyncSubject: AsyncSubject<StandardMapStateViewDTO> = new AsyncSubject<StandardMapStateViewDTO>();
    private _subscriptions: Subscription[] = [];
    private loadMapAttempted: boolean = false;

    @ViewChild('mapContent', { read: ViewContainerRef })
    private _mapContent: ViewContainerRef;

    @ViewChild('maploader', { read: ViewContainerRef, static: true })
    private _mapLoader: ViewContainerRef



    constructor(private _elementRef: ElementRef,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _mapConfigService: MapService,
        private _dialogApplicationService: DialogApplicationService,
        private _toastApplicationService: ToastApplicationService,
        private _translocoService: TranslocoService,
        public _mapFacade: AbstractMapFacade,
        private siteSettingsService: SiteSettingsService,
        private languageHttpService: LanguageHttpService
    ) {

        super(_elementRef);

        this.ResizeObservable = fromEvent(this._elementRef.nativeElement, 'resize');
        this.ResizeObservable = this.ResizeObservable.pipe(debounce(() => interval(500)));
        this.MapLoaded = false;
        this.ShowCoordinatesList = false;
        this.ShowCoordinateSystem = false;
        this.ShowCalculatedArea = false;
        this.ShowOfficialArea = false;
        this.ShowOfficialLength = false;
        this.PollShapeValidationResultsCounter = 1;
        this._shapeClippingOverlapId = '';
    }

    public get isReadOnly(): boolean {
 
        return this._mapFacade._isReadOnly || (this.MapWidgetConfigurationViewDTO?.Editing?.ReadOnly ?? false);
    }

    public static CreateMap(factory: ComponentFactory<NgMapComponent>,
        mapRef: ComponentRef<NgMapComponent>,
        componentFactoryResolver: ComponentFactoryResolver,
        mapContent: ViewContainerRef,
        resizeObservable: Observable<unknown>,
        mapConfiguration: MapConfiguration,
        mapId: string): ComponentRef<NgMapComponent> {


        resizeObservable.subscribe(() => {
            this.resizeMap(mapRef);
        });

        factory = componentFactoryResolver.resolveComponentFactory(NgMapComponent);
        mapRef = mapContent.createComponent<NgMapComponent>(factory);

        mapRef.instance.MapConfiguration = mapConfiguration;
        mapRef.instance.MapId = mapId;
        mapRef.instance.EsriLoaded.subscribe(() => {
            mapRef.instance.LoadMap();
        });

        return mapRef;
    }

    private static hasMapLoaded(mapRef: ComponentRef<NgMapComponent>): Observable<boolean> {
        return new Observable((subscriber: Subscriber<boolean>) => {
            if (!mapRef.instance)
                subscriber.next(false);
            else
                subscriber.next(true);
        });
    }

    private static resizeMap(mapRef: ComponentRef<NgMapComponent>): void {
        this.hasMapLoaded(mapRef).subscribe((mapLoaded) => {
            if (mapLoaded) {
                mapRef.instance.MapLoaded.subscribe((map: NgMapComponent) => {
                    map.Resize();
                });
            }
        });
    }

    public override ngDoCheck(): void {

        if (this.IsVisible() && this.VisibilityChecked === false) {

            this.VisibilityChecked = true;

            if (this.loadMapAttempted === false) {
                this.loadMapAttempted = true;

                setTimeout(() => {
                    if (!this.MapLoaded) {
                        this.loadMap();
                    };
                })
            }
        }
    }



    public ClipAllRestrictionOverlaps(): void {
        this.ClipRestrictionOverlap(null);
    }

    public ClipAllRestrictionOverlapsLinkVisibility(): boolean {
        return this.ClipRestrictionOverlapLinkVisibility(null);
    }

    public ClipAllWarningOverlaps(): void {
        this.ClipWarningOverlap(null);
    }

    public ClipAllWarningOverlapsLinkVisibility(): boolean {
        return this.ClipWarningOverlapLinkVisibility(null);
    }

    public ClipOverlapLinkVisibility(shapeValidationResultViewDTOs: Map<string, ShapeValidationResultViewDTO>, shapeValidationResultViewDTO: ShapeValidationResultViewDTO): boolean {

        const displayLink = (shapeValidationResultViewDTO: ShapeValidationResultViewDTO) => {

            if (shapeValidationResultViewDTO.MapReferenceCheckTopology) {
                if ((shapeValidationResultViewDTO.MapReferenceCheckTopology === MapReferenceOverlapTopology.ShapeEntirelyWithinMapReference) ||
                    (shapeValidationResultViewDTO.MapReferenceCheckTopology === MapReferenceOverlapTopology.ShapeEntirelyOutsideMapReference)) {
                    return true;
                } else {
                    return false;
                }

            } else {
                return true;
            }
        }

        if (shapeValidationResultViewDTO) {
            return displayLink(shapeValidationResultViewDTO);

        } else {
            for (const shapeValidationResultViewDTO of shapeValidationResultViewDTOs.values()) {
                if (displayLink(shapeValidationResultViewDTO)) {
                    return true;
                }
            }

            return false;
        }
    }

    public ClipRestrictionOverlap(shapeValidationResultViewDTO: ShapeValidationResultViewDTO): void {
        this.ClipOverlap(this.OverlapRestrictions, shapeValidationResultViewDTO);
    }

    public ClipRestrictionOverlapLinkVisibility(shapeValidationResultViewDTO: ShapeValidationResultViewDTO): boolean {
        return this.ClipOverlapLinkVisibility(this.OverlapRestrictions, shapeValidationResultViewDTO);
    }

    public ClipWarningOverlap(shapeValidationResultViewDTO: ShapeValidationResultViewDTO): void {
        this.ClipOverlap(this.OverlapWarnings, shapeValidationResultViewDTO);
    }

    public ClipWarningOverlapLinkVisibility(shapeValidationResultViewDTO: ShapeValidationResultViewDTO): boolean {
        return this.ClipOverlapLinkVisibility(this.OverlapWarnings, shapeValidationResultViewDTO);
    }

    public HighlightAndZoomToOverlap(shapeValidationResultViewDTO: ShapeValidationResultViewDTO): void {
        this.highlightShapeOverlaps(shapeValidationResultViewDTO, true);
    }

    public HighlightOverlap(shapeValidationResultViewDTO: ShapeValidationResultViewDTO): void {
        this.highlightShapeOverlaps(shapeValidationResultViewDTO, false);
    }

    public RemoveOverlapHighlight(): void {
        if (this._mapRef && this._mapRef.instance) {

            this._mapRef.instance.RemoveHighlightOnShapesWithSymbology();
        }
    }

    public RunShapeValidation(): void {

        if (this.MapWidgetConfigurationViewDTO.ShapeValidationConfiguration && !GuidHelper.Equals(this.MapWidgetConfigurationViewDTO.ShapeValidationConfiguration.BusinessRuleId, GuidHelper.EmptyGuid())) {
            if (this.ShapeViewDTO?.Parts?.length > 0 && this.ShapeViewDTO?.Parts[0]?.Coordinates.length > 0) {
                if (this.ShapeValidationJobSchedulerViewDTO && this.ShapeValidationJobSchedulerViewDTO.JobId) {
                    if (this.ExecutingShapeValidation) {
                        this._mapFacade.DeleteJob(this.ShapeValidationJobSchedulerViewDTO.JobId);
                    }
                }

                this._mapFacade.RunShapeValidation(
                    this.MapWidgetConfigurationViewDTO.ShapeValidationConfiguration.BusinessRuleId,
                    this.ShapeViewDTO,
                    this.OldShapeViewDTO
                );

                this.ExecutingShapeValidation = true;

                if (!this._getShapeValidationJobDetailsRequestSubscription) {
                    this._getShapeValidationJobDetailsRequestSubscription = this._mapFacade.GetShapeValidationJobDetails().subscribe((runShapeValidationState) => {
                        if (runShapeValidationState.JobSchedulerViewDTO) {
                            this.ShapeValidationJobSchedulerViewDTO = runShapeValidationState.JobSchedulerViewDTO;
                            this._mapFacade.LoadShapeValidationResults(this.ShapeValidationJobSchedulerViewDTO.JobId);

                        } else {

                            let translationResourceId: string = '';

                            if (runShapeValidationState.RunShapeValidationError && runShapeValidationState.RunShapeValidationError.status !== HttpStatusCode.NOT_FOUND) {
                                translationResourceId = 'Widget.Map.ShapeValidationJobDetailsErrorDetail';

                                this._toastApplicationService.showToast('Widget.Map.ShapeValidationJobDetailsError', translationResourceId, severity_error, true);
                            }

                            this.ShapeValid = {
                                IsValid: false,
                                translationResourceId: translationResourceId
                            };
                            this._mapFacade.SetValidity(this.dataSourceId, this.ShapeValid.IsValid);
                            this.ExecutingShapeValidation = false;
                        }

                        this._mapFacade.SetIsSaving(false);
                    });

                    this._subscriptions.push(this._getShapeValidationJobDetailsRequestSubscription);
                }

                if (!this._getShapeValidationResultsRequestSubscription) {
                    this._getShapeValidationResultsRequestSubscription = this._mapFacade.GetShapeValidationResults().subscribe((getShapeValidationResultsState) => {
                        let shapePassedShapeValidation: boolean = false;
                        let pollShapeValidationJob: boolean = false;

                        if (getShapeValidationResultsState.JobQueryResultViewDTO) {
                            const shapeValidationJobQueryResultViewDTO = getShapeValidationResultsState.JobQueryResultViewDTO;

                            if (getShapeValidationResultsState.JobQueryResultViewDTO.StatusID === JobStatus.Succeeded ||
                                getShapeValidationResultsState.JobQueryResultViewDTO.StatusID === JobStatus.Failed ||
                                getShapeValidationResultsState.JobQueryResultViewDTO.StatusID === JobStatus.Unknown) {

                                if (shapeValidationJobQueryResultViewDTO.StatusID === JobStatus.Succeeded && shapeValidationJobQueryResultViewDTO.Data) {

                                    this.clearShapeValidationResults();

                                    const shapeOverlaps: ShapeAndSymbology[] = [];
                                    const shapeValidationWarningKeys = Object.keys(shapeValidationJobQueryResultViewDTO.Data.Warnings);

                                    for (let shapeValidationWarningsCounter = 0; shapeValidationWarningsCounter < shapeValidationWarningKeys.length; shapeValidationWarningsCounter++) {

                                        const shapeValidationWarningKey = shapeValidationWarningKeys[shapeValidationWarningsCounter];
                                        const shapeValidationWarning: ShapeValidationResultViewDTO = shapeValidationJobQueryResultViewDTO.Data.Warnings[shapeValidationWarningKey];

                                        if ((shapeValidationWarning.ShapeValidationCheck === ShapeValidationCheck.LicenseOverlaps || shapeValidationWarning.ShapeValidationCheck === ShapeValidationCheck.AgreementOverlaps || shapeValidationWarning.ShapeValidationCheck === ShapeValidationCheck.RestrictedAreaOverlaps ||
                                            shapeValidationWarning.ShapeValidationCheck === ShapeValidationCheck.MapReferenceOverlaps || shapeValidationWarning.ShapeValidationCheck === ShapeValidationCheck.WithinComparisonShape || shapeValidationWarning.ShapeValidationCheck === ShapeValidationCheck.ComparisonShapeWithinShape) &&
                                            shapeValidationWarning.OverlapIntersectionCoordinates) {
                                            this.OverlapWarnings.set(shapeValidationWarningKey, shapeValidationWarning);
                                            shapeOverlaps.push(this.convertToShapeAndSymbology(shapeValidationWarning));

                                        } else {
                                            this.GeneralWarnings.set(shapeValidationWarningKey, shapeValidationWarning);
                                        }
                                    }

                                    const shapeValidationRestrictionKeys = Object.keys(shapeValidationJobQueryResultViewDTO.Data.Restrictions);

                                    for (let shapeValidationRestrictionsCounter = 0; shapeValidationRestrictionsCounter < shapeValidationRestrictionKeys.length; shapeValidationRestrictionsCounter++) {

                                        const shapeValidationRestrictionKey = shapeValidationRestrictionKeys[shapeValidationRestrictionsCounter];
                                        const shapeValidationRestriction: ShapeValidationResultViewDTO = shapeValidationJobQueryResultViewDTO.Data.Restrictions[shapeValidationRestrictionKey]

                                        if ((shapeValidationRestriction.ShapeValidationCheck === ShapeValidationCheck.LicenseOverlaps || shapeValidationRestriction.ShapeValidationCheck === ShapeValidationCheck.AgreementOverlaps || shapeValidationRestriction.ShapeValidationCheck === ShapeValidationCheck.RestrictedAreaOverlaps ||
                                            shapeValidationRestriction.ShapeValidationCheck === ShapeValidationCheck.MapReferenceOverlaps || shapeValidationRestriction.ShapeValidationCheck === ShapeValidationCheck.WithinComparisonShape || shapeValidationRestriction.ShapeValidationCheck === ShapeValidationCheck.ComparisonShapeWithinShape) &&
                                            shapeValidationRestriction.OverlapIntersectionCoordinates) {
                                            this.OverlapRestrictions.set(shapeValidationRestrictionKey, shapeValidationRestriction);
                                            shapeOverlaps.push(this.convertToShapeAndSymbology(shapeValidationRestriction));

                                        } else {
                                            this.GeneralRestrictions.set(shapeValidationRestrictionKey, shapeValidationRestriction);
                                        }
                                    }

                                    if (this.GeneralRestrictions.size > 0 || this.OverlapRestrictions.size) {
                                        this.ShapeValidationSeverity = severity_error;
                                        this.ShapeValidationMessage = this._translocoService.translate('Widget.Map.ShapeValidationError');
                                        this.ShapeValidationMessageLink = this._translocoService.translate('Widget.Map.ShapeValidationDetails');
                                    } else if (this.GeneralWarnings.size > 0 || this.OverlapWarnings.size > 0) {
                                        this.ShapeValidationSeverity = severity_warning;
                                        this.ShapeValidationMessage = this._translocoService.translate('Widget.Map.ShapeValidationWarning');
                                        this.ShapeValidationMessageLink = this._translocoService.translate('Widget.Map.ShapeValidationDetails');
                                        shapePassedShapeValidation = true;
                                    } else {
                                        this.ShapeValidationSeverity = severity_success;
                                        this.ShapeValidationMessage = this._translocoService.translate('Widget.Map.ShapeValidationSuccessful');
                                        this.ShapeValidationMessageLink = '';
                                        shapePassedShapeValidation = true;
                                        this.ShapeValidationResultsVisibility(false);
                                    }

                                    this._shapeOverlaps = shapeOverlaps;

                                    if (this._shapeOverlaps && this._shapeOverlaps.length > 0) {
                                        if (this.MapLoaded) {
                                            ShapeHelper.addSymbologyToShapes(this._mapRef, this._shapeOverlaps, null, null);
                                            this._mapRef.instance.AddShapesWithSymbology(this._shapeOverlaps);
                                        }
                                    }

                                } else {
                                    this._toastApplicationService.showToast('Widget.Map.ShapeValidationResultsError', 'Widget.Map.ShapeValidationResultsErrorDetail', severity_error, true);
                                }
                            } else {
                                let pollTimeInMiliseconds: number = this.PollShapeValidationResultsCounter * this.PollShapeValidationResultsCounter * 1000;

                                if (pollTimeInMiliseconds > 30000) {
                                    pollTimeInMiliseconds = 30000;
                                }

                                setTimeout(self => {
                                    self._mapFacade.LoadShapeValidationResults(self.ShapeValidationJobSchedulerViewDTO.JobId);
                                    this.PollShapeValidationResultsCounter++;
                                }, pollTimeInMiliseconds, this);

                                pollShapeValidationJob = true;
                            }
                        } else {
                            if (getShapeValidationResultsState.GetShapeValidationResultsError && getShapeValidationResultsState.GetShapeValidationResultsError.status !== HttpStatusCode.NOT_FOUND) {
                                const translationResourceId: string = 'Widget.Map.ShapeValidationResultsErrorDetail';

                                this._toastApplicationService.showToast('Widget.Map.ShapeValidationResultsError', translationResourceId, severity_error, true);

                                this.ShapeValid = {
                                    IsValid: this.ShapeValid.IsValid && shapePassedShapeValidation,
                                    translationResourceId: translationResourceId
                                }
                            }
                        }

                        this._mapFacade.SetValidity(this.dataSourceId, this.ShapeValid.IsValid && shapePassedShapeValidation);
                        this.ExecutingShapeValidation = pollShapeValidationJob;
                    });

                    this._subscriptions.push(this._getShapeValidationResultsRequestSubscription);
                }
            }
        }
    }

    public ShapeValidationResultsVisibility(displayShapeValidationResults: boolean): void {
        this.ShapeValidationResultsVisible = displayShapeValidationResults;
    }

    public SwitchToEditMode(): void {

        if (this.MapLoaded) {
            this.EditableState = true;
            this.loadMap();
        }
    }

    ngAfterContentInit(): void {

        const getShapeRequestSubscription = this._mapFacade.GetShape().subscribe((shapeDraftWidgetState) => {
            if (shapeDraftWidgetState.GetShapeError && shapeDraftWidgetState.GetShapeError.status !== HttpStatusCode.NOT_FOUND) {
                this._toastApplicationService.showToast('Shape.GetError', 'Shape.GetError.Detail', severity_error, true);
            }
            else if (shapeDraftWidgetState.UpdateShapeError && shapeDraftWidgetState.UpdateShapeError.status !== HttpStatusCode.NOT_FOUND) {
                this._toastApplicationService.showToast('Shape.UpdateError', 'Shape.UpdateError.Detail', severity_error, true);
            }
            else {

                if (shapeDraftWidgetState.ListState && shapeDraftWidgetState.ListState.length > 0 && shapeDraftWidgetState.ListState[0].StateModel) {
                    this.ShapeViewDTO = shapeDraftWidgetState.ListState[0].StateModel;
                } else {
                    const shape: ShapeViewDTO = this.createShape();
                    this.ShapeViewDTO = this.setShapeDefaults(shape, this.MapConfiguration);

                    if (this._mapFacade._isEditable === false) {
                        this._mapFacade.UpdateShape(this.ShapeViewDTO);
                    }
                }

                if (shapeDraftWidgetState.ListState && shapeDraftWidgetState.ListState.length > 1 && shapeDraftWidgetState.ListState[1].StateModel) {
                    this.OldShapeViewDTO = shapeDraftWidgetState.ListState[1].StateModel;
                }

                this.RunShapeValidation();

                this.ShapeValid = ShapeHelper.ValidateViewShape(this.MapWidgetConfigurationViewDTO, this.ShapeViewDTO);

                this._mapFacade.SetValidity(this.dataSourceId, this.ShapeValid.IsValid && !this.ExecutingShapeValidation);
            }

            if (shapeDraftWidgetState.GetShapeError || shapeDraftWidgetState.ListState || shapeDraftWidgetState.UpdateShapeError) {
                this._shapeAsyncSubject.next(null);
                this._shapeAsyncSubject.complete();
            }

            this._mapFacade.SetIsSaving(false);
        });

        this._subscriptions.push(getShapeRequestSubscription);
    }

    ngOnDestroy() {

        if (this.ExecutingShapeValidation) {
            this._mapFacade.DeleteJob(this.ShapeValidationJobSchedulerViewDTO.JobId);
        }

        if (this._subscriptions) {
            this._subscriptions.forEach((subcription) => {
                if (subcription) {
                    subcription.unsubscribe();
                }
            })
        }

        if (this._dialogRef) {
            this._dialogRef.destroy();
        }
    }

    ngOnInit(): void {

        const ReloadSubject: Subscription = this._mapFacade._setLoadSubject.subscribe((readyToLoad: boolean) => {

            if (readyToLoad === true) {
                this._mapFacade.LoadShape();
            }

        })
        this._subscriptions.push(ReloadSubject);

        const GetMapConfigurationSubscription: Subscription = this._mapConfigService.GetMapConfigurationAsync().subscribe((config: StandardMapStateViewDTO) => {

            this.MapConfiguration = MapConfigurationHelper.GetMapConfigurationFromState(config as StandardMapState, this.MapWidgetConfigurationViewDTO.MapID);
            this._standardMapStateAsyncSubject.next(config);
            this._standardMapStateAsyncSubject.complete();
        });

        this._subscriptions.push(GetMapConfigurationSubscription);

        this._loadingManager = new LoadingManager(
            [this._mapLoader],
            1500,
            this._componentFactoryResolver.resolveComponentFactory(PortalLoadingComponent),
            null,
            this.languageHttpService,
            this.siteSettingsService
        );
        this._loadingManager.ShowLoading();
    }


    private ClipOverlap(shapeValidationResultViewDTOs: Map<string, ShapeValidationResultViewDTO>, shapeValidationResultViewDTO: ShapeValidationResultViewDTO): void {

        const shapeOverlaps: ShapeOverlap[] = [];

        if (shapeValidationResultViewDTO) {
            this._shapeClippingOverlapId = shapeValidationResultViewDTO.OverlapUniqueID;
            const shapeOverlap: ShapeOverlap = this.PrepareShapeOverlap(shapeValidationResultViewDTO);
            shapeOverlaps.push(shapeOverlap);

        } else {
            this._shapeClippingOverlapId = shapeValidationResultViewDTOs.values().next().value.OverlapUniqueID;
            shapeValidationResultViewDTOs.forEach(shapeValidationResultViewDTO => {
                const shapeOverlap: ShapeOverlap = this.PrepareShapeOverlap(shapeValidationResultViewDTO);
                shapeOverlaps.push(shapeOverlap);
            });
        }

        const shapeValidationRunDelayDuration: number = (this.OverlapRestrictions.size + this.OverlapWarnings.size === shapeOverlaps.length) ? 0 : 2000;

        if (this._mapRef && this._mapRef.instance) {

            this._subscriptions.push(this._mapRef.instance.ClipShapeOverlap(shapeOverlaps, true).subscribe(() => {

                this._subscriptions.push(ShapeHelper.ValidateMapWidgetData(this._mapRef, this._subscriptions, this.MapWidgetConfigurationViewDTO).subscribe((validationResult: ValidationResult<ShapeUpdateDTO>) => {

                    if (validationResult.Valid) {

                        this.updateShape(validationResult.Data);
                        this._mapFacade.UpdateShape(this.ShapeViewDTO);

                        this.setMapDetails();

                        if (this.ShapeViewDTO?.Parts?.length > 0 && this.ShapeViewDTO?.Parts[0]?.Coordinates.length > 0) {
                            setTimeout(self => {
                                if (shapeOverlaps[0].Shape.ID === this._shapeClippingOverlapId) {
                                    self.RunShapeValidation();
                                }
                            }, shapeValidationRunDelayDuration, this);

                        } else {

                            this.clearShapeValidationResults();
                            this.ShapeValidationResultsVisibility(false);

                            this.ShapeValidationSeverity = severity_info;
                            this.ShapeValidationMessage = this._translocoService.translate('Shape.NoAreaAfterClipping.Detail');
                            this.ShapeValidationMessageLink = '';

                            this._toastApplicationService.showToast('Shape.NoAreaAfterClipping', 'Shape.NoAreaAfterClipping.Detail', severity_info);

                            this.ShapeValid = {
                                IsValid: this.ShapeValid.IsValid,
                                translationResourceId: null
                            }
                            this._mapFacade.SetValidity(this.dataSourceId, this.ShapeValid.IsValid);
                        }
                    }
                    else {

                        const translationResourceId: string = validationResult.ValidationMessages[0];

                        this._toastApplicationService.showToast(null, translationResourceId, severity_error, true);

                        this.ShapeValid = {
                            IsValid: this.ShapeValid.IsValid,
                            translationResourceId: translationResourceId
                        }
                        this._mapFacade.SetValidity(this.dataSourceId, this.ShapeValid.IsValid);
                    }
                }));
            }));
        }
    }

    private PrepareShapeOverlap(shapeValidationResultViewDTO: ShapeValidationResultViewDTO): ShapeOverlap {

        const shapeOverlap: ShapeOverlap = {

            Shape: {
                ID: shapeValidationResultViewDTO.OverlapUniqueID,
                GeometryType: shapeValidationResultViewDTO.GeometryType,
                SpatialReferenceID: shapeValidationResultViewDTO.SpatialReferenceID,
                OfficialArea: shapeValidationResultViewDTO.Area,
                OfficialLength: shapeValidationResultViewDTO.Length,
                Parts: [],
                OfficialAreaText: shapeValidationResultViewDTO.AreaAndUnit,
                OfficialAreaUnitID: shapeValidationResultViewDTO.AreaUnitID,
                OfficialLengthText: shapeValidationResultViewDTO.LengthAndUnit,
                OfficialLengthUnitID: shapeValidationResultViewDTO.LengthUnitID,
                IsDynamic: false,
                LegalDescription: ''
            },

            MapReferenceCheckTopology: shapeValidationResultViewDTO.MapReferenceCheckTopology
        }

        const interiorShapeParts: ClippedShapePartViewDTO[] = [];

        const overlapCoordinates: ClippedShapePartViewDTO[] = shapeValidationResultViewDTO.OverlappingShapeCoordinates.length > 0 ?
            shapeValidationResultViewDTO.OverlappingShapeCoordinates :
            shapeValidationResultViewDTO.OverlapIntersectionCoordinates;

        overlapCoordinates.forEach((clippedShapePartViewDTO: ClippedShapePartViewDTO) => {

            const exteriorShapePart: ShapePart = shapeOverlap.Shape.Parts.find(shapePart => shapePart.ID === clippedShapePartViewDTO.ShapePart);

            if (exteriorShapePart) {
                exteriorShapePart.Coordinates.push(clippedShapePartViewDTO.X, clippedShapePartViewDTO.Y);

            } else {

                if (!GuidHelper.Equals(clippedShapePartViewDTO.ParentPartID, GuidHelper.EmptyGuid())) {
                    interiorShapeParts.push(clippedShapePartViewDTO);

                } else {

                    const newExteriorShapePart: ShapePart = {
                        ID: clippedShapePartViewDTO.ShapePart,
                        PartName: clippedShapePartViewDTO.PartName,
                        Coordinates: [clippedShapePartViewDTO.X, clippedShapePartViewDTO.Y],
                        InteriorParts: [],
                        ParentPartID: clippedShapePartViewDTO.ParentPartID,
                        NameSort: ''
                    }

                    shapeOverlap.Shape.Parts.push(newExteriorShapePart);
                }
            }
        });

        interiorShapeParts.forEach((clippedShapePartViewDTO: ClippedShapePartViewDTO) => {

            const parentShapePart: ShapePart = shapeOverlap.Shape.Parts.find(shapePart => shapePart.ID === clippedShapePartViewDTO.ParentPartID);

            if (parentShapePart) {
                const interiorShapePart: ShapePart = parentShapePart.InteriorParts.find(shapePart => shapePart.ID === clippedShapePartViewDTO.ShapePart);

                if (interiorShapePart) {
                    interiorShapePart.Coordinates.push(clippedShapePartViewDTO.X, clippedShapePartViewDTO.Y);

                } else {
                    const newInteriorShapePart: ShapePart = {
                        ID: clippedShapePartViewDTO.ShapePart,
                        PartName: clippedShapePartViewDTO.PartName,
                        Coordinates: [clippedShapePartViewDTO.X, clippedShapePartViewDTO.Y],
                        InteriorParts: [],
                        ParentPartID: clippedShapePartViewDTO.ParentPartID,
                        NameSort: ''
                    }

                    parentShapePart.InteriorParts.push(newInteriorShapePart);
                }
            }
        });

        return shapeOverlap;
    }

    private clearShapeValidationResults(): void {
        this.GeneralWarnings = new Map<string, ShapeValidationResultViewDTO>();
        this.OverlapWarnings = new Map<string, ShapeValidationResultViewDTO>();
        this.GeneralRestrictions = new Map<string, ShapeValidationResultViewDTO>();
        this.OverlapRestrictions = new Map<string, ShapeValidationResultViewDTO>();
    }

    private convertToShapeAndSymbology(shapeValidation: ShapeValidationResultViewDTO): ShapeAndSymbology {
        const overlapShapeParts = [];
        let currentShapePartID: string = '';
        let overlapShapePart: ShapePart = null;
        let interiorShapePart: boolean = false;

        for (let overlapCoordinatesCounter = 0; overlapCoordinatesCounter < shapeValidation.OverlapIntersectionCoordinates.length; overlapCoordinatesCounter++) {
            const overlapIntersection: ClippedShapePartViewDTO = shapeValidation.OverlapIntersectionCoordinates[overlapCoordinatesCounter];

            if (overlapCoordinatesCounter === 0 || !GuidHelper.Equals(currentShapePartID, overlapIntersection.ShapePart)) {
                interiorShapePart = false;
                currentShapePartID = overlapIntersection.ShapePart;

                if (overlapCoordinatesCounter !== 0 && !interiorShapePart) {
                    overlapShapeParts.push(overlapShapePart);
                }

                overlapShapePart = {
                    ID: overlapIntersection.ShapePart,
                    PartName: overlapIntersection.PartName,
                    Coordinates: [
                        overlapIntersection.X,
                        overlapIntersection.Y
                    ],
                    InteriorParts: [],
                    ParentPartID: overlapIntersection.ParentPartID,
                    NameSort: null
                }

                if (!GuidHelper.Equals(overlapIntersection.ParentPartID, GuidHelper.EmptyGuid())) {
                    const parentOverlapShape: ShapePart = overlapShapeParts.find((testParentOverlapShape: ShapePart) => {
                        return testParentOverlapShape.ID === overlapIntersection.ParentPartID
                    });

                    if (parentOverlapShape) {
                        parentOverlapShape.InteriorParts.push(overlapShapePart);
                        interiorShapePart = true;
                    }
                }
            } else {
                overlapShapePart.Coordinates.push(overlapIntersection.X, overlapIntersection.Y);
            }
        }

        if (!interiorShapePart) {
            overlapShapeParts.push(overlapShapePart);
        }

        return {
            ShapeID: shapeValidation.OverlapUniqueID,
            Shape: {
                ID: shapeValidation.OverlapUniqueID,
                GeometryType: shapeValidation.GeometryType,
                SpatialReferenceID: shapeValidation.SpatialReferenceID,
                OfficialArea: shapeValidation.Area,
                OfficialLength: shapeValidation.Length,
                Parts: overlapShapeParts,
                OfficialAreaUnitID: shapeValidation.AreaUnitID,
                OfficialAreaText: shapeValidation.AreaAndUnit,
                OfficialLengthUnitID: shapeValidation.LengthUnitID,
                OfficialLengthText: shapeValidation.LengthAndUnit,
                IsDynamic: false,
                LegalDescription: ''
            },
            DisplaySymbol: null,
            HighlightSymbol: null
        };
    }

    private createShape(): ShapeViewDTO {

        const newShape: ShapeViewDTO = {
            ID: GuidHelper.NewGuid(),
            GeometryType: GeometryType.POLYGON,
            SpatialReferenceID: GuidHelper.EmptyGuid(),
            OfficialArea: 0,
            OfficialLength: 0,
            Parts: [],
            OfficialAreaUnitID: GuidHelper.EmptyGuid(),
            OfficialAreaText: '',
            OfficialLengthUnitID: GuidHelper.EmptyGuid(),
            OfficialLengthText: '',
            IsDynamic: false,
            LegalDescription: '',
            CalculatedArea: 0,
            CalculatedAreaUnitID: GuidHelper.EmptyGuid()
        }

        return newShape;
    }

    private getCoordinates(coordinates: number[]): CoordinateViewDTO[] {

        const result: CoordinateViewDTO[] = [];

        for (let xIndex: number = 0; xIndex < coordinates.length; xIndex = xIndex + 2) {
            result.push({
                X: coordinates[xIndex],
                Y: coordinates[xIndex + 1]
            });
        }

        return result;
    }

    private getCoordinatesListEntries(shapeParts: ShapePartViewDTO[], shapeSpatialReferenceType: SpatialReferenceType): CoordinatesListEntryModel[] {

        let coordinatesListEntries: CoordinatesListEntryModel[] = [];

        if (shapeParts && shapeParts.length > 0) {

            shapeParts.forEach((shapePart: ShapePartViewDTO) => {

                const shapePartCoordinates: CoordinateViewDTO[] = this.getCoordinates(shapePart.Coordinates);

                coordinatesListEntries.push({
                    Index: 0,
                    PartName: shapePart.PartName,
                    IsInteriorPart: (shapePart.ParentPartID != GuidHelper.EmptyGuid()),
                    XCoordinate: '',
                    YCoordinate: ''
                });

                shapePartCoordinates.forEach((shapePartCoordinate: CoordinateViewDTO, index: number) => {

                    const xCoordinate: string = (shapeSpatialReferenceType == SpatialReferenceType.PROJECTED) ?
                        this.roundDecimalAsString(shapePartCoordinate.X) :
                        this.roundDecimalAsString(shapePartCoordinate.X, 7);

                    const yCoordinate: string = (shapeSpatialReferenceType == SpatialReferenceType.PROJECTED) ?
                        this.roundDecimalAsString(shapePartCoordinate.Y) :
                        this.roundDecimalAsString(shapePartCoordinate.Y, 7)

                    coordinatesListEntries.push({
                        Index: index + 1,
                        PartName: shapePart.PartName,
                        IsInteriorPart: (shapePart.ParentPartID != GuidHelper.EmptyGuid()),
                        XCoordinate: xCoordinate,
                        YCoordinate: yCoordinate
                    });
                });

                if (shapePart.InteriorParts && shapePart.InteriorParts.length > 0) {
                    coordinatesListEntries = coordinatesListEntries.concat(this.getCoordinatesListEntries(shapePart.InteriorParts, shapeSpatialReferenceType));
                }
            });
        }

        return coordinatesListEntries;
    }

    private getNgMapModules(mapModules: MapModule[]): NgMapModule[] {
        const ngMapModules: NgMapModule[] = [];

        if (mapModules) {
            mapModules.forEach(mapModule => {
                switch (mapModule) {
                    case MapModule.ChooseExistingShape:
                        ngMapModules.push(NgMapModule.ChooseExistingShape);
                        break;
                    case MapModule.Diagnostics:
                        ngMapModules.push(NgMapModule.Diagnostics);
                        break;
                    case MapModule.ExpandMap:
                        ngMapModules.push(NgMapModule.ExpandMap);
                        break;
                    case MapModule.Identify:
                        ngMapModules.push(NgMapModule.Identify);
                        break;
                    case MapModule.Legend:
                        ngMapModules.push(NgMapModule.Legend);
                        break;
                    case MapModule.Measure:
                        ngMapModules.push(NgMapModule.Measure);
                        break;
                    case MapModule.Search:
                        ngMapModules.push(NgMapModule.Search);
                        break;
                    case MapModule.Selection:
                        ngMapModules.push(NgMapModule.Selection);
                        break;
                    case MapModule.ShapeEditor:
                        ngMapModules.push(NgMapModule.ShapeEditor);
                        break;
                }
            });
        }

        return ngMapModules;
    }

    private getNgMapShapeImporter(mapShapeImporters: MapShapeImporter[]): NgMapShapeImporter[] {
        const ngMapShapeImporters: NgMapShapeImporter[] = [];

        if (mapShapeImporters) {
            mapShapeImporters.forEach(mapShapeImporter => {
                switch (mapShapeImporter) {
                    case MapShapeImporter.ShapeFile:
                        ngMapShapeImporters.push(NgMapShapeImporter.ShapeFile);
                        break;
                    case MapShapeImporter.MapInfo:
                        ngMapShapeImporters.push(NgMapShapeImporter.MapInfo);
                        break;
                }
            });
        }

        return ngMapShapeImporters;
    }

    private highlightShapeOverlaps(shapeValidationResultViewDTO: ShapeValidationResultViewDTO, zoomToOverlaps: boolean): void {
        const shapeOverlaps: ShapeAndSymbology[] = [];
        let shapeHighlightSymbology: ShapeSymbology = null;

        if (shapeValidationResultViewDTO != null) {
            shapeOverlaps.push(this.convertToShapeAndSymbology(shapeValidationResultViewDTO));
        }

        if (this.MapWidgetConfigurationViewDTO.ShapeValidationConfiguration?.ShapeSymbology?.Color) {

            shapeHighlightSymbology = this.MapWidgetConfigurationViewDTO.ShapeValidationConfiguration?.ShapeSymbology;
        }

        ShapeHelper.addSymbologyToShapes(this._mapRef, shapeOverlaps, null, shapeHighlightSymbology);
        this._mapRef.instance.HighlightShapesWithSymbology(shapeOverlaps, zoomToOverlaps);
    }

    private loadMap(): void {

        this._standardMapStateAsyncSubject.subscribe((config: StandardMapStateViewDTO) => {

            forkJoin({
                tcpfconfiguration: this._mapConfigService.GetTcpfConfigurationAsync(),
                shape: this._shapeAsyncSubject
            }).subscribe((results: { tcpfconfiguration: TcpfConfigurationViewDTO; shape: ShapeViewDTO; }) => {

                let supportedModules: MapModule[] = [];
                let modulesVisibleOnStartUp: MapModule[] = [];
                let modulesToSwitchVisibility: MapModule[] = [];
                let shapeImporters: MapShapeImporter[] = [];

                this.MapConfiguration = MapConfigurationHelper.GetMapConfigurationFromState(config as StandardMapState, this.MapWidgetConfigurationViewDTO.MapID);
                this.MapConfiguration.TcpfConfiguration = results.tcpfconfiguration;

                modulesVisibleOnStartUp = this.MapWidgetConfigurationViewDTO.ModulesVisibleOnStartUp;
                modulesToSwitchVisibility = this.MapWidgetConfigurationViewDTO.ModulesToSwitchVisibility;
                shapeImporters = this.MapWidgetConfigurationViewDTO.ShapeImporters;
                supportedModules = [...new Set([...modulesVisibleOnStartUp, ...modulesToSwitchVisibility])]

                if (this._mapFacade._isEditable && !this.EditableState) {

                    this.setMapDetails();
                    if (!supportedModules.includes(MapModule.ShapeEditor)) {
                        supportedModules.push(MapModule.ShapeEditor);
                    }
                }

                this.MapConfiguration.Shape = this.ShapeViewDTO;
                if (this.OldShapeViewDTO) {
                    this.MapConfiguration.ReadonlyShapes = [this.OldShapeViewDTO];
                }

                this.MapConfiguration.Editable = this._mapFacade._isEditable && this.EditableState;
                this.MapConfiguration.OverrideModuleVisibilityInDefaultView = this._mapFacade._isEditable && !this.EditableState;

                this.MapConfiguration.SupportedModules = this.getNgMapModules(supportedModules);
                this.MapConfiguration.ModulesVisibleOnStartUp = this.getNgMapModules(modulesVisibleOnStartUp);
                this.MapConfiguration.ModulesToSwitchVisibility = this.getNgMapModules(modulesToSwitchVisibility);
                this.MapConfiguration.ShapeImporters = this.getNgMapShapeImporter(shapeImporters);
                this.MapConfiguration.ShapeEditorConfiguration = this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration;
                this.MapConfiguration.MapSize = this.MapWidgetConfigurationViewDTO.MapSize;
                this.MapConfiguration.ShowSidebarAutomatically = true;

                if (this._mapRef) {
                    this._mapRef.destroy();
                    this._mapRef = undefined;
                }

                if (this._mapContent) {
                    this._mapContent.clear();
                }

                if (this._mapFacade._isEditable === false || this.EditableState === false) {
                    this._mapRef = MapWidgetComponent.CreateMap(this._factory, this._mapRef, this._componentFactoryResolver, this._mapContent, this.ResizeObservable, this.MapConfiguration, `${this._mapFacade._pageId}_${this.MapWidgetConfigurationViewDTO.Id}`);

                    const mapLoadedSubscription = this._mapRef.instance.MapLoaded.subscribe(() => {
                        this.MapLoaded = true;
                        if (this._shapeOverlaps && this._shapeOverlaps.length > 0) {
                            ShapeHelper.addSymbologyToShapes(this._mapRef, this._shapeOverlaps, null, null);
                            this._mapRef.instance.AddShapesWithSymbology(this._shapeOverlaps);
                        }

                        if (this.OldShapeViewDTO) {
                            const oldShapeWithSymbology: ShapeAndSymbology[] = [{
                                ShapeID: this.OldShapeViewDTO.ID,
                                Shape: this.OldShapeViewDTO,
                                DisplaySymbol: null,
                                HighlightSymbol: null
                            }];

                            ShapeHelper.addSymbologyToShapes(this._mapRef, oldShapeWithSymbology, this.MapWidgetConfigurationViewDTO.OldShapeSymbology, this.MapWidgetConfigurationViewDTO.OldShapeSymbology);

                            if (!this.ShapeViewDTO || !this.ShapeViewDTO.Parts || this.ShapeViewDTO.Parts.length < 1) {
                                this._mapRef.instance.HighlightShapesWithSymbology(oldShapeWithSymbology, true);
                            } else {
                                this._mapRef.instance.AddShapesWithSymbology(oldShapeWithSymbology);
                            }
                        }
                        this._loadingManager.StopLoading();
                    });

                    this._subscriptions.push(mapLoadedSubscription);
                }
                else {

                    const modalMapData: ModalMapDataModel = {
                        MapConfiguration: this.MapConfiguration,
                        MapWidgetConfigurationViewDTO: this.MapWidgetConfigurationViewDTO,
                        MapStateId: `${this._mapFacade._pageId}_${this.MapWidgetConfigurationViewDTO.Id}_Edit`
                    }

                    const dialogOptions: DialogOptions<ModalMapDataModel> = {
                        closable: false,
                        showHeader: false,
                        footer: '',
                        header: '',
                        dataModel: modalMapData,
                        styleClass: 'map-modal'
                    }

                    this._dialogRef = this._dialogApplicationService.showFormDialog(ModalMapComponent, dialogOptions);

                    this._dialogRef.onClose.subscribe((updatedShape: ShapeUpdateDTO) => {

                        if (updatedShape) {

                            this.updateShape(updatedShape);
                            this._mapFacade.UpdateShape(this.ShapeViewDTO);
                            this._shapeOverlaps = [];

                            if (this.MapWidgetConfigurationViewDTO.ShapeValidationConfiguration && !GuidHelper.Equals(this.MapWidgetConfigurationViewDTO.ShapeValidationConfiguration.BusinessRuleId, GuidHelper.EmptyGuid())) {
                                if (!(this.ShapeViewDTO?.Parts?.length > 0 && this.ShapeViewDTO?.Parts[0]?.Coordinates.length > 0)) {
                                    this.ShapeValidationSeverity = '';
                                    this.ShapeValidationResultsVisibility(false);
                                    this.clearShapeValidationResults();
                                }
                            }

                        }

                        this._dialogRef.destroy();
                        this._dialogRef = undefined;

                        this.EditableState = false;
                        this.loadMap();
                    });

                    this._loadingManager.StopLoading();
                }
            });
        });
    }

    private roundDecimal(input: number, decimalPlaces: number = 2): number {

        const factor: number = Math.pow(10, decimalPlaces);
        return (Math.round((input + Number.EPSILON) * factor) / factor);
    }

    private roundDecimalAsString(input: number, decimalPlaces: number = 2): string {

        return this.roundDecimal(input, decimalPlaces).toFixed(decimalPlaces);
    }

    private setCalculatedArea(): void {

        let calculatedArea: string = '0 ';

        if (this.ShapeViewDTO && this.ShapeViewDTO.CalculatedArea) {

            calculatedArea = this.roundDecimalAsString(this.ShapeViewDTO.CalculatedArea) + ' ';
        }

        const calculatedAreaUnit: AreaUnit = this.MapConfiguration.StandardMapState.MapControllerState.AreaUnits.find(areaUnit => areaUnit.ID === this.ShapeViewDTO.CalculatedAreaUnitID);

        if (this.ShapeViewDTO && calculatedAreaUnit && calculatedAreaUnit.Abbreviation) {

            calculatedArea += calculatedAreaUnit.Abbreviation;
        }

        this.CalculatedArea = calculatedArea;
    }

    private setCoordinateSystem(): void {

        const spatialReference: SpatialReferenceInformation = this.MapConfiguration.StandardMapState.MapControllerState.SpatialReferences.find(spatialReference => spatialReference.SpatialReferenceID === this.ShapeViewDTO.SpatialReferenceID);


        this.CoordinateSystem = spatialReference.Name;
    }

    private setCoordinatesListColumns(shapeSpatialReferenceType: SpatialReferenceType): void {

        let coordinatesListColumns: ListFieldConfigurationViewDTO[] = [];

        if (shapeSpatialReferenceType) {

            coordinatesListColumns = [
                {
                    PropertyName: 'Index',
                    HeaderLabelResourceId: '',
                    IsRoutingField: false,
                    Navigate: null,
                    Metadata: null
                },
                {
                    PropertyName: 'XCoordinate',
                    HeaderLabelResourceId: 'Map.CoordinatesList_XCoordinateColumn_' + shapeSpatialReferenceType,
                    IsRoutingField: false,
                    Navigate: null,
                    Metadata: null
                },
                {
                    PropertyName: 'YCoordinate',
                    HeaderLabelResourceId: 'Map.CoordinatesList_YCoordinateColumn_' + shapeSpatialReferenceType,
                    IsRoutingField: false,
                    Navigate: null,
                    Metadata: null
                }
            ];
        }

        this.CoordinatesListColumns = coordinatesListColumns;
    }

    private setMapDetails(): void {

        this._standardMapStateAsyncSubject.subscribe((config: StandardMapStateViewDTO) => {

            if (this.MapWidgetConfigurationViewDTO && this.MapWidgetConfigurationViewDTO.MapDetailsConfiguration) {
                this.ShowCoordinatesList = this.MapWidgetConfigurationViewDTO.MapDetailsConfiguration.ShowCoordinatesList;
                this.ShowCalculatedArea = this.MapWidgetConfigurationViewDTO.MapDetailsConfiguration.ShowCalculatedArea;
                this.ShowCoordinateSystem = this.MapWidgetConfigurationViewDTO.MapDetailsConfiguration.ShowCoordinateSystem;
                this.ShowOfficialArea = this.MapWidgetConfigurationViewDTO.MapDetailsConfiguration.ShowOfficialArea;
                this.ShowOfficialLength = this.MapWidgetConfigurationViewDTO.MapDetailsConfiguration.ShowOfficialLength;
            }

            if (this.ShowCoordinatesList) {

                let shapeSpatialReferenceType: SpatialReferenceType = null;
                const spatialReferenceInformation: SpatialReferenceInformation = config.MapControllerState.SpatialReferences.find(spatialReference => spatialReference.SpatialReferenceID == this.ShapeViewDTO?.SpatialReferenceID);


                if (spatialReferenceInformation) {
                    shapeSpatialReferenceType = spatialReferenceInformation.SpatialReferenceType;
                }


                this.setCoordinatesListColumns(shapeSpatialReferenceType);
                this.CoordinatesListEntries = this.getCoordinatesListEntries(this.ShapeViewDTO.Parts, shapeSpatialReferenceType);

            }

            if (this.ShowCoordinateSystem) {

                this.setCoordinateSystem();
            }

            if (this.ShowCalculatedArea) {

                this.setCalculatedArea();
            }

            if (this.ShowOfficialArea) {

                this.setOfficialArea();
            }

            if (this.ShowOfficialLength) {

                this.setOfficialLength();
            }
        });
    }

    private setOfficialArea(): void {

        let officialArea: string = '0 ';

        if (this.ShapeViewDTO && this.ShapeViewDTO.OfficialArea) {

            officialArea = this.roundDecimalAsString(this.ShapeViewDTO.OfficialArea) + ' ';
        }

        const officialAreaUnit: AreaUnit = this.MapConfiguration.StandardMapState.MapControllerState.AreaUnits.find(areaUnit => areaUnit.ID === this.ShapeViewDTO.OfficialAreaUnitID);

        if (this.ShapeViewDTO && officialAreaUnit && officialAreaUnit.Abbreviation) {

            officialArea += officialAreaUnit.Abbreviation;
        }

        this.OfficialArea = officialArea;
    }

    private setOfficialLength(): void {

        let officialLength: string = '0 ';

        if (this.ShapeViewDTO && this.ShapeViewDTO.OfficialLength) {

            officialLength = this.roundDecimalAsString(this.ShapeViewDTO.OfficialLength) + ' ';
        }

        const officialLengthUnit: DistanceUnit = this.MapConfiguration.StandardMapState.MapControllerState.DistanceUnits.find(lengthUnit => lengthUnit.ID === this.ShapeViewDTO.OfficialLengthUnitID);

        if (this.ShapeViewDTO && officialLengthUnit && officialLengthUnit.Abbreviation) {

            officialLength += officialLengthUnit.Abbreviation;
        }

        this.OfficialLength = officialLength;
    }

    private setShapeDefaults(shape: ShapeViewDTO, config: MapConfigurationViewDTO): ShapeViewDTO {

        if (this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration && this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration.OfficialAreaUnitID) {

            shape.OfficialAreaUnitID = this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration.OfficialAreaUnitID;

        } else {

            if (config.DefaultAreaUnit) {
                shape.OfficialAreaUnitID = config.DefaultAreaUnit;
            }
        }

        if (this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration && this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration.OfficialLengthUnitID) {

            shape.OfficialLengthUnitID = this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration.OfficialLengthUnitID;

        } else {

            if (config.DefaultDistanceUnit) {
                shape.OfficialLengthUnitID = config.DefaultDistanceUnit;
            }
        }

        if (this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration && this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration.CalculatedAreaUnitID) {

            shape.CalculatedAreaUnitID = this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration.CalculatedAreaUnitID;

        } else {

            if (config.DefaultAreaUnit) {
                shape.CalculatedAreaUnitID = config.DefaultAreaUnit;
            }
        }

        if (this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration && this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration.ShapeSpatialReferenceID) {

            shape.SpatialReferenceID = this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration.ShapeSpatialReferenceID;

        } else {

            if (config.PageSpatialReference) {
                shape.SpatialReferenceID = config.PageSpatialReference;
            }
        }

        if (this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration && this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration.ShapeGeometryType) {

            shape.GeometryType = this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration.ShapeGeometryType;
        }

        return shape;
    }

    private updateShape(updatedShape: ShapeUpdateDTO): void {

        const { CalculatedAreaInKm2, Parts, ...shapeViewDTO } = { ...updatedShape, CalculatedArea: 0, CalculatedAreaUnitID: this.ShapeViewDTO.CalculatedAreaUnitID };

        if (!shapeViewDTO.CalculatedAreaUnitID || shapeViewDTO.CalculatedAreaUnitID == GuidHelper.EmptyGuid()) {

            if (this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration && this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration.CalculatedAreaUnitID) {

                shapeViewDTO.CalculatedAreaUnitID = this.MapWidgetConfigurationViewDTO.ShapeEditorConfiguration.CalculatedAreaUnitID;
            }
            else {

                shapeViewDTO.CalculatedAreaUnitID = this.MapConfiguration.StandardMapState.MapControllerState.AreaUnits.find(areaUnit => areaUnit.Unit == MapWidgetComponent.SquareKilometreAreaUnitName).ID;
            }
        }

        if (CalculatedAreaInKm2 && shapeViewDTO.CalculatedAreaUnitID) {

            const calculatedAreaUnit: AreaUnitViewDTO = this.MapConfiguration.StandardMapState.MapControllerState.AreaUnits.find(areaUnit => areaUnit.ID == shapeViewDTO.CalculatedAreaUnitID);

            shapeViewDTO.CalculatedArea = CalculatedAreaInKm2 / calculatedAreaUnit.ConversionFactorToKm2;
        }

        this.ShapeViewDTO = { ...shapeViewDTO, Parts: Parts };
    }
}
