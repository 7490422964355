import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LookupModel } from '../../../../../../models/lookup-model';

@Component({
    selector: 'fw-lookup-column-filter',
    templateUrl: './lookup-column-filter.component.html',
})
export class LookupColumnFilterComponent implements OnInit {

    public selectedValue: LookupModel;

    @Input()
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public InputFilter: { lookupType: number, lookupValue: any };

    @Input()
    public Options: LookupModel[];

    @Output()
    public OnFilter: EventEmitter<{ lookupType: number, lookupValue: LookupModel }> = new EventEmitter<{ lookupType: number, lookupValue: LookupModel }>();

    @Output()
    public OnClearFilter: EventEmitter<void> = new EventEmitter<void>();

    constructor(public transloco: TranslocoService) { }

    ngOnInit(): void {
        if (this.InputFilter && this.InputFilter.lookupValue != null) {
            this.selectedValue = this.InputFilter.lookupValue.lookupValue;
        }
    }

    public ApplyFilter() {

        if (this.selectedValue)
            this.OnFilter.emit({ lookupType: this.InputFilter.lookupType, lookupValue: this.selectedValue });
    }

    public ClearFilter() {
        this.selectedValue = null;;
        this.OnClearFilter.emit();
    }
}