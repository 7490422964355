import { ActionReducerMap } from '@ngrx/store';
import { ActionDraftPageState } from '../../../AppState';
import { DocumentDraftsReducer, RunShapeValidationReducer, GetShapeValidationResultsReducer, DeleteJobReducer, ShapeDraftReducer } from './create-action-details-form.reducers';


export const ActionDraftPageReducerMapper: ActionReducerMap<ActionDraftPageState> = {
    DocumentGroupWidgetState: DocumentDraftsReducer,
    ShapeDraftWidgetState: ShapeDraftReducer,
    RunShapeValidationState: RunShapeValidationReducer,
    ShapeValidationResultsState: GetShapeValidationResultsReducer,
    DeleteJobState: DeleteJobReducer
};
