import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SiteSettingsConfigurationState, SiteSettingsConfigurationStateConst } from 'src/app/AppState';

export const getSiteSettingsConfigurationState = createFeatureSelector<SiteSettingsConfigurationState>(SiteSettingsConfigurationStateConst);

export const getDateFormat = createSelector(
    getSiteSettingsConfigurationState,
    (state: SiteSettingsConfigurationState) => state.dateFormat
);

export const getCalendars = createSelector(
    getSiteSettingsConfigurationState,
    (state: SiteSettingsConfigurationState) => state.calendars
);