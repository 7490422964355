import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DiscussionListWidgetConfigurationViewDTO } from '../../../../data-transfer-objects/configuration/widgets/discussion-list-widget-configuration-view-dto';
import { DiscussionViewDTO } from '../../../../data-transfer-objects/discussion/discussion-view-dto';
import { PaginationResultModel } from '../../../../models/pagination-models';
import { IListWidget, OnLoadWrapper } from '../../../dynamic/widgets/types/lists/list-widget-interface';
import { AbstractDiscussionTopicListFacade } from '../../../../facade/abstract/abstract-discussion-topic-list.facade';
import { DiscussionsWidgetStateModel, ListState } from '../../../../AppState';
import { ToastService } from '../../../../services/deprecated/toast.service';
import { DialogApplicationService, DialogOptions } from '../../../../services/application/dialog-application.service';
import { AddNewDiscussionTopicComponent } from '../discussion-add-new/discussion-add-new.component';
import { DiscussionCreateDTO } from '../../../../data-transfer-objects/discussion/discussion-create-dto';
import { TranslocoService } from '@ngneat/transloco';
import { DiscussionLoadMode } from '../../../../data-transfer-objects/discussion/discussion-load-mode';
import { ObjectHelper } from '../../../../helpers/object-helper';
import { SelectMode } from '../../../../enums/select-mode';
import { HttpStatusCode } from '../../../../enums/http-status-codes';

@Component({
    selector: 'fw-discussion-topic-list',
    templateUrl: './discussion-topic-list.component.html'
})
export class DiscussionTopicListComponent implements OnInit, OnDestroy, IListWidget<DiscussionViewDTO> {

    public PaginationResultModel: PaginationResultModel<DiscussionViewDTO>;
    public errorMessageResourceId: string = null;
    public selectMode: SelectMode = SelectMode.Single;

    @Input()
    public DataSourceId: string;

    @Input()
    public DiscussionListWidgetConfigurationViewDTO: DiscussionListWidgetConfigurationViewDTO;

    @Output()
    public TopicClicked: EventEmitter<DiscussionViewDTO> = new EventEmitter<DiscussionViewDTO>();
    @Input()
    public IsReadOnly: boolean = false;

    private subscriptions: Subscription[] = [];
   
    constructor(
        public abstractDiscussionTopicListFacade: AbstractDiscussionTopicListFacade,
        private dialogApplicationService: DialogApplicationService,
        private toastService: ToastService,
        public transloco: TranslocoService) { }

    ngOnInit(): void {
        this.errorMessageResourceId = 'Lists.GenericGetError';

        this.DiscussionListWidgetConfigurationViewDTO.PageSize = 1;
        this.DiscussionListWidgetConfigurationViewDTO.DefaultSort = 'Topic';

        const topicListSub = this.abstractDiscussionTopicListFacade.GetListItems().subscribe((discussionTopicListState: ListState<DiscussionsWidgetStateModel>[]) => {

            if (!discussionTopicListState || discussionTopicListState.length == 0) {
                return;
            }

            const stateEntry = discussionTopicListState.find((alw) => alw.dataSourceId == this.DataSourceId);

            if (stateEntry) {

                if (stateEntry.StateModel.error && stateEntry.StateModel.error.status !== HttpStatusCode.NOT_FOUND) {

                    this.toastService.ShowErrorToast(stateEntry.StateModel.error, [{
                        Message: this.errorMessageResourceId,
                        RouterLink: null,
                        RouterText: null,
                        QueryParameters: null,
                        MessageParameters: null
                    }], true);

                } else {
                    this.errorMessageResourceId = null;
                }

                if (stateEntry.StateModel.loadMode === DiscussionLoadMode.Populate) {
                    this.PaginationResultModel = ObjectHelper.deepCopyJsonParse(stateEntry.StateModel.paginationResult);
                }
                else if (stateEntry.StateModel.loadMode === DiscussionLoadMode.UpdateLatestItemOnly) {
                    const discussionToBeUpdated = this.PaginationResultModel.Models.find(discussion => discussion.Id === stateEntry.StateModel.discussionIdToBeUpdated);
                    discussionToBeUpdated.LatestComment = stateEntry.StateModel.latestComment;
                    discussionToBeUpdated.LastUpdated = stateEntry.StateModel.lastUpdated;
                }

                this.abstractDiscussionTopicListFacade._discussionsExist = this.PaginationResultModel.Models.length > 0;
            }
        });

        this.subscriptions.push(topicListSub);
    }

    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.forEach(subscription => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            })
        }
    }
    public newDiscussionClicked() {

        const dialogOptions: DialogOptions<string> = {
            closable: false,
            showHeader: true,
            footer: '',
            header: this.transloco.translate(this.DiscussionListWidgetConfigurationViewDTO.NewDiscussionHeaderResourceId),
            dataModel: ''
        };

        const dialogRef = this.dialogApplicationService.showFormDialog(
            AddNewDiscussionTopicComponent,
            dialogOptions
        );

        const dialogSubscription = dialogRef.onClose.subscribe(
            (discussion: DiscussionCreateDTO) => {
                if (discussion) {
                    this.abstractDiscussionTopicListFacade.CreateDiscussion(discussion, this.DataSourceId);
                }
            }
        );

        this.subscriptions.push(dialogSubscription);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public LoadTopic(topic: any): void {
        this.TopicClicked.emit(this.PaginationResultModel.Models.find(discussion => discussion.Id === topic.Id));
    }

    public StripHtml(html: string) {
        let div = document.createElement('DIV');

        div.innerHTML = html;

        const cleanText = div.innerText;

        div = null; // prevent mem leaks

        return cleanText;
    }

    OnLoad(onLoadWrapper: OnLoadWrapper): void {
        const topicListSub = this.abstractDiscussionTopicListFacade._setLoadSubject.subscribe(readyToLoad => {
            if (readyToLoad === true) {
                this.abstractDiscussionTopicListFacade._entityId = this.abstractDiscussionTopicListFacade._entityId;
                this.abstractDiscussionTopicListFacade.LoadListItems(this.DiscussionListWidgetConfigurationViewDTO.Fields, onLoadWrapper.paginationModel, onLoadWrapper.searchModel, this.DiscussionListWidgetConfigurationViewDTO.Filters, this.DataSourceId);
            }
        });

        this.subscriptions.push(topicListSub);
    }
}
