<p>{{'ForgotPassword.Form.Instruction' | transloco }}</p>

<div>
    <div #inlineAlert></div>
</div>

<portal-text-field id="email" name="email" #email="ngModel" [(ngModel)]="model" required
                   [is-valid]="!email.touched || email.valid" label-text="{{'ForgotPassword.Form.Email' | transloco }}"
                   [invalid-text]="model === '' ? ('ForgotPassword.Form.EmailAddressRequired' | transloco) : ('ForgotPassword.Form.InvalidEmailAddress' | transloco)" [email]="true">
</portal-text-field>

<div class="p-dialog-footer justify-content-end">
    <p-button styleClass="btn btn-primary btn-lg" type="button" label="{{'ForgotPassword.Form.RecoverPassword' | transloco }}" [disabled]="!email.valid" (onClick)="proceedClicked()"></p-button>
</div>
