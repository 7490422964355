import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DiscussionQueryDefinitionFilterViewDTO } from '../../data-transfer-objects/configuration/list-configuration/query-definitions/discussion-query-definition-filter-view-dto';
import { DiscussionCommentCreateDTO } from '../../data-transfer-objects/discussion-comment/discussion-comment-create-dto';
import { DiscussionCreateDTO } from '../../data-transfer-objects/discussion/discussion-create-dto';
import { DiscussionViewDTO } from '../../data-transfer-objects/discussion/discussion-view-dto';
import { HttpStatusCode } from '../../enums/http-status-codes';
import { BASE_URL } from '../../helpers/base-url';
import { HttpFieldModel } from '../../models/field-list-model';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class DiscussionHttpService extends AuthenticatedHttpServiceBase {
    constructor(httpClient: HttpClient, @Inject(BASE_URL) private _baseUrl: string, errorManager: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorManager, toastService);
    }

    public getDiscussionsByFilter(entityId: string, pageId: string, discussionQueryDefinitionFilter: DiscussionQueryDefinitionFilterViewDTO, fieldModel: HttpFieldModel): Observable<DiscussionViewDTO[]> {
        return this.Post<DiscussionViewDTO[]>(`${this._baseUrl}api/discussion/filter/entityId/${entityId}/pageId/${pageId}`, discussionQueryDefinitionFilter, this.GetStandardHeaders(), [], null, [HttpStatusCode.NOT_FOUND, HttpStatusCode.BAD_REQUEST], fieldModel);
    }

    public addDiscussion(entityId: string, pageId: string, widgetId: string, discussion: DiscussionCreateDTO): Observable<DiscussionViewDTO> {
        const multiPartHeaders: HttpHeaders = this.GetMultipartFormDataHeaders();
        const headers = this.AddWidgetContextHeader(multiPartHeaders, widgetId);

        return this.Post<DiscussionViewDTO>(`${this._baseUrl}api/discussion/entityId/${entityId}/pageId/${pageId}`, this.objectToFormData(discussion), headers, [], null, [HttpStatusCode.NOT_FOUND, HttpStatusCode.BAD_REQUEST]);
    }
}
