import { createReducer, on } from '@ngrx/store';
import { DocumentGroupWidgetState, DocumentWidgetState, ShapeWidgetState } from '../../../AppState';
import { ListStateReducerHelper } from '../../../helpers/list-state-reducer-helper';
import { ObjectHelper } from '../../../helpers/object-helper';
import { actionDocumentsResetState, addActionInlineDocumentDone, addActionInlineDocumentError, getActionInlineDocumentsDone, getActionInlineDocumentsError, getActionShapeDone, getActionShapeError, actionShapeResetState, updateActionInlineDocumentDone, updateActionInlineDocumentError, updateActionShapeDone, updateActionShapeError, removeActionInlineDocumentRequest, removeActionInlineDocumentError, removeActionInlineDocumentDone } from './action-details-inline.actions';


//Documents

export const initialDocuments: DocumentGroupWidgetState =
{
    triggeredDataSourceId: null,
    listState: [],
    widgetConfigurationId: null
};

//Shapes

export const initialShapes: ShapeWidgetState = {
    GetShapeError: null,
    ListState: null,
    UpdateShapeError: null
}

export const ActionShapeReducer = createReducer<ShapeWidgetState>(initialShapes,
    on(updateActionShapeDone, (_, action) => action.shapeWidgetState),
    on(updateActionShapeError, (_, action) => action.shapeWidgetState),
    on(getActionShapeDone, (_, action) => action.shapeWidgetState),
    on(getActionShapeError, (_, action) => action.shapeWidgetState),
    on(actionShapeResetState, (_, action) => initialShapes)
);

export const ActionDocumentsReducer = createReducer<DocumentGroupWidgetState>(initialDocuments,
    on(getActionInlineDocumentsDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(getActionInlineDocumentsError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(addActionInlineDocumentDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(addActionInlineDocumentError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),

    on(removeActionInlineDocumentDone, (state, action) => { return { listState: ListStateReducerHelper.removeDocumentListItemFromState(state.listState, action.dataSourceId, action.documentId), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(updateActionInlineDocumentDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(updateActionInlineDocumentError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(actionDocumentsResetState, (state, action) => initialDocuments)
);
