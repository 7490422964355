import { ViewportScroller } from '@angular/common';
import {
    AfterViewInit, Component, DoCheck, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Intercom } from 'ng-intercom';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { FilterMatchMode, PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { RelatedAssetListFacade } from './facade/related-asset/related-asset-list.facade';
import { SiteSettingsFacade } from './facade/site-settings/site-settings.facade';
import { IntegrationConfigurationViewDTO } from './data-transfer-objects/configuration/integration-configuration-view-dto';
import { UIStylingHelper } from './helpers/ui-styling-helper';
import { NavigationLinkModel } from './models/navigation-link-model';
import { AbstractAssetListFacade } from './facade/abstract/abstract-asset-list.facade';
import { AbstractRelatedAssetListFacade } from './facade/abstract/abstract-related-asset-list.facade';
import { AssetListWidgetFacade } from './facade/deprecated/asset-list-widget.facade';
import { UIStyleFacade } from './facade/deprecated/ui-style.facade';
import { PortalOverlayComponent } from './modules/static/generics/components/portal-overlay/portal-overlay.component';
import { SessionApplicationService } from './services/application/session-application.service';
import { SignalRApplicationService } from './services/application/signalr-base-application-service';
import { ConfigurationHttpService } from './services/http/configuration-http.service';
import { LanguageHttpService } from './services/http/language-http.service';
import { RootHttpService } from './services/http/root-http.service';
import { TranslationHttpService } from './services/http/translation-http.service';
import { MenuItemApplicationService } from './services/deprecated/menu-item-application.service';
import { ModalService } from './services/deprecated/modal.service';
import { RoutingStateService } from './services/deprecated/routing-state.service';
import { ToastService } from './services/deprecated/toast.service';
import { GoogleAnalyticsConfigurationFacade } from './facade/configuration/google-analytics.facade';
import { ErrorManagerService } from './services/deprecated/error-manager.service';
import { ErrorDetails } from './models/error-details';
import { appLoadFailedMessage } from './consts/error-messages';
import { HttpServiceBase } from './services/class-definitions/http-service-base';
import { HttpStatusCode } from "./enums/http-status-codes";

@Component({
    selector: 'fw-root',
    templateUrl: './app.component.html',
    providers: [
        { provide: UIStyleFacade },
        { provide: SiteSettingsFacade },
        { provide: AbstractAssetListFacade, useClass: AssetListWidgetFacade },
        { provide: AbstractRelatedAssetListFacade, useClass: RelatedAssetListFacade },
    ],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {
    public FooterMenuLinks: NavigationLinkModel[] = [];
    public mainContentString: string = 'main-content';
    public mainMenuString: string = 'main-menu';
    shortcuts: ShortcutInput[] = [];
    public userMenuString: string = 'user-menu';
    public isNavScrollable: boolean = false;
    public isSubNavScrollable: boolean = false;

    public AppLoaded: boolean = false;
    public AppLoadFailed: boolean = false;
    public AppLoadFailureReason: string = appLoadFailedMessage;

    public IsBusy: boolean;

    @ViewChild('portalOverlay', { static: true })
    public _overlay: PortalOverlayComponent;

    @ViewChild('mainContent') mainContent: ElementRef;
    @ViewChild('mainMenu') mainMenu: ElementRef;
    @ViewChild('userMenu') userMenu: ElementRef;
    @ViewChild('subMainMenu') subMainMenu: ElementRef;

    private _translationSubscription: Subscription

    @ViewChild('modalReference', { read: ViewContainerRef, static: true })
    private _modalReference: ViewContainerRef;

    @ViewChild('toastReference', { read: ViewContainerRef, static: true })
    private _toastRef: ViewContainerRef;

    constructor(
        private _modalService: ModalService,
        private routingStateService: RoutingStateService,
        private _toastService: ToastService,
        private _rootService: RootHttpService,
        private _translationService: TranslationHttpService,
        public intercom: Intercom,
        private configurationHttpService: ConfigurationHttpService,
        private primeNGConfig: PrimeNGConfig,
        public menuItemApplicationService: MenuItemApplicationService,
        private uiStyleFacade: UIStyleFacade,
        private viewportScroller: ViewportScroller,
        private siteSettingsFacade: SiteSettingsFacade,
        private languageHttpService: LanguageHttpService,
        private signalRApplicationService: SignalRApplicationService,
        private sessionApplicationService: SessionApplicationService,
        private renderer: Renderer2,
        private googleAnalyticsConfigurationFacade: GoogleAnalyticsConfigurationFacade,
        private errorManagerService: ErrorManagerService
    ) {
    }

    public ngOnDestroy(): void {
        this._translationSubscription.unsubscribe();
        this.signalRApplicationService.Destroy();
    }

    public ngAfterViewInit(): void {

        this.shortcuts.push(
            {
                key: 'v m',
                command: () => this.scrollFocus(this.mainMenuString),
                preventDefault: true,
            },
            {
                key: 'v c',
                command: () => this.scrollFocus(this.mainContentString),
                preventDefault: true,
            },
            {
                key: 'v u',
                command: () => this.scrollFocus(this.userMenuString),
                preventDefault: true,
            }
        );
    }

    public ngOnInit(): void {

        this.signalRApplicationService.Initialise().subscribe(success => {
            if (success) {
                this.uiStyleFacade.LoadConfiguration();
                this.siteSettingsFacade.loadConfiguration();

                this.googleAnalyticsConfigurationFacade.loadGoogleAnalyticsConfiguration();

                this.googleAnalyticsConfigurationFacade.getGoogleAnalyticsConfiguration().subscribe((googleAnalyticsConfigurationViewDTO) => {
                    if (googleAnalyticsConfigurationViewDTO?.Key) {
                        this.renderGtagScript(googleAnalyticsConfigurationViewDTO.Key);
                    }
                });

                this._translationSubscription = this._translationService.LoadTranslations().subscribe(() => {
                    this._toastService.Initialize(this._toastRef);
                    this._modalService.Initialize(this._modalReference);

                    // todo: configurable
                    this.FooterMenuLinks = [
                        {
                            Name: 'Disclaimer',
                            Link: 'info/disclaimer',
                            QueryParams: {},
                            SubMenu: [],
                        },
                        {
                            Name: 'Privacy',
                            Link: 'info/privacy',
                            QueryParams: {},
                            SubMenu: [],
                        },
                    ];

                    this.uiStyleFacade.GetFormInputStyle().subscribe((formInputStyle) => {
                        UIStylingHelper.ApplyFormInputStyling(formInputStyle);
                    });

                    this.routingStateService.loadRouting();
                    this._rootService.GetInformation().subscribe((info) => {
                        console.log(info);
                    });

                    this.configurationHttpService
                        .GetIntegrationConfiguration()
                        .subscribe((config: IntegrationConfigurationViewDTO) => {
                            if (config?.Intercom?.AppId) {
                                this.intercom.boot({
                                    app_id: config.Intercom.AppId,
                                    widget: {
                                        activator: '#intercom',
                                    },
                                });
                            }
                        });

                    this.primeNGConfig.filterMatchModeOptions = {
                        text: [FilterMatchMode.CONTAINS, FilterMatchMode.EQUALS],
                        numeric: [
                            FilterMatchMode.EQUALS,
                            FilterMatchMode.GREATER_THAN,
                            FilterMatchMode.LESS_THAN,
                        ],
                        date: [
                            FilterMatchMode.DATE_IS,
                            FilterMatchMode.DATE_BEFORE,
                            FilterMatchMode.DATE_AFTER,
                        ],
                    };

                    this.sessionApplicationService.init();

                    this.menuItemApplicationService.getMenuItems();

                    this.AppLoaded = true;
                    this.errorManagerService.AppConnectedToServer = true;

                    this.errorManagerService.Error.subscribe(error => {
                        const errorDetails = error.Error as ErrorDetails;

                        if (errorDetails?.status === HttpStatusCode.SERVICE_UNAVAILABLE) {
                            this.failedToInit(errorDetails);
                        }

                        if (errorDetails?.status === HttpStatusCode.UNAUTHORIZED) {
                            this.sessionApplicationService.logout();
                        }
                    });

                    this.signalRApplicationService.Connected.subscribe(connected => {
                        this.AppLoaded = connected;
                        this.AppLoadFailed = !connected;
                        this.AppLoadFailureReason = appLoadFailedMessage;
                        this.errorManagerService.AppConnectedToServer = connected;
                    });
                }, error => {
                    this.failedToInit(error);
                });
            } else {
                this.failedToInit(null);
            }
        }, error => {
            this.failedToInit(error);
        });
    }

    private renderGtagScript(googleAnalyticsId: string) {
        const scriptTag1 = this.renderer.createElement('script');
        scriptTag1.async = true;
        scriptTag1.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
        this.renderer.appendChild(document.head, scriptTag1);

        const scriptTag2 = this.renderer.createElement('script');
        scriptTag2.text = `window.dataLayer = window.dataLayer || [];  function gtag(){dataLayer.push(arguments);} gtag('js',new Date()); gtag('config','${googleAnalyticsId}')`;
        this.renderer.appendChild(document.head, scriptTag2);
    }

    ngDoCheck(): void {
        this.onResize();
        this.IsBusy = !this.AppLoaded || HttpServiceBase.RequestCount > 0;
    }

    @HostListener('window:focus', ['$event'])
    onFocus(): void {
        this.sessionApplicationService.isAuthenticated;
    }

    @HostListener('window:resize', ['$event'])
    @HostListener('window:onload', ['$event'])
    onResize(): void {
        if (this.mainMenu) {
            this.isNavScrollable = this.mainMenu.nativeElement.scrollWidth > this.mainMenu.nativeElement.clientWidth;
        }

        if (this.subMainMenu) {
            this.isSubNavScrollable = this.subMainMenu.nativeElement.scrollWidth > this.subMainMenu.nativeElement.clientWidth;
        }
    }

    public scrollFocus(elementId: string): void {
        if (elementId === this.mainMenuString) {
            this.mainMenu.nativeElement.focus();
        }

        if (elementId === this.userMenuString) {
            this.userMenu.nativeElement.focus();
        } else {
            this.mainContent.nativeElement.focus();
        }

        this.viewportScroller.setOffset([0, 60]);
        this.viewportScroller.scrollToAnchor(elementId);
    }

    private failedToInit(error: ErrorDetails): void {
        this.AppLoaded = false;
        this.AppLoadFailed = true;

        if (error?.status === 503 && error?.detail) {
            this.AppLoadFailureReason = error.detail;
        } else {
            this.AppLoadFailureReason = appLoadFailedMessage;
        }

        /*
        if the app component failed to init for some reason, wait 30 seconds and refresh the browser window.
        this should force the SPA to restart and hopefully the problems goes away.
        (the most likely reason this would happen is if the client cannot communicate with the server).
         */
        setTimeout(() => { window.location.reload(); }, 30000);
    }
}
