import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL } from '../../helpers/base-url';
import { HttpServiceBase } from '../class-definitions/http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class UtilitiesJobHttpService extends HttpServiceBase {
    constructor(httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, errorService: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorService, toastService);
    }

    public deleteJob(jobId: number): Observable<null> {
        return this.Delete<null>(`${this.baseUrl}api/utilities/jobs/${jobId}`, this.GetStandardHeaders(), []);
    }
}
