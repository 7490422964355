import { BehaviorSubject, Observable } from 'rxjs';
import { AssetWidgetState } from '../../AppState';
import { AssetViewDTO } from '../../data-transfer-objects/asset/asset-view-dto';
import { FacadeValidation } from '../../modules/dynamic/widgets/types/validation/facade-validation';

export abstract class AbstractAssetDetailsFacade extends FacadeValidation {

    public abstract PrimaryWidgetCompletedLoading: BehaviorSubject<AssetViewDTO>;
    public abstract AssetId: string;
    public abstract _isSaving: boolean;
    public abstract _pageId: string;

    public abstract AssetSelector(): Observable<AssetWidgetState>;
    public abstract LoadAssetDetails(): void;
    public abstract ResetAssetDetails(): void;
    public abstract SetIsSaving(isSaving: boolean): void;

    public abstract UpdateAsset(assetViewDTO: AssetViewDTO): void;

    
}
