import { createAction, props } from '@ngrx/store'
import { StructuredDataLookupConfigurationState } from '../../../../AppState';


//LOAD STRUCTURED DATA
export const loadStructuredDataLookupsRequest = createAction('[StructuredDataLookups] Load Structured Data Look Ups Request');

export const loadStructuredDataLookupsDone = createAction('[StructuredDataLookups] Load Structured Data Look Ups Done',
    props<{
        structuredDataLookupConfigurationState: StructuredDataLookupConfigurationState
    }>());

export const loadStructuredDataLookupsError = createAction('[StructuredDataLookups] Load Structured Data Look Ups Error',
    props<{
        structuredDataLookupConfigurationState: StructuredDataLookupConfigurationState
    }>());

//RESET STATE
export const resetStructuredDataLookUps = createAction('[StructuredDataLookups] Reset Structured Data Look ups');
