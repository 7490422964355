import { Component, Input } from '@angular/core';
import { ContentBlockWidgetConfiguration } from '../../../../../data-transfer-objects/configuration/content-block-widget-configuration-view-dto';

@Component({
  selector: 'fw-content-block-widget',
  templateUrl: './content-block-widget.component.html',
})
export class ContentBlockWidgetComponent  {
  @Input()
  public contentBlockWidgetConfiguration: ContentBlockWidgetConfiguration;

  constructor() {}
}
