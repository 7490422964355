import { Injectable } from '@angular/core';
import { LookupConfigurationModel } from '@landadmin/structured-data/lib/models/lookup-configuration-model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { ErrorDetails } from 'src/app/models/error-details';
import { AppState, StructuredDataLookupConfigurationState } from '../../../../AppState';
import { StructuredDataLookupHttpService } from '../../../../services/http/structured-data-lookup-http.service';
import { getStructuredDataLookupConfigurationState } from '../../configuration.selectors';
import { loadStructuredDataLookupsDone, loadStructuredDataLookupsError, loadStructuredDataLookupsRequest } from './structured-data-look-up.actions';

@Injectable()
export class StructuredDataLookupEffects {

    loadStructuredDataLookups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadStructuredDataLookupsRequest),
            withLatestFrom(
                this.store.select(getStructuredDataLookupConfigurationState),
                (action, state) => state
            ), exhaustMap((lookupState) => {
                if (lookupState.viewModel) {
                    return of(lookupState.viewModel).pipe(map((lookupConfigurationModel) => {
                        return loadStructuredDataLookupsDone({
                            structuredDataLookupConfigurationState: this.buildStructuredDataLookupConfigurationState(null, lookupConfigurationModel)
                        });
                    }))
                }
                else {
                    return this.structuredDataLookupService.GetStructuredDataLookupConfiguration().pipe(
                        map((lookupConfigurationModel: LookupConfigurationModel) => {
                            return loadStructuredDataLookupsDone({
                                structuredDataLookupConfigurationState: this.buildStructuredDataLookupConfigurationState(null, lookupConfigurationModel)
                            });
                        }),
                        catchError((errorDetails: ErrorDetails) => {
                            return of(
                                loadStructuredDataLookupsError(
                                    {
                                        structuredDataLookupConfigurationState: this.buildStructuredDataLookupConfigurationState(errorDetails, null)
                                    }
                                )
                            );
                        })
                    );
                }
            })
        ));

    constructor(
        private actions$: Actions,
        private structuredDataLookupService: StructuredDataLookupHttpService,
        private store: Store<AppState>
    ) { }

    private buildStructuredDataLookupConfigurationState(getError: ErrorDetails, lookupConfigurationModel: LookupConfigurationModel): StructuredDataLookupConfigurationState {
        return {
            getError: getError,
            viewModel: lookupConfigurationModel
        }
    }

}
