import { Component, EventEmitter, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { SiteSettingsFacade } from '../../../../../facade/site-settings/site-settings.facade';
import { ConfigurableControlsHelper } from '../../../../../helpers/configurable-controls-helper';
import { CalendarType } from '../../../../../helpers/date-helper';
import { PropertyConfiguration } from '../../../../../models/configuration/widgets/properties/property-configuration';
import { ControlBaseWrapper } from '../control-wrapper-base';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';

@Component({
    selector: 'fw-calendar-wrapper',
    templateUrl: './calendar-wrapper.component.html',
})
export class CalendarWrapperComponent extends ControlBaseWrapper {

    @Input()
    public IsHijri: boolean = false;

    @Input()
    public IsHijriChanged: EventEmitter<PropertyConfiguration> = new EventEmitter<PropertyConfiguration>();

    public get Date(): Date {
        if (this.Property?.PropertyName) {
            return this.CustomFormGroup?.controls[this.Property.PropertyName]?.value;
        } else {
            return null;
        }
    }

    public get ShowToggle(): boolean {
        if (!this.hijriEnabled) {
            return false;
        }

        if (this.FormEditStyle === FormEditStyle.ReadOnly) {
            return !!this.Date;
        } else {
            return true;
        }
    }

    public selectedCalendar: { type: CalendarType, label: string, value: string };
    public hijriEnabled: boolean = false;
    public options: any[] = ConfigurableControlsHelper.GetCalendarOptions(this.transloco);

    private Subscriptions: Subscription[] = [];

    constructor(private siteSettingsFacade: SiteSettingsFacade,
        private transloco: TranslocoService) {
        super();
    }

    ngOnInit() {
        this.Subscriptions.push(this.siteSettingsFacade.getCalendars().subscribe(calendars => {
            this.hijriEnabled = !!calendars?.Hijri?.Enabled;
        }));
    }

    ngOnDestroy(): void {
        if (this.Subscriptions) {
            this.Subscriptions.forEach(subscription => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            });
        }
    }

    toggle() {
        this.IsHijri = this.selectedCalendar?.type === CalendarType.Hijri;
        this.Property.Calendar.IsHijri = this.IsHijri;
        this.IsHijriChanged.emit(this.Property);
    }
}
