/* eslint-disable */
import {
    Component,
    HostBinding,
    Input, OnDestroy, OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { StructuredDataLookupFacade } from '../../../../facade/configuration/structured-data-lookup.facade';
import { severity_error } from '../../../../consts/severity-options';
import { LoginDTO } from '../../../../data-transfer-objects/login/login-dto';
import { LoginType } from '../../../../enums/login-type';
import { Token, Username } from '../../../../guards/auth.guard';
import { DialogApplicationService, DialogComponentBase, DialogOptions } from '../../../../services/application/dialog-application.service';
import { SessionApplicationService } from '../../../../services/application/session-application.service';
import { ToastApplicationService } from '../../../../services/application/toast-application.service';
import { LanguageHttpService } from '../../../../services/http/language-http.service';
import { UserHttpService } from '../../../../services/http/user-http.service';
import { ForgotPasswordFormComponent } from '../forgot-password-form/forgot-password-form.component';
import { OneTimePinFormComponent } from '../one-time-pin-form/one-time-pin-form.component';
import { ReturnUrl } from '../../../../guards/dynamic-roles.guard';

@Component({
    selector: 'fw-portal-login-form',
    templateUrl: './login-form.component.html',
})
export class LoginFormComponent extends DialogComponentBase<LoginDTO> implements OnInit, OnDestroy {
    public model: LoginDTO = this.dialogOptions.dataModel;
    public _disabled: boolean;

    @HostBinding('class.d-none')
    public Hidden: boolean = false;
    @Input()
    public DisplayOnPage: boolean = false;
    @Input()
    public UsernameQueryParameter: boolean = false;
    public HideLoginForm: boolean = false;

    public authenticationChanged: Subscription;
    public authenticationFailed: Subscription;
    public oneTimePinRequired: Subscription;
    public dialogCloseSubscription: Subscription;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toastApplicationService: ToastApplicationService,
        private dynamicDialogRef: DynamicDialogRef,
        private dynamicDialogConfig: DynamicDialogConfig,
        private sessionApplicationService: SessionApplicationService,
        private dialogApplicationService: DialogApplicationService,
        private translocoService: TranslocoService,
        private lookupFacade: StructuredDataLookupFacade,
        private languageService: LanguageHttpService,
        private userService: UserHttpService
    ) {
        super(dynamicDialogRef, dynamicDialogConfig);
    }

    ngOnDestroy(): void {
        if (this.authenticationChanged) {
            this.authenticationChanged.unsubscribe();
        }

        if (this.authenticationFailed) {
            this.authenticationFailed.unsubscribe();
        }

        if (this.oneTimePinRequired) {
            this.oneTimePinRequired.unsubscribe();
        }

        if (this.dialogCloseSubscription) {
            this.dialogCloseSubscription.unsubscribe();
        }
    }

    ngOnInit(): void {
        if (!this.model) {
            this.model = {
                logintype: LoginType.UsernamePassword,
                username: '',
                password: '',
                onetimepin: '',
                returnUrl: null
            };
        }

        const username = this.activatedRoute.snapshot.queryParams[Username];

        if (username) {
            this.UsernameQueryParameter = true;
            this.model.username = username;
        }

        const returnUrl = this.activatedRoute.snapshot.queryParams[ReturnUrl];

        if (returnUrl && !this.model.returnUrl) {
            this.model.returnUrl = returnUrl;
        }
        
        this.authenticationChanged = this.sessionApplicationService.authenticationChanged.subscribe(
            (isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    this.lookupFacade.LoadStructuredDataLookups();
                    this.dynamicDialogRef.destroy();
                } else {
                    this._disabled = false;
                }
            });

        this.setupAuthenticationFailedEvent();

        const token = this.activatedRoute.snapshot.queryParams[Token];

        if (token) {
            this.HideLoginForm = true;
            this.sessionApplicationService.tryLoginWithToken(token, this.model.returnUrl);
        }
    }

    private setupAuthenticationFailedEvent(): void {
        this.authenticationFailed = this.sessionApplicationService.authenticationFailed.subscribe(error => {
            if (error) {
                this.toastApplicationService.showToast('SessionApplicationService.Login.LoginFailedDetail', error, severity_error);
            } else {
                this.toastApplicationService.showToast('SessionApplicationService.Login.LoginFailedDetail', 'SessionApplicationService.Login.IncorrectUsernameOrPassword', severity_error);
            }

            this.dynamicDialogRef.destroy();
            this._disabled = false;
        });
    }

    public forgotPasswordClicked(): void {
        const dialogOptions: DialogOptions<string> = {
            closable: true,
            showHeader: true,
            footer: '',
            header: this.translocoService.translate('ForgotPassword.Form.Title'),
            dataModel: '',
            styleClass: 'dialog-sm'
        };

        this.dialogApplicationService.showFormDialog(
            ForgotPasswordFormComponent,
            dialogOptions
        );

        this.dynamicDialogRef.destroy();
    }

    public submitClicked(): void {
        this._disabled = true;

        if (!this.DisplayOnPage) {
            this.userService.getUserIdPRedirectUrl(this.model.username, this.model.returnUrl).subscribe((userIdPRedirectUrl) => {
                if (userIdPRedirectUrl?.RedirectUrl) {
                    if (userIdPRedirectUrl.RedirectUrl.startsWith(window.location.origin)) {
                        const returnUrl = userIdPRedirectUrl.RedirectUrl.substring(window.location.origin.length);
                        
                        this.router.navigateByUrl(returnUrl);
                    } else {
                        window.location.href = userIdPRedirectUrl.RedirectUrl;
                    }
                } else {
                    this.router.navigate(['/page/login'], {
                        queryParams: { 
                            Username: this.model.username,
                            ReturnUrl: this.model.returnUrl
                        },
                    });
                }

                this.dynamicDialogRef.destroy();
            });
        } else {
            this.languageService.GetCurrentLanguageId().subscribe(languageId => {
                this.model.logintype = LoginType.UsernamePassword
                this.model.onetimepin = '';
                this.sessionApplicationService.tryLogin(this.model, false, languageId, this.model.returnUrl);
            });

            if (this.oneTimePinRequired) {
                this.oneTimePinRequired.unsubscribe();
            }

            this.oneTimePinRequired = this.sessionApplicationService.oneTimePinRequired.subscribe(() => {
                const dialogOptions: DialogOptions<LoginDTO> = {
                    closable: true,
                    showHeader: true,
                    footer: '',
                    header: this.translocoService.translate('OneTimePin.Form.TwoFactorAuthenticationTitle'),
                    dataModel: this.model,
                    styleClass: "dialog-sm"
                };

                const dialogRef = this.dialogApplicationService.showFormDialog(
                    OneTimePinFormComponent,
                    dialogOptions
                );

                this.dialogCloseSubscription = dialogRef.onClose.subscribe(() => {
                    this.setupAuthenticationFailedEvent();
                    this.dialogCloseSubscription.unsubscribe();
                });

                this.authenticationFailed.unsubscribe();
                this.oneTimePinRequired.unsubscribe();
                this._disabled = false;
            });
        }
    }
}
