import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Roles } from 'src/app/enums/roles-enum';
import { Observable, Subscriber } from 'rxjs';
import { ErrorManagerService } from 'src/app/services/deprecated/error-manager.service';
import { ErrorModel } from 'src/app/models/error-model';
import { RoleApplicationService } from '../services/application/role-application.service';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {

    constructor(private roleApplicationService: RoleApplicationService, private router: Router, private errorManager: ErrorManagerService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {

        return new Observable<boolean | UrlTree>((subscriber: Subscriber<boolean | UrlTree>) => {

            const roles: Roles[] = route.data.roles;

            if (!roles) {
                this.errorManager.AddError(new ErrorModel('This route is role protected but no roles have been provided.'))
            }

            this.roleApplicationService.validateUserRole(roles).subscribe((isValid: boolean) => {

                if (isValid)
                    subscriber.next(isValid);
                else {
                    this.errorManager.AddError(new ErrorModel(`You are not authorized to view this page`));
                    subscriber.next(this.router.parseUrl('/error'));
                }
            });

        });

    }
}

