import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommodityHttpService } from '../../services/http/commodity-http.service';
import { of } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { getCommodityRequest, getCommodityDone, getCommodityError, addCommodityRequest, addCommodityDone, addCommodityError, removeCommodityRequest, removeCommodityDone, removeCommodityError } from './commodity.actions';
import { CommodityViewDTO } from '../../data-transfer-objects/commodity/commodity-view-dto';
import { CommodityWidgetState } from '../../AppState';
import { ErrorDetails } from 'src/app/models/error-details';

@Injectable()
export class CommodityEffects {

    getCommodities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getCommodityRequest),
            switchMap((action) => {
                return this.commodityHttpService
                    .getCommoditiesByEntityId(action.entityId, action.pageId)
                    .pipe(
                        map((commodities: CommodityViewDTO[]) => {
                            const commodityWidgetState: CommodityWidgetState = {
                                UpdateError: null,
                                GetError: null,                                
                                Commodities: commodities,
                            };
                            return getCommodityDone({
                                commodityWidgetState: commodityWidgetState,
                            });
                        }),
                        catchError((errorDetails: ErrorDetails) => {
                            return of(
                                getCommodityError({
                                    commodityWidgetState: {
                                        UpdateError: null,
                                        GetError: errorDetails,
                                        Commodities: null,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    addCommodity$ = createEffect(() => this.actions$.pipe(
        ofType(addCommodityRequest),
        mergeMap((action) => {
            return this.commodityHttpService
                .addCommodity(action.entityId, action.pageId, action.commodityId)
                .pipe(
                    map(() => {
                        return addCommodityDone({
                            addedCommodity: { CommodityId: action.commodityId }
                        });
                    }),
                    catchError(((errorDetails: ErrorDetails) => {
                        return of(addCommodityError({
                            addError: errorDetails,
                            commodityId: action.commodityId
                        }));
                    }))
                );
        })
    ));

    removeCommodity$ = createEffect(() => this.actions$.pipe(
        ofType(removeCommodityRequest),
        mergeMap((action) => {
            return this.commodityHttpService
                .removeCommodity(action.entityId, action.pageId, action.commodityId)
                .pipe(
                    map(() => {
                        return removeCommodityDone({
                            removedCommodity: { CommodityId: action.commodityId }
                        });
                    }),
                    catchError(((errorDetails: ErrorDetails) => {
                        return of(removeCommodityError({
                            removeError: errorDetails,
                            commodityId: action.commodityId
                        }));
                    }))
                );
        })
    ));

    constructor(
        private actions$: Actions,
        private commodityHttpService: CommodityHttpService
    ) { }
}
