<div *ngIf="model && !HideLoginForm">
    <form #form="ngForm">
        <portal-text-field id="username" name="username" #username="ngModel" (keydown.enter)="submitClicked()"
                           [(ngModel)]="model.username"
                           required
                           [is-valid]="!username.touched || username.valid"
                           [is-disabled]="_disabled || (DisplayOnPage && UsernameQueryParameter)"
                           label-text="{{'Login.Form.Username' | transloco }}"
                           [invalid-text]="model.username === '' ? ('Login.Form.EmailAddressRequired' | transloco) : ('Login.Form.InvalidEmailAddress' | transloco)">
        </portal-text-field>

        <portal-password-field *ngIf="DisplayOnPage" id="password" name="password" #password="ngModel" (keydown.enter)="submitClicked()"
                               [(ngModel)]="model.password"
                               required
                               [is-valid]="!password.touched || password.valid"
                               [is-disabled]="_disabled"
                               label-text="{{'Login.Form.Password' | transloco }}"
                               [invalid-text]="model.password === '' ? ('Login.Form.PasswordRequired' | transloco) : ('Login.Form.PasswordIncorrect' | transloco)">
        </portal-password-field>

        <div class="p-dialog-footer justify-content-end">
            <p-button styleClass="btn btn-primary btn-lg" type="button" label="{{'Login.Form.SignIn' | transloco }}" [disabled]="_disabled || !form.valid || (form.valid && !form.dirty)" (onClick)="submitClicked()"></p-button>
            <p-button *ngIf="DisplayOnPage" styleClass="btn btn-lg" (click)="forgotPasswordClicked()">{{'Login.Form.ForgotPassword' | transloco }}</p-button>
        </div>
    </form>
</div>
