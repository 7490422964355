import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Type } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { ListState, RelatedAssetListWidgetStateModel } from 'src/app/AppState';
import { AssetListViewDTO } from 'src/app/data-transfer-objects/asset/asset-list-view-dto';
import { RelatedAssetQueryDefinitionSearchViewDTO } from 'src/app/data-transfer-objects/configuration/list-configuration/query-definitions/related-asset-query-definition-search-view-dto';
import { RelatedAssetListWidgetAdditionalConfigurationData, RelatedAssetListWidgetConfigurationViewDTO } from 'src/app/data-transfer-objects/configuration/widgets/related-asset-list-widget-configuration-view-dto';
import { RelatedAssetListViewDTO } from 'src/app/data-transfer-objects/related-asset/related-asset-list-view-dto';
import { SelectMode } from 'src/app/enums/select-mode';
import { PaginationResultModel } from 'src/app/models/pagination-models';
import { SelectableListItem } from 'src/app/models/selectable-list-item';
import { DialogApplicationService } from 'src/app/services/application/dialog-application.service';
import { ToastService } from 'src/app/services/deprecated/toast.service';
import { GuidHelper } from '../../../../../../helpers/guid-helper';
import { BaseFormComponent } from '../../../../../static/classes/base-form-component';
import { AssetListWithRelationshipWidgetComponent } from '../asset-list-with-relationship-widget/asset-list-with-relationship-widget.component';
import { BaseAssetListWidgetComponent } from '../base-asset-list-widget.component';
import { IListWidget, NavigateWrapper, OnLoadWrapper } from '../list-widget-interface';
import { AbstractRelatedAssetListFacade } from '../../../../../../facade/abstract/abstract-related-asset-list.facade';
import { FormEditStyle } from 'src/app/enums/configuration/form-edit-style';
import { ToastApplicationService } from 'src/app/services/application/toast-application.service';
import { severity_warning } from 'src/app/consts/severity-options';
import { EditingOperation } from 'src/app/enums/editing-operation';

@Component({
    selector: 'fw-related-asset-list-widget',
    templateUrl: './related-asset-list-widget.component.html'
})
export class RelatedAssetListWidgetComponent extends BaseFormComponent<AssetListViewDTO> implements OnInit, OnDestroy, IListWidget<RelatedAssetListViewDTO> {
    public PaginationResultModel: PaginationResultModel<RelatedAssetListViewDTO>;
    public DataSourceId: string;
    public errorMessageResourceId: string = null;
    public AddButtonDisabled: boolean = false;
    public disablePagination: boolean = false;

    @Input()
    public RelatedAssetListWidgetConfigurationViewDTO: RelatedAssetListWidgetConfigurationViewDTO;

    @Input()
    public FormEditStyle: FormEditStyle;

    private GetRelatedAssetListSubscription: Subscription;
    private ReloadDataListSubscription: Subscription;
    private stateEntry: ListState<RelatedAssetListWidgetStateModel>;
    private onLoadWrapper: OnLoadWrapper;

    constructor(
        private abstractRelatedAssetListFacade: AbstractRelatedAssetListFacade,
        private toastService: ToastService,
        private router: Router,
        public dialogApplicationService: DialogApplicationService,
        private translocoService: TranslocoService,
        private toastApplicationService: ToastApplicationService,
        cdr: ChangeDetectorRef
    ) {
        super(cdr);
    }

    public get isReadOnly(): boolean {
        return this.FormEditStyle == FormEditStyle.ReadOnly || (this.RelatedAssetListWidgetConfigurationViewDTO?.Editing?.ReadOnly ?? false);
    }

    public get addEnabled(): boolean {
        return !this.isReadOnly && this.RelatedAssetListWidgetConfigurationViewDTO.Editing?.AllowedOperations?.includes(EditingOperation.Add);
    }

    public get selectMode(): SelectMode {
        return this.RelatedAssetListWidgetConfigurationViewDTO.Editing.Dialog.List.SelectMode;
    }

    public get deleteEnabled(): boolean {
        return !this.isReadOnly && this.RelatedAssetListWidgetConfigurationViewDTO.Editing?.AllowedOperations?.includes(EditingOperation.Remove);
    }

    public get dialogData() {

        const configData: RelatedAssetListWidgetAdditionalConfigurationData = {
            AssetListWidgetConfigurationViewDTO: this.RelatedAssetListWidgetConfigurationViewDTO.Editing.Dialog.List,
            ShowOptionItems: this.RelatedAssetListWidgetConfigurationViewDTO.Editing.RelationshipTypeId === null
        };
        return configData;
    }

    public get dialogHeaderText(): string {
        return this.translocoService.translate('RelatedAsset.Dialog.Title');
    }

    public get dialogLoadButtonText(): string {
        return this.translocoService.translate('RelatedAsset.ShowDialog.Button');
    }

    public get dialogComponentToShow(): Type<BaseAssetListWidgetComponent> {
        return AssetListWithRelationshipWidgetComponent;
    }

    public ClearForm(): void { }
    public PopulateForm(): void { }

    public NavigateByContext(navigateWrapper: NavigateWrapper): void {
        this.abstractRelatedAssetListFacade.ExecuteBehaviour(navigateWrapper.fieldConfiguration.PropertyName, this.RelatedAssetListWidgetConfigurationViewDTO.Id, navigateWrapper.model.Id, null);
    }

    public OnLoad(onLoadWrapper: OnLoadWrapper): void {
        const relatedAssetQueryDefinitionSearchViewDTO: RelatedAssetQueryDefinitionSearchViewDTO = {
            SavedSearchId: this.RelatedAssetListWidgetConfigurationViewDTO.RelatedAssetQueryDefinition.SavedSearchId,
            AssetId: this.abstractRelatedAssetListFacade.EntityId
        };

        this.onLoadWrapper = onLoadWrapper;

        this.abstractRelatedAssetListFacade.LoadListItems(this.RelatedAssetListWidgetConfigurationViewDTO.Fields, onLoadWrapper.paginationModel, onLoadWrapper.searchModel, relatedAssetQueryDefinitionSearchViewDTO, this.DataSourceId);
    }

    ngOnDestroy() {
        this.GetRelatedAssetListSubscription.unsubscribe();

        if (this.ReloadDataListSubscription) {
            this.ReloadDataListSubscription.unsubscribe();
        }
    }

    ngOnInit(): void {

        this.DataSourceId = GuidHelper.NewGuid();

        this.GetRelatedAssetListSubscription = this.abstractRelatedAssetListFacade.GetListItems().subscribe((relatedAssetListWidget: ListState<RelatedAssetListWidgetStateModel>[]) => {

            if (!relatedAssetListWidget || relatedAssetListWidget.length == 0) {
                return;
            }

            this.stateEntry = relatedAssetListWidget.find((alw) => alw.dataSourceId == this.DataSourceId);

            if (this.stateEntry) {
                
                if (this.stateEntry.StateModel.error) {

                    this.errorMessageResourceId = 'Lists.GenericGetError';
                    this.toastService.ShowErrorToast(this.stateEntry.StateModel.error, [{
                        Message: this.errorMessageResourceId,
                        RouterLink: null,
                        RouterText: null,
                        QueryParameters: null,
                        MessageParameters: null
                    }], true);

                    this.PaginationResultModel = this.stateEntry.StateModel.paginationResult;

                } else {
                    this.errorMessageResourceId = null;
                    this.PaginationResultModel = this.stateEntry.StateModel.paginationResult;

                    const isValid: boolean = !this.RelatedAssetListWidgetConfigurationViewDTO.Required || (this.stateEntry.StateModel && this.stateEntry.StateModel.paginationResult && this.stateEntry.StateModel.paginationResult.Models.length > 0);

                    this.abstractRelatedAssetListFacade.SetValidity(this.DataSourceId, isValid);

                }
            }
        });

        this.ReloadDataListSubscription = this.abstractRelatedAssetListFacade
            .reloadListDataSelector()
            .subscribe((relatedAssetWidgetStates) => {
                const state = relatedAssetWidgetStates.find(x => x.dataSourceId === this.DataSourceId);

                const operations = state?.StateModel?.operations ?? 0;

                if (operations > 0) {
                    this.disablePagination = true;
                    this.AddButtonDisabled = true;
                    return;
                } else {
                    this.disablePagination = false;
                }

                if (state && state.StateModel.reloadList) {
                    this.OnLoad(this.onLoadWrapper);
                }

                if (state && state.StateModel.paginationResult) {
                    if (this.selectMode === SelectMode.Single && state.StateModel.paginationResult.Models.length >= 1) {
                        this.AddButtonDisabled = true;
                    } else {
                        this.AddButtonDisabled = false;
                    }
                }
            });
    }

    public onItemsAdd(selectedItems: SelectableListItem[]): void {
        if (selectedItems) {
            for (const selectedItem of selectedItems) {
                if (selectedItem.id == this.abstractRelatedAssetListFacade?.EntityId) {
                    this.toastApplicationService.showToast('Widget.RelatedAssetList.SelfRelationNotSupported', 'Widget.RelatedAssetList.SelfRelationNotSupported.Detail', severity_warning);
                }
            }

            this.abstractRelatedAssetListFacade.addRelatedAssets(
                this.DataSourceId,
                this.abstractRelatedAssetListFacade.EntityId ?? '',
                selectedItems.map((selectedItem) => {
                    const relationshipTypeId: string =
                        this.RelatedAssetListWidgetConfigurationViewDTO.Editing.RelationshipTypeId !== null
                            ? this.RelatedAssetListWidgetConfigurationViewDTO.Editing.RelationshipTypeId
                            : selectedItem.value;

                    return {
                        RelationshipTypeId: relationshipTypeId,
                        AssetId: selectedItem.id
                    }
                }),
                this.abstractRelatedAssetListFacade.PageId);
        }
    }

    public onItemDelete(itemId: string): void {
        this.abstractRelatedAssetListFacade.removeRelatedAssets(
            this.DataSourceId,
            this.abstractRelatedAssetListFacade.EntityId ?? '',
            [itemId],
            this.abstractRelatedAssetListFacade.PageId,
        );
    }
}
