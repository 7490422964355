import { Injectable } from '@angular/core';
import { StructuredDataEntryModel } from '@landadmin/structured-data/lib/models/structured-data-entry-model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { ErrorDetails } from 'src/app/models/error-details';
import { PaginationResultModel } from '../../models/pagination-models';
import { StructuredDataEntryHttpService } from '../../services/http/structured-data-entry-http.service';
import { createStructuredDataEntryRequest, deleteStructuredDataEntryRequest, endStructuredDataOperationFail, endStructuredDataOperationSuccess, loadStructuredDataEntryDone, loadStructuredDataEntryRequest, updateStructuredDataEntryRequest } from './structured-data.actions';

@Injectable()
export class StructuredDataEntryEffects {

    addRemoveUpdateEntry$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadStructuredDataEntryRequest, updateStructuredDataEntryRequest, createStructuredDataEntryRequest, deleteStructuredDataEntryRequest),
            concatMap((action) => {
                if (action.type === loadStructuredDataEntryRequest.type) {
                    return this.structuedDataService.getStructuredDataEntry(action.pageId, action.rootNodeId, action.entityId, action.paginationModel)
                        .pipe(
                            map((pagingResultModel: PaginationResultModel<StructuredDataEntryModel>) => loadStructuredDataEntryDone({ rootNodeId: action.rootNodeId, data: pagingResultModel })),
                            catchError((errorDetails: ErrorDetails) => of(endStructuredDataOperationFail({rootNodeId: action.rootNodeId, errorDetails: errorDetails})))
                        );
                } else if (action.type === deleteStructuredDataEntryRequest.type) {
                    return this.structuedDataService.deleteStrucutredDataEntry(action.pageId, action.rootNodeId, action.entityId, action.parentEntryId)
                        .pipe(
                            map(() => endStructuredDataOperationSuccess({rootNodeId: action.rootNodeId})),
                            catchError((errorDetails: ErrorDetails) => of(endStructuredDataOperationFail({rootNodeId: action.rootNodeId, errorDetails: errorDetails})))
                        );
                } else if (action.type === createStructuredDataEntryRequest.type) {
                    return this.structuedDataService.createStrucutredDataEntry(action.pageId, action.rootNodeId, action.entityId, action.structuredDataCreateModel)
                        .pipe(
                            map(() => endStructuredDataOperationSuccess({rootNodeId: action.rootNodeId})),
                            catchError((errorDetails: ErrorDetails) => of(endStructuredDataOperationFail({rootNodeId: action.rootNodeId, errorDetails: errorDetails})))
                        );
                } else {
                    return this.structuedDataService.updateStructuredDataEntry(action.pageId, action.rootNodeId, action.entityId, action.structuredDataUpdateModel)
                        .pipe(
                            map(() => endStructuredDataOperationSuccess({rootNodeId: action.rootNodeId})),
                            catchError((errorDetails: ErrorDetails) => of(endStructuredDataOperationFail({rootNodeId: action.rootNodeId, errorDetails: errorDetails})))
                        );
                }
            })
        ));

    constructor(private actions$: Actions, private structuedDataService: StructuredDataEntryHttpService) {

    }
}
