import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { FilterMatchMode, SelectItem } from 'primeng/api';
import { FilterMatchModeHelper } from '../../../../../../helpers/filter-match-mode-helper';

@Component({
    selector: 'fw-string-column-filter',
    templateUrl: './string-column-filter.component.html',
})
export class StringColumnFilterComponent implements OnInit {

    public inputText: string;
    public selectedValue: string = FilterMatchMode.CONTAINS;
    public stringMatchModeOptions: SelectItem[];

    @Input()
    public InputFilter: { value: string, matchMode: string };

    @Output()
    public OnFilter: EventEmitter<{ value: string, matchMode: string }> = new EventEmitter<{ value: string, matchMode: string }>();

    @Output()
    public OnClearFilter: EventEmitter<void> = new EventEmitter<void>();

    constructor(public transloco: TranslocoService) { }

    ngOnInit(): void {
        if (this.InputFilter) {
            this.inputText = this.InputFilter.value;
            this.selectedValue = this.InputFilter.matchMode;
        }

        this.SetFilterMatchModes();
    }

    public ClearInput() {
        this.inputText = null;
    }

    public ApplyFilter() {
        if (!this.DisplayInputText() || (this.inputText && this.inputText.length > 0)) {
            this.OnFilter.emit({ value: this.inputText, matchMode: this.selectedValue });
        }

    }

    public ClearFilter() {
        this.inputText = null;
        this.selectedValue = FilterMatchMode.CONTAINS;
        this.OnClearFilter.emit();
    }

    public DisplayInputText(): boolean {
        return this.selectedValue !== FilterMatchModeHelper.IS_EMPTY &&
            this.selectedValue !== FilterMatchModeHelper.IS_NOT_EMPTY;
    }

    private SetFilterMatchModes() {
        this.stringMatchModeOptions = FilterMatchModeHelper.GetStringMatchModes(this.transloco);
    }


}