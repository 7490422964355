import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL } from 'src/app/helpers/base-url';
import { CompanyModel } from 'src/app/models/company-model';
import { PaginationModel, PaginationResultModel } from 'src/app/models/pagination-models';
import { SearchModel } from 'src/app/models/search-model';
import { UserRepresentationListWidetConfigurationViewDTO } from '../../data-transfer-objects/configuration/user-representation-list-widget-configuration-view-dto';
import { RepresentativeOfListViewDTO } from '../../data-transfer-objects/representative/representative-list-view-dto';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyHttpService extends AuthenticatedHttpServiceBase {

    constructor(httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, errorManager: ErrorManagerService, toastService: ToastService) {

        super(httpClient, errorManager, toastService);

    }   

    public GetCompaniesBySearch(userRepresentativeListWidgetConfigurationViewDTO: UserRepresentationListWidetConfigurationViewDTO): Observable<PaginationResultModel<RepresentativeOfListViewDTO>> {
        const headers: HttpHeaders = this.AddWidgetContextHeader(this.AddWidgetContextHeader(this.GetStandardHeaders(), userRepresentativeListWidgetConfigurationViewDTO.WidgetId), userRepresentativeListWidgetConfigurationViewDTO.PageId);
        return this.PostAuthenticated<PaginationResultModel<RepresentativeOfListViewDTO>>(`${this.baseUrl}api/companies/search/excludeUserRepresentationFilter`, userRepresentativeListWidgetConfigurationViewDTO, headers);
    }

}
