import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JobQueryResultViewDTO } from '../../data-transfer-objects/job/job-query-result-view-dto';
import { JobSchedulerViewDTO } from '../../data-transfer-objects/job/job-scheduler-view-dto';
import { ShapeValidationCreateDTO } from '../../data-transfer-objects/shape/shape-validation-create-dto';
import { ShapeValidationResultsViewDTO } from '../../data-transfer-objects/shape/shape-validation-results-view-dto';
import { BASE_URL } from '../../helpers/base-url';
import { HttpServiceBase } from '../class-definitions/http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class UtilitiesShapeHttpService extends HttpServiceBase {
    constructor(httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, errorService: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorService, toastService);
    }

    public validateShapeRetrieveJobResult(jobId: number): Observable<JobQueryResultViewDTO<ShapeValidationResultsViewDTO>> {
        return this.Get<JobQueryResultViewDTO<ShapeValidationResultsViewDTO>>(`${this.baseUrl}api/utilities/jobs/${jobId}`, this.GetStandardHeaders());
    }

    public validateShapeScheduleJob(shapeValidationCreateDTO: ShapeValidationCreateDTO): Observable<JobSchedulerViewDTO> {
        return this.Post<JobSchedulerViewDTO>(`${this.baseUrl}api/utilities/shape/validate`, shapeValidationCreateDTO, this.GetStandardHeaders(), []);
    }
}
