/* eslint-disable */

import { Component, ComponentRef, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, timer } from 'rxjs';
import { StyleVariants } from 'src/app/enums/bootstrap/style-variants';
import { MessageRouterLink } from '../../../../../models/message-router-link';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
})
export class ToastComponent {
    public ShowCloseButton: boolean = true;

    public Messages: MessageRouterLink[] = [];

    public LoadingText: string = '';

    public IsLoading: boolean = false;

    public ToastVariant: StyleVariants = StyleVariants.Dark;

    public Translate: boolean = true;

    //exposed to allow user of the toast component to subscribe to onClose of toast
    public OnCloseSubject: Subject<null> = new Subject<null>();

    @Input('componentReference')
    public ComponentRef: ComponentRef<ToastComponent>;


    constructor(private transloco: TranslocoService) {

    }

    public StartCloseCount(): void;
    public StartCloseCount(milliSeconds: number): void;
    public StartCloseCount(milliSeconds: number = 10000): void {
        timer(milliSeconds).subscribe((count: number) => {
            this.onClose();
        });
    }

    public onClose() {
        this.OnCloseSubject.next();
        this.ComponentRef.destroy();
    }
}
