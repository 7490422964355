import { createAction, props } from '@ngrx/store';
import { DocumentWidgetState, ShapeWidgetState, RunShapeValidationState, ShapeValidationResultsState, DeleteJobState } from '../../../AppState';
import { DocumentListViewDTO } from '../../../data-transfer-objects/document/document-list-view-dto';
import { DocumentViewDTO } from '../../../data-transfer-objects/document/document-view-dto';
import { ShapeValidationCreateDTO } from '../../../data-transfer-objects/shape/shape-validation-create-dto';
import { ShapeViewDTO } from '../../../data-transfer-objects/shape/shape-view-dto';

//Shape Drafts
export const updateShapeDraftRequest = createAction('[AssetDetails] Update Shape Draft Request', props<{ pageId: string, shapeViewDTO: ShapeViewDTO, assetId: string, typeId: string }>());
export const updateShapeDraftDone = createAction('[AssetDetails] Update Shape Draft Done', props<{ shapeDraftWidgetState: ShapeWidgetState }>());
export const updateShapeDraftError = createAction('[AssetDetails] Update Shape Draft Error', props<{ shapeDraftWidgetState: ShapeWidgetState }>());

export const getShapeDraftRequest = createAction('[AssetDetails] Get Shape Draft Request', props<{ pageId: string }>());
export const getShapeDraftDone = createAction('[AssetDetails] Get Shape Draft Done', props<{ shapeDraftWidgetState: ShapeWidgetState }>());
export const getShapeDraftError = createAction('[AssetDetails] Get Shape Draft Error', props<{ shapeDraftWidgetState: ShapeWidgetState }>());

export const shapeDraftResetState = createAction('[AssetDetails] Shape Draft Reset State');

//Shape Validation
export const runShapeValidationRequest = createAction('[AssetDetails] Run Shape Validation Request', props<{ shapeValidationCreateDTO: ShapeValidationCreateDTO }>());
export const runShapeValidationDone = createAction('[AssetDetails] Run Shape Validation Done', props<{ runShapeValidationState: RunShapeValidationState }>());
export const runShapeValidationError = createAction('[AssetDetails] Run Shape Validation Error', props<{ runShapeValidationState: RunShapeValidationState }>());
export const shapeValidationResetState = createAction('[AssetDetails] Shape Validation Reset State');

export const getShapeValidationResultsRequest = createAction('[AssetDetails] Get Shape Validation Results Request', props<{ jobId: number }>());
export const getShapeValidationResultsDone = createAction('[AssetDetails] Get Shape Validation Results Done', props<{ getShapeValidationResultsState: ShapeValidationResultsState }>());
export const getShapeValidationResultsError = createAction('[AssetDetails] Get Shape Validation Results Error', props<{ getShapeValidationResultsState: ShapeValidationResultsState }>());
export const shapeValidationResultsResetState = createAction('[AssetDetails] Shape Validation Results Reset State');

//Job Deletion
export const deleteJobRequest = createAction('[AssetDetails] Delete Job Request', props<{ jobId: number }>());
export const deleteJobDone = createAction('[AssetDetails] Delete Job Done', props<{ deleteJobState: DeleteJobState }>());
export const deleteJobError = createAction('[AssetDetails] Delete Job  Error', props<{ deleteJobState: DeleteJobState }>());


//Document drafts
export const updateDocumentDraftDone = createAction('[AssetDetails] Update Document Draft Done', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());
export const updateDocumentDraftRequest = createAction('[AssetDetails] Update Document Draft Request', props<{ documentListViewDTO: DocumentListViewDTO[], documentViewDTO: DocumentViewDTO, parentEntityId: string, documentBlob: File, dataSourceId: string, widgetId: string }>());
export const updateDocumentDraftError = createAction('[AssetDetails] Update Document Draft Error', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());

export const addDocumentDraftDone = createAction('[AssetDetails] Add Document Draft Done', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());
export const addDocumentDraftRequest = createAction('[AssetDetails] Add Document Draft Request', props<{ documentListViewDTO: DocumentListViewDTO[], documentViewDTO: DocumentViewDTO, parentEntityId: string, documentBlob: File, pageId: string, dataSourceId: string, widgetId: string }>());
export const addDocumentDraftError = createAction('[AssetDetails] Add Document Draft Error', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());

export const getDocumentDraftsRequest = createAction('[AssetDetails] Get Document Drafts Request', props<{ pageId: string, dataSourceId: string, widgetId: string }>());
export const getDocumentDraftsDone = createAction('[AssetDetails] Get Document Drafts Done', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());
export const getDocumentDraftsError = createAction('[AssetDetails] Get Document Drafts Error', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());

export const documentDraftResetState = createAction('[AssetDetails] Document Draft Reset State');
