import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { SiteSettingsFacade } from 'src/app/facade/site-settings/site-settings.facade';
import { Calendars } from '../../data-transfer-objects/configuration/site-settings-configuration-view-dto';

@Injectable({
    providedIn: 'root'
})

export class SiteSettingsService {
    public dateFormat: string = '';
    public calendars: Calendars = null;
    protected _subscription: Subscription

    public constructor(
        protected siteSettingsFacade: SiteSettingsFacade
    ) {
        // The RxJS first() operator waits until the first value is emitted from an observable and then
        // automatically unsubscribes, so there is no need to explicitly unsubscribe from the subscription.
        this.siteSettingsFacade
            .getDateFormat()
            .pipe(first())
            .subscribe((dateFormat: string) => {
                this.dateFormat = dateFormat;
            });
    }
}
