import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SessionDetailsWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/session-details-widget-configuration-view-dto';
import { SessionViewDTO } from '../../../../../data-transfer-objects/session/session-view-dto';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { ConfigurableControlsHelper } from '../../../../../helpers/configurable-controls-helper';
import { BaseWidget } from '../base-widget';
import { AbstractSessionDetailsFacade } from '../../../../../facade/abstract/abstract-session-details.facade';
import { UIStylingHelper } from '../../../../../helpers/ui-styling-helper';

@Component({
    selector: 'fw-session-details-widget',
    templateUrl: './session-details-widget.component.html',
})
export class SessionDetailsWidgetComponent extends BaseWidget implements OnInit {
    public FormGroup: FormGroup = new FormGroup({});

    @Input()
    public SessionDetailsWidgetConfiguration: SessionDetailsWidgetConfigurationViewDTO;

    @Input()
    public FormEditStyle: FormEditStyle = FormEditStyle.ReadOnly;

    private SessionViewDTO: SessionViewDTO;

    constructor(
        private actionDetailsFacade: AbstractSessionDetailsFacade,
        public elementRef: ElementRef) {
        super(elementRef);
    }

    ngOnInit(): void {

        this.SessionViewDTO = this.actionDetailsFacade.GetSessionDetails();
        ConfigurableControlsHelper.PopulateFormControl(this.SessionViewDTO, this.SessionDetailsWidgetConfiguration.Properties, this.FormGroup);
    }

    public getLayoutMode(): string {
        return UIStylingHelper.getLayoutMode(this.SessionDetailsWidgetConfiguration.LayoutMode)
    }

    public isReadOnly(): boolean {
        return true;
     }
}
