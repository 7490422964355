import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommodityViewDTO } from '../../data-transfer-objects/commodity/commodity-view-dto';
import { BASE_URL } from '../../helpers/base-url';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class CommodityHttpService extends AuthenticatedHttpServiceBase {

    constructor(httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, errorService: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorService, toastService);
    }

    public getCommoditiesByEntityId(entityId: string, pageId: string): Observable<CommodityViewDTO[]> {
        return this.GetAuthenticated<CommodityViewDTO[]>(`${this.baseUrl}api/commodity/${entityId}`, this.AddPageIdHeader(this.GetStandardHeaders(), pageId));
    }

    public addCommodity(entityId: string, pageId: string, commodityId: string): Observable<null> {

        return this.PostAuthenticated<null>(`${this.baseUrl}api/commodity/${commodityId}/entityId/${entityId}`, {}, this.AddPageIdHeader(this.GetStandardHeaders(), pageId));
    }

    public removeCommodity(entityId: string, pageId: string, commodityId: string): Observable<null> {

        return this.DeleteAuthenticated<null>(`${this.baseUrl}api/commodity/${commodityId}/entityId/${entityId}`, this.AddPageIdHeader(this.GetStandardHeaders(), pageId));
    }
}
