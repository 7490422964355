/* eslint-disable */
import { Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { $ } from 'jquery';
import { BootstrapHelper } from 'src/app/helpers/bootstrap-helper';

declare let $: any;

@Directive({
    selector: '[portal-tooltip]',
})
export class TooltipDirective implements OnChanges, OnDestroy {

    @Input()
    public TooltipPlacement: string = '';

    @Input()
    public TooltipTitle: string = '';

    constructor(private elementRef: ElementRef) { }

    ngOnDestroy(): void {
        BootstrapHelper.CleanTooltip($);
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges._tooltipPlacement || simpleChanges._tooltipTitle) {
            if (this.TooltipPlacement)
                BootstrapHelper.InitializeTooltip(this.elementRef.nativeElement, $, this.TooltipTitle, this.TooltipPlacement);
            else
                BootstrapHelper.InitializeTooltip(this.elementRef.nativeElement, $, this.TooltipTitle);
        }
    }
}
