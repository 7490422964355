<p-dropdown [(ngModel)]="selectedValue" optionValue="value" optionLabel="label" [options]="stringMatchModeOptions" (onChange)="ClearInput()" class="d-block mb-10"></p-dropdown>
﻿<div *ngIf="DisplayInputText()">
    <input type="text" pInputText [(ngModel)]="inputText" />
</div>

<div class="p-column-filter-buttonbar p-0 pt-40">
    <button pbutton pripple (click)="ClearFilter()" class="p-button-outlined p-button-sm p-button">
        <span class="p-button-label">{{'List.Filter.Clear'| transloco}}</span>
    </button>
    <button pbutton pripple (click)="ApplyFilter()" class="p-button-sm p-button">
        <span class="p-button-label">{{'List.Filter.Apply'| transloco}}</span>
    </button>
</div>
