export class DocumentCreateDto {
    constructor(public Id: string,
        public DocumentTypeId: string,
        public SensitivityId: string,
        public EntityId: string,
        public DateUploaded: string,
        public DocumentBlob: File,
        public FileName: string,
        public StateGroupId?: string) {
    }
}