import { OrderByDirection } from '../enums/comparator-enum';

export class PaginationModel {
    public Limit: number;
    public OrderByList: OrderByModel[];
    public Page: number;
    public ShowAll: boolean;
}

export class OrderByModel {
    public Field: string;
    public OrderByDirection: OrderByDirection;
}

export class PaginationResultModel<TModel> {
    public DisplayNextPage: boolean;
    public Models: TModel[];
    public TotalRecords: number;
    public TotalUnfilteredRecords?: number;
    public Page: number;
}
