import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL } from '../helpers/base-url';
import { SessionApplicationService } from '../services/application/session-application.service';

@Injectable()
export class AuthenticationTokenInterceptor implements HttpInterceptor {

    constructor(private sessionApplicationService: SessionApplicationService, @Inject(BASE_URL) private _baseUrl) {

    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.sessionApplicationService.authenticationToken) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${this.sessionApplicationService.authenticationToken}` }
            });
        }

        return next.handle(request);
    }
}
