import { Directive, Input, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ValidatableInputControlBase } from './validatable-input-control-base';

@Directive()
export abstract class DebounceTimeControlBase<T> extends ValidatableInputControlBase<T> implements OnDestroy {

    public textSubject = new Subject<string>();
    public textSubjectSubscription: Subscription;
    constructor(protected transloco: TranslocoService) {

        super(transloco);

        //This will wait for the user to stop typing for 1.5 seconds
        //Then call onAcceptChanges if the value is unique.
        this.textSubjectSubscription = this.textSubject.pipe(
            debounceTime(1500),
            distinctUntilChanged(),
            map(() => {
                this.onAcceptChanges();
            })).subscribe();
    }


    public onTextValueChange(value: string): void {
        this.textSubject.next(value);
    }

    public onNumberValueChange(value: number): void {
        this.textSubject.next(value.toString());
    }

    ngOnDestroy(): void {
        this.textSubjectSubscription.unsubscribe();
    }

}