import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorDetails } from 'src/app/models/error-details';
import { DiscussionCommentViewDTO } from '../../../data-transfer-objects/discussion-comment/discussion-comment-view-dto';
import { OrderByDirection } from '../../../enums/comparator-enum';
import { OrderByModel, PaginationModel } from '../../../models/pagination-models';
import { DiscussionCommentsHttpService } from '../../../services/http/discussion-comments-http.service';
import { addDiscussionCommentRequest, addDiscussionCommentRequestDone, addDiscussionCommentRequestError, loadDiscussionCommentsRequest, loadDiscussionCommentsRequestDone, loadDiscussionCommentsRequestError } from './discussion-comments.actions';

@Injectable()
export class DiscussionCommentsEffects {

    addDiscussionComments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addDiscussionCommentRequest),
            switchMap((action) => {

                return this.discussionCommentsHttpService.addDiscussionComment(action.entityId, action.pageId, action.discussionId, action.discussionCommentCreateDto).pipe(
                    map((addedComment: DiscussionCommentViewDTO) => {
                        return addDiscussionCommentRequestDone({ discussionCommentViewDTO: addedComment });
                    }),
                    catchError((errorDetails: ErrorDetails) => {
                        return of(addDiscussionCommentRequestError({ addError: errorDetails }));
                    })
                );
            })
        ));

    loadDiscussionComments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadDiscussionCommentsRequest),
            switchMap((action) => {

                const orderByModels: OrderByModel[] = [];

                const orderByModel: OrderByModel = {
                    Field: 'Timestamp',
                    OrderByDirection: OrderByDirection.Descending,
                };

                orderByModels.push(orderByModel);

                const paginationModel: PaginationModel = {
                    Limit: 100,
                    OrderByList: orderByModels,
                    Page: 1,
                    ShowAll: false
                };
                return this.discussionCommentsHttpService.getDiscussionComments(action.discussionId, action.entityId, action.pageId, paginationModel).pipe(
                    map((comments: DiscussionCommentViewDTO[]) => {
                        return loadDiscussionCommentsRequestDone({ discussionCommentsWidgetState: { DiscussionComments: comments, GetDiscussionCommentsError: null } });
                    }),
                    catchError((errorDetails: ErrorDetails) => {
                        return of(loadDiscussionCommentsRequestError({ discussionCommentsWidgetState: { DiscussionComments: null, GetDiscussionCommentsError: errorDetails } }));
                    })
                );
            })
        ));

    constructor(
        private actions$: Actions,
        private discussionCommentsHttpService: DiscussionCommentsHttpService
    ) { }

}
