import { createReducer, on } from '@ngrx/store';
import { ListState, PageConfigurationState } from '../../../AppState';
import { ListStateReducerHelper } from '../../../helpers/list-state-reducer-helper';
import { loadPageConfigurationDone, loadPageConfigurationError, resetPageConfiguration } from './site-configuration.actions';


//Assets
export const initialPageConfigurationState: ListState<PageConfigurationState>[] = [];

export const PageConfigurationReducer = createReducer<ListState<PageConfigurationState>[]>(initialPageConfigurationState,
    on(loadPageConfigurationDone, (state, action) => { return ListStateReducerHelper.GetUpdatedListState<PageConfigurationState>(state, action.pageId, action.pageConfigurationState)}),
    on(loadPageConfigurationError, (state, action) => { return ListStateReducerHelper.GetUpdatedListState<PageConfigurationState>(state, action.pageId, action.pageConfigurationState) }),
    on(resetPageConfiguration, () => initialPageConfigurationState)
);


