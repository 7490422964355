import { Injectable, Inject } from '@angular/core';
import { LookupModel } from 'src/app/models';
import { KeyValue } from '@angular/common';
import { Observable, of, Subscriber, ReplaySubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { LandfolioLookupTables, LookupTables } from 'src/app/enums/lookup-table-enum';
import { BASE_URL } from 'src/app/helpers/base-url';
import { ErrorManagerService } from './error-manager.service';
import { ToastService } from './toast.service';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { GuidHelper } from '../../helpers/guid-helper';

@Injectable({
    providedIn: 'root'
})
export class LookupCacheService {

    private _landfolioLookupTableCache: KeyValue<string, Observable<LookupModel[]>>[];
    private _lookupTableCache: KeyValue<LookupTables, Observable<LookupModel[]>>[];
    private _lookupTableService: LookupTableService;

    constructor(httpClient: HttpClient, @Inject(BASE_URL) baseUrl: string, errorManager: ErrorManagerService, toastService: ToastService) {

        this._landfolioLookupTableCache = new Array<KeyValue<string, Observable<LookupModel[]>>>();
        this._lookupTableCache = new Array<KeyValue<LookupTables, Observable<LookupModel[]>>>();
        this._lookupTableService = new LookupTableService(httpClient, baseUrl, errorManager, toastService);
    }

    public GetFilteredLandfolioLookupTable(lookupId: string, filter: string) {

        const cacheEntry: KeyValue<string, Observable<LookupModel[]>> = this._landfolioLookupTableCache.find((kvp: KeyValue<string, Observable<LookupModel[]>>) => {
            return kvp.key === `${lookupId.toString().toLowerCase() + filter.toString().toLowerCase()}`;
        });

        if (!cacheEntry) {
            const replaySubject: ReplaySubject<LookupModel[]> = new ReplaySubject<LookupModel[]>(1);

            this._lookupTableService.GetFilteredLookupId(lookupId, filter).subscribe((lookupModels: LookupModel[]) => {
                replaySubject.next(lookupModels);
            });

            const resultObs: Observable<LookupModel[]> = replaySubject.asObservable();

            this._landfolioLookupTableCache.push({
                key: `${lookupId.toString().toLowerCase() + filter.toString().toLowerCase()}`,
                value: resultObs
            });

            return resultObs;
        }
        else {
            return cacheEntry.value;
        }

    }

    public GetLandfolioLookupTable(lookupId: string): Observable<LookupModel[]> {

        const cacheEntry: KeyValue<string, Observable<LookupModel[]>> = this._landfolioLookupTableCache.find((kvp: KeyValue<string, Observable<LookupModel[]>>) => {
            return kvp.key === lookupId;
        });

        if (cacheEntry === undefined) {
            const replaySubject: ReplaySubject<LookupModel[]> = new ReplaySubject<LookupModel[]>(1);

            this._lookupTableService.GetById(lookupId).subscribe((lookupModels: LookupModel[]) => {
                replaySubject.next(lookupModels);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }, (error: any) => console.error(error));

            const resultObs: Observable<LookupModel[]> = replaySubject.asObservable();


            this._landfolioLookupTableCache.push({
                key: lookupId,
                value: resultObs
            })

            return resultObs;
        }
        else {
            return cacheEntry.value;
        }

    }

    public GetLandfolioLookupValue(lookupEnum: LandfolioLookupTables, id: string): Observable<string> {

        return new Observable<string>((observer: Subscriber<string>) => {

            this.GetLandfolioLookupTable(LandfolioLookupTables[lookupEnum]).subscribe((result: LookupModel[]) => {
                observer.next(result.find(function (ele: LookupModel) {

                    if ((GuidHelper.ValidGuid(id)) && (!GuidHelper.Equals(id, GuidHelper.EmptyGuid()))) {
                        return GuidHelper.Equals(ele.Id, id);
                    } else {
                        return ele.Id.toString() === id.toString();
                    }

                }).Value);
            }, (error) => {
                observer.error(error);
            });

        });
    }

    public GetLookupTable(lookupEnum: LookupTables): Observable<LookupModel[]> {

        const cacheEntry: KeyValue<LookupTables, Observable<LookupModel[]>> = this._lookupTableCache.find((kvp: KeyValue<LookupTables, Observable<LookupModel[]>>) => {
            return kvp.key === lookupEnum;
        });

        if (cacheEntry === undefined) {
            const replaySubject: ReplaySubject<LookupModel[]> = new ReplaySubject<LookupModel[]>(1);

            this._lookupTableService.GetByLookupId(lookupEnum).subscribe((lookupModels: LookupModel[]) => {

                replaySubject.next(lookupModels);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }, (error: any) => console.error(error));

            const resultObs: Observable<LookupModel[]> = replaySubject.asObservable();


            this._lookupTableCache.push({
                key: lookupEnum,
                value: resultObs
            })

            return resultObs;
        }
        else {
            return cacheEntry.value;
        }

    }

    public GetLookupValue(lookupEnum: LookupTables, id: string): Observable<string> {

        return new Observable<string>((observer: Subscriber<string>) => {

            this.GetLookupTable(lookupEnum).subscribe((result: LookupModel[]) => {
                observer.next(result.find(function (ele: LookupModel) {

                    if ((GuidHelper.ValidGuid(id)) && (!GuidHelper.Equals(id, GuidHelper.EmptyGuid()))) {
                        return GuidHelper.Equals(ele.Id, id);
                    } else {
                        return ele.Id.toString() === id.toString();
                    }

                }).Value);
            }, (error) => {
                observer.error(error);
            });

        });
    }

}

class LookupTableService extends AuthenticatedHttpServiceBase {

    constructor(httpClient: HttpClient, private baseUrl: string, errorManager: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorManager, toastService);
    }

    GetById(modelId: string): Observable<LookupModel[]> {
        return this.GetAuthenticated<LookupModel[]>(`${this.baseUrl}api/Lookups/LandfolioLookupTable/${modelId}`, this.GetStandardHeaders());
    }

    GetByLookupId(modelEnum: LookupTables): Observable<LookupModel[]> {
        return this.GetAuthenticated<LookupModel[]>(`${this.baseUrl}api/Lookups/LookupTable/${modelEnum.toString()}`, this.GetStandardHeaders());
    }

    GetFilteredLookupId(lookup: string, filter: string): Observable<LookupModel[]> {
        return this.GetAuthenticated<LookupModel[]>(`${this.baseUrl}api/lookups/landfoliolookuptable/${lookup}?filter=${filter}`, this.GetStandardHeaders());
    }

}
