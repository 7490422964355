import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscriber } from 'rxjs';
import { Roles } from '../../enums/roles-enum';
import { ClientUserModel } from '../../models/client-user-model';
import { RoleModel } from '../../models/role-model';
import { SessionApplicationService } from './session-application.service';

@Injectable({
    providedIn: 'root'
})
export class RoleApplicationService {

    private _currentRoles: ReplaySubject<RoleModel[]> = new ReplaySubject<RoleModel[]>(1);
    private _userModel: ReplaySubject<ClientUserModel> = new ReplaySubject<ClientUserModel>(1);

    constructor(private sessionApplicationService: SessionApplicationService) { }

    public getUserRole(): Observable<Roles> {

        return new Observable<Roles>((subscriber: Subscriber<Roles>) => {

            // this._currentRoles.subscribe((roles: RoleModel[]) => {

            //     this._userModel.subscribe((userModel: ClientUserModel) => {

            //         subscriber.next(this.mapRole(roles.find((role: RoleModel) => {
            //             return role.Id === userModel.RoleId;
            //         }).Name));

            //     });

            // });

        });


    }

    public getUserRoles(): Observable<RoleModel[]> {
        return this._currentRoles.asObservable();
    }

    public mapRole(roleName: string): Roles {

        let result: Roles;

        switch (roleName) {

            case 'Land Manager':
                result = Roles.LandManager
                break;
            case 'Tenure Applicant':
                result = Roles.TenureApplicant
                break;
            case 'Kiosk Administrator':
                result = Roles.KioskAdministrator
                break;
        }

        return result;

    }

    public validateUserRole(roles: Roles[]): Observable<boolean> {

        return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
            this.sessionApplicationService.getCurrentClientUser().subscribe((userModel: ClientUserModel) => {
                const found: boolean = false;

                // this._currentRoles.subscribe((currentRoles: RoleModel[]) => {

                //     const currentRole = this.mapRole(currentRoles.find((role: RoleModel) => {
                //         return role.Id === userModel.RoleId;
                //     }).Name);

                //     if (roles.indexOf(currentRole) > -1) {
                //         found = true;
                //     }

                //     subscriber.next(found);

                // });

            });

        });

    }
}
