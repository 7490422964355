import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { PageConfigurationViewDTO } from '../data-transfer-objects/configuration/page-configuration-view.dto';
import { ConfigurationHttpService } from '../services/http/configuration-http.service';
import { DynamicPageIdParameter } from '../consts/portal-base-routes';
import { SessionApplicationService } from '../services/application/session-application.service';
import { PageType } from '../enums/configuration/page-type';
import { BasePageConfigurationViewDTO } from '../data-transfer-objects/configuration/pages/base-page-configuration-view-dto';
import { ErrorModel } from "../models/error-model";
import { HttpStatusCode } from "../enums/http-status-codes";
import { ErrorManagerService } from "../services/deprecated/error-manager.service";

export const UserId: string = 'UserId';
export const ReturnUrl: string = 'ReturnUrl';

@Injectable({
    providedIn: 'root'
})
export class DynamicRolesGuard implements CanActivate, OnDestroy {

    private _subscriptions: Subscription[] = [];

    constructor(
        private _router: Router,
        private sessionApplicationService: SessionApplicationService,
        private _configurationService: ConfigurationHttpService,
        private _error: ErrorManagerService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return new Observable<boolean>((subscriber: Subscriber<boolean>) => {

            const pageId: string = route.params[DynamicPageIdParameter];
            const getPageConfigurationSubscription: Subscription = this._configurationService.GetPageConfiguration(pageId).subscribe((page: PageConfigurationViewDTO) => {

                if (page.PageType === PageType.UserDetails) {
                    const userId: string = route.queryParams[UserId];
                    if (!userId) {
                        subscriber.next(false);
                    }
                }

                const currentPage: BasePageConfigurationViewDTO = this.getCurrentPage(page);

                if (this.sessionApplicationService.isAuthenticated && this.sessionApplicationService.hasRoles) {
                    let canActivate: boolean = false;
                    const userRoles: string[] = this.sessionApplicationService.currentUser.Roles;

                    for (let userRoleCounter: number = 0; userRoleCounter < userRoles.length; userRoleCounter++) {

                        if (!currentPage.AllowAllAuthenticatedAccess) {
                            canActivate = currentPage.Roles.find((role: string) => userRoles[userRoleCounter].toUpperCase() === role.toUpperCase()) !== undefined;
                        } else {
                            canActivate = true;
                        }

                        if (canActivate) {
                            break;
                        }
                    }

                    subscriber.next(canActivate);
                } else if (this.sessionApplicationService.isAuthenticated) {
                    subscriber.next(currentPage.AllowAllAuthenticatedAccess);
                } else {
                    if (!currentPage.AllowAnonymousAccess) {
                        this.sessionApplicationService.login(window.location.origin + state.url);
                    }

                    subscriber.next(currentPage.AllowAnonymousAccess);
                }
            }, error => {
                subscriber.next(false);
                
                if (error?.status === HttpStatusCode.FORBIDDEN && error?.detail) {
                    this._error.HandleError(new ErrorModel(error));
                }
            });

            this._subscriptions.push(getPageConfigurationSubscription);
        });
    }

    ngOnDestroy(): void {

        if (this._subscriptions) {
            this._subscriptions.forEach((subcription) => {
                if (subcription) {
                    subcription.unsubscribe();
                }
            })
        }
    }

    //TODO: Can be moved out for public access when needed
    private getCurrentPage(page: PageConfigurationViewDTO): BasePageConfigurationViewDTO {


        let currentPage: BasePageConfigurationViewDTO;

        switch (page.PageType) {
            case PageType.AssetDetails:
                currentPage = page.AssetDetailsPage;
                break;
            case PageType.Basic:
                currentPage = page.BasicPage;
                break;
            case PageType.CreateAction:
                currentPage = page.CreateActionPage;
                break;
            case PageType.CreateAsset:
                currentPage = page.CreateAssetPage;
                break;
            case PageType.UserDetails:
                currentPage = page.UserDetailsPage;
                break;
            case PageType.ActionDetails:
                currentPage = page.ActionDetailsPage;
                break;
            case PageType.AssetDetails:
                currentPage = page.AssetDetailsPage;
                break;
            default:
                currentPage = null;
        }

        return currentPage;
    }
}
