import { createAction, props } from '@ngrx/store';
import { RelatedAssetAddDTO } from 'src/app/data-transfer-objects/related-asset/related-asset-add-dto';
import { ListFieldConfigurationViewDTO } from '../../data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { RelatedAssetSearchModel } from '../../data-transfer-objects/related-asset/related-asset-search-dto';
import { PaginationModel, PaginationResultModel } from '../../models/pagination-models';
import { RelatedAssetListViewDTO } from '../../data-transfer-objects/related-asset/related-asset-list-view-dto';

export const getRelatedAssetsBySearchRequest = createAction('[RelatedAssets] Get Asset Related Assets By Search Request', props<{ fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, relatedAssetSearchDTO: RelatedAssetSearchModel, dataSourceId: string, pageId: string }>());
export const getRelatedAssetsBySearchDone = createAction('[RelatedAssets] Get Asset Related Assets By Search Done', props<{ result: PaginationResultModel<RelatedAssetListViewDTO>, dataSourceId: string }>());
export const addRelatedAssets = createAction('[RelatedAssets] Add Asset Related Assets', props<{ dataSourceId: string, assetId: string, relatedAssets: RelatedAssetAddDTO[], pageId: string }>());
export const removeRelatedAssets = createAction('[RelatedAssets] Remove Asset Related Assets', props<{ dataSourceId: string, assetId: string, relatedAssetIds: string[], pageId: string }>());
export const startOperation = createAction('[RelatedAssets] Start Operation', props<{ dataSourceId: string }>());
export const endOperation = createAction('[RelatedAssets] End Operation', props<{ dataSourceId: string }>());