import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getIdPsConfigurationRequest, resetIdPsConfiguration } from '../../state/configuration/idp/idp.actions';
import { getIdPsConfiguration } from '../../state/configuration/idp/idp.selectors';
import { AppState } from '../../AppState';
import { IdPConfigurationViewDTO } from '../../data-transfer-objects/configuration/idp-configuration-view-dto';

@Injectable({
    providedIn: 'root'
})
export class IdPsConfigurationFacade {

    constructor(private store: Store<AppState>) {
    }

    public getIdPsConfiguration(): Observable<IdPConfigurationViewDTO> {
        return this.store.pipe(select(getIdPsConfiguration));
    }

    public loadIdPsConfiguration(forceAuthentication: boolean): void {
        this.store.dispatch(getIdPsConfigurationRequest({ forceAuthentication: forceAuthentication }));
    }

    public ResetState(): void {
        this.store.dispatch(resetIdPsConfiguration());
    }
}
