import { createReducer, on } from '@ngrx/store';
import { DiscussionCommentsWidgetState } from '../../../AppState';
import { ObjectHelper } from '../../../helpers/object-helper';
import { addDiscussionCommentRequestDone, addDiscussionCommentRequestError, loadDiscussionCommentsRequestDone, loadDiscussionCommentsRequestError, resetDiscussionComments } from './discussion-comments.actions';

export const initialDiscussionCommentsState: DiscussionCommentsWidgetState = { DiscussionComments: null, GetDiscussionCommentsError: null };

export const DiscussionCommentsReducer = createReducer<DiscussionCommentsWidgetState>(initialDiscussionCommentsState,
    on(loadDiscussionCommentsRequestDone, (state, action) => action.discussionCommentsWidgetState),
    on(loadDiscussionCommentsRequestError, (state, action) => action.discussionCommentsWidgetState),

    on(addDiscussionCommentRequestDone, (state, action) => {
        const stateToReturn: DiscussionCommentsWidgetState = ObjectHelper.deepCopyJsonParse(state);
        stateToReturn.DiscussionComments.unshift(action.discussionCommentViewDTO);
        return stateToReturn;
    }),
    on(addDiscussionCommentRequestError, (state, action) => {
        const stateToReturn: DiscussionCommentsWidgetState = ObjectHelper.deepCopyJsonParse(state);
        stateToReturn.GetDiscussionCommentsError = action.addError;
        return stateToReturn;
    }),
    on(resetDiscussionComments, () => initialDiscussionCommentsState)
);
