import { FormControl, FormGroup } from '@angular/forms';
import { NgbCalendarIslamicUmalqura, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { throwError } from 'rxjs';
import { BaseDetailsWidgetConfigurationViewDTO } from '../data-transfer-objects/configuration/base-details-widget-configuration';
import { DynamicControlType } from '../enums/configuration/dynamic-control-type';
import { PropertyConfiguration } from '../models/configuration/widgets/properties/property-configuration';
import { CalendarType } from './date-helper';

export class ConfigurableControlsHelper {
    //This could be refactored a bit.
    public static PopulateFormControl(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any,
        properties: PropertyConfiguration[],
        formGroup: FormGroup
    ) {
        properties.forEach((property) => {
            if (property.ControlType == DynamicControlType.ComplexProperty) {
                const childData = data[property.PropertyName];
                const childProperties = property.Properties;
                const newChildFormGroup = new FormGroup({});

                const existingChildFormGroup = formGroup.controls[
                    property.PropertyName
                ] as FormGroup;

                if (existingChildFormGroup) {
                    this.PopulateFormControl(
                        childData,
                        childProperties,
                        existingChildFormGroup
                    );
                } else {
                    this.PopulateFormControl(
                        childData,
                        childProperties,
                        newChildFormGroup
                    );
                    formGroup.addControl(property.PropertyName, newChildFormGroup);
                }
            } else if (property.ControlType == DynamicControlType.PropertyGroup) {
                const childData = data;
                const childProperties = property.Properties;
                const newChildFormGroup = new FormGroup({});

                const existingChildFormGroup = formGroup.controls[
                    property.PropertyName
                ] as FormGroup;

                if (existingChildFormGroup) {
                    if (data[property.PropertyName]) {
                        if (existingChildFormGroup.controls[property.PropertyName]) {
                            existingChildFormGroup.controls[property.PropertyName].setValue(
                                data[property.PropertyName]
                            );
                        } else {
                            const newAbstractControl = new FormControl(
                                data[property.PropertyName]
                            );
                            newAbstractControl.setValue(data[property.PropertyName]);
                            existingChildFormGroup.addControl(
                                property.PropertyName,
                                newAbstractControl
                            );
                        }
                    }

                    this.PopulateFormControl(
                        childData,
                        childProperties,
                        existingChildFormGroup
                    );
                } else {
                    this.PopulateFormControl(
                        childData,
                        childProperties,
                        newChildFormGroup
                    );
                    formGroup.addControl(property.PropertyName, newChildFormGroup);
                }
            } else if (property.ControlType == DynamicControlType.Dropdown) {
                const abstractFormDropDown = formGroup.controls[property.PropertyName];

                const dropDownData = data[property.PropertyName]?.toString();

                if (!abstractFormDropDown) {
                    const abstractControl = new FormControl(dropDownData);
                    formGroup.addControl(property.PropertyName, abstractControl);
                } else {
                    abstractFormDropDown.setValue(dropDownData);
                }
            } else if (property.ControlType == DynamicControlType.Calendar) {
                const abstractFormControlCalendar =
                    formGroup.controls[property.PropertyName];

                if (!abstractFormControlCalendar) {
                    let abstractControl: FormControl;
                    if (data) {
                        if (data[property.PropertyName]) {
                            abstractControl = new FormControl(
                                this.GetConfiguredCalendarDate(new Date(data[property.PropertyName]), property.Calendar.IsHijri)
                            );
                        } else {
                            abstractControl = new FormControl('');
                            abstractControl.setValue('');
                        }
                    }
                    formGroup.addControl(property.PropertyName, abstractControl);
                } else {
                    if (data[property.PropertyName]) {
                        abstractFormControlCalendar.setValue(
                            this.GetConfiguredCalendarDate(new Date(data[property.PropertyName]), property.Calendar.IsHijri)
                        );
                    } else {
                        abstractFormControlCalendar.setValue('');
                    }
                }
            } else {
                const abstractFormControl = formGroup.controls[property.PropertyName];

                if (!abstractFormControl) {
                    let abstractControl: FormControl;
                    if (data) {
                        abstractControl = new FormControl(data[property.PropertyName]);
                    } else {
                        abstractControl = new FormControl({});
                    }
                    formGroup.addControl(property.PropertyName, abstractControl);
                } else {
                    abstractFormControl.setValue(data[property.PropertyName]);
                }
            }
        });

        this.MarkAsTouched(formGroup);

    }

    public static UpdateCalendarFormControls(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any,
        calendarProperty: PropertyConfiguration,
        formGroup: FormGroup
    ) {

        //will technically only be used by calendar control type but just in case
        if (calendarProperty.ControlType == DynamicControlType.Calendar) {
            const abstractFormControlCalendar =
                formGroup.controls[calendarProperty.PropertyName];

            if (!abstractFormControlCalendar) {
                let abstractControl: FormControl;
                if (data) {
                    if (data[calendarProperty.PropertyName]) {

                        abstractControl = new FormControl(
                            this.GetConfiguredCalendarDate(new Date(data[calendarProperty.PropertyName]), calendarProperty.Calendar.IsHijri));

                    } else {
                        abstractControl = new FormControl('');
                        abstractControl.setValue('');
                    }
                }
                formGroup.addControl(calendarProperty.PropertyName, abstractControl);
            } else {
                if (data[calendarProperty.PropertyName]) {

                    abstractFormControlCalendar.setValue(
                        this.GetConfiguredCalendarDate(new Date(data[calendarProperty.PropertyName]), calendarProperty.Calendar.IsHijri)
                    );
                } else {
                    abstractFormControlCalendar.setValue('');
                }
            }
        }
        else {
            throwError('Only Calendar control type is supported');
        }

        this.MarkAsTouched(formGroup);

    }

    public static ResolveGroupPropertyValues(
        widgetConfiguration: BaseDetailsWidgetConfigurationViewDTO,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formGroup: FormGroup): any {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let groupData: any = {};

        widgetConfiguration.Properties.forEach((property) => {
            if (property.ControlType === DynamicControlType.PropertyGroup) {
                for (const p in formGroup.controls[property.PropertyName].value) {
                    groupData = { ...groupData, ...formGroup.controls[property.PropertyName].value }
                }
            } else {
                groupData[property.PropertyName] = formGroup.controls[property.PropertyName].value;
            }
        });

        return groupData;
    }

    private static MarkAsTouched(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(key => {

            if (formGroup.get(key).value) {
                formGroup.get(key).markAsTouched();
            }
        });
    }

    public static GetConfiguredCalendarDate(gregorianDate: Date, isHijri: boolean): NgbDate | Date {

        if (isHijri) {
            return this.ConvertGregorianToHijri(gregorianDate);
        }

        return gregorianDate;
    }

    public static ConvertHijriToGregorian(hijriDate: NgbDate): Date {
        return new NgbCalendarIslamicUmalqura().toGregorian(hijriDate);
    }

    public static ConvertGregorianToHijri(gregorianDate: Date): NgbDate {
        return new NgbCalendarIslamicUmalqura().fromGregorian(gregorianDate);
    }

    public static GetCalendarOptions(transloco: TranslocoService): any[] {
        return [
            { type: CalendarType.Gregorian, label: transloco.translate('Calendar.Gregorian.LongDescription'), value: transloco.translate('Calendar.Gregorian.ShortDescription') },
            { type: CalendarType.Hijri, label: transloco.translate('Calendar.Hijri.LongDescription'), value: transloco.translate('Calendar.Hijri.ShortDescription') },
        ];
    }
}
