<fw-inline-edit-text-field *ngIf="FormEditStyle === FormEditStyles.Inline || FormEditStyle === FormEditStyles.ReadOnly"
                           [CustomFormGroup]="CustomFormGroup"
                           [PropertyName]="Property.PropertyName"
                           [Label]="Property.LabelResourceId"
                           [LabelStyle]="Property.LabelStyle"
                           [ValueStyle]="Property.ValueStyle"
                           [TextField]="Property.TextField"
                           [AcceptChanges]="AcceptChanges"
                           [FormEditStyle]="FormEditStyle">
</fw-inline-edit-text-field>

<fw-form-edit-text-field *ngIf="FormEditStyle === FormEditStyles.Form"
                         [CustomFormGroup]="CustomFormGroup"
                         [PropertyName]="Property.PropertyName"
                         [Label]="Property.LabelResourceId"
                         [LabelStyle]="Property.LabelStyle"
                         [TextField]="Property.TextField"
                         [AcceptChanges]="AcceptChanges">
</fw-form-edit-text-field>
