import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentCreateDto } from 'src/app/data-transfer-objects/document/document-create-dto';
import { DocumentListViewDTO } from 'src/app/data-transfer-objects/document/document-list-view-dto';
import { BASE_URL } from 'src/app/helpers/base-url';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';
import { DocumentDraftHttpService } from './document-draft-http.service';

@Injectable({
    providedIn: 'root'
})
export class AssetDocumentDraftHttpService extends DocumentDraftHttpService {

    constructor(httpClient: HttpClient, @Inject(BASE_URL) baseUrl: string, errorManager: ErrorManagerService, toastService: ToastService) {
        super(httpClient, baseUrl, errorManager, toastService);
    }

    public addDocument(documentCreateDto: DocumentCreateDto, pageId: string, stateGroupId: string): Observable<null> {
        return this.AddDocument(documentCreateDto, `asset/${pageId}?stateGroupId=${stateGroupId}`);
    }

    public getDocuments(pageId: string, stateGroupId: string): Observable<DocumentListViewDTO[]> {
        return this.GetDocumentsByEntityDraft(`asset/${pageId}?stateGroupId=${stateGroupId}`);
    }

    public removeDocumentDraft(pageId: string, stateGroupId: string): Observable<null> {
        return this.RemoveEntityDraft(`asset/${pageId}?stateGroupId=${stateGroupId}`);
    }
}
