import { Route } from '@angular/router';
import { Roles } from '../enums/roles-enum';
import { AuthGuard } from '../guards/auth.guard';
import { DynamicRolesGuard } from '../guards/dynamic-roles.guard';
import { LoginGuard } from '../guards/login.guard';
import { PreNavigationGuard } from '../guards/pre-navigation.guard';
import { RedirectComponent } from '../guards/redirect.component';
import { RoleGuard } from '../guards/role.guard';
import { RouteHelper } from '../helpers/route-helper';
import { StaticRoute } from '../interfaces/route/static-route';

export const AuditLogBaseRoute: string = 'audit-log';
export const DashboardBaseRoute: string = 'dash-board';
export const DynamicPageBaseRoute: string = 'page';
export const DynamicPageIdParameter: string = 'pageId';
export const LandingPageRelativeRoute: string = `landing`;
export const LandingPageBaseRoute: string = `${DynamicPageBaseRoute}/${LandingPageRelativeRoute}`;

export const PortalBaseRoutes: Route[] =
    [
        {
            path: DashboardBaseRoute,
            component: RedirectComponent,
            canActivate: [PreNavigationGuard]
        },
        {
            path: 'basic-page',
            loadChildren: () => import('../modules/dynamic/pages/basic/basic-page.module').then(m => m.BasicPageModule),
            canActivate: [PreNavigationGuard]
        },
        {
            path: '',
            redirectTo: LandingPageBaseRoute,
            pathMatch: 'full'
        },
        {
            path: AuditLogBaseRoute,
            loadChildren: () => import('../modules/static/pages/audit-log/audit-log.module').then(m => m.AuditLogModule),
            canActivate: [AuthGuard, PreNavigationGuard, RoleGuard],
            data: {
                roles: [Roles.KioskAdministrator]
            }
        },
        {
            path: 'error',
            loadChildren: () => import('../modules/static/pages/error/error.module').then(m => m.ErrorModule)
        },
        {
            matcher: RouteHelper.MatchStaticPages,
            staticPageId: 'setPassword',
            loadChildren: () => import('../modules/static/pages/set-password/set-password.module').then(m => m.SetPasswordModule),
            canActivate: [LoginGuard, PreNavigationGuard],
        } as StaticRoute,        
        {
            matcher: RouteHelper.MatchStaticPages,
            staticPageId: 'register',
            loadChildren: () => import('../modules/static/pages/register-user/register-user.module').then(m => m.RegisterUserModule)
        } as StaticRoute,
        {
            matcher: RouteHelper.MatchStaticPages,
            staticPageId: 'post-payment-failure',
            loadChildren: () => import('../modules/static/pages/post-payment-failure/post-payment-failure.module').then(m => m.PostPaymentFailureModule)
        } as StaticRoute,
        {
            matcher: RouteHelper.MatchStaticPages,
            staticPageId: 'post-payment-success',
            loadChildren: () => import('../modules/static/pages/post-payment-success/post-payment-success.module').then(m => m.PostPaymentSuccessModule)
        } as StaticRoute,
        {
            matcher: RouteHelper.MatchStaticPages,
            staticPageId: 'authentication-settings',
            loadChildren: () => import('../modules/static/pages/authentication-settings/authentication-settings.module').then(m => m.AuthenticationSettingsModule),
            canActivate: [AuthGuard, PreNavigationGuard]
        } as StaticRoute,
        {
            matcher: RouteHelper.MatchStaticPages,
            staticPageId: 'login',
            loadChildren: () => import('../modules/static/pages/login/login.module').then(m => m.LoginModule)
        } as StaticRoute,
        {
            matcher: RouteHelper.MatchStaticPages,
            staticPageId: 'idperror',
            loadChildren: () => import('../modules/static/pages/idp-error/idp-error.module').then(m => m.IdPErrorModule)
        } as StaticRoute,
        {
            path: `${DynamicPageBaseRoute}/:${DynamicPageIdParameter}`,
            loadChildren: () => import('../modules/dynamic/pages/master/master.module').then(m => m.MasterModule),
            canActivate: [PreNavigationGuard, DynamicRolesGuard]
        },
        {
            path: '**',
            loadChildren: () => import('../modules/static/pages/error/error.module').then(m => m.ErrorModule)
        }
    ];
