import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StructuredDataModule } from '@landadmin/structured-data';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { IntercomModule } from 'ng-intercom';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { NgxPaginationModule } from 'ngx-pagination';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { StyleClassModule } from 'primeng/styleclass';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ActionDetailsFormPageStateConst, ActionDetailsPageStateConst, ActionDraftPageStateConst, AssetDetailsPageStateConst, AssetDraftPageStateConst, BasicPageStateConst, ConfigurationStateConst, GoogleAnalyticsConfigurationStateConst, IdPsConfigurationStateConst, SiteSettingsConfigurationStateConst, UIStyleConfigurationStateConst, UserDetailsPageStateConst, WidgetStateConst } from './AppState';
import { PostPaymentFacade } from './facade/post-payment/post-payment.facade';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { RedirectComponent } from './guards/redirect.component';
import { BASE_URL, getBaseUrl } from './helpers/base-url';
import { AuthenticationTokenInterceptor } from './interceptors/authentication-token.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { DirectivesModule } from './modules/directives/directives.module';
import { DynamicModule } from './modules/dynamic/dynamic.module';
import { ModalContainerComponent } from './modules/static/components/form-modal-container/modal-container.component';
import { RoleSwitchFormComponent } from './modules/static/forms/role-switch-form/role-switch-form.component';
import { SwitchRepresentationComponent } from './modules/static/forms/switch-representation/switch-representation.component';
import { PortalLoadingComponent } from './modules/static/generics/components/portal-loading/portal-loading.component';
import { ToastComponent } from './modules/static/generics/components/toast/toast.component';
import { GenericControlsModule } from './modules/static/generics/generic-controls.module';
import { StaticModule } from './modules/static/static.module';
import { PipeModule } from './pipes/pipe-module';
import { NewActionEffects } from './state/action/action.effects';
import { NewAssetEffects } from './state/asset/asset.effects';
import { BehaviourEffects } from './state/behaviour/behaviour.effects';
import { CommodityEffects } from './state/commodity/commodity.effects';
import { CompanyEffects } from './state/company/company.effects';
import { ConfigurationReducerMapper } from './state/configuration/configuration.reducers.maps';
import { GoogleAnalyticsConfigurationEffects } from './state/configuration/google-analytics/google-analytics.effects';
import { GoogleAnalyticsConfigurationReducerMapper } from './state/configuration/google-analytics/google-analytics.reducers';
import { IdPsConfigurationEffects } from './state/configuration/idp/idp.effects';
import { IdPsConfigurationReducerMapper } from './state/configuration/idp/idp.reducers';
import { SiteConfigurationEffects } from './state/configuration/site/site-configuration.effects';
import { StructuredDataLookupEffects } from './state/configuration/structured-data/look-up/structured-data-look-up.effects';
import { StructuredDataNodeEffects } from './state/configuration/structured-data/node/structured-data-node.effects';
import { ActionDetailsPageFormEffects } from './state/deprecated/action-details-form/action-details-form.effects';
import { ActionDetailsFormReducerMapper } from './state/deprecated/action-details-form/action-details-form.reducers.maps';
import { ActionDetailsInlinePageEffects } from './state/deprecated/action-details-inline/action-details-inline.effects';
import { ActionDetailsInlineReducerMapper } from './state/deprecated/action-details-inline/action-details-inline.reducers.maps';
import { AssetDetailsPageEffects } from './state/deprecated/asset-details-inline/asset-details-inline.effects';
import { AssetDetailsInlineReducerMapper } from './state/deprecated/asset-details-inline/asset-details-inline.reducers.maps';
import { BasicPageEffects } from './state/deprecated/basic-page/basic-page.effects';
import { BasicPageReducerMapper } from './state/deprecated/basic-page/basic-page.reducer.maps';
import { ActionListReducer, AssetListReducer, UserListReducer } from './state/deprecated/basic-page/basic-page.reducers';
import { ActionPageEffects } from './state/deprecated/create-action-details-form/create-action-details-form.effects';
import { ActionDraftPageReducerMapper } from './state/deprecated/create-action-details-form/create-action-details-form.reducer.maps';
import { AssetPageEffects } from './state/deprecated/create-asset-details-form/create-asset-details-form.effects';
import { AssetDraftPageReducerMapper } from './state/deprecated/create-asset-details-form/create-asset-details-form.reducer.maps';
import { UIStyleEffects } from './state/deprecated/ui-style/ui-style.effects';
import { UIStyleFormInputStyleReducerMapper } from './state/deprecated/ui-style/ui-style.reducers';
import { UserDetailsEffects } from './state/deprecated/user-details/user-details-inline.effects';
import { UserDetailsInlineReducerMapper } from './state/deprecated/user-details/user-details-inline.reducer.maps';
import { DiscussionCommentsEffects } from './state/discussion/discussion-comments/discussion-comments.effects';
import { DiscussionParticipantsEffects } from './state/discussion/discussion-participants/discussion-participants.effects';
import { DiscussionEffects } from './state/discussion/discussion.effects';
import { PartyEffects } from './state/party/party.effects';
import { PostPaymentEffects } from './state/post-payment/post-payment.effects';
import { PowerBIEffects } from './state/power-bi/power-bi.effects';
import { RelatedAssetsEffects } from './state/related-asset/related-assets.effects';
import { RepresentativeEffects } from './state/representative/representative.effects';
import { SiteSettingsEffects } from './state/site-settings/site-settings.effects';
import { siteSettingsDateFormatReducerMapper } from './state/site-settings/site-settings.reducers';
import { StructuredDataEntryEffects } from './state/structured-data/structured-data.effects';
import { UserRoleEffects } from './state/user-role/user-role.effects';
import { WidgetsReducerMapper } from './state/widgets.reducers.maps';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { ConfigurationHttpService } from './services/http/configuration-http.service';

function appLoadFactory(config: ConfigurationHttpService) {
    return () => config.GetReCaptchaConfigurationSiteKey().toPromise();
}

@NgModule({
    declarations: [
        AppComponent,
        RedirectComponent
    ],
    imports: [
        StructuredDataModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        PipeModule,
        NgxPaginationModule,
        BrowserAnimationsModule,
        GenericControlsModule,
        StaticModule,
        DynamicModule,
        DirectivesModule,
        TranslocoRootModule,
        ToastModule,
        StyleClassModule,
        RecaptchaModule,
        StoreModule.forRoot({
            assetListWidgetState: AssetListReducer,
            actionListWidgetState: ActionListReducer,
            userListWidgetState: UserListReducer
        }),
        StoreModule.forFeature(ActionDetailsFormPageStateConst, ActionDetailsFormReducerMapper),
        StoreModule.forFeature(AssetDraftPageStateConst, AssetDraftPageReducerMapper),
        StoreModule.forFeature(BasicPageStateConst, BasicPageReducerMapper),
        StoreModule.forFeature(AssetDetailsPageStateConst, AssetDetailsInlineReducerMapper),
        StoreModule.forFeature(UserDetailsPageStateConst, UserDetailsInlineReducerMapper),
        StoreModule.forFeature(ActionDraftPageStateConst, ActionDraftPageReducerMapper),
        StoreModule.forFeature(ActionDetailsPageStateConst, ActionDetailsInlineReducerMapper),
        StoreModule.forFeature(UIStyleConfigurationStateConst, UIStyleFormInputStyleReducerMapper),
        StoreModule.forFeature(WidgetStateConst, WidgetsReducerMapper),
        StoreModule.forFeature(ConfigurationStateConst, ConfigurationReducerMapper),
        StoreModule.forFeature(SiteSettingsConfigurationStateConst, siteSettingsDateFormatReducerMapper),
        StoreModule.forFeature(IdPsConfigurationStateConst, IdPsConfigurationReducerMapper),
        StoreModule.forFeature(GoogleAnalyticsConfigurationStateConst, GoogleAnalyticsConfigurationReducerMapper),
        StoreDevtoolsModule.instrument(),
        EffectsModule.forRoot([
            StructuredDataNodeEffects,
            StructuredDataEntryEffects,
            StructuredDataLookupEffects,
            ActionDetailsPageFormEffects,
            UserDetailsEffects,
            UserRoleEffects,
            AssetPageEffects,
            AssetDetailsPageEffects,
            BasicPageEffects,
            ActionPageEffects,
            ActionDetailsInlinePageEffects,
            UIStyleEffects,
            SiteSettingsEffects,
            DiscussionEffects,
            DiscussionCommentsEffects,
            DiscussionParticipantsEffects,
            NewAssetEffects,
            NewActionEffects,
            RelatedAssetsEffects,
            SiteConfigurationEffects,
            PostPaymentEffects,
            IdPsConfigurationEffects,
            PartyEffects,
            CommodityEffects,
            RepresentativeEffects,
            CompanyEffects,
            GoogleAnalyticsConfigurationEffects,
            PowerBIEffects,
            BehaviourEffects
        ]),
        IntercomModule.forRoot({
            appId: ''
        }),
        KeyboardShortcutsModule.forRoot(),
        TranslateModule.forRoot()
    ],
    providers: [
        {
            provide: BASE_URL,
            useFactory: getBaseUrl
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationTokenInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appLoadFactory,
            deps: [ConfigurationHttpService],
            multi: true,
        },
        AuthGuard,
        LoginGuard,
        DialogService,
        DynamicDialogRef,
        DynamicDialogConfig,
        MessageService,
        PostPaymentFacade
    ],
    bootstrap: [AppComponent],
    entryComponents: [ModalContainerComponent, ToastComponent, RoleSwitchFormComponent, PortalLoadingComponent, SwitchRepresentationComponent]
})
export class AppModule { }
