import { ActionReducerMap } from '@ngrx/store';
import { ActionDetailsPageState } from '../../../AppState';
import { ActionFormIsSavingReducer, ActionFormLoadedReducer, ActionShapeFormReducer, ActionFormDeleteJobReducer, DocumentDraftsReducer, GetActionFormShapeValidationResultsReducer, RunActionFormShapeValidationReducer } from './action-details-form.reducers';


export const ActionDetailsFormReducerMapper: ActionReducerMap<ActionDetailsPageState> = {
    DocumentGroupWidgetState: DocumentDraftsReducer,
    IsSaving: ActionFormIsSavingReducer,
    ActionDraftLoaded: ActionFormLoadedReducer,
    ShapeWidgetState: ActionShapeFormReducer,
    RunShapeValidationState: RunActionFormShapeValidationReducer,
    ShapeValidationResultsState: GetActionFormShapeValidationResultsReducer,
    DeleteJobState: ActionFormDeleteJobReducer
};
