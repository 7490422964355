import { createAction, props } from '@ngrx/store';
import { ReferenceCodeListWidgetState } from 'src/app/AppState';
import { ListFieldConfigurationViewDTO } from 'src/app/data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { PaginationModel } from 'src/app/models/pagination-models';

export const getAssetReferenceCodesRequest = createAction('[ReferenceCodes] Get Asset Reference Codes Request', props<{ fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, assetId: string, dataSourceId: string, pageId: string, widgetId: string }>());
export const getAssetReferenceCodesDone = createAction('[ReferenceCodes] Get Asset Reference Codes Done', props<{ referenceCodeListWidgetState: ReferenceCodeListWidgetState, dataSourceId: string }>());
export const getAssetReferenceCodesError = createAction('[ReferenceCodes] Get Asset Reference Codes Error', props<{ referenceCodeListWidgetState: ReferenceCodeListWidgetState, dataSourceId: string }>());

export const getActionReferenceCodesRequest = createAction('[ReferenceCodes] Get Action Reference Codes Request', props<{ fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, actionId: string, dataSourceId: string, pageId: string, widgetId: string }>());
export const getActionReferenceCodesDone = createAction('[ReferenceCodes] Get Action Reference Codes Done', props<{ referenceCodeListWidgetState: ReferenceCodeListWidgetState, dataSourceId: string }>());
export const getActionReferenceCodesError = createAction('[ReferenceCodes] Get Action Reference Codes Error', props<{ referenceCodeListWidgetState: ReferenceCodeListWidgetState, dataSourceId: string }>());

export const resetReferenceCodesList = createAction('[ReferenceCodes] Reset Reference Codes List');
