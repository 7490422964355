import { Injectable } from '@angular/core';
import { SessionViewDTO } from '../../data-transfer-objects/session/session-view-dto';

@Injectable({
    providedIn: 'root'
})
export abstract class AbstractSessionDetailsFacade {
       
    public abstract GetSessionDetails(): SessionViewDTO;
}
