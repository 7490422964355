import { createReducer, on } from '@ngrx/store';
import { UIStyleConfigurationState } from '../../../AppState';
import { FormInputStyle } from '../../../enums/configuration/form-input-style';
import { getUIStyleConfigurationDone } from './ui-style.actions';

const initialUIStyleDTO: UIStyleConfigurationState = {
    FormInputStyle: FormInputStyle.Stacked
}

export const UIStyleFormInputStyleReducerMapper = createReducer<UIStyleConfigurationState>(initialUIStyleDTO,
    on(getUIStyleConfigurationDone, (_, action) => {
    
        const stateToReturn: UIStyleConfigurationState = {
            FormInputStyle: action.configuration.FormInputStyle
        };
        return stateToReturn;
    })
);
