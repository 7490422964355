import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { LandfolioLookupTables, LookupTables } from '../../../../../enums/lookup-table-enum';
import { LookupModel } from '../../../../../models';
import { DropdownConfiguration } from '../../../../../models/configuration/widgets/properties/controls/dropdown/dropdown-configuration';
import { LookupCacheService } from '../../../../../services/deprecated/lookup-cache.service';
import { ValidatableInputControlBase } from '../../input-controls/validatable-input-control-base';

@Component({
    selector: 'fw-inline-edit-dropdown',
    templateUrl: './inline-edit-dropdown.component.html',
    host: { 'class': 'inline-edit' }
})
export class InlineEditDropdownComponent extends ValidatableInputControlBase<number | string> implements OnInit {
    public Options: LookupModel[];

    @Input()
    public Dropdown: DropdownConfiguration;

    constructor(protected transloco: TranslocoService, private lookupManagerService: LookupCacheService) {
        super(transloco);
    }

    //todo: create abstract method in base and override in all children
    public GetDisplayValue() {

        if (this.Options) {
            const abstractControl = this.CustomFormGroup.controls[this.PropertyName];

            if (abstractControl.value) {
                const value = abstractControl.value.toString();
                const option = this.Options.find(o => o.Id === value);

                if (option) {
                    return option.Value;
                }
            }
        }
    }

    InitialiseLookup() {
        if (this.Dropdown.IsLandfolioLookup === true) {
            const lookupTable: LandfolioLookupTables = LandfolioLookupTables[this.Dropdown.LookupKey];

            const getLandfolioLookupTableObservable: Observable<LookupModel[]> = this.Dropdown.Filter ?
                this.lookupManagerService.GetFilteredLandfolioLookupTable(LandfolioLookupTables[lookupTable], this.Dropdown.Filter) :
                this.lookupManagerService.GetLandfolioLookupTable(LandfolioLookupTables[lookupTable]);

            getLandfolioLookupTableObservable.subscribe((lookupModels: LookupModel[]) => {
                this.Options = lookupModels;
                this.ConfigureDropdownValidation(this.Dropdown.Validation);
            });
        }
        else {
            const lookupTable: LookupTables = LookupTables[this.Dropdown.LookupKey];

            this.lookupManagerService.GetLookupTable(lookupTable).subscribe((lookupModels: LookupModel[]) => {
                this.Options = lookupModels;
                this.ConfigureDropdownValidation(this.Dropdown.Validation);
            });
        }
    }

    ngOnInit(): void {
        this.InitialiseLookup();
    }

    public onCancelChanges() {
        this.editMode = false;

        if (this.Data) {
            this.CustomFormGroup.controls[this.PropertyName].setValue(this.Data.toString());
        }
    }

    //override functionality
    onFocusOut(event) {

        if (!event.sourceCapabilities &&
            (event.target && (event.target.nodeName === 'INPUT'))
        ) {
            return;
        }

        if (!event.relatedTarget || (event.relatedTarget.className && event.relatedTarget.className === 'view-mode')) {
            this.onCancelChanges();
        }
    }
}
