import { BehaviorSubject, Observable } from 'rxjs';
import { DiscussionParticipantsWidgetState } from '../../AppState';
import { DiscussionCommentCreateDTO } from '../../data-transfer-objects/discussion-comment/discussion-comment-create-dto';
import { DiscussionViewDTO } from '../../data-transfer-objects/discussion/discussion-view-dto';

export abstract class AbstractDiscussionTopicCommentFacade {
    public abstract _setLoadSubject: BehaviorSubject<boolean>;
    public abstract _discussion: DiscussionViewDTO;
    public abstract _entityId: string;
    public abstract _pageId: string;    

    public abstract LoadDiscussionParticipantsSelector(): Observable<DiscussionParticipantsWidgetState>;
    public abstract LoadDiscussionParticipants(): void;
    public abstract AddDiscussionComment(discussionCommentCreateDto: DiscussionCommentCreateDTO): void

    public abstract ResetState(): void;
}
