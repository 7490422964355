import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RelatedAssetAddDTO } from 'src/app/data-transfer-objects/related-asset/related-asset-add-dto';
import { RelatedAssetListViewDTO } from 'src/app/data-transfer-objects/related-asset/related-asset-list-view-dto';
import { RelatedAssetSearchModel } from '../../data-transfer-objects/related-asset/related-asset-search-dto';
import { HttpStatusCode } from '../../enums/http-status-codes';
import { BASE_URL } from '../../helpers/base-url';
import { HttpFieldModel } from '../../models/field-list-model';
import { PaginationModel, PaginationResultModel } from '../../models/pagination-models';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class RelatedAssetHttpService extends AuthenticatedHttpServiceBase {
    constructor(httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, errorService: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorService, toastService);
    }

    public getRelatedAssetsBySearch(paginationModel: PaginationModel, relatedAssetSearchDTO: RelatedAssetSearchModel, fieldModel: HttpFieldModel, pageId: string): Observable<PaginationResultModel<RelatedAssetListViewDTO>> {
        return this.PostAuthenticated<PaginationResultModel<RelatedAssetListViewDTO>>(`${this.baseUrl}api/relatedasset/search`, relatedAssetSearchDTO.relatedAssetQueryDefinition, this.AddPageIdHeader(this.GetStandardHeaders(), pageId), [], paginationModel, [HttpStatusCode.BAD_REQUEST, HttpStatusCode.NOT_FOUND], fieldModel);
    }

    public addRelatedAssets(assetId: string, relatedAssets: RelatedAssetAddDTO[], pageId: string): Observable<null> {
        return this.PostAuthenticated<null>(`${this.baseUrl}api/relatedasset/add/${assetId}`, relatedAssets, this.AddPageIdHeader(this.GetStandardHeaders(), pageId));
    }

    public removeRelatedAssets(assetId: string, relatedAssetIds: string[], pageId: string): Observable<null> {
        return this.DeleteAuthenticated<null>(
            `${this.baseUrl}api/relatedasset/remove/${assetId}`,
            this.AddPageIdHeader(this.GetStandardHeaders(), pageId),
            [],
            [],
            relatedAssetIds
        );
    }
}
