import { Injectable, NgZone } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Observable, ReplaySubject, Subject, Subscriber, Subscription } from 'rxjs';
import { severity_error } from 'src/app/consts/severity-options';
import { ReportDocumentDTO } from 'src/app/data-transfer-objects/signal-r/report-document-dto';
import { ErrorDetails } from 'src/app/models/error-details';
import { PaymentConnectorRequestOutputViewDTO } from '../../data-transfer-objects/payment/payment-connector-request-output-view-dto';
import { FileProcessingHelper } from '../../helpers/file-processing-helper';
import { NavigationResponseModel } from '../../models/behaviours/response-models/navigation-response-model';
import { SignalRService } from '../http/signal-r.service';
import { SessionApplicationService } from './session-application.service';
import { ToastApplicationService } from './toast-application.service';
import { AsynchronousProcessResultDTO } from 'src/app/data-transfer-objects/signal-r/asynchronous-process-result-dto';
import { AsynchronousProcessNotificationService } from '../deprecated/asynchronous-process-notification.service';
import { ErrorManagerService } from "../deprecated/error-manager.service";
import { ErrorModel } from "../../models/error-model";

@Injectable({
    providedIn: 'root'
})
export class SignalRApplicationService {

    private _connected = new Subject<boolean>();
    private _clientIdSubject = new ReplaySubject<string>(1);
    private subscriptions: Subscription[] = [];

    public get ClientId(): Observable<string> {
        return this._clientIdSubject.asObservable();
    }

    public get Connected(): Subject<boolean> {
        return this._connected;
    }

    constructor(private signalRService: SignalRService,
        private toastApplicationService: ToastApplicationService,
        private asynchronousProcessNotificationService: AsynchronousProcessNotificationService,
        private sessionApplicationService: SessionApplicationService,
        private errorManagerService: ErrorManagerService,
        private router: Router,
        private zone: NgZone) {
    }

    public ResetPage(): Observable<null> {
        return new Observable((subscriber: Subscriber<null>) => {
            this.signalRService.registerHandler('resetPage').subscribe(() => {
                subscriber.next();
            });
        });
    }

    public InterceptPaymentRequest(): Observable<PaymentConnectorRequestOutputViewDTO> {
        return new Observable((subscriber: Subscriber<PaymentConnectorRequestOutputViewDTO>) => {
            this.subscriptions.push(this.signalRService.registerHandler('paymentRequest').subscribe((output: PaymentConnectorRequestOutputViewDTO) => {
                subscriber.next(output);
            }));
        });
    }

    public HandleNavigation(): void {
        this.subscriptions.push(this.signalRService.registerHandler<NavigationResponseModel>('navigation').subscribe((navigation: NavigationResponseModel) => {
            let params: Params = null;

            if (navigation.found) {
                if (navigation.queryParameters) {
                    params = {};
                    navigation.queryParameters.forEach(queryParameter => {
                        params[queryParameter.name] = queryParameter.value
                    });
                }

                this.DoNavigate(params, navigation.destinationPageId);
            } else {
                this.DoNotFoundNavigate(navigation.error);
            }

        }));
    }

    public FinishedExecutingBehaviour(): Observable<string> {
        return new Observable((subscriber: Subscriber<string>) => {
            this.subscriptions.push(this.signalRService.registerHandler('finishedExecutingBehaviour').subscribe((taskId: string) => {
                subscriber.next(taskId);
            }));
        });
    }

    /**
     * This function should only be called from the main entry component, like app component*/
    public Initialise(): Observable<boolean> {
        return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
            try {
                this.subscriptions.push(this.signalRService.getClientId().subscribe((clientId: string) => {
                    this._clientIdSubject.next(clientId);
                    this.signalRService.getClientIdOnReconnect(this._clientIdSubject, this._connected);
                    this.SetupGlobalHandlers();
                    this._connected.next(true);
                    subscriber.next(true);
                }, () => {
                    subscriber.next(false);
                }));
            } catch {
                subscriber.next(false);
            }
        });
    }

    /**
     * This function should only be called from the main entry component, like app component*/
    public Destroy(): void {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    private DoNavigate(params: Params, destinationPageId: string): void {
        if (params) {
            this.zone.run(() => {
                this.router.navigate([`page/${destinationPageId}`], { queryParams: params });
            });
        } else {
            this.zone.run(() => {
                this.router.navigate([`page/${destinationPageId}`]);
            });
        }
    }

    private DoNotFoundNavigate(error: string): void {
        this.zone.run(() => {
            //todo: this will be a 404 page when we have one
            this.errorManagerService.HandleError(new ErrorModel(error));
        });
    }

    private HandleError(): void {
        //todo: subscription cleanup pattern
        this.subscriptions.push(this.signalRService.registerHandler<ErrorDetails>('handleError').subscribe((errorDetails: ErrorDetails) => {
            this.toastApplicationService.showToast(errorDetails.errorMessageResourceId, errorDetails, severity_error, true);
        }));
    }

    private RegisterInitialiseReportGenerate(): void {
        this.asynchronousProcessNotificationService.InitialiseNotification(this.subscriptions,
            this.signalRService.registerHandler<AsynchronousProcessResultDTO<ReportDocumentDTO>>('initialiseReportGenerate'),
            (reportOutput: AsynchronousProcessResultDTO<ReportDocumentDTO>) => reportOutput.messageTranslationResourceId
        );
    }

    private RegisterDownloadReport(): void {

        this.asynchronousProcessNotificationService.SuccessNotification(this.subscriptions,
            this.signalRService.registerHandler<AsynchronousProcessResultDTO<ReportDocumentDTO>>('downloadReport'),
            (reportOutputDTO: AsynchronousProcessResultDTO<ReportDocumentDTO>) => reportOutputDTO.messageTranslationResourceId,
            (reportOutputDTO: AsynchronousProcessResultDTO<ReportDocumentDTO>) => reportOutputDTO.successTranslationResourceId,
            (reportOutput: AsynchronousProcessResultDTO<ReportDocumentDTO>) => {
                const blob = FileProcessingHelper.ConvertBase64ToBlob(reportOutput.result.reportBlob);
                const file = FileProcessingHelper.ConvertBlobToFile(blob, reportOutput.result.reportName);
                FileProcessingHelper.DownloadFile(file, reportOutput.result.reportName);
            }
        );
    }

    private RegisterErrorGeneratingReport(): void {
        this.asynchronousProcessNotificationService.FailureNotification(this.subscriptions,
            this.signalRService.registerHandler<AsynchronousProcessResultDTO<ReportDocumentDTO>>('errorGeneratingReport'),
            (reportOutput: AsynchronousProcessResultDTO<ReportDocumentDTO>) => reportOutput.messageTranslationResourceId
        );
    }

    //these are non page specific handlers and are available to all pages
    private SetupGlobalHandlers(): void {
        this.RegisterInitialiseReportGenerate();
        this.RegisterDownloadReport();
        this.RegisterErrorGeneratingReport();
        this.HandleError();
        this.HandleNavigation();
    }
}
