import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DiscussionsWidgetStateModel, ListState } from '../../AppState';
import { ListFieldConfigurationViewDTO } from '../../data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { DiscussionQueryDefinitionFilterViewDTO } from '../../data-transfer-objects/configuration/list-configuration/query-definitions/discussion-query-definition-filter-view-dto';
import { DiscussionCreateDTO } from '../../data-transfer-objects/discussion/discussion-create-dto';
import { PaginationModel } from '../../models/pagination-models';
import { SearchModel } from '../../models/search-model';
import { AbstractListFacade } from './abstract-list.facade';

@Injectable()
export abstract class AbstractDiscussionTopicListFacade extends AbstractListFacade<DiscussionQueryDefinitionFilterViewDTO, DiscussionsWidgetStateModel> {

    public abstract _entityId: string;
    public abstract _widgetId: string;
    public abstract _discussionsExist: boolean;
    public abstract _setLoadSubject: BehaviorSubject<boolean>;


    public abstract GetListItems(): Observable<ListState<DiscussionsWidgetStateModel>[]>;
    public abstract LoadListItems(fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, searchModel: SearchModel, discussionQueryDefinition: DiscussionQueryDefinitionFilterViewDTO, dataSourceId: string): void;
    public abstract UpdateDiscussion(dataSourceId: string, discussionId: string, latestComment: string, lastUpdated: Date): void;
    public abstract CreateDiscussion(discussionCreateDto: DiscussionCreateDTO, dataSourceId: string): void;

    public abstract ResetState(): void;
}
