import { TranslocoService } from '@ngneat/transloco';
import { FilterMatchMode, SelectItem } from 'primeng/api';

export class FilterMatchModeHelper {

    public static readonly IS_EMPTY = 'isEmpty';
    public static readonly IS_NOT_EMPTY = 'isNotEmpty';
    static readonly NULL = 'null';
    static readonly NOT_NULL = 'notNull';


    public static CanFilterWithoutValue(filterMatchMode: string): boolean {
        switch (filterMatchMode) {
            case this.IS_EMPTY:
            case this.IS_NOT_EMPTY:
            case this.NULL:
            case this.NOT_NULL:
                return true;
            default:
                return false;
        }
    }

    public static GetStringMatchModes(transloco: TranslocoService): SelectItem[] {
        return [
            {
                label: transloco.translate('List.Filter.Comparator.Contains'),
                value: FilterMatchMode.CONTAINS
            },
            {
                label: transloco.translate('List.Filter.Comparator.StartsWith'),
                value: FilterMatchMode.STARTS_WITH
            },
            {
                label: transloco.translate('List.Filter.Comparator.EndsWith'),
                value: FilterMatchMode.ENDS_WITH
            },
            {
                label: transloco.translate('List.Filter.Comparator.Exact'),
                value: FilterMatchMode.EQUALS
            },
            {
                label: transloco.translate('List.Filter.Comparator.IsEmpty'),
                value: this.IS_EMPTY
            },
            {
                label: transloco.translate('List.Filter.Comparator.IsNotEmpty'),
                value: this.IS_NOT_EMPTY
            }
        ];
    }

    public static GetNumberMatchModes(transloco: TranslocoService): SelectItem[] {
        return [
            {
                label: transloco.translate('List.Filter.Comparator.Exact'),
                value: FilterMatchMode.EQUALS
            },
            {
                label: transloco.translate('List.Filter.Comparator.LessThanOrEqual'),
                value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
            },
            {
                label: transloco.translate('List.Filter.Comparator.GreaterThanOrEqual'),
                value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            }
        ];
    }
    public static GetDateMatchModes(transloco: TranslocoService): SelectItem[] {
        return [            
            {
                label: transloco.translate('List.Filter.Comparator.DateBeforeOrEqual'),
                value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
            },
            {
                label: transloco.translate('List.Filter.Comparator.DateAfterOrEqual'),
                value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            },
            //{
            //    label: transloco.translate('List.Filter.Comparator.Null'),
            //    value: this.NULL
            //},
            //{
            //    label: transloco.translate('List.Filter.Comparator.NotNull'),
            //    value: this.NOT_NULL
            //},
            {
                label: transloco.translate('List.Filter.Comparator.DateIs'),
                value: FilterMatchMode.DATE_IS
            },
            {
                label: transloco.translate('List.Filter.Comparator.DateBetween'),
                value: FilterMatchMode.BETWEEN
            }
        ];
    }
}