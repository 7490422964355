import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RepresentativeOfListViewDTO } from '../../../../data-transfer-objects/representative/representative-list-view-dto';
import { CurrentUserRepresentativeUpdateDTO } from '../../../../data-transfer-objects/users/current-user-representative-update-dto';
import { DialogComponentBase } from '../../../../services/application/dialog-application.service';
import { SessionApplicationService } from '../../../../services/application/session-application.service';

@Component({
    selector: 'fw-switch-representation',
    templateUrl: './switch-representation.component.html',
})
export class SwitchRepresentationComponent extends DialogComponentBase<RepresentativeOfListViewDTO[]> implements OnInit {

    public isDefaultRepresentation: boolean = false;
    public representitives: RepresentativeOfListViewDTO[];
    public selectedRepresentative: RepresentativeOfListViewDTO = null;

    constructor(
        private dynamicDialogRef: DynamicDialogRef,
        private dynamicDialogConfig: DynamicDialogConfig,
        private authenticationService: SessionApplicationService) {
        super(dynamicDialogRef, dynamicDialogConfig);
    }

    public cancelClicked() {
        this.dynamicDialogRef.close();
        this.dynamicDialogRef.destroy();
    }

    public ngOnInit(): void {
        this.populateRepresentativeList();
    }

    public proceedClicked() {

        const currentUserRepresentativeUpdateDTO: CurrentUserRepresentativeUpdateDTO = {
            IsDefaultRepresentation: this.isDefaultRepresentation,
            RepresentativeId: this.selectedRepresentative.Id
        }

        this.dynamicDialogRef.close(currentUserRepresentativeUpdateDTO);

    }

    private populateRepresentativeList(): void {

        this.representitives = this.dialogOptions.dataModel;

        if (this.authenticationService.currentUser && this.authenticationService.currentUser.RepresenteeId) {

            this.selectedRepresentative = this.representitives.find(representitiveListViewDto => representitiveListViewDto.Id === this.authenticationService.currentUser.RepresenteeId);

            //This is done because the selected represetitive may no longer be a representitive in the landfolio data. 
            if (!this.selectedRepresentative) {
                this.selectedRepresentative = this.representitives[0];
            }

            this.isDefaultRepresentation = this.authenticationService.currentUser.IsDefaultRepresentation;

        } else {
            this.selectedRepresentative = this.representitives[0];
        }

    }
}
