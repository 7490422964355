import { StructuredDataLibraryService, ValueType } from '@landadmin/structured-data';
import { LookupConfigurationModel } from '@landadmin/structured-data/lib/models/lookup-configuration-model';
import { StructuredDataEntryModel } from '@landadmin/structured-data/lib/models/structured-data-entry-model';
import { StructuredDataEntryUnitValueModel } from '@landadmin/structured-data/lib/models/structured-data-entry-unit-value-model';
import { StructuredDataEntryValueModel } from '@landadmin/structured-data/lib/models/structured-data-entry-value-model';
import { StructuredDataNodeModel } from '@landadmin/structured-data/lib/models/structured-data-node-model';
import { Observable, Subscriber } from 'rxjs';
import { StructuredDataAddedTransactionDTO, StructuredDataDeletedTransactionDTO, StructuredDataEntryTransactionDTO, StructuredDataUpdatedTransactionDTO } from '../data-transfer-objects/structured-data/structured-data-entry-update-dto';
import { LookUpConfigurationService } from '../services/deprecated/lookup-configuration.service';
import { isStringEmptyOrUndefined } from '../utilities/utils.string';
import { ArrayHelper } from './array-helper';
import { GuidHelper } from './guid-helper';
import { ObjectHelper } from './object-helper';

export class StructuredDataHelper {


    private static NonSaveableiItemsRemoved: number;
    private static rootMustSave: boolean;

    public static AppendInflatedEntries(structuredDataEntries: StructuredDataEntryModel[], structuredDataIdsToAdd: Array<string>): void {

        structuredDataEntries.forEach((entryModel: StructuredDataEntryModel) => {

            if (!(entryModel.ChildEntries == null || entryModel.ChildEntries == undefined) && entryModel.ChildEntries.length > 0) {
                this.AppendInflatedEntries(entryModel.ChildEntries, structuredDataIdsToAdd);
            } else {
                if (entryModel.Value === null) {

                    if (structuredDataIdsToAdd.findIndex(x => x === entryModel.Id) === -1) {
                        structuredDataIdsToAdd.push(entryModel.Id);
                    }
                }
                else {
                    switch (entryModel.ValueType) {
                        case ValueType.Distance:
                        case ValueType.Area:
                        case ValueType.TimeInterval:
                        case ValueType.Weight:
                        case ValueType.Volume:
                        case ValueType.Currency:
                            if (entryModel.Value === null || entryModel.Value.Value === null || entryModel.Value.UnitDecimalPlaces === null || entryModel.Value.Unit === null || entryModel.Value.Unit === '')
                                if (structuredDataIdsToAdd.findIndex(x => x === entryModel.Id) === -1) {
                                    structuredDataIdsToAdd.push(entryModel.Id);
                                }
                            break;
                        case ValueType.YesNo:
                            if (entryModel.Value.Value === null) {
                                if (structuredDataIdsToAdd.findIndex(x => x === entryModel.Id) === -1) {
                                    structuredDataIdsToAdd.push(entryModel.Id);
                                }
                            }
                            break;
                        case ValueType.OptionList:
                            if (entryModel.Value === null || entryModel.Value.Value === null || entryModel.Value.Id === GuidHelper.EmptyGuid())
                                if (structuredDataIdsToAdd.findIndex(x => x === entryModel.Id) === -1) {
                                    structuredDataIdsToAdd.push(entryModel.Id);
                                }
                            break;
                        default:
                            break;

                    }

                }
            }
        })
    }

    private static ChildEntryEqual(a: StructuredDataEntryModel, b: StructuredDataEntryModel): boolean {
        let aCopy: StructuredDataEntryModel = ObjectHelper.deepCopyJsonParse(a);
        let bCopy: StructuredDataEntryModel = ObjectHelper.deepCopyJsonParse(b);

        if (aCopy.ValueType !== bCopy.ValueType) {
            return false;
        }
        
        if (this.IsYesNoCell(aCopy.ValueType) || this.IsCommodityCell(aCopy.ValueType) || this.IsForeignKeyCell(aCopy.ValueType) || this.IsOptionListCell(aCopy.ValueType)) {
            aCopy.Value ||= {};
            aCopy.Value.Id ||= GuidHelper.EmptyGuid();
            aCopy.Value.Value ||= '';

            bCopy.Value ||= {};
            bCopy.Value.Id ||= GuidHelper.EmptyGuid();
            bCopy.Value.Value ||= '';

            return JSON.stringify(aCopy.Value) === JSON.stringify(bCopy.Value);
        } else if (this.IsCurrencyCell(aCopy.ValueType) || this.IsUnitValueCell(aCopy.ValueType)) {
            const emptyModel = this.GetEmptyUnitValueModel(2);

            aCopy.Value ||= {};
            aCopy.Value.Unit ||= emptyModel.Unit;
            aCopy.Value.UnitId ||= emptyModel.UnitId;
            aCopy.Value.UnitDecimalPlaces ||= emptyModel.UnitDecimalPlaces;
            aCopy.Value.Value ||= '';

            bCopy.Value ||= {};
            bCopy.Value.Unit ||= emptyModel.Unit;
            bCopy.Value.UnitId ||= emptyModel.UnitId;
            bCopy.Value.UnitDecimalPlaces ||= emptyModel.UnitDecimalPlaces;
            bCopy.Value.Value ||= '';

            return JSON.stringify(aCopy.Value) === JSON.stringify(bCopy.Value);
        } else if (this.IsDateCell(aCopy.ValueType) || this.IsNumberCell(aCopy.ValueType) || this.IsTextCell(aCopy.ValueType) || this.IsPercentageCell(aCopy.ValueType)) {
            aCopy.Value ||= '';
            bCopy.Value ||= '';

            return JSON.stringify(aCopy.Value) === JSON.stringify(bCopy.Value);
        }

        return false;
    }

    private static ChildEntryHasValue(a: StructuredDataEntryModel): boolean {
        if (!a) {
            return false;
        }
        
        let childCopy: StructuredDataEntryModel = ObjectHelper.deepCopyJsonParse(a);

        if (this.IsYesNoCell(childCopy.ValueType) || this.IsCommodityCell(childCopy.ValueType) || this.IsForeignKeyCell(childCopy.ValueType) || this.IsOptionListCell(childCopy.ValueType)) {
            const emptyModel = this.GetEmptyValueModel();
            
            childCopy.Value ||= {};
            childCopy.Value.Id ||= GuidHelper.EmptyGuid();
            childCopy.Value.Value ||= '';

            if (GuidHelper.ValidGuid(childCopy.Value.Value)) {
                emptyModel.Value = GuidHelper.EmptyGuid();    
            } else {
                emptyModel.Value = '';
            }
            
            return JSON.stringify(childCopy.Value) !== JSON.stringify(emptyModel); 
        } else if (this.IsCurrencyCell(childCopy.ValueType) || this.IsUnitValueCell(childCopy.ValueType)) {

            const emptyModel = this.GetEmptyUnitValueModel(2);

            emptyModel.Value = '';
            childCopy.Value ||= {};
            childCopy.Value.Unit ||= emptyModel.Unit;
            childCopy.Value.UnitId ||= emptyModel.UnitId;
            childCopy.Value.UnitDecimalPlaces ||= emptyModel.UnitDecimalPlaces;
            childCopy.Value.Value ||= '';

            return JSON.stringify(childCopy.Value) !== JSON.stringify(emptyModel);
        } else if (this.IsDateCell(childCopy.ValueType) || this.IsNumberCell(childCopy.ValueType) || this.IsTextCell(childCopy.ValueType) || this.IsPercentageCell(childCopy.ValueType)) {
            return !!childCopy.Value;
        }
        
        return false;
    }
    
    public static IsStructuredDataModelEmpty(entry: StructuredDataEntryModel): boolean {

        let isEmpty: boolean = true;

        entry.ChildEntries.some((childEntry: StructuredDataEntryModel) => {
            if (this.IsYesNoCell(childEntry.ValueType)
                || this.IsCommodityCell(childEntry.ValueType)
                || this.IsForeignKeyCell(childEntry.ValueType)
                || this.IsOptionListCell(childEntry.ValueType)) {

                const emptyModel = this.GetEmptyValueModel();

                if (JSON.stringify(childEntry.Value) !== JSON.stringify(emptyModel)) {
                    emptyModel.Value = '';
                    if (JSON.stringify(childEntry.Value) !== JSON.stringify(emptyModel) && childEntry.Value !== null && childEntry.Value !== GuidHelper.EmptyGuid()) {
                        isEmpty = false;
                    }
                }

            } else if (this.IsCurrencyCell(childEntry.ValueType)
                || this.IsUnitValueCell(childEntry.ValueType)) {

                const emptyModel = this.GetEmptyUnitValueModel(2);

                if (JSON.stringify(childEntry.Value) !== JSON.stringify(emptyModel) && childEntry.Value !== null && Number.isNaN(childEntry.Value.Value) === false) {
                    isEmpty = false;
                }

            } else if (this.IsDateCell(childEntry.ValueType)
                || this.IsNumberCell(childEntry.ValueType)
                || this.IsTextCell(childEntry.ValueType)
                || this.IsPercentageCell(childEntry.ValueType)) {

                if (childEntry.Value !== null && !isStringEmptyOrUndefined(childEntry.Value)) {
                     isEmpty = false;
                }
            }

        });

        return isEmpty;

    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static GetEmptyTextValueCell(): any {
        return null;
    }

    public static GetEmptyUnitValueModel(decPlaces: number): StructuredDataEntryUnitValueModel {
        return {
            Unit: '',
            UnitId: GuidHelper.EmptyGuid(),
            UnitDecimalPlaces: decPlaces,
            Value: null
        }
    }

    public static GetEmptyValueModel(): StructuredDataEntryValueModel {
        return {
            Id: GuidHelper.EmptyGuid(),
            Value: null
        }
    }

    //This should be moved into the structured data library
    public static GetNullEntryFromNode(node: StructuredDataNodeModel): StructuredDataEntryModel {

        const rootEntry: StructuredDataEntryModel = {
            Id: GuidHelper.NewGuid(),
            ChildEntries: [],
            IsRoot: true,
            StructuredDataNodeId: node.Id,
            Value: null,
            ValueType: null
        };

        for (let childNodeCount: number = 0; childNodeCount < node.ChildNodes.length; childNodeCount++) {
            const childEntry: StructuredDataEntryModel = {
                Id: GuidHelper.NewGuid(),
                IsRoot: false,
                ChildEntries: [],
                StructuredDataNodeId: node.ChildNodes[childNodeCount].Id,
                ValueType: node.ChildNodes[childNodeCount].ValueOption.ValueType,
                Value: null
            }

            if (this.IsYesNoCell(node.ChildNodes[childNodeCount].ValueOption.ValueType)
                || this.IsCommodityCell(node.ChildNodes[childNodeCount].ValueOption.ValueType)
                || this.IsForeignKeyCell(node.ChildNodes[childNodeCount].ValueOption.ValueType)
                || this.IsOptionListCell(node.ChildNodes[childNodeCount].ValueOption.ValueType)) {
                childEntry.Value = this.GetEmptyValueModel();
            } else if (this.IsCurrencyCell(node.ChildNodes[childNodeCount].ValueOption.ValueType)
                || this.IsUnitValueCell(node.ChildNodes[childNodeCount].ValueOption.ValueType)) {
                childEntry.Value = this.GetEmptyUnitValueModel(2);
            } else if (this.IsDateCell(node.ChildNodes[childNodeCount].ValueOption.ValueType)
                || this.IsNumberCell(node.ChildNodes[childNodeCount].ValueOption.ValueType)
                || this.IsTextCell(node.ChildNodes[childNodeCount].ValueOption.ValueType)
                || this.IsPercentageCell(node.ChildNodes[childNodeCount].ValueOption.ValueType)) {
                childEntry.Value = this.GetEmptyTextValueCell();
            }

            rootEntry.ChildEntries.push(childEntry);
        }

        return rootEntry;
    }

    public static GetParentEntry(allParents: StructuredDataEntryModel[], childEntryToFind: StructuredDataEntryModel): StructuredDataEntryModel {
        for (const parentEntry of allParents) {
            if (parentEntry.ChildEntries) {
                for (const childEntry of parentEntry.ChildEntries) {
                    if (childEntry.Id == childEntryToFind.Id) {
                        return parentEntry;
                    }
                }
            }
        }

        return null;
    }

    public static GetStructuredDataAddIds(structuredDataAddModels: Array<StructuredDataAddedTransactionDTO>, structuredDataAddIds: Array<string>): void {

        structuredDataAddModels.forEach((addModel: StructuredDataAddedTransactionDTO) => {

            structuredDataAddIds.push(addModel.Id);

            if (!(addModel.ChildEntries == null || addModel.ChildEntries == undefined)) {
                this.GetStructuredDataAddIds(addModel.ChildEntries, structuredDataAddIds);
            }
        })
    }

    public static GetStructuredDataDeleteIds(structuredDataDeleteModels: Array<StructuredDataDeletedTransactionDTO>, structuredDataDeleteIds: Array<string>): void {

        structuredDataDeleteModels.forEach((deleteModel: StructuredDataDeletedTransactionDTO) => {

            structuredDataDeleteIds.push(deleteModel.Id);

            if (!(deleteModel.ChildEntries == null || deleteModel.ChildEntries == undefined)) {
                this.GetStructuredDataDeleteIds(deleteModel.ChildEntries, structuredDataDeleteIds);
            }
        })
    }


    //This method pushes the structured data value to the add model
    public static HandleAdd(structuredDataModel: StructuredDataEntryModel, structuredDataEntryEditModel: StructuredDataEntryTransactionDTO): void {

        const structuredDatAddModel = StructuredDataHelper.MapToStructuredDataAddModel(structuredDataModel, true);
        structuredDataEntryEditModel.StructuredDataAddedTransactionsDTO.push(structuredDatAddModel);
    }

    //This method searches through all the update models and deletes them 
    //Then it searches through all the add models and delete them
    //Then it pushes the structured data value to the delete model.
    public static HandleDelete(structuredDataModel: StructuredDataEntryModel, structuredDataEntryEditModel: StructuredDataEntryTransactionDTO): void {

        const addedCount = structuredDataEntryEditModel.StructuredDataAddedTransactionsDTO.length;

        const structuredDataEntryIds: Array<string> = new Array<string>();

        structuredDataEntryIds.push(structuredDataModel.Id);
        if (!(structuredDataModel.ChildEntries == null || structuredDataModel.ChildEntries == undefined)) {
            this.GetStructuredDataEntryIds(structuredDataModel.ChildEntries, structuredDataEntryIds);
        }

        this.RemoveFromUpdateModels(structuredDataEntryEditModel.StructuredDataUpdatedTransactionsDTO, structuredDataEntryIds);
        this.NonSaveableiItemsRemoved = 0;
        this.RemoveFromAddModels(structuredDataEntryEditModel.StructuredDataAddedTransactionsDTO, structuredDataEntryIds);

        //this if statement checks if there was a change to the add model. If not, then do not try delete it.
        if (addedCount === this.NonSaveableiItemsRemoved + structuredDataEntryEditModel.StructuredDataAddedTransactionsDTO.length) {
            const structuredDataDeleteModel = this.MapToStructuredDataDeleteModel(structuredDataModel);
            structuredDataEntryEditModel.StructuredDataDeletedTransactionsDTO.push(structuredDataDeleteModel);
        }

    }

    //This method searches the add list and updates it.
    //If no results are found in the add list it will search the update list and update it.
    //if no results are found in the update list it will push a new item to the update list.
    public static HandleUpdate(structuredDataModel: StructuredDataEntryModel, structuredDataEntryEditModel: StructuredDataEntryTransactionDTO): void {

        let modelReplace: boolean = false;

        if (structuredDataModel.Value == null || structuredDataModel.Value == undefined) {
            const structuredDataDeleteModel = this.MapToStructuredDataDeleteModel(structuredDataModel);
            structuredDataEntryEditModel.StructuredDataDeletedTransactionsDTO.push(structuredDataDeleteModel);
        }
        else {

            if (!(structuredDataEntryEditModel.StructuredDataAddedTransactionsDTO == null || structuredDataEntryEditModel.StructuredDataAddedTransactionsDTO == undefined)) {
                modelReplace = this.ReplaceAddModel(structuredDataModel, structuredDataEntryEditModel.StructuredDataAddedTransactionsDTO, modelReplace);
            }

            if (!(structuredDataEntryEditModel.StructuredDataUpdatedTransactionsDTO == null || structuredDataEntryEditModel.StructuredDataUpdatedTransactionsDTO == undefined) && !modelReplace) {
                modelReplace = this.ReplaceUpdateModel(structuredDataModel, structuredDataEntryEditModel.StructuredDataUpdatedTransactionsDTO, modelReplace);
            }

            if (!modelReplace) {

                const structuredDataUpdateModel = this.MapToStructuredDataUpdateModel(structuredDataModel);
                structuredDataEntryEditModel.StructuredDataUpdatedTransactionsDTO.push(structuredDataUpdateModel);
            }
        }

    }

    public static IsCommodityCell(valueType: ValueType): boolean {
        return valueType === ValueType.Commodity;
    }

    public static IsCurrencyCell(valueType: ValueType): boolean {
        return valueType === ValueType.Currency;
    }

    public static IsDateCell(valueType: ValueType): boolean {
        return valueType === ValueType.Date;
    }

    public static IsForeignKeyCell(valueType: ValueType): boolean {
        return valueType === ValueType.User || valueType === ValueType.LegalEntity;
    }

    public static IsNumberCell(valueType: ValueType): boolean {
        return valueType === ValueType.Number;
    }

    public static IsOptionListCell(valueType: ValueType): boolean {
        return valueType === ValueType.OptionList;
    }

    public static IsPercentageCell(valueType: ValueType): boolean {
        return valueType === ValueType.Percent;
    }

    //This method checks if there are any duplicate ID's
    public static IsStructuredDataEditModelValid(structuredDataEditModel: StructuredDataEntryTransactionDTO): boolean {

        let valid = false;
        const structuredDataAddIds: Array<string> = new Array<string>();
        const structuredDataDeleteIds: Array<string> = new Array<string>();
        const structuredDataUpdateIds: Array<string> = new Array<string>();

        if (!(structuredDataEditModel.StructuredDataAddedTransactionsDTO === null || structuredDataEditModel.StructuredDataAddedTransactionsDTO === undefined)) {

            this.GetStructuredDataAddIds(structuredDataEditModel.StructuredDataAddedTransactionsDTO, structuredDataAddIds);
        }

        if (!(structuredDataEditModel.StructuredDataDeletedTransactionsDTO === null || structuredDataEditModel.StructuredDataDeletedTransactionsDTO === undefined)) {

            this.GetStructuredDataDeleteIds(structuredDataEditModel.StructuredDataDeletedTransactionsDTO, structuredDataDeleteIds);
        }

        if (!(structuredDataEditModel.StructuredDataDeletedTransactionsDTO === null || structuredDataEditModel.StructuredDataDeletedTransactionsDTO === undefined)) {
            structuredDataEditModel.StructuredDataUpdatedTransactionsDTO.forEach((updateModel: StructuredDataUpdatedTransactionDTO) => {
                structuredDataUpdateIds.push(updateModel.Id);
            })
        }

        if (ArrayHelper.ArrayOneHasValueFromArrayTwo(structuredDataAddIds, structuredDataDeleteIds)) {
            return false;
        }

        if (ArrayHelper.ArrayOneHasValueFromArrayTwo(structuredDataAddIds, structuredDataUpdateIds)) {
            return false;
        }

        if (ArrayHelper.ArrayOneHasValueFromArrayTwo(structuredDataUpdateIds, structuredDataDeleteIds)) {
            return false;
        }

        if (!(structuredDataEditModel.StructuredDataUpdatedTransactionsDTO === null || structuredDataEditModel.StructuredDataUpdatedTransactionsDTO === undefined)) {
            structuredDataEditModel.StructuredDataUpdatedTransactionsDTO.forEach((model: StructuredDataUpdatedTransactionDTO) => {
                if (model.Value && !valid) {
                    valid = this.IsStructuredDataEntryUnitValueModelValidForUpdate(model.Value);;
                }
            })
        }

        if (!(structuredDataEditModel.StructuredDataDeletedTransactionsDTO === null || structuredDataEditModel.StructuredDataDeletedTransactionsDTO === undefined) && !valid) {
            valid = structuredDataEditModel.StructuredDataDeletedTransactionsDTO.length > 0
        }

        if (!(structuredDataEditModel.StructuredDataAddedTransactionsDTO === null || structuredDataEditModel.StructuredDataAddedTransactionsDTO === undefined) && !valid) {

            structuredDataEditModel.StructuredDataAddedTransactionsDTO.forEach((model: StructuredDataAddedTransactionDTO) => {

                if (model.ChildEntries) {
                    model.ChildEntries.forEach((childEntry: StructuredDataAddedTransactionDTO) => {
                        if (childEntry.Value && !valid) {
                            valid = this.IsStructuredDataEntryUnitValueModelValidForAdd(childEntry.Value);;
                        }
                    });
                }
            })
        }

        return valid;
    }

    public static IsTextCell(valueType: ValueType): boolean {
        return valueType === ValueType.Text;
    }

    public static IsUnitValueCell(valueType: ValueType): boolean {
        return valueType === ValueType.Distance
            || valueType === ValueType.Area
            || valueType === ValueType.TimeInterval
            || valueType === ValueType.Weight
            || valueType === ValueType.Volume;
    }

    public static IsValueValid(structuredDataModel: StructuredDataEntryModel): boolean {

        switch (structuredDataModel.ValueType) {
            case ValueType.Distance:
            case ValueType.Area:
            case ValueType.TimeInterval:
            case ValueType.Weight:
            case ValueType.Volume:
            case ValueType.Currency:
                if (structuredDataModel.Value === null || structuredDataModel.Value.Value === null || structuredDataModel.Value.UnitDecimalPlaces === null || structuredDataModel.Value.Unit === null || isStringEmptyOrUndefined(structuredDataModel.Value.Unit))
                    return false;
        }

        return true;

    }
    public static IsYesNoCell(valueType: ValueType): boolean {
        return valueType === ValueType.YesNo;
    }


    public static MapStructuredDataEntryAddChildren(structuredDataEntryModel: Array<StructuredDataEntryModel>): Array<StructuredDataAddedTransactionDTO> {

        const structuredDataAddModels: Array<StructuredDataAddedTransactionDTO> = new Array<StructuredDataAddedTransactionDTO>();

        structuredDataEntryModel.forEach((entryModel: StructuredDataEntryModel) => {

            structuredDataAddModels.push(
                {
                    ChildEntries: entryModel.ChildEntries === null || entryModel.ChildEntries === undefined ? null : this.MapStructuredDataEntryAddChildren(entryModel.ChildEntries),
                    Id: entryModel.Id,
                    IsRoot: entryModel.IsRoot,
                    StructuredDataNodeId: entryModel.StructuredDataNodeId,
                    Value: entryModel.Value,
                    ValueType: entryModel.ValueType,
                    Save: true
                }
            )
        });

        return structuredDataAddModels;
    }

    public static MapToStructuredDataAddModel(structuredDataEntryModel: StructuredDataEntryModel, save: boolean): StructuredDataAddedTransactionDTO {

        return {
            ChildEntries: (structuredDataEntryModel.ChildEntries == null || structuredDataEntryModel.ChildEntries == undefined) ? null : this.MapStructuredDataEntryAddChildren(structuredDataEntryModel.ChildEntries),
            Id: structuredDataEntryModel.Id,
            IsRoot: structuredDataEntryModel.IsRoot,
            StructuredDataNodeId: structuredDataEntryModel.StructuredDataNodeId,
            Value: structuredDataEntryModel.Value,
            ValueType: structuredDataEntryModel.ValueType,
            Save: save
        };
    }

    public static MapToStructuredDataDeleteModel(structuredDataEntryModel: StructuredDataEntryModel): StructuredDataDeletedTransactionDTO {

        return {
            ChildEntries: (structuredDataEntryModel.ChildEntries == null || structuredDataEntryModel.ChildEntries == undefined) ? null : this.MapStructuredDataEntryDeleteChildren(structuredDataEntryModel.ChildEntries),
            Id: structuredDataEntryModel.Id,
        };
    }

    public static MapToStructuredDataUpdateModel(structuredDataEntryModel: StructuredDataEntryModel): StructuredDataUpdatedTransactionDTO {

        return {
            Id: structuredDataEntryModel.Id,
            Value: structuredDataEntryModel.Value,
            ValueType: structuredDataEntryModel.ValueType
        };
    }

    public static RegisterStructuredData(structuredDataLibraryService: StructuredDataLibraryService, lookupConfigurationService: LookUpConfigurationService): Observable<null> {

        return new Observable((subscriber: Subscriber<null>) => {
            lookupConfigurationService.GetStructuredDataLookupConfiguration().subscribe((lookupConfigurationModel: LookupConfigurationModel) => {
                structuredDataLibraryService.RegisterLookupConfiguration(lookupConfigurationModel);
                subscriber.next();
            });

        });
    }

    private static GetStructuredDataEntryIds(structuredDataEntryModels: Array<StructuredDataEntryModel>, structuredDataEntryIds: Array<string>): void {

        structuredDataEntryModels.forEach((entryModel: StructuredDataEntryModel) => {

            structuredDataEntryIds.push(entryModel.Id);

            if (!(entryModel.ChildEntries == null || entryModel.ChildEntries == undefined)) {
                this.GetStructuredDataDeleteIds(entryModel.ChildEntries, structuredDataEntryIds);
            }
        })
    }

    private static IsStructuredDataEntryUnitValueModelValidForAdd(structuredDataUpdateEntry: StructuredDataAddedTransactionDTO): boolean {

        switch (structuredDataUpdateEntry.ValueType) {
            case ValueType.Currency:
            case ValueType.Distance:
            case ValueType.Weight:
            case ValueType.Volume:
            case ValueType.TimeInterval:
            case ValueType.Area:

                if (structuredDataUpdateEntry.Value) {
                    const unitValueModel = (structuredDataUpdateEntry.Value as StructuredDataEntryUnitValueModel);

                    if ((unitValueModel.UnitId == null || unitValueModel.UnitId == undefined) || (unitValueModel.Value == null || unitValueModel.Value == undefined))
                        return false;
                }
        }

        return true;

    }

    private static IsStructuredDataEntryUnitValueModelValidForUpdate(structuredDataUpdateEntry: StructuredDataUpdatedTransactionDTO): boolean {

        switch (structuredDataUpdateEntry.ValueType) {
            case ValueType.Currency:
            case ValueType.Distance:
            case ValueType.Weight:
            case ValueType.Volume:
            case ValueType.TimeInterval:
            case ValueType.Area:

                if (structuredDataUpdateEntry.Value) {
                    const unitValueModel = (structuredDataUpdateEntry.Value as StructuredDataEntryUnitValueModel);

                    if ((unitValueModel.UnitId == null || unitValueModel.UnitId == undefined) || (unitValueModel.Value == null || unitValueModel.Value == undefined))
                        return false;
                }
        }

        return true;

    }

    private static MapStructuredDataEntryDeleteChildren(structuredDataEntryModel: Array<StructuredDataEntryModel>): Array<StructuredDataDeletedTransactionDTO> {

        const structuredDataDeleteModels: Array<StructuredDataDeletedTransactionDTO> = new Array<StructuredDataDeletedTransactionDTO>();

        structuredDataEntryModel.forEach((entryModel: StructuredDataEntryModel) => {

            structuredDataDeleteModels.push(
                {
                    ChildEntries: entryModel.ChildEntries === null || entryModel.ChildEntries === undefined ? null : this.MapStructuredDataEntryDeleteChildren(entryModel.ChildEntries),
                    Id: entryModel.Id
                }
            )
        });

        return structuredDataDeleteModels;
    }

    private static RemoveFromAddModels(structuredDataAddModels: Array<StructuredDataAddedTransactionDTO>, structuredDataEntryIds: Array<string>): void {

        for (let addModelCount = 0; addModelCount < structuredDataAddModels.length; addModelCount++) {

            const addModel = structuredDataAddModels[addModelCount];

            this.rootMustSave = addModel.Save;

            if (structuredDataEntryIds.some(entryId => entryId === addModel.Id)) {
                structuredDataAddModels.splice(addModelCount, 1);

                if (this.rootMustSave === false) {
                    this.NonSaveableiItemsRemoved++;
                }

            }

        }
    }

    private static RemoveFromUpdateModels(structuredDataUpdateModels: Array<StructuredDataUpdatedTransactionDTO>, structuredDataEntryIds: Array<string>): void {

        for (let struturedDataCount = 0; struturedDataCount < structuredDataUpdateModels.length; struturedDataCount++) {

            const updateModel = structuredDataUpdateModels[struturedDataCount];

            if (structuredDataEntryIds.some(entryId => entryId === updateModel.Id)) {
                structuredDataUpdateModels.splice(struturedDataCount, 1);
            }
        }
    }

    private static ReplaceAddModel(structuredDataModel: StructuredDataEntryModel, structuredDataAddModel: Array<StructuredDataAddedTransactionDTO>, modelReplace: boolean): boolean {

        structuredDataAddModel.forEach((model: StructuredDataAddedTransactionDTO) => {
            if (model.Id === structuredDataModel.Id) {
                model.Value = structuredDataModel.Value;
                model.ValueType = structuredDataModel.ValueType;
                model.IsRoot = structuredDataModel.IsRoot;
                model.ChildEntries = this.MapStructuredDataEntryAddChildren(structuredDataModel.ChildEntries);
                model.StructuredDataNodeId = structuredDataModel.StructuredDataNodeId;
                modelReplace = true;
            }

            if (!(model.ChildEntries == null || model.ChildEntries == undefined)) {
                modelReplace = this.ReplaceAddModel(structuredDataModel, model.ChildEntries, modelReplace);
            }
        })

        return modelReplace;
    }

    private static ReplaceUpdateModel(structuredDataModel: StructuredDataEntryModel, structuredDataAddModel: Array<StructuredDataUpdatedTransactionDTO>, modelReplace: boolean): boolean {

        structuredDataAddModel.forEach((model: StructuredDataUpdatedTransactionDTO) => {
            if (GuidHelper.Equals(model.Id, structuredDataModel.Id)) {
                model.Value = structuredDataModel.Value;
                model.ValueType = structuredDataModel.ValueType;
                modelReplace = true;
            }
        });

        return modelReplace;
    }
    
    public static HasValue(a: StructuredDataEntryModel): boolean {
        const childEntires = a?.ChildEntries || [];
        
        if (childEntires.length) {
            for (const entry of childEntires) {
                if (this.HasValue(entry)) {
                    return true;
                }
            }

            return false;
        } else {
            return this.ChildEntryHasValue(a);
        }
    }

    public static ArrayEqual(a: StructuredDataEntryModel[], b: StructuredDataEntryModel[]) {
        if (a.length !== b.length) {
            return false;
        }

        for (let i = 0; i < a.length; i++) {
            if (!this.Equal(a[i], b[i])) {
                return false;
            }
        }
        
        return true;
    }
    
    private static Equal(a: StructuredDataEntryModel, b: StructuredDataEntryModel): boolean {
        const aIsBlank = !StructuredDataHelper.HasValue(a);
        const bIsBlank = !StructuredDataHelper.HasValue(b);
        
        if (aIsBlank && bIsBlank) {
            return true;
        }
        
        if (aIsBlank || bIsBlank) {
            return false;
        }
        
        const aChildEntires = a.ChildEntries || [];
        const bChildEntires = b.ChildEntries || [];
        
        if (aChildEntires.length && bChildEntires.length) {
            if (aChildEntires.length != bChildEntires.length) {
                return false;
            }
            
            for (let i = 0; i < aChildEntires.length; i++) {
                if (!(this.Equal(aChildEntires[i], bChildEntires[i]))) {
                    return false;
                }
            }

            return true;
        } else if (aChildEntires.length || bChildEntires.length) {
            return false;
        } else {
            return this.ChildEntryEqual(a, b);
        }
    }
}
