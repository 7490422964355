import { createAction, props } from '@ngrx/store';
import { ErrorDetails } from 'src/app/models/error-details';
import { CommodityWidgetState } from '../../AppState';
import { CommodityViewDTO } from '../../data-transfer-objects/commodity/commodity-view-dto';

export const getCommodityRequest = createAction('[Commodity] Get Commodity Request', props<{ entityId: string, pageId: string }>());
export const getCommodityDone = createAction('[Commodity] Get Commodity Done', props<{ commodityWidgetState: CommodityWidgetState }>());
export const getCommodityError = createAction('[Commodity] Get Commodity Error', props<{ commodityWidgetState: CommodityWidgetState }>());

export const addCommodityRequest = createAction('[Commodity] Add Commodity Request', props<{ entityId: string, pageId: string, commodityId: string}>());
export const addCommodityDone = createAction('[Commodity] Add Commodity Done', props<{ addedCommodity: CommodityViewDTO }>());
export const addCommodityError = createAction('[Commodity] Add Commodity Error', props<{
    addError: ErrorDetails,
    commodityId: string
}>());

export const removeCommodityRequest = createAction('[Commodity] Remove Commodity Request', props<{ entityId: string, pageId: string, commodityId: string }>());
export const removeCommodityDone = createAction('[Commodity] Remove Commodity Done', props<{ removedCommodity: CommodityViewDTO }>());
export const removeCommodityError = createAction('[Commodity] Remove Commodity Error', props<{
    removeError: ErrorDetails,
    commodityId: string
}>());

//reset
export const resetCommodity = createAction('[Commodity] Reset Commodities');
