import { FilterMetadata } from 'primeng/api';
import { ListFilterViewDTO } from '../data-transfer-objects/filter/list-filter-view-dto';
import { FilterMatchModeHelper } from './filter-match-mode-helper';
import { ObjectHelper } from './object-helper';

export class FilterMetadataHelper {
    public static GetParsedFilters(filters?: { [s: string]: FilterMetadata }) {
        const parsedFilters: ListFilterViewDTO = {};
        const filtersCloned: ListFilterViewDTO = ObjectHelper.deepCopyJsonParse(filters);

        Object.entries(filtersCloned).forEach(([filterField, filterValue]) => {

            if (FilterMatchModeHelper.CanFilterWithoutValue(filterValue[0].matchMode)) {
                parsedFilters[filterField] = filterValue;
            }
            else if (filterValue[0].value && filterValue[0].value !== null) {
                //lookup
                if (filterValue[0].value.lookupType) {
                    filterValue[0].lookupType = filterValue[0].value.lookupType;
                    filterValue[0].value = filterValue[0].value.lookupValue.Id;
                    parsedFilters[filterField] = filterValue;
                }
                //date
                else if (Array.isArray(filterValue[0].value)) {
                    parsedFilters[filterField] = filterValue[0].value;
                }
                //string/number
                else if (filterValue[0].value) {

                    if (FilterMatchModeHelper.CanFilterWithoutValue(filterValue[0].value.matchMode)) {
                        filterValue[0].matchMode = filterValue[0].value.matchMode;
                        filterValue[0].value = null;
                    }
                    else if (filterValue[0].value.value) {
                        filterValue[0].matchMode = filterValue[0].value.matchMode;
                        filterValue[0].value = filterValue[0].value.value;
                    }
                    else {
                        filterValue[0].value = filterValue[0].value.toString();
                    }

                    parsedFilters[filterField] = filterValue;
                }
            }
        });
        return parsedFilters;
    }
}