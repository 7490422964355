import { createReducer, on } from '@ngrx/store';
import { ListState, ReferenceCodeListWidgetState } from '../../AppState';
import { ListStateReducerHelper } from '../../helpers/list-state-reducer-helper';
import { getActionReferenceCodesDone, getActionReferenceCodesError, getAssetReferenceCodesDone, getAssetReferenceCodesError, resetReferenceCodesList } from './reference-code.actions';

export const initialReferenceCodeListState: ListState<ReferenceCodeListWidgetState>[] = [];

export const ReferenceCodeListReducer = createReducer<ListState<ReferenceCodeListWidgetState>[]>(initialReferenceCodeListState,
    on(getAssetReferenceCodesDone, (state, action) => ListStateReducerHelper.GetUpdatedListState<ReferenceCodeListWidgetState>(state, action.dataSourceId, action.referenceCodeListWidgetState)),
    on(getAssetReferenceCodesError, (state, action) => ListStateReducerHelper.GetUpdatedListState<ReferenceCodeListWidgetState>(state, action.dataSourceId, action.referenceCodeListWidgetState)),

    on(getActionReferenceCodesDone, (state, action) => ListStateReducerHelper.GetUpdatedListState<ReferenceCodeListWidgetState>(state, action.dataSourceId, action.referenceCodeListWidgetState)),
    on(getActionReferenceCodesError, (state, action) => ListStateReducerHelper.GetUpdatedListState<ReferenceCodeListWidgetState>(state, action.dataSourceId, action.referenceCodeListWidgetState)),

    on(resetReferenceCodesList, () => initialReferenceCodeListState)
);
