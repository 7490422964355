<div [formGroup]="CustomFormGroup" class="textarea">
    <fw-general-validator [PropertyName]="PropertyName"
                          [(CustomFormGroup)]="CustomFormGroup"
                          [(ValidationDictionary)]="ValidationDictionary"></fw-general-validator>
    <textarea class="p-inputtext" pInputTextarea [formControlName]="PropertyName" (input)="onTextValueChange($event.target.value)" placeholder=" "></textarea>
    <ng-template [ngIf]="ShowLabel()">
	    <label [ngClass]="GetLabelStylingClass()">
	        {{Label | transloco}}
	        <span *ngIf="TextArea.Validation.Required">*</span>
	    </label>
    </ng-template>
</div>
