import { OnInit } from '@angular/core';
import { Component, OnDestroy } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { StructuredDataLookupFacade } from '../../../../facade/configuration/structured-data-lookup.facade';
import { severity_error } from '../../../../consts/severity-options';
import { LoginDTO } from '../../../../data-transfer-objects/login/login-dto';
import { LoginType } from '../../../../enums/login-type';
import { DialogComponentBase } from '../../../../services/application/dialog-application.service';
import { SessionApplicationService } from '../../../../services/application/session-application.service';
import { ToastApplicationService } from '../../../../services/application/toast-application.service';
import { LanguageHttpService } from '../../../../services/http/language-http.service';

@Component({
    selector: 'fw-one-time-pin-form',
    templateUrl: './one-time-pin-form.component.html'
})
export class OneTimePinFormComponent extends DialogComponentBase<LoginDTO> implements OnInit, OnDestroy {
    public model: LoginDTO = this.dialogOptions.dataModel;
    public rememberClient: boolean = false;
    public _disabled: boolean;

    private Subscriptions: Subscription[] = [];

    constructor(
        private toastApplicationService: ToastApplicationService,
        private dynamicDialogRef: DynamicDialogRef,
        private dynamicDialogConfig: DynamicDialogConfig,
        private sessionApplicationService: SessionApplicationService,
        private lookupFacade: StructuredDataLookupFacade,
        private languageService: LanguageHttpService
    ) {
        super(dynamicDialogRef, dynamicDialogConfig);
    }

    ngOnDestroy(): void {
        if (this.Subscriptions) {
            this.Subscriptions.forEach(subscription => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            });
        }
    }

    ngOnInit(): void {
        this.Subscriptions.push(this.sessionApplicationService.authenticationChanged.subscribe(
            (isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    this.lookupFacade.LoadStructuredDataLookups();
                    this.dynamicDialogRef.destroy();
                } else {
                    this._disabled = false;
                }
            }));

        this.Subscriptions.push(this.sessionApplicationService.authenticationFailed.subscribe(error => {
            if (error) {
                this.toastApplicationService.showToast('SessionApplicationService.Login.LoginFailedDetail', error, severity_error);
            } else {
                this.toastApplicationService.showToast('SessionApplicationService.Login.LoginFailedDetail', 'SessionApplicationService.Login.IncorrectOTP', severity_error);
            }

            this._disabled = false;
        }));
    }

    public proceedClicked(): void {
        this._disabled = true;

        this.languageService.GetCurrentLanguageId().subscribe(languageId => {
            this.model.logintype = LoginType.OneTimePassword;
            this.sessionApplicationService.tryLogin(this.model, this.rememberClient, languageId, '');
        });
    }

    public sendOneTimePin(): void {
        this.sessionApplicationService.sendOneTimePin(this.model.username);
    }
}
