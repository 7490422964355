import { UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { DynamicPageBaseRoute } from '../consts/portal-base-routes';
import { StaticRoute } from '../interfaces/route/static-route';

export class RouteHelper {

    public static MatchStaticPages(url: UrlSegment[], group: UrlSegmentGroup, route: StaticRoute): UrlMatchResult {
        if (url.length === 2 && url[0].path === DynamicPageBaseRoute && url[1].path === route.staticPageId) {
            return {
                consumed: url
            };
        } else {
            return null;
        }
    }
}