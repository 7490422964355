<p-paginator #paginator 
    [rows]="rows" 
    [first]="first"
    [totalRecords]="totalRecords"
    [showCurrentPageReport]="true"
    [currentPageReportTemplate]="totalRecords > rows ? currentPageReportTemplate : currentPageReportTemplateSinglePage"
    [showPageLinks]="totalRecords > rows"
    [showFirstLastIcon]="totalRecords > rows"
    [ngClass]="getPaginatorStyleClass()"
    (onPageChange)="paginate($event)">
</p-paginator>