import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListState, RelatedAssetListWidgetStateModel } from 'src/app/AppState';
import { ListFieldConfigurationViewDTO } from 'src/app/data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { RelatedAssetQueryDefinitionSearchViewDTO } from 'src/app/data-transfer-objects/configuration/list-configuration/query-definitions/related-asset-query-definition-search-view-dto';
import { RelatedAssetAddDTO } from 'src/app/data-transfer-objects/related-asset/related-asset-add-dto';
import { PaginationModel } from 'src/app/models/pagination-models';
import { SearchModel } from 'src/app/models/search-model';
import { AbstractListFacade } from './abstract-list.facade';

@Injectable()
export abstract class AbstractRelatedAssetListFacade extends AbstractListFacade<RelatedAssetQueryDefinitionSearchViewDTO, RelatedAssetListWidgetStateModel> {

    public abstract EntityId: string;
    public abstract showAddButton: boolean;

    public abstract GetListItems(): Observable<ListState<RelatedAssetListWidgetStateModel>[]>;
    public abstract LoadListItems(fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, searchModel: SearchModel, assetQueryDefinition: RelatedAssetQueryDefinitionSearchViewDTO, dataSourceId: string): void;
    public abstract addRelatedAssets(dataSourceId: string, assetId: string, relatedAssets: RelatedAssetAddDTO[], pageId: string): void;
    public abstract removeRelatedAssets(dataSourceId: string, assetId: string, relatedAssetIds: string[], pageId: string): void;
    public abstract reloadListDataSelector(): Observable<ListState<RelatedAssetListWidgetStateModel>[]>;
}
