import { Directive, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { TextDisplayStyle } from 'src/app/enums/configuration/text-display-style-enum';
import { UIStylingHelper } from 'src/app/helpers/ui-styling-helper';

@Directive()
export abstract class InputControlBase<T> {
    public Data: T;
    public abstractFormControl: AbstractControl;

    @Input()
    public CustomFormGroup: FormGroup;

    @Input()
    public Label: string;

    @Input()
    public LabelStyle: TextDisplayStyle;

    @Input()
    public ValueStyle: TextDisplayStyle;

    @Input()
    public PropertyName: string;

    @Input()
    public ShouldShowLabel: boolean = true;
    
    constructor(protected transloco: TranslocoService) {
    }

    public ShowLabel(): boolean {
        return this.ShouldShowLabel && this.Label != null;
    }

    public GetAbstractControl(): AbstractControl {
        return this.CustomFormGroup.controls[this.PropertyName];
    }

    public StorePreviousValue(): void {
        this.Data = this.CustomFormGroup.controls[this.PropertyName].value;
    }

    public GetLabelStylingClass() : string {

        return UIStylingHelper.GetTextDisplayStyling(this.LabelStyle);
    }

    public GetValueStylingClass() : string {

        return UIStylingHelper.GetTextDisplayStyling(this.ValueStyle);
    }
}