import { createReducer, on } from '@ngrx/store';
import { GoogleAnalyticsConfigurationState } from 'src/app/AppState';
import { getGoogleAnalyticsConfigurationDone, getGoogleAnalyticsConfigurationError, resetGoogleAnalyticsConfiguration } from './google-analytics.actions';

export const initialGoogleAnalyticsConfigurationState: GoogleAnalyticsConfigurationState = {
    GoogleAnalyticsConfiguration: null,
    GetGoogleAnalyticsConfigurationError: null
}

export const GoogleAnalyticsConfigurationReducerMapper = createReducer<GoogleAnalyticsConfigurationState>(
    initialGoogleAnalyticsConfigurationState,
    on(getGoogleAnalyticsConfigurationDone, (_, action) => {
        return {
            GoogleAnalyticsConfiguration: action.configuration.GoogleAnalyticsConfiguration,
            GetGoogleAnalyticsConfigurationError: null
        }
    }),
    on(getGoogleAnalyticsConfigurationError, (_, action) => {
        return {
            GoogleAnalyticsConfiguration: null,
            GetGoogleAnalyticsConfigurationError: action.configuration.GetGoogleAnalyticsConfigurationError
        }
    }),
    on(resetGoogleAnalyticsConfiguration, () => initialGoogleAnalyticsConfigurationState)
);
