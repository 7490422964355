import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { TextAreaConfiguration } from '../../../../../models/configuration/widgets/properties/controls/textarea/text-area-configuration';
import { DebounceTimeControlBase } from '../../input-controls/debounce-time-control-base';
import { ValidatableInputControlBase } from '../../input-controls/validatable-input-control-base';

@Component({
    selector: 'fw-form-edit-text-area',
    templateUrl: './form-edit-text-area.component.html',
    host: { 'class': 'form-field' }
})
export class FormEditTextAreaComponent extends DebounceTimeControlBase<string> implements OnInit {

    @Input()
    public TextArea: TextAreaConfiguration;

    constructor(protected transloco: TranslocoService) {
        super(transloco);
    }

    ngOnInit(): void {
        this.ConfigureTextAreaValidation(this.TextArea.Validation);
    }
}
