<div class="form-field">
    <div>
        <input #topic="ngModel" [(ngModel)]="newDiscussion.Topic" type="text" pInputText required>
        <label>
            {{'Widget.Discussion.New.Title' | transloco}}
            <span>*</span>
        </label>
    </div>
    <div>
        <textarea #comment="ngModel" [(ngModel)]="newDiscussion.DiscussionCommentCreateDTO.Comment" class="p-inputtext" pInputTextarea required></textarea>
        <label>
            {{'Widget.Discussion.New.Comment' | transloco}}
            <span>*</span>
        </label>
    </div>
    <div>
        <fw-file-upload [(UploadedOutputFiles)]="UploadedOutputFiles"></fw-file-upload>
    </div>
</div>
<div class="p-dialog-footer">
    <p-button styleClass="btn btn-lg" type="button" label="{{'Widget.Discussion.New.CancelButton' | transloco}}" (onClick)="cancelClicked()"></p-button>
    <p-button styleClass="btn btn-primary btn-lg" type="button" label="{{'Widget.Discussion.New.AddButton' | transloco}}" [disabled]="!topic.valid || !comment.valid" (onClick)="addClicked()"></p-button>
</div>