import { Component, DoCheck, ElementRef, EventEmitter } from '@angular/core';
import { GuidHelper } from '../../../../helpers/guid-helper';
import { PropertyConfiguration } from '../../../../models/configuration/widgets/properties/property-configuration';

@Component({
    template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class BaseWidget implements DoCheck {    

    //disabling lint error for now. this should be suffixed with Component
    protected dataSourceId: string;
    protected VisibilityChecked: boolean = false;
    protected Visible: boolean = false;

    constructor(public elementRef: ElementRef) {
        this.dataSourceId = GuidHelper.NewGuid();
    }

    public IsVisible(): boolean {
        const element = this.elementRef.nativeElement;

        //if this if statement is true, it means widget is not visible
        if (element.offsetParent === null) {
            if (this.Visible === true) {
                this.VisibilityChecked = false;
            }
            this.Visible = false;
            return false;
        }

        this.Visible = true;
        return true;
    }

    public ngDoCheck(): void {
        if (this.IsVisible()) {
            this.VisibilityChecked = true;
        }
    }
}
