<div>
    <span class="validation">{{ _invalidText }}</span>
    <input type="password" [ngClass]="{'was-validated': !_isValid, 'is-invalid': !_isValid }"
           class="p-inputtext"
           (blur)="onTouch()"
           (keyup)="onChange()"
           (change)="onChange()"
           [(ngModel)]="_value" value="{{_value}}"
           [attr.id]="NewComponentId('pwd')"
           [attr.disabled]="!_disabled ? null : 'disabled'" placeholder=" " autocomplete="on" />
    <span *ngIf="PasswordTogglable" portal-tooltip [class]="PasswordToggleClass" style="position:absolute;top:25px;right:10px;" [tooltip-title]="PasswordToggleText" (click)="PasswordToggle()"></span>
    <label [for]="NewComponentId('pwd')">{{_labelText}}</label>
</div>