<ng-container *ngIf="!IsHijri" [ngTemplateOutlet]="Gregorian"></ng-container>
<ng-container *ngIf="IsHijri" [ngTemplateOutlet]="Hijri"></ng-container>
<div *ngIf="ShowToggle" class="calendar-toggle">
    <p-dropdown [options]="options"
                [(ngModel)]="selectedCalendar"
                (onChange)="toggle()"
                optionLabel="label" appendTo="body">
        <ng-template let-item pTemplate="selectedItem">
            {{item.value}}
        </ng-template>
    </p-dropdown>
</div>

<ng-template #Gregorian>
    <fw-inline-edit-calendar *ngIf="FormEditStyle === FormEditStyles.Inline || FormEditStyle === FormEditStyles.ReadOnly"
                             [CustomFormGroup]="CustomFormGroup"
                             [PropertyName]="Property.PropertyName"
                             [Label]="Property.LabelResourceId"
                             [LabelStyle]="Property.LabelStyle"
                             [ValueStyle]="Property.ValueStyle"
                             [Calendar]="Property.Calendar"
                             [AcceptChanges]="AcceptChanges"
                             [FormEditStyle]="FormEditStyle"
                             [ShouldShowLabel]="ShouldShowLabel"
                             [class.multi-calendar]="hijriEnabled">
    </fw-inline-edit-calendar>
    <fw-form-edit-calendar *ngIf="FormEditStyle === FormEditStyles.Form"
                           [CustomFormGroup]="CustomFormGroup"
                           [PropertyName]="Property.PropertyName"
                           [Label]="Property.LabelResourceId"
                           [LabelStyle]="Property.LabelStyle"
                           [Calendar]="Property.Calendar"
                           [AcceptChanges]="AcceptChanges"
                           [ShouldShowLabel]="ShouldShowLabel"
                           [class.multi-calendar]="hijriEnabled">
    </fw-form-edit-calendar>
</ng-template>

<ng-template #Hijri>
    <fw-inline-edit-uaq-calendar *ngIf="FormEditStyle === FormEditStyles.Inline || FormEditStyle === FormEditStyles.ReadOnly"
                                 [CustomFormGroup]="CustomFormGroup"
                                 [PropertyName]="Property.PropertyName"
                                 [Label]="Property.LabelResourceId"
                                 [LabelStyle]="Property.LabelStyle"
                                 [ValueStyle]="Property.ValueStyle"
                                 [Calendar]="Property.Calendar"
                                 [AcceptChanges]="AcceptChanges"
                                 [FormEditStyle]="FormEditStyle"
                                 [ShouldShowLabel]="ShouldShowLabel"
                                 [class.multi-calendar]="hijriEnabled">
    </fw-inline-edit-uaq-calendar>
    <fw-form-edit-uaq-calendar *ngIf="FormEditStyle === FormEditStyles.Form"
                               [CustomFormGroup]="CustomFormGroup"
                               [PropertyName]="Property.PropertyName"
                               [Label]="Property.LabelResourceId"
                               [LabelStyle]="Property.LabelStyle"
                               [Calendar]="Property.Calendar"
                               [AcceptChanges]="AcceptChanges"
                               [ShouldShowLabel]="ShouldShowLabel"
                               [class.multi-calendar]="hijriEnabled">
    </fw-form-edit-uaq-calendar>
</ng-template>