import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { GuidHelper } from '../../../../../helpers/guid-helper';
import { LookupModel } from '../../../../../models';
import { StructuredDataCellBase } from '../structured-data-cell-base';

@Component({
    selector: 'fw-yes-no-cell',
    templateUrl: './yes-no-cell.component.html',
    host: { 'class': 'inline-edit' }
})
export class YesNoCellComponent extends StructuredDataCellBase implements OnInit {


    public FormEditStyles = FormEditStyle;

    public Options: LookupModel[];
    public CustomFormGroup: FormGroup;
    public editMode: boolean = false;

    public entryModelIdDataId: string;

    public entryModelId: string;

    @Input()
    public AcceptChanges: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public FormEditStyle: FormEditStyle;

    constructor(protected transloco: TranslocoService) {
        super();
    }

    public StorePreviousValue(): void {
        this.entryModelIdDataId = this.CustomFormGroup.controls[this.entryModelId].value;
    }

    public onKeyDownEnter(event) {

        this.editMode = this.FormEditStyle !== this.FormEditStyles.ReadOnly
        //on enter accept is triggered hence this check is in place to prevent this behaviour and only accept when explicitly clicked
        if (event.target.nodeName !== 'BUTTON') {
            event.preventDefault();
        }
    }

    public GetDropDownDisplayValue(): string {

        if (this.Options) {
            const abstractControl = this.CustomFormGroup.controls[this.entryModelId];

            if (abstractControl.value && abstractControl.value !== GuidHelper.EmptyGuid()) {
                const id = abstractControl.value;
                const option = this.Options.find(o => o.Id === id.toString());

                if (option) {
                    return option.Value;
                }
            }
        }
    }

    public onAcceptChanges() {

        this.entryModelIdDataId = this.CustomFormGroup.controls[this.entryModelId].value;

        this.editMode = false;
        this.AcceptChanges.emit();
    }

    ngOnInit(): void {

        this.Options = Object.assign([], this.Options);
        this.Options.unshift({
            CommonlyUsed: false,
            Id: GuidHelper.EmptyGuid(),
            Value: ' '
        });
    }

    public onCancelChanges() {
        this.editMode = false;

        this.CustomFormGroup.controls[this.entryModelId].setValue(this.entryModelIdDataId.toString());
    }
}