import { createAction, props } from '@ngrx/store'
import { AssetWidgetState } from '../../AppState';
import { AssetViewDTO } from '../../data-transfer-objects/asset/asset-view-dto';


export const getAssetRequest = createAction('[AssetDetails] New Get Asset Request', props<{ pageId: string , assetId: string}>());
export const getAssetDone = createAction('[AssetDetails] New Get Asset Done', props<{ assetWidgetState: AssetWidgetState }>());
export const getAssetError = createAction('[AssetDetails] New Get Asset Error Done', props<{ assetWidgetState: AssetWidgetState }>());

export const updateAssetRequest = createAction('[AssetDetails] New Update Asset Request', props<{ assetViewDTO: AssetViewDTO, pageId: string }>());
export const updateAssetDone = createAction('[AssetDetails] New Update Asset Done', props<{ assetWidgetState: AssetWidgetState }>());
export const updateAssetError = createAction('[AssetDetails] New Update Asset Error Done', props<{ assetWidgetState: AssetWidgetState }>());

export const assetResetState = createAction('[AssetDetails]New  Asset Reset State');

export const executeAssetBehaviourRequest = createAction('[AssetDetails] Execute Asset Behaviour Request', props<{ taskId: string, pageId: string, clientId: string, widgetId: string, assetId: string }>());
export const executeAssetBehaviourDone = createAction('[AssetDetails] Execute Asset Behaviour Done');
