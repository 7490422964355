import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionViewDTO } from '../../data-transfer-objects/action/action-view-dto';
import { ActionListViewDTO } from '../../data-transfer-objects/action/action-list-view-dto';
import { ActionSearchModel } from '../../data-transfer-objects/action/action-search-dto';
import { HttpFieldModel } from '../../models/field-list-model';
import { PaginationModel, PaginationResultModel } from 'src/app/models/pagination-models';
import { BASE_URL } from '../../helpers/base-url';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';
import { HttpStatusCode } from '../../enums/http-status-codes';
import { ActionUpdateDTO } from '../../data-transfer-objects/action/action-update-dto';
import { ActionDraftBehaviourContextModel } from '../../models/behaviours/context-models/action/action-draft-behaviour-context-model';
import { PaymentConnectorResponseInputContextModel } from '../../data-transfer-objects/payment/payment-connector-response-input-view-dto';
import { ReferenceCodeViewDTO } from 'src/app/data-transfer-objects/reference-code/reference-code-view-dto';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';

@Injectable({
    providedIn: 'root'
})
export class ActionHttpService extends AuthenticatedHttpServiceBase {

    constructor(
        httpClient: HttpClient,
        @Inject(BASE_URL)
        private baseUrl: string,
        errorManager: ErrorManagerService,
        toastService: ToastService
    ) {
        super(httpClient, errorManager, toastService);
    }

    public executeActionBehaviour(actionDraftBehaviourContextModel: ActionDraftBehaviourContextModel, widgetId: string, pageId: string): Observable<null> {
        const headers: HttpHeaders = this.AddWidgetContextHeader(this.AddPageIdHeader(this.GetStandardHeaders(), pageId), widgetId);
        return this.Post<null>(`${this.baseUrl}api/action/ExecuteBehaviour`, actionDraftBehaviourContextModel, headers);
    }

    public ExecutePaymentBehaviour(context: PaymentConnectorResponseInputContextModel): Observable<null> {
        return this.Post<null>(`${this.baseUrl}api/action/ExecutePaymentBehaviour`, context, this.GetStandardHeaders());
    }

    public createAction(pageId: string, assetId: string): Observable<null> {
        return this.Post<null>(`${this.baseUrl}api/action/${pageId}?assetId=${assetId}`, null, this.GetStandardHeaders());
    }

    public getAction(pageId: string, entityId: string): Observable<ActionViewDTO> {
        return this.Get<ActionViewDTO>(`${this.baseUrl}api/action/${entityId}/page/${pageId}`, this.GetStandardHeaders());
    }

    public newUpdateAction(pageId: string, actionUpdateDTO: ActionUpdateDTO): Observable<null> {
        return this.Put<null>(`${this.baseUrl}api/action/page/${pageId}`, actionUpdateDTO, this.GetStandardHeaders(), [], [HttpStatusCode.NOT_FOUND, HttpStatusCode.BAD_REQUEST]);
    }

    public getActionBySearch(paginationModel: PaginationModel, actionSearchDTO: ActionSearchModel, fieldModel: HttpFieldModel): Observable<PaginationResultModel<ActionListViewDTO>> {
        return this.Post<PaginationResultModel<ActionListViewDTO>>(`${this.baseUrl}api/action/search`, actionSearchDTO, this.GetStandardHeaders(), [], paginationModel, [HttpStatusCode.BAD_REQUEST, HttpStatusCode.NOT_FOUND], fieldModel);
    }

    public getActionReferenceCodes(actionId: string, fieldModel: HttpFieldModel, paginationModel: PaginationModel, pageId: string, widgetId: string): Observable<PaginationResultModel<ReferenceCodeViewDTO>> {
        const headers: HttpHeaders = this.AddWidgetContextHeader(this.AddPageIdHeader(this.GetStandardHeaders(), pageId), widgetId);
        return this.GetAuthenticated<PaginationResultModel<ReferenceCodeViewDTO>>(`${this.baseUrl}api/action/${actionId}/referenceCodes`, headers, [], paginationModel, [], fieldModel);
    }
}
