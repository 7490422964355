import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorDetails } from 'src/app/models/error-details';
import { ConfigurationHttpService } from 'src/app/services/http/configuration-http.service';
import { getIdPsConfigurationDone, getIdPsConfigurationError, getIdPsConfigurationRequest } from './idp.actions';

@Injectable()
export class IdPsConfigurationEffects {
    getIdPsConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getIdPsConfigurationRequest),
            switchMap((action) =>
                this.configHttpService
                    .GetIdPsConfiguration(action.forceAuthentication)
                    .pipe(
                        map(configuration => getIdPsConfigurationDone({
                            configuration: {
                                IdPsConfiguration: {
                                    InternalIdentityProviderConfiguration: configuration.InternalIdentityProviderConfiguration,
                                    Saml2IdPs: configuration.Saml2IdPs
                                },
                                GetIdPsConfigurationError: null
                            }
                        })),
                        catchError((errorDetails: ErrorDetails) => of(getIdPsConfigurationError({
                            configuration: {
                                IdPsConfiguration: null,
                                 GetIdPsConfigurationError: errorDetails
                            }
                        })))
                    ))
        ));

    constructor(
        private actions$: Actions,
        public configHttpService: ConfigurationHttpService) {
    }
}
