import { Component, ComponentFactoryResolver, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { StructuredDataLibraryService, ValueType } from '@landadmin/structured-data';
import { IDisplayMapper } from '@landadmin/structured-data/lib/interfaces/i-display-mapper';
import { IEditOverride } from '@landadmin/structured-data/lib/interfaces/i-edit-override';
import { LookupConfigurationModel } from '@landadmin/structured-data/lib/models/lookup-configuration-model';
import { StructuredDataEntryModel } from '@landadmin/structured-data/lib/models/structured-data-entry-model';
import { StructuredDataModel } from '@landadmin/structured-data/lib/models/structured-data-model';
import { PaginatorPageChange } from '@landadmin/structured-data/lib/models/structured-data-pagination-override';
import { BehaviorSubject, forkJoin, Subject, Subscription } from 'rxjs';
import { StructuredDataLookupFacade } from '../../../../../facade/configuration/structured-data-lookup.facade';
import { StructuredDataNodeFacade } from '../../../../../facade/configuration/structured-data-node.facade';
import { StructuredDataMapperHelper } from '../../../../../configuration/structured-data-configuration-mapper-helper';
import { severity_warning } from '../../../../../consts/severity-options';
import { StructuredDataListWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/widgets/structured-data-list-widget-configuration-view-dto';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { LayoutMode } from '../../../../../enums/layout-mode';
import { GuidHelper } from '../../../../../helpers/guid-helper';
import { ObjectHelper } from '../../../../../helpers/object-helper';
import { StructuredDataHelper } from '../../../../../helpers/structured-data-helper';
import { LocaleDateFormatPipe } from '../../../../../pipes/locale-date-format.pipe';
import { ToastApplicationService } from '../../../../../services/application/toast-application.service';
import { LanguageHttpService } from '../../../../../services/http/language-http.service';
import { SiteSettingsService } from '../../../../../services/deprecated/site-settings.service';
import { BaseWidget } from '../base-widget';
import { AbstractStructuredDataEntryFacade } from '../../../../../facade/abstract/abstract-structured-data.facade';
import { TranslocoService } from '@ngneat/transloco';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogApplicationService, DialogOptions } from '../../../../../services/application/dialog-application.service';
import { StructuredDataDialogConfiguration, StructuredDataWidgetDialogComponent } from '../sd-dialog/structured-data-widget-dialog.component';
import { filter, first, map } from 'rxjs/operators';
import { StateCacheFacade } from '../../../../../facade/state-cache/state-cache.facade';
import { RowUpdateStyle } from '../../../../../enums/configuration/row-update-style';

@Component({
    selector: 'fw-structured-data-widget',
    templateUrl: './structured-data-widget.component.html',
})

export class StructuredDataWidgetComponent extends BaseWidget implements OnInit, OnDestroy {
    public displayMappers: IDisplayMapper[] = [];
    public instanceId: string = null;
    public loaded: boolean = false;
    public lookups: LookupConfigurationModel = null;
    public emptyListMessage: string;
    public setPageSubject: Subject<number> = new Subject<number>();
    public setTotalRecordsSubject: Subject<number> = new Subject<number>();
    public structuredDataModel: StructuredDataModel;
    public structuredDataPreviousEntries: StructuredDataEntryModel[];
    public structuredDataModelAdded: StructuredDataEntryModel;

    @Input()
    public StructuredDataListWidgetConfigurationViewDTO: StructuredDataListWidgetConfigurationViewDTO;

    private _currentPage: number = 1;
    private _totalRecords: number = 0;
    private _sharedState: SharedStructuredDataListWidgetState = {
        operationInProgress: false
    };
    private disableAddButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private subscriptions: Subscription[] = [];
    private previoustState: string = null;

    public get isReadOnly(): boolean {
        return (this.StructuredDataListWidgetConfigurationViewDTO.Editing?.ReadOnly ?? false) || this.structuredDataFacade._formEditStyle === FormEditStyle.ReadOnly;
    }

    public get isDialogEditMode(): boolean {
        if (this.isReadOnly || this.isSingleEntryMode) {
            return false;
        } else {
            return this.StructuredDataListWidgetConfigurationViewDTO.Editing?.RowUpdateStyle !== RowUpdateStyle.Row;
        }
    }

    public get isReadWrite(): boolean {
        return !this.isReadOnly;
    }

    public get isFormEditStyle(): boolean {
        return this.structuredDataFacade._formEditStyle === FormEditStyle.Form;
    }

    public get isInlineStyle(): boolean {
        return this.structuredDataFacade._formEditStyle === FormEditStyle.Inline;
    }

    public get isSingleEntryMode(): boolean {
        return this.StructuredDataListWidgetConfigurationViewDTO.LayoutMode === LayoutMode.Details;
    }

    public get isMultiEntryMode(): boolean {
        return !this.isSingleEntryMode;
    }

    public get canEditEntriesInline(): boolean {
        return this.isReadWrite && !this.isDialogEditMode;
    }

    public get canAddEntries(): boolean {
        return this.isReadWrite && !this.isSingleEntryMode;
    }

    public get canDeleteEntries(): boolean {
        return this.isReadWrite && !this.isSingleEntryMode;
    }

    public get structuredDataClass(): string {
        let style = '';

        switch (this.StructuredDataListWidgetConfigurationViewDTO.LayoutMode) {
            case LayoutMode.List:
                style += 'structured-data';
                break;
            case LayoutMode.Details:
                style += 'structured-data lm-details';
                break;
            case LayoutMode.Card:
                style += 'structured-data lm-card';
                break;
        }

        if (this.isReadOnly) {
            style += ' sd-readonly';
        } else if (this.isInlineStyle) {
            style += ' sd-inline-edit';
        } else if (this.isFormEditStyle) {
            style += ' sd-form-edit';
        }

        if (this.isOperationInProgress) {
            style += ' p-disabled';
        }

        if (this.StructuredDataListWidgetConfigurationViewDTO.Editing?.RowUpdateStyle == RowUpdateStyle.Row) {
            style += ' form-row';
        }

        return style;
    }

    public get isPaginationEnabled(): boolean {
        return this.structuredDataFacade._enablePaging && this.isMultiEntryMode;
    }

    private get currentPage(): number {
        return this._currentPage;
    }

    private set currentPage(page: number) {
        if (this._currentPage != page) {
            this._currentPage = page;

            this.setPageSubject.next(page);
        }
    }

    private get isLastPage(): boolean {
        return this.currentPage === this.lastPage;
    }

    private get isLastPageFull(): boolean {
        return this.totalRecords !== 0 && this.totalRecords % this.StructuredDataListWidgetConfigurationViewDTO.PageSize === 0;
    }

    private get isFirstPage(): boolean {
        return this.currentPage === 1;
    }

    private get lastPage(): number {

        if ((this.totalRecords) <= 0) {
            return 1;
        }

        return Math.ceil((this.totalRecords) / this.StructuredDataListWidgetConfigurationViewDTO.PageSize);
    }

    public get pageSize(): number {
        if (this.isSingleEntryMode) {
            return 1;
        }

        return this.isPaginationEnabled ? this.StructuredDataListWidgetConfigurationViewDTO.PageSize : 100;
    }

    public get totalRecords(): number {
        return this._totalRecords;
    }

    public set totalRecords(totalRecords: number) {
        if (this._totalRecords != totalRecords) {
            this._totalRecords = totalRecords;

            this.setTotalRecordsSubject.next(totalRecords);
        }
    }

    public get isOperationInProgress(): boolean {
        return this._sharedState.operationInProgress || false;
    }

    public set isOperationInProgress(value: boolean) {
        this.setSharedState({
            operationInProgress: value
        });
    }

    public get lastEntry(): StructuredDataEntryModel {
        if (this.structuredDataModel.Entries.length) {
            return this.structuredDataModel.Entries[this.structuredDataModel.Entries.length - 1];
        } else {
            return null;
        }
    }

    constructor(
        private structuredDataFacade: AbstractStructuredDataEntryFacade,
        private structuredDataNodeFacade: StructuredDataNodeFacade,
        private languageHttpService: LanguageHttpService,
        private translocoService: TranslocoService,
        private siteSettingsService: SiteSettingsService,
        private toastApplicationService: ToastApplicationService,
        public _structuredDataLibraryService: StructuredDataLibraryService,
        public _componentFactoryResolver: ComponentFactoryResolver,
        public structuredDataLookupFacade: StructuredDataLookupFacade,
        public elementRef: ElementRef,
        private dynamicDialogReference: DynamicDialogRef,
        private dynamicDialogConfig: DynamicDialogConfig,
        private dialogApplicationService: DialogApplicationService,
        private stateCacheFacade: StateCacheFacade) {

        super(elementRef);
    }

    public Add(structuredDataEntryModel: StructuredDataEntryModel): void {
        if (this.isDialogEditMode) {
            this.structuredDataModel.Entries.pop();
            this.Edit(structuredDataEntryModel, false);

            return;
        }

        if (!this.isPaginationEnabled) {
            this.updateSharedSate();
            this.ToggleAddButtonEnabled();

            return;
        }

        if (!this.isLastPage) {
            const remainder = this.totalRecords % this.StructuredDataListWidgetConfigurationViewDTO.PageSize;

            if (remainder === 0) {
                this.CopyEntries([structuredDataEntryModel], this.lastPage + 1, this.totalRecords + 1);
            } else {
                this.structuredDataModelAdded = structuredDataEntryModel;

                this.structuredDataModel.Entries.pop();

                this.ProccessPaging(this.lastPage);
            }
        } else {
            if (this.structuredDataModel.Entries.length > this.StructuredDataListWidgetConfigurationViewDTO.PageSize) {
                this.CopyEntries([structuredDataEntryModel], this.currentPage + 1, this.totalRecords + 1);
            } else {
                this.totalRecords++;
            }
        }

        this.updateSharedSate();
        this.ToggleAddButtonEnabled();
    }

    public Delete(structuredDataEntryModel: StructuredDataEntryModel, updateMode?: RowUpdateStyle) {
        if (StructuredDataHelper.HasValue(structuredDataEntryModel)) {
            this.structuredDataFacade.DeleteStructuredDataEntry(
                this.StructuredDataListWidgetConfigurationViewDTO.RootNodeId,
                ObjectHelper.deepCopyJsonParse(structuredDataEntryModel));
        }

        const targetPage = this.isLastPage && !this.isFirstPage && this.structuredDataModel.Entries.length === 0
            ? this.currentPage - 1
            : this.currentPage;

        this.updateSharedSate();
        this.ToggleAddButtonEnabled();

        this.ProccessPaging(targetPage);
    }

    public Edit(structuredDataEntryModel: StructuredDataEntryModel, isEdit: boolean): void {
        setTimeout(() => {
            const dialogReference = this.getModalDialog({
                Entries: [ObjectHelper.deepCopyJsonParse(structuredDataEntryModel)],
                Node: this.structuredDataModel.Node
            }, isEdit);

            dialogReference.onClose.subscribe((entry: StructuredDataEntryModel) => {
                if (entry) {
                    if (isEdit) {
                        const existingEntry = this.structuredDataModel.Entries.find(x => x.Id == entry.Id);

                        for (let i = 0; i < existingEntry.ChildEntries.length; i++) {
                            existingEntry.ChildEntries[i] = entry.ChildEntries[i];
                        }

                        this.UpdateRow(existingEntry);
                    } else {
                        this.UpdateRow(entry);

                        const targetPage = this.isLastPageFull
                            ? this.lastPage + 1
                            : this.lastPage;

                        this.ProccessPaging(targetPage);
                    }
                }
            });
        });
    }

    public Paginate(pageChange: PaginatorPageChange) {
        if (pageChange.Page != this.currentPage) {
            this.ProccessPaging(pageChange.Page);
        }
    }

    public Update(structuredDataModel: StructuredDataEntryModel): void {
        if (this.IsOptionListEntryCommonlyUsed(structuredDataModel)) {
            this.structuredDataModel.Entries = ObjectHelper.deepCopyJsonParse(this.structuredDataPreviousEntries);
            this.toastApplicationService.showToast('StructuredData.Widget.InvalidOptionListItem', 'StructuredData.Widget.InvalidOptionListItem.Detail', severity_warning);
            return;
        }

        const rowEntry = StructuredDataHelper.GetParentEntry(this.structuredDataModel.Entries, structuredDataModel);

        this.UpdateRow(rowEntry);
        this.ToggleAddButtonEnabled();

        if (this.lastEntry?.Id !== structuredDataModel.Id && !StructuredDataHelper.HasValue(rowEntry)) {
            this.ProccessPaging(this.currentPage);
        }
    }

    public UpdateRow(structuredDataModel: StructuredDataEntryModel): void {
        this.structuredDataFacade.UpdateStructuredDataEntry(
            this.StructuredDataListWidgetConfigurationViewDTO.RootNodeId,
            ObjectHelper.deepCopy(structuredDataModel));

        this.structuredDataPreviousEntries = ObjectHelper.deepCopyJsonParse(this.structuredDataModel.Entries);
        this.updateSharedSate();
    }

    updateSharedSate(): void {
        if (!this._sharedState.entries || !StructuredDataHelper.ArrayEqual(this.structuredDataModel.Entries, this._sharedState.entries)) {
            this.setSharedState({
                entries: ObjectHelper.deepCopyJsonParse(this.structuredDataModel.Entries)
            });
        }
    }

    ngOnDestroy(): void {

        if (this.subscriptions) {
            this.subscriptions.forEach(subscription => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            });
        }


        this._structuredDataLibraryService.DiscardInstance(this.instanceId);


        this.structuredDataFacade.ResetState();
    }

    ngOnInit(): void {

        this.instanceId = GuidHelper.NewGuid();
        this.setPageSubject = new Subject<number>();
        this.emptyListMessage = this.translocoService.translate(this.StructuredDataListWidgetConfigurationViewDTO.EmptyListResourceId);

        this.structuredDataModel = {
            Entries: [],
            Node: null
        };

        this.structuredDataPreviousEntries = [];

        this.structuredDataLookupFacade.LoadStructuredDataLookups();

        this.subscriptions.push(this.structuredDataFacade._setLoadSubject.subscribe((readyToLoad: boolean) => {
            if (readyToLoad) {
                this.ProccessPaging(1);
            }
        }));

        forkJoin({
            node: this.structuredDataNodeFacade.LoadStructuredDataNodesSelector()
                .pipe(
                    filter(states => !!states?.length),
                    map(states => states.find(x => x.dataSourceId === this.structuredDataFacade._entityTypeId)),
                    filter(state => !!state),
                    map(state => state.StateModel.viewModel.find(x => x.Id === this.StructuredDataListWidgetConfigurationViewDTO.RootNodeId)),
                    filter(rootNode => !!rootNode),
                    first()
                ),
            lookups: this.structuredDataLookupFacade.LoadStructuredDataLookupsSelector()
                .pipe(
                    filter(x => !!x.viewModel),
                    map(x => x.viewModel),
                    first()
                )
        }).subscribe(result => {
            this.structuredDataModel.Node = Object.assign([], result.node);
            this.lookups = result.lookups;

            this.subscriptions.push(this.structuredDataFacade.LoadStructuredDataEntrySelector()
                .pipe(
                    filter(states => !!states?.length),
                    map(states => states.find(x => x.dataSourceId == this.StructuredDataListWidgetConfigurationViewDTO.RootNodeId)),
                    filter(state => !!state)
                )
                .subscribe(structuredDataWidgetState => {
                    if (!this.structuredDataModelAdded && this.previoustState === JSON.stringify(structuredDataWidgetState)) {
                        this.isOperationInProgress = false;
                        return;
                    }

                    const viewModel = structuredDataWidgetState.StateModel.viewModel;

                    if (viewModel && viewModel.Models) {
                        this.CopyEntries(viewModel.Models, viewModel.Page, viewModel.TotalRecords);
                    } else {
                        this.CopyEntries([], 1, 0);
                    }

                    this.PopulateStructuredData();
                    this.ToggleAddButtonEnabled();

                    this.structuredDataPreviousEntries = ObjectHelper.deepCopyJsonParse(this.structuredDataModel.Entries);
                    this.previoustState = JSON.stringify(structuredDataWidgetState);
                }));

            this.subscriptions.push(this.stateCacheFacade.GetCache<SharedStructuredDataListWidgetState>(this.StructuredDataListWidgetConfigurationViewDTO.RootNodeId)
                .pipe(
                    filter(data => data?.widgetId !== this.StructuredDataListWidgetConfigurationViewDTO.Id),
                    map(data => Object.assign({}, data, { widgetId: '' })),
                    filter(data => JSON.stringify(data) !== JSON.stringify(this._sharedState))
                )
                .subscribe(data => {
                    this._sharedState = Object.assign({}, this._sharedState, data);

                    if (this._sharedState.entries) {
                        if (!StructuredDataHelper.ArrayEqual(this.structuredDataModel.Entries, this._sharedState.entries)) {
                            this.structuredDataPreviousEntries = ObjectHelper.deepCopyJsonParse(this.structuredDataModel.Entries);
                            this.structuredDataModel.Entries = ObjectHelper.deepCopyJsonParse(this._sharedState.entries);
                        }
                    }
                }));
        })
    }

    public setSharedState(value: SharedStructuredDataListWidgetState): void {
        const toSend: SharedStructuredDataListWidgetState = { ...value, widgetId: this.StructuredDataListWidgetConfigurationViewDTO.Id };
        const toKeep: SharedStructuredDataListWidgetState = { ...value, widgetId: '' };

        this._sharedState = Object.assign({}, this._sharedState, toKeep);
        this.stateCacheFacade.SetCache<SharedStructuredDataListWidgetState>(this.StructuredDataListWidgetConfigurationViewDTO.RootNodeId, toSend);
    }

    public getModalDialog(structuredDataModel: StructuredDataModel, isEdit: boolean): DynamicDialogRef {
        const headerResourceId = isEdit ? this.StructuredDataListWidgetConfigurationViewDTO.Editing?.Dialog?.EditTitleResourceId : this.StructuredDataListWidgetConfigurationViewDTO.Editing?.Dialog?.AddNewTitleResourceId;

        const dialogOptions: DialogOptions<any, StructuredDataDialogConfiguration> = {
            closable: true,
            footer: '',
            header: this.translocoService.translate(headerResourceId),
            dataModel: null,
            configuration: { structuredDataModel: structuredDataModel, dialogConfiguration: this.StructuredDataListWidgetConfigurationViewDTO.Editing?.Dialog },
            showHeader: true,
            styleClass: 'nested-footer dialog-sm'
        };

        return this.dialogApplicationService.showFormDialog(
            StructuredDataWidgetDialogComponent,
            dialogOptions
        );
    }

    private IsOptionListEntryCommonlyUsed(structuredDataModel: StructuredDataEntryModel): boolean {
        const commonlyUsedId = '----------------';

        return (structuredDataModel.ValueType === ValueType.OptionList &&
            structuredDataModel.Value.Id === commonlyUsedId);
    }

    private ToggleAddButtonEnabled(): void {
        if (this.structuredDataModel.Entries.length <= 0) {
            this.disableAddButtonSubject.next(false);
        } else {
            if (!this.isLastPage || (this.isLastPage && StructuredDataHelper.HasValue(this.lastEntry))) {
                this.disableAddButtonSubject.next(false);
            } else {
                this.disableAddButtonSubject.next(true);
            }
        }
    }

    private CopyEntries(structuredDataEntries: StructuredDataEntryModel[], page: number, totalEntries: number) {
        const entryCount = structuredDataEntries?.length || 0;
        let newEntries: StructuredDataEntryModel[] = ObjectHelper.deepCopyJsonParse(structuredDataEntries);
        let addingEntry = false;

        if (this.isSingleEntryMode && entryCount == 0) {
            const rootEntry = StructuredDataHelper.GetNullEntryFromNode(this.structuredDataModel.Node);
            newEntries = [];
            newEntries.push(rootEntry);
        }

        if (this.structuredDataModelAdded) {
            newEntries.push(this.structuredDataModelAdded);
            this.structuredDataModelAdded = null;
            addingEntry = true;
        }

        for (const newEntry of newEntries) {
            const blank = StructuredDataHelper.GetNullEntryFromNode(this.structuredDataModel.Node);

            for (const blankChild of blank.ChildEntries) {
                const newChild = newEntry.ChildEntries.find(x => x.StructuredDataNodeId == blankChild.StructuredDataNodeId);

                if (!newChild) {
                    newEntry.ChildEntries.push(blankChild);
                }
            }
        }

        const currentEntries: StructuredDataEntryModel[] = ObjectHelper.deepCopyJsonParse(this.structuredDataModel.Entries);

        if (this.isReadOnly) {
            this.FormatStructuredDataEntries(newEntries);
        }

        if (StructuredDataHelper.ArrayEqual(currentEntries, newEntries)) {
            this.isOperationInProgress = false;
            return;
        }

        this.totalRecords = totalEntries;
        this.currentPage = page;

        this.structuredDataPreviousEntries = ObjectHelper.deepCopyJsonParse(this.structuredDataModel.Entries);
        this.structuredDataModel.Entries = newEntries;
        this.isOperationInProgress = false;
    }

    private FormatStructuredDataEntries(entries: StructuredDataEntryModel[]) {
        entries.forEach((entryModel: StructuredDataEntryModel) => {
            if (entryModel.Value) {
                //added a switch case instead we need to format other readonly values in future
                switch (entryModel.ValueType) {
                    case ValueType.Date:
                        entryModel.Value = new Date(entryModel.Value);
                }
            } else {
                if (entryModel.ChildEntries && entryModel.ChildEntries.length > 0) {
                    this.FormatStructuredDataEntries(entryModel.ChildEntries);
                }
            }
        });
    }

    private GetEditMappers(): Array<IEditOverride> {
        if (this.isMultiEntryMode) {
            const formEditStyle = this.isDialogEditMode || this.isReadOnly
                ? FormEditStyle.ReadOnly
                : FormEditStyle.Form;

            return StructuredDataMapperHelper.GetStructuredDataFormEditMappers(this.subscriptions, formEditStyle);
        } else {
            if (this.isReadOnly) {
                return StructuredDataMapperHelper.GetStructuredDataEditMappers(this._componentFactoryResolver, FormEditStyle.ReadOnly, this.subscriptions);
            } else if (this.isFormEditStyle) {
                return StructuredDataMapperHelper.GetStructuredDataEditMappers(this._componentFactoryResolver, FormEditStyle.Form, this.subscriptions);
            } else if (this.isInlineStyle) {
                return StructuredDataMapperHelper.GetStructuredDataInlineEditMappers(this._componentFactoryResolver, this.subscriptions, this._structuredDataLibraryService, this.structuredDataModel.Node);
            } else {
                throw new Error('FormEditStyle is not supported.');
            }
        }
    }

    private PopulateStructuredData(): void {
        if (this.structuredDataModel.Node && !this.loaded) {
            if (!this._structuredDataLibraryService.IsLookupConfigurationRegistered()) {
                this._structuredDataLibraryService.RegisterLookupConfiguration(this.lookups);
            }

            this._structuredDataLibraryService.RegisterInstance(
                this.instanceId,
                this.displayMappers,
                this.GetEditMappers(),
                null,

                StructuredDataMapperHelper.GetAddButtonOverride(
                    this._componentFactoryResolver,
                    this.subscriptions,
                    this.disableAddButtonSubject,
                    this.translocoService.translate('StructuredData.Widget.AddRow.Button')),

                StructuredDataMapperHelper.GetPaginateOverride(
                    this._componentFactoryResolver,
                    this.subscriptions,
                    () => this.currentPage,
                    () => this.StructuredDataListWidgetConfigurationViewDTO.PageSize,
                    () => this.totalRecords,
                    this.setPageSubject,
                    this.setTotalRecordsSubject),

                this.StructuredDataListWidgetConfigurationViewDTO.HideLabelsForEmptyValues && this.isReadOnly && this.isSingleEntryMode);

            this.loaded = true;
        }
    }

    private ProccessPaging(targetPage: number): void {
        this.isOperationInProgress = true;
        this.structuredDataFacade.LoadStructuredDataEntry(this.StructuredDataListWidgetConfigurationViewDTO.RootNodeId, {
            Limit: this.pageSize,
            OrderByList: null,
            Page: targetPage,
            ShowAll: !this.isPaginationEnabled
        });
    }
}

interface SharedStructuredDataListWidgetState {
    widgetId?: string;
    operationInProgress?: boolean;
    entries?: StructuredDataEntryModel[];
}
