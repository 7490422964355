<div [ngClass]="getContainerStyling()">
    <div [ngStyle]="getBackground()"></div>
    <ng-template ngFor let-content [ngForOf]="containerConfiguration.Contents">
        <fw-widget *ngIf="content.ContainerContentType === containerContentType.Widget"
                   [widgetConfiguration]="content.Widget"
                   [FormEditStyle]="FormEditStyle"
                   [ngClass]="getWidgetStyling(content)"></fw-widget>

        <fw-section *ngIf="content.ContainerContentType === containerContentType.Section"
                    [sectionConfiguration]="content.Section"
                    [FormEditStyle]="FormEditStyle"></fw-section>
    </ng-template>
</div>