import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorDetails } from 'src/app/models/error-details';
import { DiscussionParticipantViewDTO } from '../../../data-transfer-objects/discussion-participant/discussion-participant-view-dto';
import { DiscussionParticipantHttpService } from '../../../services/http/discussion-participant-http.service';
import { loadDiscussionParticipantsRequest, loadDiscussionParticipantsRequestDone, loadDiscussionParticipantsRequestError } from './discussion-participants.actions';

@Injectable()
export class DiscussionParticipantsEffects {

    loadDiscussionParticipants$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadDiscussionParticipantsRequest),
            switchMap((action) => {
                return this.discussionParticipantHttpService.getDiscussionParticipants(action.topicId, action.entityId, action.pageId).pipe(
                    map((participants: DiscussionParticipantViewDTO[]) => {
                        return loadDiscussionParticipantsRequestDone({ discussionParticipantsWidgetState: { DiscussionParticipants: participants, GetDiscussionParticipantsError: null } });
                    }),
                    catchError((errorDetails: ErrorDetails) => {
                        return of(loadDiscussionParticipantsRequestError({ discussionParticipantsWidgetState: { DiscussionParticipants: null, GetDiscussionParticipantsError: errorDetails } }));
                    })
                );
            })
        ));



    constructor(
        private actions$: Actions,
        private discussionParticipantHttpService: DiscussionParticipantHttpService
    ) { }

}
