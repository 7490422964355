/* eslint-disable */

import {
  EventEmitter,
  Input,
  ChangeDetectorRef,
  ViewChild,
  Output,
  Directive,
} from '@angular/core';
import { NgForm } from '@angular/forms';

@Directive()
export abstract class BaseFormComponent<TModel> {
  @ViewChild('form', { static: true })
  public Form: NgForm;

  public SubmitVisible: boolean = true;

  @Output('form-submit')
  public Submit: EventEmitter<TModel>;

  @Input('value')
  public Model: TModel;

  constructor(protected _cdr: ChangeDetectorRef) {
    this.ClearForm();
    this.Submit = new EventEmitter<TModel>();
  }

  @Input('is-disabled')
  public _disabled: boolean;

  public abstract ClearForm(): void;

  public abstract PopulateForm(): void;

  public SetDisabled(disabled: boolean): void {
    this._disabled = disabled;
    this._cdr.detectChanges();
  }
}
