import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListState, ReferenceCodeListWidgetState } from 'src/app/AppState';
import { ListFieldConfigurationViewDTO } from 'src/app/data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { ReferenceCodeQueryDefinitionConfigurationViewDTO } from 'src/app/data-transfer-objects/configuration/list-configuration/query-definitions/reference-code-query-definition-configuration-view-dto';
import { PaginationModel } from 'src/app/models/pagination-models';
import { SearchModel } from 'src/app/models/search-model';
import { AbstractListFacade } from './abstract-list.facade';

@Injectable()
export abstract class AbstractReferenceCodeListFacade extends AbstractListFacade<ReferenceCodeQueryDefinitionConfigurationViewDTO, ReferenceCodeListWidgetState> {

    public abstract EntityId: string;
    public abstract WidgetId: string;

    public abstract GetListItems(): Observable<ListState<ReferenceCodeListWidgetState>[]>;
    public abstract LoadListItems(fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, searchModel: SearchModel, queryDefinition: ReferenceCodeQueryDefinitionConfigurationViewDTO, dataSourceId: string): void;
}