import { Component, Input } from '@angular/core';
import { ColumnSectionConfigurationViewDTO } from 'src/app/data-transfer-objects/configuration/column-section-configuration-view-dto';
import { ColumnRatio } from 'src/app/enums/configuration/column-ratio';
import { FormEditStyle } from 'src/app/enums/configuration/form-edit-style';
import { UIStylingHelper } from 'src/app/helpers/ui-styling-helper';

@Component({
    selector: 'fw-column-section',
    templateUrl: './column-section.component.html'
})
export class ColumnSectionComponent {

    @Input()
    public columnSectionConfiguration: ColumnSectionConfigurationViewDTO;

    @Input()
    public formEditStyle: FormEditStyle;    

    private columnWidthWithoutLineBreaksArrayCache: string[] | undefined;
    private columnWidthWithLineBreaksArrayCache: string[] | undefined;
    private mobileColumnWidthWithoutLineBreaksArrayCache: string[] | undefined;
    private mobileColumnWidthWithLineBreaksArrayCache: string[] | undefined;
    private tabletColumnWidthWithoutLineBreaksArrayCache: string[] | undefined;
    private tabletColumnWidthWithLineBreaksArrayCache: string[] | undefined;
    private ShowLineBreak: boolean = false;
    private ShowLineBreakMobile: boolean = false;
    private ShowLineBreakTablet: boolean = false;

    get columnWidthArrayWithoutLineBreaks(): string[] {
        if (!this.columnWidthWithoutLineBreaksArrayCache) {
            this.columnWidthWithoutLineBreaksArrayCache = this.isConfigurationSet(this.columnSectionConfiguration.ColumnWidth)
                ? this.columnSectionConfiguration.ColumnWidth.split(',').map((item: string) => item.replace(ColumnRatio.LineBreak, ''))
                : []
        }

        return this.columnWidthWithoutLineBreaksArrayCache;
    }

    get columnWidthArrayWithLineBreaks(): string[] {
        if (!this.columnWidthWithLineBreaksArrayCache) {
            this.columnWidthWithLineBreaksArrayCache = this.isConfigurationSet(this.columnSectionConfiguration.ColumnWidth)
                ? this.columnSectionConfiguration.ColumnWidth.split(',')
                : []
        }

        return this.columnWidthWithLineBreaksArrayCache;
    }

    get mobileColumnWidthArrayWithoutLineBreaks(): string[] {
        if (!this.mobileColumnWidthWithoutLineBreaksArrayCache) {
            this.mobileColumnWidthWithoutLineBreaksArrayCache = this.isConfigurationSet(this.columnSectionConfiguration.MobileColumnWidth)
                ? this.columnSectionConfiguration.MobileColumnWidth.split(',').map((item: string) => item.replace(ColumnRatio.LineBreak, ''))
                : []
        }

        return this.mobileColumnWidthWithoutLineBreaksArrayCache;
    }

    get mobileColumnWidthArrayWithLineBreaks(): string[] {
        if (!this.mobileColumnWidthWithLineBreaksArrayCache) {
            this.mobileColumnWidthWithLineBreaksArrayCache = this.isConfigurationSet(this.columnSectionConfiguration.MobileColumnWidth)
                ? this.columnSectionConfiguration.MobileColumnWidth.split(',')
                : []
        }

        return this.mobileColumnWidthWithLineBreaksArrayCache;
    }

    get tabletColumnWidthArrayWithoutLineBreaks(): string[] {
        if (!this.tabletColumnWidthWithoutLineBreaksArrayCache) {
            this.tabletColumnWidthWithoutLineBreaksArrayCache = this.isConfigurationSet(this.columnSectionConfiguration.TabletColumnWidth)
                ? this.columnSectionConfiguration.TabletColumnWidth.split(',').map((item: string) => item.replace(ColumnRatio.LineBreak, ''))
                : []
        }

        return this.tabletColumnWidthWithoutLineBreaksArrayCache;
    }

    get tabletColumnWidthArrayWithLineBreaks(): string[] {
        if (!this.tabletColumnWidthWithLineBreaksArrayCache) {
            this.tabletColumnWidthWithLineBreaksArrayCache = this.isConfigurationSet(this.columnSectionConfiguration.TabletColumnWidth)
                ? this.columnSectionConfiguration.TabletColumnWidth.split(',')
                : []
        }

        return this.tabletColumnWidthWithLineBreaksArrayCache;
    }
    
    public displayLineBreak(currentContainerWithLineBreak: string): boolean {
         return currentContainerWithLineBreak != undefined && currentContainerWithLineBreak.includes(ColumnRatio.LineBreak) ? true : false; 
    }

    public getContainerClass(containerIndex: number): string {
        let stylingClass: string = '';
        const columnWidthArrayWithoutLineBreaks: string = this.columnWidthArrayWithoutLineBreaks[containerIndex];
        const mobileColumnWidthArrayWithoutLineBreaks: string = this.mobileColumnWidthArrayWithoutLineBreaks[containerIndex];
        const tabletColumnWidthArrayWithoutLineBreaks: string = this.tabletColumnWidthArrayWithoutLineBreaks[containerIndex];

        this.ShowLineBreak = this.displayLineBreak(this.columnWidthArrayWithLineBreaks[containerIndex]);
        this.ShowLineBreakMobile = this.displayLineBreak(this.mobileColumnWidthArrayWithLineBreaks[containerIndex]);
        this.ShowLineBreakTablet = this.displayLineBreak(this.tabletColumnWidthArrayWithLineBreaks[containerIndex]);

        if (this.columnSectionConfiguration !== null) {            
            if (this.columnSectionConfiguration.ColumnWidth === null || columnWidthArrayWithoutLineBreaks === null) {
                stylingClass += 'flex-1';
            } else if (columnWidthArrayWithoutLineBreaks === ColumnRatio.Auto) {
                stylingClass += ' flex-inherit';
            } else if (!this.configurationContainsPixels(columnWidthArrayWithoutLineBreaks) && !isNaN(+columnWidthArrayWithoutLineBreaks)) {
                stylingClass += ' flex-' + this.getColumnWidthRatioStyle(+columnWidthArrayWithoutLineBreaks).toString();
            }
            if (this.columnSectionConfiguration.MobileColumnWidth === null || mobileColumnWidthArrayWithoutLineBreaks === null) {
                stylingClass += ' flex-sm-1';
            } else if (mobileColumnWidthArrayWithoutLineBreaks === ColumnRatio.Auto) {
                stylingClass += ' flex-sm-inherit';
            } else if (!this.configurationContainsPixels(mobileColumnWidthArrayWithoutLineBreaks) && !isNaN(+mobileColumnWidthArrayWithoutLineBreaks)) {
                stylingClass += ' flex-sm-'+ this.getColumnWidthRatioStyle(+mobileColumnWidthArrayWithoutLineBreaks).toString();
            }
            if (this.columnSectionConfiguration.TabletColumnWidth === null || tabletColumnWidthArrayWithoutLineBreaks === null) {
                stylingClass += ' flex-md-1';
            } else if (tabletColumnWidthArrayWithoutLineBreaks === ColumnRatio.Auto) {
                stylingClass += ' flex-md-inherit';
            } else if (!this.configurationContainsPixels(tabletColumnWidthArrayWithoutLineBreaks) && !isNaN(+tabletColumnWidthArrayWithoutLineBreaks)) {
                stylingClass += ' flex-md-' + this.getColumnWidthRatioStyle(+tabletColumnWidthArrayWithoutLineBreaks).toString();
            } 
                
        }

        return stylingClass;
    }

    public getContainerStyle(containerIndex: number): object {
        let styleWidth: object = {
            width: null
        }
        const currentContainerWidth: string = this.columnWidthArrayWithoutLineBreaks[containerIndex];
        const currentMobileContainerWidth: string = this.mobileColumnWidthArrayWithoutLineBreaks[containerIndex];
        const currentTabletContainerWidth: string = this.tabletColumnWidthArrayWithoutLineBreaks[containerIndex];

        if (this.columnSectionConfiguration !== null) {
            if (this.columnSectionConfiguration.ColumnWidth !== null && this.configurationContainsPixels(currentContainerWidth) && currentContainerWidth !== null) {
                styleWidth = { width: currentContainerWidth };
            }
            if (this.columnSectionConfiguration.MobileColumnWidth !== null && this.configurationContainsPixels(currentMobileContainerWidth) && currentMobileContainerWidth !== null) {
                styleWidth = { width: currentMobileContainerWidth };
            }
            if (this.columnSectionConfiguration.TabletColumnWidth !== null && this.configurationContainsPixels(currentTabletContainerWidth) && currentTabletContainerWidth !== null) {
                styleWidth = { width: currentTabletContainerWidth };
            }
        }

        return styleWidth;
    }

    public getBackground(): object {

        return UIStylingHelper.getSectionBackground(this.columnSectionConfiguration);
    }

    public getBackgroundWidth(): string {

        return UIStylingHelper.getSectionBackgroundWidth(this.columnSectionConfiguration);
    }

    public getSectionStyling(): string {

        return UIStylingHelper.getSectionStyling(this.columnSectionConfiguration);
    }

    private isConfigurationSet(columnWidthConfiguration: string): boolean {
        return this.columnSectionConfiguration !== null && columnWidthConfiguration !== null;
    }

    private configurationContainsPixels(currentContainerWithoutLineBreak: string): boolean {
        return currentContainerWithoutLineBreak.includes(ColumnRatio.Px)
    }

    private getColumnWidthRatioStyle(flex: number): number {
        if (flex < 1 || flex > 12) {
            throw new Error(`Unsupported flex value ${flex}`);
        }

        return flex;
    }
}