import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL } from '../../helpers/base-url';
import { BehaviourContextBase } from '../../models/behaviours/context-models/behaviour-context-base';
import { BehaviourContextModel } from '../../models/behaviours/context-models/task/task-behaviour-context-model';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class BehaviourHttpService extends AuthenticatedHttpServiceBase {

    constructor(httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, errorService: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorService, toastService);
    }

    public executePageBehaviour(behaviourContextModel: BehaviourContextBase, pageId: string, assetId: string, actionId: string): Observable<null> {
        const context: BehaviourContextModel = {
            AssetId: assetId,
            ActionId: actionId,
            OnPageLoadContext: behaviourContextModel
        };

        const headers: HttpHeaders = this.AddPageIdHeader(this.GetStandardHeaders(), pageId);
        return this.PostAuthenticated<null>(`${this.baseUrl}api/basic/ExecuteBehaviour`, context, headers);
    }
}
