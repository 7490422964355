import { Injectable } from '@angular/core';
import { StructuredDataNodeModel } from '@landadmin/structured-data/lib/models/structured-data-node-model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ErrorDetails } from 'src/app/models/error-details';
import { AppState, StructuredDataNodeConfigurationState } from '../../../../AppState';
import { StructuredDataNodeHttpService } from '../../../../services/http/structured-data-node-http.service';
import { getStructuredDataNodeConfigurationState } from '../../configuration.selectors';
import { loadStructuredDataNodesDone, loadStructuredDataNodesError, loadStructuredDataNodesRequest } from './structured-data-node.actions';

@Injectable()
export class StructuredDataNodeEffects {

    loadStructuredDataNodes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadStructuredDataNodesRequest),
            withLatestFrom(
                this.store.select(getStructuredDataNodeConfigurationState)
            ),
            switchMap(([action, state]) => {

                if (state.length > 0) {

                    const structuredDataNodesState = state.find(x => x.dataSourceId === action.entityTypeId);

                    if (structuredDataNodesState) {
                        return of(structuredDataNodesState.StateModel).pipe(map(() => {
                            return loadStructuredDataNodesDone({
                                entityTypeId: action.entityTypeId,
                                structuredDataNodeConfigurationState: structuredDataNodesState.StateModel
                            });
                        }))
                    }
                }
                
                return this.structuredDataNodeHttpService.GetStructuredDataNodeConfiguration(action.pageId, action.entityTypeId).pipe(
                    map((structuredDataNodeModels: StructuredDataNodeModel[]) => {
                        return loadStructuredDataNodesDone({
                            entityTypeId: action.entityTypeId,
                            structuredDataNodeConfigurationState: this.buildStructuredDataNodeConfigurationState(null, structuredDataNodeModels)
                        });
                    }),
                    catchError((errorDetails: ErrorDetails) => {
                        return of(
                            loadStructuredDataNodesError(
                                {
                                    entityTypeId: action.entityTypeId,
                                    structuredDataNodeConfigurationState: this.buildStructuredDataNodeConfigurationState(errorDetails, null)
                                }
                            )
                        );
                    })
                );
            })
        ));

    constructor(
        private actions$: Actions,
        private structuredDataNodeHttpService: StructuredDataNodeHttpService,
        private store: Store<AppState>
    ) { }

    private buildStructuredDataNodeConfigurationState(getError: ErrorDetails, structuredDataNodeModel: StructuredDataNodeModel[]): StructuredDataNodeConfigurationState {
        return {
            getError: getError,
            viewModel: structuredDataNodeModel
        }
    }

}
