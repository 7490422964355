/* eslint-disable */

import { Input, ElementRef, Directive } from '@angular/core';
import { GenericComponentBase } from './generic-component-base';

@Directive()
export abstract class ValidationComponentBase extends GenericComponentBase {
  @Input('is-valid')
  public _isValid: boolean;

  @Input('invalid-text') _invalidText?: string;

  public _required: boolean;

  public constructor(elementReference: ElementRef) {
    super();

    if (
      (elementReference.nativeElement as HTMLElement).attributes.getNamedItem(
        'required'
      ) === null
    ) {
      this._required = false;
    } else {
      this._required = true;
    }
  }

  protected SetDefaults(): void {
    this._invalidText =
      this._invalidText === undefined ? '' : this._invalidText;
    this._isValid = this._isValid === undefined ? true : this._isValid;
    super.SetDefaults();
  }
}
