import { Injectable, Inject, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { timer, Observable, ReplaySubject, Subscriber, Subscription } from 'rxjs';

import { BASE_URL } from 'src/app/helpers/base-url';
import { ErrorManagerService } from './error-manager.service';
import { ToastService } from './toast.service';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { StandardMapStateViewDTO } from '../../data-transfer-objects/mapping/standard-map-state-view-dto';
import { MapStateCacheViewDTO } from '../../data-transfer-objects/mapping/map-state-cache-view-dto';
import { TcpfConfigurationViewDTO } from '../../data-transfer-objects/configuration/tcpf-configuration-view-dto';

@Injectable({
    providedIn: 'root'
})
export class MapService extends AuthenticatedHttpServiceBase {

    public cacheRefreshed: EventEmitter<null> = new EventEmitter<null>();

    private refreshSubscription: Subscription;
    private standardMapStateCache: ReplaySubject<StandardMapStateViewDTO> = new ReplaySubject<StandardMapStateViewDTO>(1); 
    private tcpfConfigurationCache: ReplaySubject<TcpfConfigurationViewDTO> = new ReplaySubject<TcpfConfigurationViewDTO>(1); 

    constructor(httpClient: HttpClient, @Inject(BASE_URL) private baseUrl, errorManager: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorManager, toastService);
    }

    public DestroyCachedState(): void {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
            this.refreshSubscription = undefined;
        }
    }

    public GetMapConfigurationAsync(): Observable<StandardMapStateViewDTO> {
        return this.standardMapStateCache.asObservable();
    }

    public GetTcpfConfigurationAsync(): Observable<TcpfConfigurationViewDTO> {
        return this.tcpfConfigurationCache.asObservable();
    }

    public refreshCache(): void {

        if (!this.standardMapStateCache)
            this.standardMapStateCache = new ReplaySubject<StandardMapStateViewDTO>(1);

        if (!this.tcpfConfigurationCache)
            this.tcpfConfigurationCache = new ReplaySubject<TcpfConfigurationViewDTO>(1);

        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
            this.refreshSubscription = undefined;
        }

        this.GetAuthenticated<MapStateCacheViewDTO>(`${this.baseUrl}api/config/map`, this.GetStandardHeaders()).subscribe((mapState) => {

            this.standardMapStateCache.next(mapState.StandardMapState);
            this.tcpfConfigurationCache.next(mapState.TcpfConfiguration);
            this.tcpfConfigurationCache.complete();
            
            const initialDelay: number = mapState.ExpiryInMinutes * 60000;

            const reset: Observable<number> = timer(initialDelay);

            this.refreshSubscription = reset.subscribe(() => {
                this.refreshCache();
            });

            this.cacheRefreshed.emit();

        });

    }

}
