import { createReducer, on } from '@ngrx/store';
import { ListState, PowerBIWidgetState } from '../../AppState';
import { ListStateReducerHelper } from '../../helpers/list-state-reducer-helper';
import { getEmbedTokenDone, getEmbedTokenError, reset } from './power-bi.actions';

export const initialState: ListState<PowerBIWidgetState>[] = [];

export const PowerBIReducer = createReducer<ListState<PowerBIWidgetState>[]>(initialState,
    on(getEmbedTokenDone, (state, action) => ListStateReducerHelper.GetUpdatedListState<PowerBIWidgetState>(state, action.dataSourceId, action.stateModel)),
    on(getEmbedTokenError, (state, action) => ListStateReducerHelper.GetUpdatedListState<PowerBIWidgetState>(state, action.dataSourceId, action.stateModel)),
    on(reset, () => initialState));
