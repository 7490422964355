import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CheckboxConfiguration } from '../../../../../models/configuration/widgets/properties/controls/checkbox/checkbox-configuration';
import { ValidatableInputControlBase } from '../../input-controls/validatable-input-control-base';

@Component({
    selector: 'fw-inline-edit-checkbox',
    templateUrl: './inline-edit-checkbox.component.html',
    host: { 'class': 'inline-edit' }
})
export class InlineEditCheckboxComponent extends ValidatableInputControlBase<boolean> implements OnInit {

    @Input()
    public Checkbox: CheckboxConfiguration;

    constructor(protected transloco: TranslocoService) {
        super(transloco);
    }

    ngOnInit(): void {
        this.ConfigureCheckboxValidation(this.Checkbox.Validation);        
    }
}
