import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GuidHelper } from 'src/app/helpers/guid-helper';
import { EntityShapeQueryDefinitionConfigurationDTO } from '../../data-transfer-objects/shape-draft/entity-shape-query-definition-configuration-dto';
import { ShapeDraftUpdateDTO } from '../../data-transfer-objects/shape-draft/shape-draft-dto';
import { ShapeWrapperViewDTO } from '../../data-transfer-objects/shape/shape-view-dto';
import { BASE_URL } from '../../helpers/base-url';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class ShapeDraftHttpService extends AuthenticatedHttpServiceBase {

    constructor(httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, errorService: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorService, toastService);
    }

    public getDraft(pageId: string, entityShapeQueryDefinitionConfigurationDTO: EntityShapeQueryDefinitionConfigurationDTO[]): Observable<ShapeWrapperViewDTO> {
        return this.PostAuthenticated<ShapeWrapperViewDTO>(`${this.baseUrl}api/shapedraft/${pageId}`, entityShapeQueryDefinitionConfigurationDTO, this.GetStandardHeaders());
    }

    public updateDraft(pageId: string, shapeDraftUpdateDTO: ShapeDraftUpdateDTO, typeId: string): Observable<null> {
        return this.PutAuthenticated<null>(`${this.baseUrl}api/shapedraft/${pageId}/typeId/${typeId}`, shapeDraftUpdateDTO, this.GetStandardHeaders(), []);
    }
}
