import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { ActionHttpService } from '../../services/http/action-http.service';
import { executePaymentProcessResponseDone, executeProcessPaymentResponseRequest } from './post-payment.actions';

@Injectable()
export class PostPaymentEffects {   

    executePaymentProcessResponseRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(executeProcessPaymentResponseRequest),
            switchMap((action) => {
                return this.actionHttpService
                    .ExecutePaymentBehaviour(action.postPaymentRedirectResponse)
                    .pipe(
                        map(() => {
                            return executePaymentProcessResponseDone();
                        })
                    );
            })
        )
    );

    constructor(
        private actions$: Actions,
        public actionHttpService: ActionHttpService,
    ) { }
}
