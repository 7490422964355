import { HttpStatusCode } from '../enums/http-status-codes';
import { StyleVariants } from '../enums/bootstrap/style-variants';

export class ToastNotificationSetting {

    constructor(public StatusCode: HttpStatusCode,
        public StyleVariant: StyleVariants,
        public Heading: string
    ) {

    }

    public static GetOKSetting(): ToastNotificationSetting {
        return new ToastNotificationSetting(
            HttpStatusCode.OK,
            StyleVariants.Info,
            null
        );
    }

    public static GetWarningSetting(statusCode: HttpStatusCode): ToastNotificationSetting {
        return new ToastNotificationSetting(
            statusCode,
            StyleVariants.Danger,
            null
        );
    }

}
