<div class="loader-overlay">
    <div class="loader-dots">
        <div class="dots"></div>
        <div class="dots delay1"></div>
        <div class="dots delay2"></div>
        <div class="dots delay3"></div>
    </div>
    <p *ngIf="!HideText">Loading</p>
</div>

