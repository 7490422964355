import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommodityViewDTO } from '../../../../data-transfer-objects/commodity/commodity-view-dto';
import { CommoditiesWidgetConfigurationViewDTO } from '../../../../data-transfer-objects/configuration/commodities-widget-configuration-view-dto';
import { DialogComponentBase } from '../../../../services/application/dialog-application.service';

@Component({
    selector: 'fw-select-commodities',
    templateUrl: './select-commodities.component.html'
})
export class SelectCommoditiesComponent extends DialogComponentBase<{ Configuration: CommoditiesWidgetConfigurationViewDTO, FormGroup: FormGroup, Commodities: CommodityViewDTO[] }> {

    public Commodities: CommodityViewDTO[];
    public Configuration: CommoditiesWidgetConfigurationViewDTO;
    public FormGroup: FormGroup;

    constructor(
        public dynamicDialogRef: DynamicDialogRef,
        dynamicDialogConfig: DynamicDialogConfig
    ) {
        super(dynamicDialogRef, dynamicDialogConfig);
        this.Configuration = this.dialogOptions.dataModel.Configuration;
        this.FormGroup = this.dialogOptions.dataModel.FormGroup;
        this.Commodities = this.dialogOptions.dataModel.Commodities;
    }

    cancelClicked(): void {
        this.dynamicDialogRef.close();
        this.dynamicDialogRef.destroy();
    }

    confirmClicked(): void {
        this.dynamicDialogRef.close(this.Commodities);
    }
}
