import { createAction, props } from '@ngrx/store';
import { RepresentativeWidgetState } from '../../AppState';
import { UserRepresentationListWidetConfigurationViewDTO } from '../../data-transfer-objects/configuration/user-representation-list-widget-configuration-view-dto';
import { RepresentativeOfListViewDTO } from '../../data-transfer-objects/representative/representative-list-view-dto';
import { FormEditStyle } from '../../enums/configuration/form-edit-style';

export const getRepresentativeRequest = createAction('[Representative] Get Representative Request', props<{ UserRepresentationListWidgetConfiguration: UserRepresentationListWidetConfigurationViewDTO, FormEditStyle: FormEditStyle }>());
export const getRepresentativeDone = createAction('[Representative] Get Representative Done', props<{ RepresentativeWidgetState: RepresentativeWidgetState }>());
export const getRepresentativeError = createAction('[Representative] Get Representative Error', props<{ RepresentativeWidgetState: RepresentativeWidgetState }>());

export const addRepresentativeRequest = createAction('[Representative] Add Representative Request', props<{ personId: string, representativeOfDTO: RepresentativeOfListViewDTO }>());
export const addRepresentativeDone = createAction('[Representative] Add Representative Done', props<{ addedRepresentativeId: string }>());
export const addRepresentativeError = createAction('[Representative] Add Representative Error', props<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addError: any,
    RepresentativeId: string
}>());

export const removeRepresentativeRequest = createAction('[Representative] Remove Representative Request', props<{ personId: string, representativeOfDTO: RepresentativeOfListViewDTO }>());
export const removeRepresentativeDone = createAction('[Representative] Remove Representative Done', props<{ removedRepresentativeId: string }>());
export const removeRepresentativeError = createAction('[Representative] Remove Representative Error', props<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    removeError: any,
    representativeOfDTO: RepresentativeOfListViewDTO
}>());

//reset
export const resetRepresentative = createAction('[Representative] Reset Representatives');
