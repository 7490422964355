import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { PowerBIHttpService } from '../../services/http/power-bi-http.service';
import { executePowerBIListBehaviourDone, executePowerBIListBehaviourRequest, getEmbedTokenDone, getEmbedTokenError, getEmbedTokenRequest } from './power-bi.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ErrorDetails } from 'src/app/models/error-details';
import { BehaviourContextModel } from '../../models/behaviours/context-models/task/task-behaviour-context-model';
import { BasicHttpService } from '../../services/http/basic-http';

@Injectable()
export class PowerBIEffects {

    executeListBehaviourRequest$ = createEffect(() => this.actions$.pipe(
        ofType(executePowerBIListBehaviourRequest),
        switchMap((action) => {

            const basicBehaviourContext: BehaviourContextModel = {
                AssetId: action.assetId,
                ActionId: action.actionId,
                PowerBIContext: {
                    ClientId: action.clientId,
                    PageId: action.pageId,
                    EventType: action.eventType,
                    HyperlinkId: action.hyperlinkId,
                    WidgetId: action.widgetId
                }
            };

            return this.basicHttpService.executeBasicBehaviour(basicBehaviourContext, action.widgetId, action.pageId).pipe(
                map(() => {
                    return executePowerBIListBehaviourDone();
                })
            );
        })
    ));

    getEmbedAccessToken$ = createEffect(() => this.actions$.pipe(
        ofType(getEmbedTokenRequest),
        mergeMap((action) => {

            return this.powerBIHttpService.GetEmbedAccessToken(action.embedConfiguration).pipe(
                map(token => getEmbedTokenDone({ dataSourceId: action.dataSourceId, stateModel: { EmbedAccessToken: token, GetError: null } })),
                catchError((errorDetails: ErrorDetails) => {
                    return of(getEmbedTokenError({ dataSourceId: action.dataSourceId, stateModel: { GetError: errorDetails, EmbedAccessToken: null } }))
                })
            );

        })
    ));

    constructor(
        private actions$: Actions,
        private powerBIHttpService: PowerBIHttpService,
        private basicHttpService: BasicHttpService
    ) { }
}