import { createAction, props } from '@ngrx/store';
import { UserRoleWidgetState } from '../../AppState';
import { UserRoleUpdateDTO } from '../../data-transfer-objects/role/user-role-update-dto';

//load
export const loadUserRoleRequest = createAction('[UserRole] Load UserRole Request',
    props<{
        userId: string
    }>());

export const loadUserRoleDone = createAction('[UserRole] Load UserRole Done',
    props<{
        userRoleWidgetState: UserRoleWidgetState
    }>());

export const loadUserRoleError = createAction('[UserRole] Load UserRole Error',
    props<{
        userRoleWidgetState: UserRoleWidgetState
    }>());

//update
export const updateUserRoleRequest = createAction('[UserRole] Update UserRole Request',
    props<{
        userRoleUpdateDTO: UserRoleUpdateDTO
    }>());

export const updateUserRoleDone = createAction('[UserRole] Update UserRole Done',
    props<{
        userRoleWidgetState: UserRoleWidgetState
    }>());

export const updateUserRoleError = createAction('[UserRole] Update UserRole Error',
    props<{
        userRoleWidgetState: UserRoleWidgetState
    }>());

//reset
export const resetUserRoles = createAction('[UserRole] Reset UserRoles');
