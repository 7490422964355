import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BASE_URL } from 'src/app/helpers/base-url';
import { HttpClient } from '@angular/common/http';
import { HttpServiceBase } from '../class-definitions/http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class RootHttpService extends HttpServiceBase {

    constructor(httpClient: HttpClient, @Inject(BASE_URL) private _baseUrl, errorManager: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorManager, toastService);
    }

    public GetInformation(): Observable<string> {
        return this.Get<string>(`${this._baseUrl}api/information`, this.GetStandardHeaders());
    }
}
