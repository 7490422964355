import { Component, Input } from '@angular/core';
import { TileWidgetConfiguration } from '../../../../../data-transfer-objects/configuration/tile-widget-configuration-view-dto';

@Component({
  selector: 'fw-tile-widget',
  templateUrl: './tile-widget.component.html',
})
export class TileWidgetComponent {
  @Input()
  public TileWidgetConfiguration: TileWidgetConfiguration;

  constructor() {}
}
