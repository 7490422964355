import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL } from '../../helpers/base-url';
import { BasicBehaviourContextModel } from '../../models/behaviours/context-models/basic/basic-behaviour-context-model';
import { BehaviourContextModel } from '../../models/behaviours/context-models/task/task-behaviour-context-model';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class BasicHttpService extends AuthenticatedHttpServiceBase {

    constructor(httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, errorService: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorService, toastService);
    }
    
    public executeBasicBehaviour(basicBehaviourContextModel: BehaviourContextModel, widgetId: string, pageId: string): Observable<null> {
        const headers: HttpHeaders = this.AddWidgetContextHeader(this.AddPageIdHeader(this.GetStandardHeaders(), pageId), widgetId);
        return this.PostAuthenticated<null>(`${this.baseUrl}api/basic/ExecuteBehaviour`, basicBehaviourContextModel, headers);
    }

}
