import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { ConfigurationHttpService } from 'src/app/services/http/configuration-http.service';
import { getSiteSettingsConfigurationDone, getSiteSettingsConfigurationRequest, getSiteSettingsConfigurationRequestError } from './site-settings.actions';

@Injectable()
export class SiteSettingsEffects {
    getSiteSettingsConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteSettingsConfigurationRequest),
            exhaustMap(() =>
                this.configHttpService
                    .GetSiteSettingsConfiguration()
                    .pipe(
                        map(configuration => getSiteSettingsConfigurationDone({
                            configuration: {
                                dateFormat: configuration.DateFormat,
                                calendars: configuration.Calendars
                            }
                        })),
                        catchError((err) => of(getSiteSettingsConfigurationRequestError({
                            getError: err
                        })))
                    ))
        ));

    constructor(
        private actions$: Actions,
        public configHttpService: ConfigurationHttpService) {
    }
}
