import { DocumentWidgetState, ListState } from '../AppState';
import { ObjectHelper } from './object-helper';

export class ListStateReducerHelper {
    public static GetUpdatedListState<T>(state: ListState<T>[], dataSourceId: string, stateModel: T): ListState<T>[] {
        let stateToReturn: ListState<T>[] = [];
        stateToReturn = ObjectHelper.deepCopyJsonParse(state);

        if (stateToReturn.length > 0 && stateToReturn.find((s) => s.dataSourceId === dataSourceId)) {
            const stateEntry = stateToReturn.find(
                (s) => s.dataSourceId === dataSourceId
            );
            stateEntry.StateModel = stateModel;
        } else {
            stateToReturn.push({
                dataSourceId: dataSourceId,
                StateModel: stateModel,
            });
        }

        return stateToReturn;
    }

    public static GetListState<T>(state: ListState<T>[], dataSourceId: string): T {
        return state.find(s => s.dataSourceId == dataSourceId)?.StateModel;
    }

    public static removeListItemFromState<T>(state: ListState<T>[], dataSourceId: string, itemIds: string[]): ListState<T>[] {
        const stateToReturn = ObjectHelper.deepCopyJsonParse(state);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (stateToReturn.length > 0 && stateToReturn.find((s: any) => s.dataSourceId === dataSourceId)
        ) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const stateEntry = stateToReturn.find((s: any) => s.dataSourceId === dataSourceId);

            itemIds.forEach((itemId: string) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const index = stateEntry.StateModel.paginationResult.Models.findIndex((item: any) => item.Id === itemId);
                stateEntry.StateModel.paginationResult.Models.splice(index, 1);
            });
        }

        return stateToReturn;
    }

    public static removeDocumentListItemFromState(listState: ListState<DocumentWidgetState>[], dataSourceId: string, documentId: string): ListState<DocumentWidgetState>[] {

        const listStateCopy: ListState<DocumentWidgetState>[] = ObjectHelper.deepCopyJsonParse(listState);
        const listStateIndex = listStateCopy.findIndex((item) => item.dataSourceId === dataSourceId);
        const documentIndex = listStateCopy[listStateIndex].StateModel.DocumentDrafts.findIndex((item) => item.Id === documentId);
        listStateCopy[listStateIndex].StateModel.DocumentDrafts.splice(documentIndex, 1);
        listStateCopy[listStateIndex].StateModel.DocumentRemoved = true;

        //these aren't reset hence having to explicitly setting it to false
        listStateCopy[listStateIndex].StateModel.DocumentAdded = false;
        listStateCopy[listStateIndex].StateModel.DocumentReplaced = false;
        return listStateCopy;
    }
}
