import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { SiteSettingsService } from "../services/deprecated/site-settings.service";
import { LanguageHttpService } from "../services/http/language-http.service";
import { HijriDateHelper } from "./date-helper";

@Injectable()
export class HijriCalendareDateFormatter extends NgbDateParserFormatter {

    constructor(
        private siteSettingsService: SiteSettingsService,
        private languageHttpService: LanguageHttpService
    ) {
        super();
    }

    parse(value: string): NgbDateStruct {

        //this will be implemented in future should there be a client requirement to capture dates manually
        throw new Error('Method not implemented.');
    }
    format(date: NgbDateStruct): string {

        if (date) {
            return HijriDateHelper.formatDate(date.day, date.month, date.year, this.siteSettingsService.dateFormat, this.languageHttpService.getCurrentLanguageLocale());
        }

        return '';
    }
}