import { createAction, props } from '@ngrx/store';
import { CompanyListState } from '../../AppState';
import { UserRepresentationListWidetConfigurationViewDTO } from '../../data-transfer-objects/configuration/user-representation-list-widget-configuration-view-dto';
import { FormEditStyle } from '../../enums/configuration/form-edit-style';

export const getCompaniesRequest = createAction('[Companies] Get Companies Request', props<{ UserRepresentationListWidgetConfiguration: UserRepresentationListWidetConfigurationViewDTO, FormEditStyle: FormEditStyle }>());
export const getCompaniesDone = createAction('[Companies] Get Companies Done', props<{ CompanyListState: CompanyListState }>());
export const getCompaniesError = createAction('[Companies] Get Companies Error', props<{ CompanyListState: CompanyListState }>());

//reset
export const resetCompanies = createAction('[Companies] Reset Companies');
