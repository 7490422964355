import { Injectable } from '@angular/core';
import { StructuredDataEntryModel } from '@landadmin/structured-data/lib/models/structured-data-entry-model';
import { BehaviorSubject, Observable, Subject, Subscriber } from 'rxjs';
import { ListState, StructuredDataListWidgetState } from '../../AppState';
import { FormEditStyle } from '../../enums/configuration/form-edit-style';
import { PaginationModel } from '../../models/pagination-models';
import { RowUpdateStyle } from '../../enums/configuration/row-update-style';

@Injectable()
export abstract class AbstractStructuredDataEntryFacade {

    public abstract _enablePaging: boolean;
    public abstract _entityId: string;
    public abstract _entityTypeId: string;
    public abstract _formEditStyle: FormEditStyle;
    public abstract _pageId: string;
    public abstract _setLoadSubject: BehaviorSubject<boolean>;

    public abstract CreateStructuredDataEntry(rootNodeId: string, structuredDataEntryModel: StructuredDataEntryModel): void;

    public abstract DeleteStructuredDataEntry(rootNodeId: string, structuredDataEntryModel: StructuredDataEntryModel): void;

    public abstract LoadStructuredDataEntry(rootNodeId: string, paginationModel: PaginationModel): void;
    
    public abstract LoadStructuredDataEntrySelector(): Observable<ListState<StructuredDataListWidgetState>[]>;

    public abstract ResetState(): void;

    public abstract UpdateStructuredDataEntry(rootNodeId: string, structuredDataEntryModel: StructuredDataEntryModel): void;
}
