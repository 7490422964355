export enum WidgetType {
    ContentBlock = 1,
    Tile = 2,
    Task = 3,
    AssetDetails = 4,
    UserDetails = 5,
    UserList = 6,
    Map = 7,
    RepresentativeList = 8,
    DocumentList = 9,
    Commodity = 10,
    AssetList = 11,
    ActionDetails = 12,
    ActionList = 13,
    StructuredDataList = 14,
    Discussions = 15,
    RelatedAssetList = 16,
    UserRoles = 17,
    SessionDetails = 18,
    PartyList = 19,
    PowerBI = 20,
    ReferenceCodeList = 21,
    MapReferenceList = 22,
}
