<fw-inline-edit-dropdown *ngIf="FormEditStyle === FormEditStyles.Inline || FormEditStyle === FormEditStyles.ReadOnly"
                         [CustomFormGroup]="CustomFormGroup"
                         [PropertyName]="Property.PropertyName"
                         [Label]="Property.LabelResourceId"
                         [LabelStyle]="Property.LabelStyle"
                         [ValueStyle]="Property.ValueStyle"
                         [Dropdown]="Property.Dropdown"
                         [AcceptChanges]="AcceptChanges"
                         [FormEditStyle]="FormEditStyle">
</fw-inline-edit-dropdown>
<fw-form-edit-dropdown *ngIf="FormEditStyle === FormEditStyles.Form"
                         [CustomFormGroup]="CustomFormGroup"
                         [PropertyName]="Property.PropertyName"
                         [Label]="Property.LabelResourceId"
                         [LabelStyle]="Property.LabelStyle"
                         [Dropdown]="Property.Dropdown"
                         [AcceptChanges]="AcceptChanges">
</fw-form-edit-dropdown>