import { createAction, props } from '@ngrx/store';
import { DiscussionParticipantsWidgetState } from '../../../AppState';

//participants
export const loadDiscussionParticipantsRequest = createAction('[Discussions] Load Discussion Participants Request',
    props<{
        topicId: string,
        pageId: string,
        entityId: string
    }>());

export const loadDiscussionParticipantsRequestDone = createAction('[Discussions] Load Discussion Participants Done',
    props<{
        discussionParticipantsWidgetState: DiscussionParticipantsWidgetState
    }>());

export const loadDiscussionParticipantsRequestError = createAction('[Discussions] Load Discussion Participants Error',
    props<{
        discussionParticipantsWidgetState: DiscussionParticipantsWidgetState
    }>());

//reset
export const resetDiscussionParticipants = createAction('[Discussions] Reset Discussion Participants');
