import { createReducer, on } from '@ngrx/store';
import { StructuredDataLookupConfigurationState } from '../../../../AppState';
import { loadStructuredDataLookupsDone, loadStructuredDataLookupsError, resetStructuredDataLookUps } from './structured-data-look-up.actions';


//Assets
export const initialStructuredDataLookupConfigurationState: StructuredDataLookupConfigurationState = {
    getError: null,
    viewModel: null
};

export const StructuredDataLookupReducer = createReducer<StructuredDataLookupConfigurationState>(initialStructuredDataLookupConfigurationState,
    on(loadStructuredDataLookupsDone, (_, action) => action.structuredDataLookupConfigurationState),
    on(loadStructuredDataLookupsError, (_, action) => action.structuredDataLookupConfigurationState),
    on(resetStructuredDataLookUps, () => initialStructuredDataLookupConfigurationState)
);


