import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import {
    UserDetailsWidgetConfiguration
} from '../../../../../data-transfer-objects/configuration/user-details-widget-configuration-view-dto';
import { Subscription } from 'rxjs';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { UserPersonViewDTO } from '../../../../../data-transfer-objects/user/user-person-view-dto';
import { ToastService } from '../../../../../services/deprecated/toast.service';
import { ConfigurableControlsHelper } from '../../../../../helpers/configurable-controls-helper';
import { AbtractUserDetailsFacade } from '../../../../../facade/abstract/abstract-user-details.facade';
import { HttpStatusCode } from '../../../../../enums/http-status-codes';
import { UIStylingHelper } from '../../../../../helpers/ui-styling-helper';
import { BaseDetailsWidget } from '../base-details-widget';

@Component({
    selector: 'fw-user-details-widget',
    templateUrl: './user-details-widget.component.html'
})

export class UserDetailsWidgetComponent extends BaseDetailsWidget<UserPersonViewDTO> implements OnInit, OnDestroy {

    public AcceptChanges: EventEmitter<void> = new EventEmitter<void>();
    public AcceptGroupChanges: EventEmitter<void> = new EventEmitter<void>();
    public DiscardChanges: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public FormEditStyle: FormEditStyle;

    @Input()
    public UserDetailsWidgetConfiguration: UserDetailsWidgetConfiguration;

    private AcceptChangesSubscription: Subscription;
    private AcceptGroupChangesSubscription: Subscription;
    private DiscardChangesSubscription: Subscription;
    private GetUserDetailsSubscription: Subscription;
    private UpdateUserError: Subscription;
    private UpdateUserDetailError: Subscription;

    private acceptedChangedExecutedFromThisWidget: boolean = false;
    private acceptedGroupChanges: boolean = false;

    constructor(private userDetailsFacade: AbtractUserDetailsFacade,
        private toastService: ToastService,
        public elementRef: ElementRef) {
        super(elementRef);
    }

    ngOnDestroy() {

        super.ngOnDestroy();

        this.GetUserDetailsSubscription.unsubscribe();
        this.AcceptChangesSubscription.unsubscribe();
        this.AcceptGroupChangesSubscription.unsubscribe();
        this.DiscardChangesSubscription.unsubscribe();
        this.UpdateUserError.unsubscribe();
        this.UpdateUserDetailError.unsubscribe();

        this.userDetailsFacade.ResetUserDetails();
    }

    ngOnInit(): void {

        super.ngOnInit();

        this.userDetailsFacade.LoadUserDetails();

        this.UpdateUserError = this.userDetailsFacade.UpdateUserError().subscribe(error => {
            if (error && error.successfull === false) {

                if (this.acceptedChangedExecutedFromThisWidget === true) {
                    this.toastService.ShowErrorToast(error, [{
                        Message: 'UserDetails.Form.GenericSaveError',
                        RouterLink: null,
                        RouterText: null,
                        QueryParameters: null,
                        MessageParameters: null
                    }], true);

                    this.acceptedChangedExecutedFromThisWidget = false;
                }

                this.userDetailsFacade.LoadUserDetails();
            }
        });

        this.UpdateUserDetailError = this.userDetailsFacade.UserDetailsSelector().subscribe(state => {
            if (state.UpdateUserDetailsError && state.UpdateUserDetailsError.status !== HttpStatusCode.NOT_FOUND) {
                this.toastService.ShowErrorToast(state.UpdateUserDetailsError, [{
                    Message: 'UserDetails.Form.GenericSaveError',
                    RouterLink: null,
                    RouterText: null,
                    QueryParameters: null,
                    MessageParameters: null
                }], true)
            }
        });

        this.GetUserDetailsSubscription = this.userDetailsFacade.UserDetailsSelector().subscribe(userDetailsWidgetState => {

            this.DataModel = userDetailsWidgetState.UserDetailsViewDTO;

            if (userDetailsWidgetState) {
                if (userDetailsWidgetState.GetUserDetailsError === null) {

                    this.DataModel = userDetailsWidgetState.UserDetailsViewDTO;

                    if (this.acceptedChangedExecutedFromThisWidget === false || this.acceptedGroupChanges === true) {
                        ConfigurableControlsHelper.PopulateFormControl(this.DataModel, this.UserDetailsWidgetConfiguration.Properties, this.FormGroup);
                    }

                    this.acceptedChangedExecutedFromThisWidget = false;
                    this.acceptedGroupChanges = false;
                }
            }
        });

        this.AcceptChangesSubscription = this.AcceptChanges.subscribe(() => {
            this.acceptedChangedExecutedFromThisWidget = true;
            this.saveAcceptedChanges();
            this.convertHijriDatesToGregorian();
            this.userDetailsFacade.UpdateUser(this.DataModel);
        });

        this.AcceptGroupChangesSubscription = this.AcceptGroupChanges.subscribe(() => {
            this.acceptedGroupChanges = true;
            this.saveAcceptedChanges();
            this.convertHijriDatesToGregorian();
            this.userDetailsFacade.UpdateAndGetUser(this.DataModel);
        });

        this.DiscardChangesSubscription = this.DiscardChanges.subscribe(() => {
            ConfigurableControlsHelper.PopulateFormControl(this.DataModel, this.UserDetailsWidgetConfiguration.Properties, this.FormGroup);
        });
    }

    private saveAcceptedChanges(): void {
        const userDtoFromForm = ConfigurableControlsHelper.ResolveGroupPropertyValues(this.UserDetailsWidgetConfiguration, this.FormGroup);

        this.DataModel = {
            ...this.DataModel, ...userDtoFromForm,
            Address: {
                ...this.DataModel.Address, ...userDtoFromForm.Address
            }
        }
    }

    public getLayoutMode(): string {
        return UIStylingHelper.getLayoutMode(this.UserDetailsWidgetConfiguration.LayoutMode)
    }

    public isReadOnly(): boolean {
        return this.isWidgetReadOnly(this.UserDetailsWidgetConfiguration);
     }
}
