import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';
import { RepresentativeWidgetState } from '../../../../../AppState';
import { DynamicPageIdParameter } from '../../../../../consts/portal-base-routes';
import { severity_error, severity_success } from '../../../../../consts/severity-options';
import { RepresentativeListWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/representative-list-widget-configuration-view-dto';
import { UserRepresentationListWidetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/user-representation-list-widget-configuration-view-dto';
import { RepresentativeOfListViewDTO } from '../../../../../data-transfer-objects/representative/representative-list-view-dto';
import { RepresentativeListWidgetDataModel } from '../../../../../data-transfer-objects/representative/representative-list-widget-data-model';
import { OrderByDirection } from '../../../../../enums/comparator-enum';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { PaginationModel } from '../../../../../models/pagination-models';
import { RepresentativeModel } from '../../../../../models/representative-model';
import {
    DialogApplicationService,
    DialogOptions
} from '../../../../../services/application/dialog-application.service';
import { SessionApplicationService } from '../../../../../services/application/session-application.service';
import { ToastApplicationService } from '../../../../../services/application/toast-application.service';
import { AddRepresentationComponent } from '../../../../static/forms/add-representation/add-representation.component';
import { AbstractRepresentativeListFacade } from '../../../../../facade/abstract/abstract-representative-list.facade';
import { TranslationHelper } from 'src/app/helpers/translation-helper';
import { UIStylingHelper } from 'src/app/helpers/ui-styling-helper';

@Component({
    selector: 'fw-representative-list-widget',
    templateUrl: './representative-list-widget.component.html'
})
export class RepresentativeListWidgetComponent implements OnInit, OnDestroy {
    RepresentativeListWidgetDataModel: RepresentativeListWidgetDataModel;
    SelectedRepresentative: RepresentativeOfListViewDTO = null;

    public first: number = 0;
    public formEditStyle = FormEditStyle;
    public loading: boolean;
    public representatives: RepresentativeModel[] = [];
    public totalRecords: number;
    public currentPageReportTemplate: string;

    @Input()
    public RepresentativeListWidgetConfiguration: RepresentativeListWidgetConfigurationViewDTO;

    private _subcriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private sessionApplicationService: SessionApplicationService,
        private dialogApplicationService: DialogApplicationService,
        private translocoService: TranslocoService,
        private transloco: TranslocoService,
        private confirmationService: ConfirmationService,
        private representativeListFacade: AbstractRepresentativeListFacade,
        public toastApplicationService: ToastApplicationService
    ) 
    {
        this.currentPageReportTemplate = this.translocoService.translate(TranslationHelper.CurrentPageReportTemplateResourceId);
    }

    addRepresentation() {
        const dialogOptions: DialogOptions<{ RepresentativeListWidgetConfiguration: RepresentativeListWidgetConfigurationViewDTO, PageId: string }
        > = {
            closable: true,
            footer: '',
            header: this.translocoService.translate('AddRepresentation.Form.Title'),
            dataModel: { PageId: this.representativeListFacade.PageId, RepresentativeListWidgetConfiguration: this.RepresentativeListWidgetConfiguration },            
            showHeader: true,
            styleClass: 'dialog-sm',
        };

        this.dialogApplicationService.showFormDialog(
            AddRepresentationComponent,
            dialogOptions
        );
    }

    loadRepresentatives(event: LazyLoadEvent) {
        this.loading = true;

        this.representativeListFacade.UserRepresentationListWidgetConfiguration = this.buildRepresentationListWidgetConfiguration(event);
        this.representativeListFacade.LoadRepresentatives();
    }

    getTableStyleClass(): string {
        let tableStyleClass = '';

        if (this.RepresentativeListWidgetDataModel?.totalRecords <= this.RepresentativeListWidgetConfiguration?.PageSize) {
            tableStyleClass += UIStylingHelper.hidePagingStyleClass;
        }

        return tableStyleClass;
    }

    ngOnDestroy() {
        if (this._subcriptions) {
            this._subcriptions.forEach((subcription) => {
                if (subcription) {
                    subcription.unsubscribe();
                }
            });
        }
        this.representativeListFacade.ResetState();
    }

    ngOnInit(): void {
        if (this.RepresentativeListWidgetDataModel == null) {
            this.RepresentativeListWidgetDataModel =
                new RepresentativeListWidgetDataModel();
            this.RepresentativeListWidgetDataModel.representatives = [];
            this.RepresentativeListWidgetDataModel.totalRecords = 0;
            this.RepresentativeListWidgetDataModel.FormEditStyle = FormEditStyle.Inline;
        }

        this._subcriptions.push(this.representativeListFacade
            .GetRepresentatives()
            .subscribe((representativeWidgetState: RepresentativeWidgetState) => {
                if (representativeWidgetState.AddError) {
                    this.toastApplicationService.showToast('Representative.List.AddError', 'Representative.List.AddError.Detail', severity_error);
                } else if (representativeWidgetState.RemoveError) {
                    this.toastApplicationService.showToast('Representative.List.DeleteError', 'Representative.List.DeleteError.Detail', severity_error);
                } else if (representativeWidgetState.GetError) {
                    this.toastApplicationService.showToast('Representative.List.GetError', 'Representative.List.GetError.Detail', severity_error);
                } else {
                    this.RepresentativeListWidgetDataModel = representativeWidgetState.Representatives;
                    this.loading = false;
                }
            }));
    }

    removeRepresentation(id: string) {
        this.confirmationService.confirm({
            message: this.transloco.translate(
                'Representation.List.RemoveDialogMessage'
            ),
            header: this.transloco.translate(
                'Representation.List.RemoveDialogHeader'
            ),
            accept: () => {
                this.representativeListFacade.RemoveRepresentative(
                    this.sessionApplicationService.currentUser.PersonId,
                    this.RepresentativeListWidgetDataModel.representatives.find(r => r.Id === id)
                );

                this.toastApplicationService.showToast('Representation.List.RemoveRepresentation', 'Representation.List.RemovedSuccesfully', severity_success);
            },
        });
    }

    private buildRepresentationListWidgetConfiguration(event?: LazyLoadEvent) {
        const paginationModel: PaginationModel = new PaginationModel();
        paginationModel.Limit = this.RepresentativeListWidgetConfiguration.PageSize;
        paginationModel.Page = event.first / event.rows + 1;
        paginationModel.OrderByList = [
            {
                Field:
                    this.RepresentativeListWidgetConfiguration.Fields[0].PropertyName,
                OrderByDirection: OrderByDirection.Ascending,
            },
        ];

        const fieldList: Array<string> = new Array<string>();
        this.RepresentativeListWidgetConfiguration.Fields.forEach(function (
            listFieldConfigurationViewDTO
        ) {
            fieldList.push(listFieldConfigurationViewDTO.PropertyName);
        });

        const userRepresentationListWidgetConfiguration: UserRepresentationListWidetConfigurationViewDTO =
        {
            PersonId: this.sessionApplicationService.currentUser.PersonId,
            PageId: this.route.snapshot.params[DynamicPageIdParameter],
            WidgetId: this.RepresentativeListWidgetConfiguration.Id,
            Fields: fieldList,
            PaginationModel: paginationModel,
        };

        return userRepresentationListWidgetConfiguration;
    }

    public getLayoutMode(): string {
        return UIStylingHelper.getLayoutMode(this.RepresentativeListWidgetConfiguration.LayoutMode)
    }
}
