import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { LookupModel } from '../../../../../models';
import { DialogComponentBase } from '../../../../../services/application/dialog-application.service';

@Component({
    selector: 'fw-select-document-type',
    templateUrl: './select-document-type.component.html'
})
export class SelectDocumentTypeComponent extends DialogComponentBase<LookupModel[]> implements OnInit {

    public currentDocumentType: string = null;
    public documentTypes: LookupModel[];

    constructor(
        private dynamicDialogRef: DynamicDialogRef,
        private dynamicDialogConfig: DynamicDialogConfig) {
        super(dynamicDialogRef, dynamicDialogConfig);
    }

    public cancelClicked() {
        this.dynamicDialogRef.close();
        this.dynamicDialogRef.destroy();
    }

    ngOnInit(): void {
        this.documentTypes = this.dialogOptions.dataModel;
        this.currentDocumentType = this.documentTypes[0].Id;
    }

    public proceedClicked() {
        const selectedDocumentType: LookupModel = this.documentTypes.find((documentType) => documentType.Id === this.currentDocumentType);
        this.dynamicDialogRef.close(selectedDocumentType);
    }
}
