import { ActionReducerMap } from '@ngrx/store';
import { WidgetState } from '../AppState';
import { ActionDetailsReducer } from './action/action.reducers';
import { AssetDetailsReducer } from './asset/asset.reducers';
import { CommodityReducer } from './commodity/commodity.reducers';
import { CompanyReducer } from './company/company.reducers';
import { DiscussionCommentsReducer } from './discussion/discussion-comments/discussion-comments.reducer';
import { DiscussionParticipantsReducer } from './discussion/discussion-participants/discussion-participants.reducer';
import { DiscussionsReducer } from './discussion/discussion.reducer';
import { PartyListReducer } from './party/party.reducers';
import { PowerBIReducer } from './power-bi/power-bi.reducers';
import { ReferenceCodeListReducer } from './referenceCode/reference-code.reducers';
import { RelatedAssetListReducer } from './related-asset/related-assets.reducers';
import { RepresentativeReducer } from './representative/representative.reducers';
import { StructuredDataListReducer } from './structured-data/structured-data.reducers';
import { UserRoleReducer } from './user-role/user-role.reducers';
import { MapReferenceListReducer } from './map-reference/map-reference.reducers';
import { StateCacheReducer } from './state-cache/state-cache.reducers';

export const WidgetsReducerMapper: ActionReducerMap<WidgetState> = {
    structuredDataListWidgetState: StructuredDataListReducer,
    discussionCommentsWidgetState: DiscussionCommentsReducer,
    discussionParticipantsWidgetState: DiscussionParticipantsReducer,
    assetWidgetState: AssetDetailsReducer,
    actionWidgetState: ActionDetailsReducer,
    discussionTopicListWidgetState: DiscussionsReducer,
    relatedAssetListWidgetState: RelatedAssetListReducer,
    userRoleWidgetState: UserRoleReducer,
    partyListWidgetState: PartyListReducer,
    commodityWidgetState: CommodityReducer,
    representativeWidgetState: RepresentativeReducer,
    companyListState: CompanyReducer,
    powerBIWidgetState: PowerBIReducer,
    referenceCodeListWidgetState: ReferenceCodeListReducer,
    mapReferenceListWidgetState: MapReferenceListReducer,
    widgetStateCache: StateCacheReducer
};
