<div [class.is-readonly]="isReadOnly">
    <div class="view-mode" (keydown.enter)="OpenModal()" tabindex="0">
        <label>
            {{UserRolesWidgetConfigurationViewDTO?.LabelResourceId | transloco}}
        </label>
        <div>
            <div class="view-fields">{{CurrentRoles}}<span (click)="OpenModal()">
                    <button *ngIf="FormEditStyle !== FormEditStyles.ReadOnly && !isReadOnly" tabindex="-1" class="pi pi-pencil"></button>
                </span>
            </div>
        </div>
    </div>
</div>
<ng-template #formEditUserRoles>
    <fw-form-edit-user-roles [UserRolesWidgetConfigurationViewDTO]="UserRolesWidgetConfigurationViewDTO"
                             [CustomFormGroup]="CustomFormGroup"
                             [AcceptChanges]="AcceptChanges"
                             [SelectedOptions]="Options"
                             [ReadyToStorePreviousValue]="readyToStorePreviousValue"
                             [FormEditStyle]="FormEditStyle"></fw-form-edit-user-roles>
</ng-template>