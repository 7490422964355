<div class="topic-list">
    <span class="topic-list-header">{{DiscussionListWidgetConfigurationViewDTO.HeaderResourceId | transloco}}</span>
    <p-button [disabled]="IsReadOnly" styleClass="btn btn-sm disc-btn-new" type="button" label="{{DiscussionListWidgetConfigurationViewDTO.NewDiscussionButtonResourceId | transloco }}" (onClick)="newDiscussionClicked()"></p-button>
    <fw-list (OnLoad)="OnLoad($event)"
             (SelectableFieldClicked)="LoadTopic($event)"
             [ListConfigurationViewDTO]="DiscussionListWidgetConfigurationViewDTO"
             [ErrorMessageResourceId]="errorMessageResourceId"
             [PaginationResultModel]="PaginationResultModel"
             [IsUserList]="false"
             [IsSelectable]="true"
             [selectMode]="selectMode"
             [autoSelectFirstItem]="true"
             [IsPageable]="false"
             [CustomBodyTemplate]="CustomListBodyTemplate">
    </fw-list>
</div>

<ng-template #CustomListBodyTemplate let-data>
    <td>
        <div class="d-flex">
            <span class="topic-name" title="{{data['Topic']}}">{{data['Topic']}}</span>
            <span class="topic-date">{{data['LastUpdated'] | localeDateFormat: { stringDate: true } }}</span>
        </div>
        <div class="topic-comment" title="{{StripHtml(data['LatestComment'])}}">{{StripHtml(data['LatestComment'])}}</div>
    </td>
</ng-template>



