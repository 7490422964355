<div>
    <div class="view-mode" [attr.tabindex]="FormEditStyle === FormEditStyles.ReadOnly ? -1 : 0" (keydown.enter)="OpenCommodityModal()">
        <label>
            {{'InlineEditCommodity.Label' | transloco}}
            <span class="required" *ngIf="Configuration.Required && editMode">*</span>
        </label>
        <div [formGroup]="CustomFormGroup">
            <div class="view-fields">
                <p-chips [disabled]="isReadOnly" [formControlName]="PropertyName" [placeholder]="('InlineEditCommodity.Placeholder' | transloco)" (onRemove)="RemoveChip()">
                    <ng-template let-item pTemplate="item">{{GetCommodityName(item)}}</ng-template>
                </p-chips>
                <span *ngIf="!isReadOnly" (click)="OpenCommodityModal()">
                    <button tabindex="-1" class="pi pi-pencil"></button>
                </span>
            </div>
        </div>
    </div>
</div>