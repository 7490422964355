import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState, AssetListWidgetStateModel, ListState } from '../../AppState';
import { AssetSearchModel } from '../../data-transfer-objects/asset/asset-search-dto';
import { ListFieldConfigurationViewDTO } from '../../data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { AssetQueryDefinitionSearchViewDTO } from '../../data-transfer-objects/configuration/list-configuration/query-definitions/asset-query-definition-search-view-dto';
import { PaginationModel } from '../../models/pagination-models';
import { SearchModel } from '../../models/search-model';
import { AbstractAssetListFacade } from '../abstract/abstract-asset-list.facade';
import { executeListBehaviourRequest, getBasicAssetsBySearchRequest } from '../../state/deprecated/basic-page/basic-page.actions';
import { ListFilterViewDTO } from '../../data-transfer-objects/filter/list-filter-view-dto';
import { take } from 'rxjs/operators';

@Injectable()
export class AssetListWidgetFacade extends AbstractAssetListFacade {
    public PageId: string;

    public AssetId: string = null;

    constructor(private store: Store<AppState>) {
        super();
    }

    public ExecuteBehaviour(propertyName: string, widgetId: string, assetId?: string, actionId?: string, userId?: string): void {
        this.signalRLoadedObservable.subscribe(signalr => {
            signalr.ClientId.pipe(take(1)).subscribe(clientId => {
                this.store.dispatch(executeListBehaviourRequest({
                    pageId: this._behaviourContext.PageId,
                    clientId: clientId,
                    propertyName: propertyName,
                    widgetId: widgetId,
                    listWidgetAssetId: assetId,
                    listWidgetActionId: actionId
                }));
            })
        });
    }

    public GetListItems(): Observable<ListState<AssetListWidgetStateModel>[]> {
        const assetListWidgetState = this.store.pipe(select(state => state.assetListWidgetState));

        return assetListWidgetState;
    }

    public LoadListItems(fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, searchModel: SearchModel, assetQueryDefinition: AssetQueryDefinitionSearchViewDTO, dataSourceId: string,
        filters?: ListFilterViewDTO): void {

        const assetSearchDTO: AssetSearchModel = {
            assetQueryDefinition: assetQueryDefinition,
            searchModel: searchModel,
            filters: filters
        };

        this.store.dispatch(getBasicAssetsBySearchRequest({
            fields: fields,
            assetSearchDTO: assetSearchDTO,
            dataSourceId: dataSourceId,
            paginationModel: paginationModel
        }));
    }

    //TODO: to be removed as part of LFF-1320
    public LoadUserListItems() {
    }

}
