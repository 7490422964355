/* eslint-disable @typescript-eslint/no-explicit-any */
export class ObjectHelper {

    //Creates a deep copy of an object
    public static deepCopy<T>(target: T): T {
        if (target === null) {
            return target;
        } else if (target instanceof Date) {
            return new Date(target.getTime()) as any;
        } else if (target instanceof Array) {
            const copy = [] as any[];
            (target as any[]).forEach((v) => { copy.push(v); });
            return copy.map((n: any) => this.deepCopy<any>(n)) as any;
        } else if (typeof target === 'object' && target !== {}) {
            const copy = { ...(target as { [key: string]: any }) } as { [key: string]: any };
            Object.keys(copy).forEach(k => {
                copy[k] = this.deepCopy<any>(copy[k]);
            });
            return copy as T;
        } else if (typeof target === 'string')
            return target;
        else if (typeof target === 'boolean')
            return target;
        else if (typeof target === 'number')
            return target
        else
            return null;
    };

    public static deepCopyJsonParse(objectToCopy) {
        return JSON.parse(JSON.stringify(objectToCopy));
    }

    //Creates a shallow copy of an object
    public static shallowCopy<T>(target: T): T {
        return Object.assign({}, target);
    };
}
