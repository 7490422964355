import { createAction, props } from '@ngrx/store';
import { PowerBIWidgetState } from '../../AppState';
import { EmbedConfigurationViewDTO } from '../../data-transfer-objects/power-bi/embed-configuration-view-dto';
import { PowerBIEventType } from '../../enums/power-bi-event-type';

export const getEmbedTokenRequest = createAction('[PowerBI] Get Embed Token Request', props<{ embedConfiguration: EmbedConfigurationViewDTO, dataSourceId: string }>());
export const getEmbedTokenDone = createAction('[PowerBI] Get Embed Token Done', props<{ stateModel: PowerBIWidgetState, dataSourceId: string }>());
export const getEmbedTokenError = createAction('[PowerBI] Get Embed Token Error', props<{ stateModel: PowerBIWidgetState, dataSourceId: string }>());
export const reset = createAction('[PowerBI] Reset');

export const executePowerBIListBehaviourRequest = createAction('[PowerBI] Execute PowerBI List Behaviour Request', props<{ eventType: PowerBIEventType, widgetId: string, clientId: string, pageId: string, assetId: string, actionId: string, hyperlinkId?: string }>());
export const executePowerBIListBehaviourDone = createAction('[PowerBI] Execute PowerBI List Behaviour Done');
