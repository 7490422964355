<div [ngClass]="{
        'toast' : true,
        'toast-slide': true,
        'toast-primary': ToastVariant === 1,
        'toast-secondary': ToastVariant === 2,
        'toast-success': ToastVariant === 3,
        'toast-danger': ToastVariant === 4,
        'toast-warning' : ToastVariant === 5,
        'toast-info' : ToastVariant === 6,
        'toast-light' : ToastVariant === 7,
        'toast-dark':  ToastVariant === 8}" role="alert" data-delay="5000" data-autohide="true">
    <button *ngIf="ShowCloseButton" (click)="onClose()" type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="toast-loading" *ngIf="LoadingText && !Translate">
        <p-progressSpinner *ngIf="IsLoading"></p-progressSpinner>
        <span>{{LoadingText}}</span>
    </div>
    <div class="toast-loading" *ngIf="LoadingText && Translate">
        <p-progressSpinner *ngIf="IsLoading"></p-progressSpinner>
        <span>{{ LoadingText | transloco }}</span>
    </div>

    <ng-template ngFor let-message [ngForOf]="Messages">

        <div *ngIf="Translate">
            <p *ngIf="message.PreventNavigate">
                {{ message.Message | transloco: message.MessageParameters }}
                &nbsp;
                <a href="javascript:void(0);" *ngIf="message.RouterLink" (click)="onClose()">{{ message.RouterTextTranslationResourceId| transloco }}</a>
            </p>
            <p *ngIf="!message.PreventNavigate">
                {{ message.Message | transloco: message.MessageParameters }}
                &nbsp;
                <a *ngIf="message.RouterLink" [routerLink]="message.RouterLink" [queryParams]="message.QueryParameters" (click)="onClose()">{{ message.RouterText }}</a>
            </p>
        </div>
        <div *ngIf="!Translate">
            <p *ngIf="message.PreventNavigate">
                {{ message.Message}}
                &nbsp;
                <a href="javascript:void(0);" *ngIf="message.RouterLink" (click)="onClose()">{{ message.RouterTextTranslationResourceId}}</a>
            </p>
            <p *ngIf="!message.PreventNavigate">
                {{ message.Message}}
                &nbsp;
                <a *ngIf="message.RouterLink" [routerLink]="message.RouterLink" [queryParams]="message.QueryParameters" (click)="onClose()">{{ message.RouterText }}</a>
            </p>
        </div>
    </ng-template>
</div>
