import { ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { StructuredDataLibraryService, ValueType } from '@landadmin/structured-data';
import { IAddOverride } from '@landadmin/structured-data/lib/interfaces/i-add-override';
import { IEditOverride } from '@landadmin/structured-data/lib/interfaces/i-edit-override';
import { IPaginationOverride } from '@landadmin/structured-data/lib/interfaces/i-pagination-override';
import { LookupOptionListModel } from '@landadmin/structured-data/lib/models/lookup-option-list-model';
import { StructuredDataAddOverride } from '@landadmin/structured-data/lib/models/structured-data-add-override';
import { StructuredDataEntryUnitValueModel } from '@landadmin/structured-data/lib/models/structured-data-entry-unit-value-model';
import { StructuredDataEntryValueModel } from '@landadmin/structured-data/lib/models/structured-data-entry-value-model';
import { StructuredDataNodeModel } from '@landadmin/structured-data/lib/models/structured-data-node-model';
import { PaginatorPageChange, StructuredDataPaginationOverride } from '@landadmin/structured-data/lib/models/structured-data-pagination-override';
import { StructuredDataEditOverride } from '@landadmin/structured-data/lib/models/structured-date-edit-override';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { FormEditStyle } from '../enums/configuration/form-edit-style';
import { ConfigurableControlsHelper } from '../helpers/configurable-controls-helper';
import { GuidHelper } from '../helpers/guid-helper';
import { LookupModel } from '../models';
import { PaginatorComponent } from '../modules/dynamic/controls/general/paginator/paginator.component';
import { InlineEditInputNumberComponent } from '../modules/dynamic/controls/inline-edit/inline-edit-input-number/inline-edit-input-number.component';
import { InlineEditTextFieldComponent } from '../modules/dynamic/controls/inline-edit/inline-edit-text-field/inline-edit-text-field.component';
import { LookupCellComponent } from '../modules/dynamic/controls/structured-data-cell-overrides/option-list-cell/look-up-cell.component';
import { UnitValueComponent } from '../modules/dynamic/controls/structured-data-cell-overrides/unit-value-cell/unit-value-cell.component';
import { YesNoCellComponent } from '../modules/dynamic/controls/structured-data-cell-overrides/yes-no-cell/yes-no-cell.component';
import { CalendarWrapperComponent } from '../modules/dynamic/controls/wrappers/calendar-wrapper/calendar-wrapper.component';
import { PortalLinkComponent } from '../modules/static/generics/components/portal-link/portal-link.component';

export class StructuredDataMapperHelper {

    public static GetAddButtonOverride(componentFactoryResolver: ComponentFactoryResolver, subscriptions: Subscription[], behaviourSubject: BehaviorSubject<boolean>, addButtonText: string): IAddOverride {

        const portalLinkFactory = componentFactoryResolver.resolveComponentFactory(PortalLinkComponent);
        const addOverride: Subject<StructuredDataAddOverride> = new Subject<StructuredDataAddOverride>();

        const addOverrideInterface: IAddOverride = {
            Add: addOverride
        };

        addOverride.subscribe((model: StructuredDataAddOverride) => {

            const addOverrideInstance = model.ViewReference.createComponent(portalLinkFactory);

            (addOverrideInstance.location.nativeElement as HTMLElement).onclick = () => {
                model.FireDelete.next();
            };
            //todo
            (addOverrideInstance.location.nativeElement as HTMLElement).innerHTML = addButtonText;

            subscriptions.push(behaviourSubject.subscribe((disableAddButton: boolean) => {
                if (disableAddButton === true) {
                    (addOverrideInstance.location.nativeElement as HTMLElement).className = 'btn sd-btn-add disable-link';
                } else {
                    (addOverrideInstance.location.nativeElement as HTMLElement).className = 'btn sd-btn-add';
                }
            }));

            addOverrideInstance.changeDetectorRef.detectChanges();
        });

        return addOverrideInterface;
    }

    public static GetPaginateOverride(componentFactoryResolver: ComponentFactoryResolver, subscriptions: Subscription[], getPage: () => number, getLimit: () => number, getTotalRecords: () => number, setPageSubject: Subject<number>, updateTotalRecordsSubject: Subject<number>): IPaginationOverride {

        const paginationFactory = componentFactoryResolver.resolveComponentFactory(PaginatorComponent);
        const paginateOverride: Subject<StructuredDataPaginationOverride> = new Subject<StructuredDataPaginationOverride>();

        const paginationOverrideInterface: IPaginationOverride = {
            OnPageChange: paginateOverride
        };

        paginateOverride.subscribe((model: StructuredDataPaginationOverride) => {
            const paginateOverrideInstance = model.ViewReference.createComponent(paginationFactory);

            setPageSubject.subscribe((page: number) => {
                paginateOverrideInstance.instance.updateCurrentPage(page);
            });

            updateTotalRecordsSubject.subscribe((totalRecords: number) => {
                paginateOverrideInstance.instance.updateTotalRecords(totalRecords);
            });

            paginateOverrideInstance.instance.first = (getPage() - 1) * getLimit();
            paginateOverrideInstance.instance.rows = getLimit();
            paginateOverrideInstance.instance.totalRecords = getTotalRecords();
            paginateOverrideInstance.instance.paginationSubject.subscribe((paginatorPageChange: PaginatorPageChange) => {
                model.PageChangeSubject.next(paginatorPageChange);
            });

            paginateOverrideInstance.changeDetectorRef.detectChanges();
        });

        return paginationOverrideInterface;
    }

    public static GetStructuredDataEditMappers(componentFactoryResolver: ComponentFactoryResolver, formEditStyle: FormEditStyle, subscriptions: Subscription[]): Array<IEditOverride> {

        const factory = componentFactoryResolver.resolveComponentFactory(CalendarWrapperComponent);
        const structuredDataEditOverride: Subject<StructuredDataEditOverride> = new Subject<StructuredDataEditOverride>();

        subscriptions.push(structuredDataEditOverride.subscribe((override: StructuredDataEditOverride) => {
            const propertyName = 'InputCalendar';
            const formGroup: FormGroup = new FormGroup({});
            const abstractControl: FormControl = new FormControl({});

            const componentInstance: ComponentRef<CalendarWrapperComponent> = override.ViewReference.createComponent(factory);
            componentInstance.instance.ShouldShowLabel = false;

            if (override.Entry.Value) {
                abstractControl.setValue(new Date(override.Entry.Value));
            } else {
                abstractControl.setValue('');
            }

            formGroup.addControl(propertyName, abstractControl);
            componentInstance.instance.CustomFormGroup = formGroup;
            componentInstance.instance.Property = {
                PropertyName: propertyName, Calendar: { Validation: { Required: false }, IsHijri: false }
            };
            componentInstance.instance.FormEditStyle = formEditStyle;

            subscriptions.push(componentInstance.instance.AcceptChanges.subscribe(() => {

                let date;

                if ((formGroup.value[propertyName] as NgbDate)?.year) {
                    date = ConfigurableControlsHelper.ConvertHijriToGregorian(formGroup.value[propertyName]);
                } else {
                    date = formGroup.value[propertyName] as Date;
                }
                
                override.Entry.Value = date;
                override.FireEdit.next(override.Entry);
            }));

            subscriptions.push(componentInstance.instance.IsHijriChanged.subscribe((prop) => {
                if (override.Entry.Value) {
                    abstractControl.setValue(ConfigurableControlsHelper.GetConfiguredCalendarDate(new Date(override.Entry.Value), prop.Calendar?.IsHijri ?? false));
                } else {
                    abstractControl.setValue('');
                }
            }));

            componentInstance.changeDetectorRef.detectChanges();
        }));

        const editOverride: Array<IEditOverride> = [{
            Edit: structuredDataEditOverride,
            ValueType: ValueType.Date
        }];

        return editOverride;
    }

    public static GetStructuredDataFormEditMappers(subscriptions: Subscription[], formEditStyle: FormEditStyle): Array<IEditOverride> {

        const formCalendarOverride = this.BuildFormDateControl(subscriptions, formEditStyle);
        const editOverrides: Array<IEditOverride> = [formCalendarOverride]
        return editOverrides;
    }

    public static GetStructuredDataInlineEditMappers(componentFactoryResolver: ComponentFactoryResolver, subscriptions: Subscription[], structuredDataLibraryService: StructuredDataLibraryService, nodeModel: StructuredDataNodeModel): Array<IEditOverride> {

        const inlineDateControl = this.BuildInlineDateControl(componentFactoryResolver, subscriptions);
        const inlineNumberOverride = this.BuildInlineNumberControls(componentFactoryResolver, subscriptions);
        const inlineTextOverride = this.BuildInlineTextControl(componentFactoryResolver, subscriptions);
        const inlineUnitControl = this.BuildInlineUnitControls(componentFactoryResolver, subscriptions, structuredDataLibraryService);
        const optionListControl = this.BuildInlineLookupControls(componentFactoryResolver, subscriptions, structuredDataLibraryService, nodeModel);

        let editOverrides: Array<IEditOverride> = [inlineTextOverride, inlineDateControl]

        editOverrides = editOverrides.concat(inlineUnitControl);
        editOverrides = editOverrides.concat(optionListControl);
        editOverrides = editOverrides.concat(inlineNumberOverride);

        return editOverrides;

    }


    private static BuildFormDateControl(subscriptions: Subscription[], formEditStyle: FormEditStyle): IEditOverride {

        //Should ensure date works.
        const structuredDataEditOverride: Subject<StructuredDataEditOverride> = new Subject<StructuredDataEditOverride>();

        subscriptions.push(structuredDataEditOverride.subscribe((override: StructuredDataEditOverride) => {
            const propertyName = 'InputCalendar';
            const formGroup: FormGroup = new FormGroup({});
            const abstractControl: FormControl = new FormControl({});
            const componentInstance = override.ViewReference.createComponent(CalendarWrapperComponent);

            componentInstance.instance.ShouldShowLabel = false;

            if (override?.Entry?.Value) {
                abstractControl.setValue(new Date(override.Entry.Value));
            } else {
                abstractControl.setValue('');
            }

            formGroup.addControl(propertyName, abstractControl);
            componentInstance.instance.CustomFormGroup = formGroup;
            componentInstance.instance.Property = {
                PropertyName: propertyName, Calendar: { Validation: { Required: false }, IsHijri: false }
            };
            componentInstance.instance.FormEditStyle = formEditStyle;

            subscriptions.push(componentInstance.instance.AcceptChanges.subscribe(() => {

                let date;

                if ((formGroup.value[propertyName] as NgbDate)?.year) {
                    date = ConfigurableControlsHelper.ConvertHijriToGregorian(formGroup.value[propertyName]);
                } else {
                    date = formGroup.value[propertyName] as Date;
                }

                override.Entry.Value = date;
                override.FireEdit.next(override.Entry);
            }));

            subscriptions.push(componentInstance.instance.IsHijriChanged.subscribe((prop) => {
                if (override.Entry.Value) {
                    abstractControl.setValue(ConfigurableControlsHelper.GetConfiguredCalendarDate(new Date(override.Entry.Value), prop.Calendar?.IsHijri ?? false));
                } else {
                    abstractControl.setValue('');
                }
            }));

            componentInstance.changeDetectorRef.detectChanges();
        }));

        return {
            Edit: structuredDataEditOverride,
            ValueType: ValueType.Date
        };
    }

    //Inline
    private static BuildInlineTextControl(componentFactoryResolver: ComponentFactoryResolver, subscriptions: Subscription[]): IEditOverride {

        const factory = componentFactoryResolver.resolveComponentFactory(InlineEditTextFieldComponent);
        const structuredDataEditOverride: Subject<StructuredDataEditOverride> = new Subject<StructuredDataEditOverride>();

        subscriptions.push(structuredDataEditOverride.subscribe((override: StructuredDataEditOverride) => {
            const propertyName = 'InputText';
            const formGroup: FormGroup = new FormGroup({});
            const abstractControl: FormControl = new FormControl({});

            abstractControl.setValue(override.Entry.Value);

            const componentInstance: ComponentRef<InlineEditTextFieldComponent> = override.ViewReference.createComponent(factory);
            componentInstance.instance.ShouldShowLabel = false;

            if (override.Entry.Value) {
                abstractControl.setValue(override.Entry.Value);
            }

            formGroup.addControl(propertyName, abstractControl);
            componentInstance.instance.CustomFormGroup = formGroup;
            componentInstance.instance.PropertyName = propertyName;

            subscriptions.push(componentInstance.instance.AcceptChanges.subscribe(() => {
                override.Entry.Value = formGroup.value['InputText'];
                override.FireEdit.next(override.Entry);
            }));

            componentInstance.changeDetectorRef.detectChanges();
        }));

        const editOverride: IEditOverride =
        {
            Edit: structuredDataEditOverride,
            ValueType: ValueType.Text
        };

        return editOverride;
    }
    private static BuildInlineDateControl(componentFactoryResolver: ComponentFactoryResolver, subscriptions: Subscription[]): IEditOverride {

        //Should ensure date works.
        const factory = componentFactoryResolver.resolveComponentFactory(CalendarWrapperComponent);
        const structuredDataEditOverride: Subject<StructuredDataEditOverride> = new Subject<StructuredDataEditOverride>();

        subscriptions.push(structuredDataEditOverride.subscribe((override: StructuredDataEditOverride) => {
            const propertyName = 'InputCalendar';
            const abstractControl: FormControl = new FormControl({});
            const componentInstance: ComponentRef<CalendarWrapperComponent> = override.ViewReference.createComponent(factory);


            componentInstance.instance.CustomFormGroup = new FormGroup({});
            componentInstance.instance.ShouldShowLabel = false;

            if (override.Entry.Value) {
                abstractControl.setValue(new Date(override.Entry.Value));
            } else {
                abstractControl.setValue('');
            }

            componentInstance.instance.CustomFormGroup.addControl(propertyName, abstractControl);
            componentInstance.instance.Property = {
                PropertyName: propertyName, Calendar: { Validation: { Required: false }, IsHijri: false }
            };
            componentInstance.instance.FormEditStyle = FormEditStyle.Inline;

            subscriptions.push(componentInstance.instance.AcceptChanges.subscribe(() => {
                let date;

                if ((componentInstance.instance.CustomFormGroup.value[propertyName] as NgbDate)?.year) {
                    date = ConfigurableControlsHelper.ConvertHijriToGregorian(componentInstance.instance.CustomFormGroup.value[propertyName]);
                } else {
                    date = componentInstance.instance.CustomFormGroup.value[propertyName] as Date;
                }

                override.Entry.Value = date;
                override.FireEdit.next(override.Entry);
            }));

            subscriptions.push(componentInstance.instance.IsHijriChanged.subscribe((prop) => {
                if (override.Entry.Value) {
                    abstractControl.setValue(ConfigurableControlsHelper.GetConfiguredCalendarDate(new Date(override.Entry.Value), prop.Calendar?.IsHijri ?? false));
                } else {
                    abstractControl.setValue('');
                }
            }));

            componentInstance.changeDetectorRef.detectChanges();
        }));

        const editOverride: IEditOverride =
        {
            Edit: structuredDataEditOverride,
            ValueType: ValueType.Date
        };

        return editOverride;
    }
    private static BuildInlineNumberControls(componentFactoryResolver: ComponentFactoryResolver, subscriptions: Subscription[]): IEditOverride[] {

        const structuredDataNumberEditOverride: Subject<StructuredDataEditOverride> = new Subject<StructuredDataEditOverride>();
        const structuredDataPercentEditOverride: Subject<StructuredDataEditOverride> = new Subject<StructuredDataEditOverride>();

        subscriptions.push(structuredDataNumberEditOverride.subscribe((override: StructuredDataEditOverride) => {
            this.BuildInlineNumberControl(componentFactoryResolver, subscriptions, override, false);

        }));

        subscriptions.push(structuredDataPercentEditOverride.subscribe((override: StructuredDataEditOverride) => {
            this.BuildInlineNumberControl(componentFactoryResolver, subscriptions, override, true);

        }));

        const editOverride: IEditOverride[] = [
            {
                Edit: structuredDataNumberEditOverride,
                ValueType: ValueType.Number
            },
            {
                Edit: structuredDataPercentEditOverride,
                ValueType: ValueType.Percent
            }

        ];

        return editOverride;
    }
    private static BuildInlineUnitControls(componentFactoryResolver: ComponentFactoryResolver, subscriptions: Subscription[], structuredDataLibraryService: StructuredDataLibraryService): IEditOverride[] {

        const structuredDataEditCurrencyOverride: Subject<StructuredDataEditOverride> = new Subject<StructuredDataEditOverride>();
        const structuredDataEditDistanceOverride: Subject<StructuredDataEditOverride> = new Subject<StructuredDataEditOverride>();
        const structuredDataEditWeightOverride: Subject<StructuredDataEditOverride> = new Subject<StructuredDataEditOverride>();
        const structuredDataEditVolumeOverride: Subject<StructuredDataEditOverride> = new Subject<StructuredDataEditOverride>();
        const structuredDataEditTimeIntervalOverride: Subject<StructuredDataEditOverride> = new Subject<StructuredDataEditOverride>();
        const structuredDataEditAreaOverride: Subject<StructuredDataEditOverride> = new Subject<StructuredDataEditOverride>();

        subscriptions.push(structuredDataEditCurrencyOverride.subscribe((override: StructuredDataEditOverride) => {
            const lookups = structuredDataLibraryService.GetLookup(ValueType.Currency) as LookupModel[];
            this.BuildInlineUnitControl(componentFactoryResolver, subscriptions, lookups, override)
        }));

        subscriptions.push(structuredDataEditDistanceOverride.subscribe((override: StructuredDataEditOverride) => {
            const lookups = structuredDataLibraryService.GetLookup(ValueType.Distance) as LookupModel[];
            this.BuildInlineUnitControl(componentFactoryResolver, subscriptions, lookups, override)
        }));

        subscriptions.push(structuredDataEditWeightOverride.subscribe((override: StructuredDataEditOverride) => {
            const lookups = structuredDataLibraryService.GetLookup(ValueType.Weight) as LookupModel[];
            this.BuildInlineUnitControl(componentFactoryResolver, subscriptions, lookups, override)
        }));

        subscriptions.push(structuredDataEditVolumeOverride.subscribe((override: StructuredDataEditOverride) => {
            const lookups = structuredDataLibraryService.GetLookup(ValueType.Volume) as LookupModel[];
            this.BuildInlineUnitControl(componentFactoryResolver, subscriptions, lookups, override)
        }));

        subscriptions.push(structuredDataEditTimeIntervalOverride.subscribe((override: StructuredDataEditOverride) => {
            const lookups = structuredDataLibraryService.GetLookup(ValueType.TimeInterval) as LookupModel[];
            this.BuildInlineUnitControl(componentFactoryResolver, subscriptions, lookups, override)
        }));

        subscriptions.push(structuredDataEditAreaOverride.subscribe((override: StructuredDataEditOverride) => {
            const lookups = structuredDataLibraryService.GetLookup(ValueType.Area) as LookupModel[];
            this.BuildInlineUnitControl(componentFactoryResolver, subscriptions, lookups, override)
        }));

        const editOverride: IEditOverride[] = [
            {
                Edit: structuredDataEditCurrencyOverride,
                ValueType: ValueType.Currency
            },
            {
                Edit: structuredDataEditDistanceOverride,
                ValueType: ValueType.Distance
            },
            {
                Edit: structuredDataEditWeightOverride,
                ValueType: ValueType.Weight
            },
            {
                Edit: structuredDataEditVolumeOverride,
                ValueType: ValueType.Volume
            },
            {
                Edit: structuredDataEditTimeIntervalOverride,
                ValueType: ValueType.TimeInterval
            },
            {
                Edit: structuredDataEditAreaOverride,
                ValueType: ValueType.Area
            }
        ];

        return editOverride;
    }
    private static BuildInlineYesNoOverrideControl(componentFactoryResolver: ComponentFactoryResolver, subscriptions: Subscription[], lookups: LookupModel[], override: StructuredDataEditOverride): void {
        const factory = componentFactoryResolver.resolveComponentFactory(YesNoCellComponent);
        const entryModelId = 'Id';

        const formGroup: FormGroup = new FormGroup({});
        const entryModelIdControl: FormControl = new FormControl({});

        if (override.Entry.Value) {
            const id = override.Entry.Value;
            entryModelIdControl.setValue(id.toString());
        } else {
            entryModelIdControl.setValue(GuidHelper.EmptyGuid());
        }


        const componentInstance: ComponentRef<YesNoCellComponent> = override.ViewReference.createComponent(factory);
        componentInstance.instance.Options = lookups;

        formGroup.addControl(entryModelId, entryModelIdControl);

        componentInstance.instance.CustomFormGroup = formGroup;
        componentInstance.instance.entryModelId = entryModelId;

        subscriptions.push(componentInstance.instance.AcceptChanges.subscribe(() => {
            const structuredDataEntryValueModelId = formGroup.controls[entryModelId].value;

            override.Entry.Value = structuredDataEntryValueModelId;
            override.FireEdit.next(override.Entry);
        }));

        componentInstance.changeDetectorRef.detectChanges();
    }
    private static BuildInlineOptionListControl(componentFactoryResolver: ComponentFactoryResolver, subscriptions: Subscription[], lookups: LookupModel[], override: StructuredDataEditOverride): void {
        const factory = componentFactoryResolver.resolveComponentFactory(LookupCellComponent);
        const entryModelValue = 'Value';
        const entryModelId = 'Id';

        const formGroup: FormGroup = new FormGroup({});
        const entryModelValueControl: FormControl = new FormControl({});
        const entryModelIdControl: FormControl = new FormControl({});

        if (override.Entry.Value) {
            const id = override.Entry.Value.Id;
            const value = override.Entry.Value.Value;

            entryModelIdControl.setValue(id);
            entryModelValueControl.setValue(value);
        } else {
            entryModelValueControl.setValue(' ');
            entryModelIdControl.setValue('00000000-0000-0000-0000-000000000000');
        }


        const componentInstance: ComponentRef<LookupCellComponent> = override.ViewReference.createComponent(factory);


        componentInstance.instance.Options = lookups;


        if (override.Entry.Value) {
            entryModelIdControl.setValue(override.Entry.Value.Id);
            entryModelValueControl.setValue(override.Entry.Value.Value);
        }

        formGroup.addControl(entryModelValue, entryModelValueControl);
        formGroup.addControl(entryModelId, entryModelIdControl);

        componentInstance.instance.CustomFormGroup = formGroup;
        componentInstance.instance.entryModelValue = entryModelValue;
        componentInstance.instance.entryModelId = entryModelId;

        subscriptions.push(componentInstance.instance.AcceptChanges.subscribe(() => {

            const structuredDataEntryValueModelValue = formGroup.controls[entryModelValue].value;
            const structuredDataEntryValueModelId = formGroup.controls[entryModelId].value;

            const structuredDataEntryUnitValueModel: StructuredDataEntryValueModel = {
                Id: structuredDataEntryValueModelId,
                Value: structuredDataEntryValueModelValue
            }

            override.Entry.Value = structuredDataEntryUnitValueModel;
            override.FireEdit.next(override.Entry);

        }));

        componentInstance.changeDetectorRef.detectChanges();
    }
    private static BuildInlineUnitControl(componentFactoryResolver: ComponentFactoryResolver, subscriptions: Subscription[], lookups: LookupModel[], override: StructuredDataEditOverride): void {
        const factory = componentFactoryResolver.resolveComponentFactory(UnitValueComponent);
        const unitDropDownValue = 'UnitDropDownValue';
        const unitDropDownValueId = 'UnitDropDownValueId';
        const unitValue = 'UnitValue';
        const unitDecimalValue = 'UnitDecimalValue';

        const formGroup: FormGroup = new FormGroup({});
        const unitDropDownValueControl: FormControl = new FormControl({});
        const unitDropValueControl: FormControl = new FormControl({});
        const unitDecimalValueControl: FormControl = new FormControl({});
        const unitDropDownValueIdControl: FormControl = new FormControl({});

        if (override.Entry.Value) {
            const unitId = override.Entry.Value.UnitId;
            const unit = override.Entry.Value.Unit;
            const value = override.Entry.Value.Value;
            const unitDecimalPlaces = override.Entry.Value.UnitDecimalPlaces;

            unitDropDownValueControl.setValue(unit);
            unitDropValueControl.setValue(value);
            unitDecimalValueControl.setValue(unitDecimalPlaces);
            unitDropDownValueIdControl.setValue(unitId);
        } else {
            unitDropDownValueControl.setValue('');
            unitDropValueControl.setValue('');
            unitDecimalValueControl.setValue('0');
            unitDropDownValueIdControl.setValue('00000000-0000-0000-0000-000000000000');
        }

        const componentInstance: ComponentRef<UnitValueComponent> = override.ViewReference.createComponent(factory);
        componentInstance.instance.Options = lookups;

        if (override.Entry.Value) {
            unitDropDownValueControl.setValue(override.Entry.Value.Unit);
            unitDropValueControl.setValue(override.Entry.Value.Value);
            unitDecimalValueControl.setValue(override.Entry.Value.UnitDecimalPlaces);
            unitDropDownValueIdControl.setValue(override.Entry.Value.UnitId);
        }

        formGroup.addControl(unitDropDownValue, unitDropDownValueControl);
        formGroup.addControl(unitValue, unitDropValueControl);
        formGroup.addControl(unitDecimalValue, unitDecimalValueControl);
        formGroup.addControl(unitDropDownValueId, unitDropDownValueIdControl);

        componentInstance.instance.CustomFormGroup = formGroup;
        componentInstance.instance.unitDropDownValue = unitDropDownValue;
        componentInstance.instance.unitValue = unitValue;
        componentInstance.instance.unitDecimalValue = unitDecimalValue;
        componentInstance.instance.unitDropDownValueId = unitDropDownValueId;

        subscriptions.push(componentInstance.instance.AcceptChanges.subscribe(() => {

            const unitDropDownValueData = formGroup.controls[unitDropDownValue].value;
            const unitDropDownValueDataId = formGroup.controls[unitDropDownValueId].value;
            const unitValueData = formGroup.controls[unitValue].value;
            const unitDecimalValueData = formGroup.controls[unitDecimalValue].value;

            const structuredDataEntryUnitValueModel: StructuredDataEntryUnitValueModel = {
                Unit: unitDropDownValueData,
                UnitDecimalPlaces: unitDecimalValueData,
                UnitId: unitDropDownValueDataId,
                Value: unitValueData
            }

            override.Entry.Value = structuredDataEntryUnitValueModel;
            override.FireEdit.next(override.Entry);
        }));

        componentInstance.changeDetectorRef.detectChanges();
    }
    private static BuildInlineLookupControls(componentFactoryResolver: ComponentFactoryResolver, subscriptions: Subscription[], structuredDataLibraryService: StructuredDataLibraryService, nodeModel: StructuredDataNodeModel): IEditOverride[] {

        const structuredDataOptionListOverride: Subject<StructuredDataEditOverride> = new Subject<StructuredDataEditOverride>();
        const structuredDataCommodityOverride: Subject<StructuredDataEditOverride> = new Subject<StructuredDataEditOverride>();
        const structuredDataYesNoOverride: Subject<StructuredDataEditOverride> = new Subject<StructuredDataEditOverride>();

        subscriptions.push(structuredDataOptionListOverride.subscribe((override: StructuredDataEditOverride) => {

            const optionsListModel = structuredDataLibraryService.GetLookup(ValueType.OptionList) as LookupOptionListModel[];
            const optionListId = this.FindOptionListId(override.Entry.StructuredDataNodeId, nodeModel);
            const lookups = optionsListModel.find((lookupOptionList: LookupOptionListModel) => {
                return lookupOptionList.Id === optionListId;
            }).Items;

            this.BuildInlineOptionListControl(componentFactoryResolver, subscriptions, lookups, override)
        }));

        subscriptions.push(structuredDataCommodityOverride.subscribe((override: StructuredDataEditOverride) => {

            const lookups = structuredDataLibraryService.GetLookup(ValueType.Commodity) as LookupModel[];
            this.BuildInlineOptionListControl(componentFactoryResolver, subscriptions, lookups, override)
        }));

        subscriptions.push(structuredDataYesNoOverride.subscribe((override: StructuredDataEditOverride) => {

            const lookups = structuredDataLibraryService.GetLookup(ValueType.YesNo) as LookupModel[];
            this.BuildInlineYesNoOverrideControl(componentFactoryResolver, subscriptions, lookups, override)
        }));

        const editOverride: IEditOverride[] = [
            {
                Edit: structuredDataOptionListOverride,
                ValueType: ValueType.OptionList
            },
            {
                Edit: structuredDataCommodityOverride,
                ValueType: ValueType.Commodity
            },
            {
                Edit: structuredDataYesNoOverride,
                ValueType: ValueType.YesNo
            }
        ];

        return editOverride;
    }
    private static BuildInlineNumberControl(componentFactoryResolver: ComponentFactoryResolver, subscriptions: Subscription[], override: StructuredDataEditOverride, showPercentage: boolean) {
        const propertyName = 'InputNumber';
        const formGroup: FormGroup = new FormGroup({});
        const abstractControl: FormControl = new FormControl({});
        const factory = componentFactoryResolver.resolveComponentFactory(InlineEditInputNumberComponent);

        abstractControl.setValue(override.Entry.Value);

        const componentInstance: ComponentRef<InlineEditInputNumberComponent> = override.ViewReference.createComponent(factory);
        componentInstance.instance.ShouldShowLabel = false;
        componentInstance.instance.ShowPercentage = showPercentage;

        if (override.Entry.Value) {
            abstractControl.setValue(override.Entry.Value);
        }

        formGroup.addControl(propertyName, abstractControl);
        componentInstance.instance.CustomFormGroup = formGroup;
        componentInstance.instance.PropertyName = propertyName;

        subscriptions.push(componentInstance.instance.AcceptChanges.subscribe(() => {
            override.Entry.Value = formGroup.value['InputNumber']
            override.FireEdit.next(override.Entry);
        }));

        componentInstance.changeDetectorRef.detectChanges();
    }

    private static FindOptionListId(structuredDataNodeId: string, nodeModel: StructuredDataNodeModel): string {

        const structuredDataNode = nodeModel.ChildNodes.find(x => x.Id === structuredDataNodeId);

        return structuredDataNode.ValueOption.OptionListId;


    }


}
