import { createAction, props } from '@ngrx/store'
import { PageConfigurationState, StructuredDataLookupConfigurationState } from '../../../AppState';


//LOAD STRUCTURED DATA
export const loadPageConfigurationRequest = createAction('[SiteConfiguration] Load Page Configuration Request',
    props<{
        pageId: string
    }>());

export const loadPageConfigurationDone = createAction('[SiteConfiguration] Load Page Configuration Done',
    props<{
        pageId: string,
        pageConfigurationState: PageConfigurationState
    }>());

export const loadPageConfigurationError = createAction('[SiteConfiguration] Load Page Configuration Error',
    props<{
        pageId: string,
        pageConfigurationState: PageConfigurationState
    }>());

//RESET STATE
export const resetPageConfiguration = createAction('[SiteConfiguration] Reset Load Page Configuration');
