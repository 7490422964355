import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PortalBaseRoutes } from './consts/portal-base-routes';


@NgModule({
    imports: [RouterModule.forRoot(PortalBaseRoutes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})

export class AppRoutingModule { }
