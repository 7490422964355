<div [formGroup]="CustomFormGroup">
    <fw-general-validator [PropertyName]="PropertyName"
                          [(CustomFormGroup)]="CustomFormGroup"
                          [(ValidationDictionary)]="ValidationDictionary"></fw-general-validator>
    <input type="text" pInputText [formControlName]="PropertyName" (input)="onTextValueChange($event.target.value)" />
    <ng-template [ngIf]="ShowLabel()">
	    <label [ngClass]="GetLabelStylingClass()">
	        {{Label | transloco}}
	        <span *ngIf="TextField.Validation.Required">*</span>
	    </label>
    </ng-template>
</div>