import { createReducer, on } from '@ngrx/store';
import { UserRoleWidgetState } from '../../AppState';
import { loadUserRoleDone, loadUserRoleError, resetUserRoles, updateUserRoleDone, updateUserRoleError } from './user-role.actions';

export const initialUserRoleState: UserRoleWidgetState = { Error: null, Roles: null };

export const UserRoleReducer = createReducer<UserRoleWidgetState>(initialUserRoleState,
    on(loadUserRoleDone, (state, action) => action.userRoleWidgetState),
    on(loadUserRoleError, (state, action) => action.userRoleWidgetState),
    on(updateUserRoleDone, (state, action) => action.userRoleWidgetState),
    on(updateUserRoleError, (state, action) => action.userRoleWidgetState),
    on(resetUserRoles, () => initialUserRoleState)
);
