<div [formGroup]="CustomFormGroup">
    <fw-general-validator [PropertyName]="PropertyName"
                    [(CustomFormGroup)]="CustomFormGroup"
                    [(ValidationDictionary)]="ValidationDictionary"></fw-general-validator>
    <p-toggleButton [formControlName]="PropertyName" onIcon="pi pi-check" offIcon="pi" (focusout)="onAcceptChanges()"></p-toggleButton>
    <ng-template [ngIf]="ShowLabel()">
	    <label [ngClass]="GetLabelStylingClass()">
	        {{Label | transloco}}
	        <span *ngIf="Checkbox.Validation.Required">*</span>
	    </label>
    </ng-template>
</div>