import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserHttpService } from '../../services/http/user-http.service';
import { RepresentativesHttpService } from '../../services/http/representatives-http.service';
import { of } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { getRepresentativeRequest, removeRepresentativeDone, removeRepresentativeError, removeRepresentativeRequest, getRepresentativeDone, addRepresentativeError, addRepresentativeDone, getRepresentativeError, addRepresentativeRequest } from './representative.actions';
import { PaginationResultModel } from '../../models/pagination-models';
import { RepresentativeOfListViewDTO } from '../../data-transfer-objects/representative/representative-list-view-dto';
import { RepresentativeListWidgetDataModel } from '../../data-transfer-objects/representative/representative-list-widget-data-model';
import { CompanyHttpService } from '../../services/http/company-http.service';
import { ErrorDetails } from 'src/app/models/error-details';

@Injectable()
export class RepresentativeEffects {

    getRepresentatives$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getRepresentativeRequest),
            switchMap((action) => {
                return this.userService
                    .getRepresentations(action.UserRepresentationListWidgetConfiguration)
                    .pipe(
                        map((representativepaginationResultModel: PaginationResultModel<RepresentativeOfListViewDTO>) => {
                            const representativeListWidgetDataModel: RepresentativeListWidgetDataModel = {

                                representatives: representativepaginationResultModel.Models.map((representative) => {

                                    return {
                                        Id: representative.Id,
                                        FullName: representative.FullName,
                                        Email: representative.Email,
                                        Code: representative.Code,
                                    };
                                }),
                                totalRecords: representativepaginationResultModel.TotalRecords,
                                FormEditStyle: action.FormEditStyle
                            };
                            return getRepresentativeDone({
                                RepresentativeWidgetState: {
                                    Representatives: representativeListWidgetDataModel,
                                    AddError: null,
                                    RemoveError: null,
                                    GetError: null
                                }
                            });
                        }),
                        catchError((errorDetails: ErrorDetails) => {
                            return of(
                                getRepresentativeError({
                                    RepresentativeWidgetState: {
                                        Representatives: null,
                                        AddError: null,
                                        RemoveError: null,
                                        GetError: errorDetails
                                    }
                                })
                            );
                        })
                    );
            })
        )
    );

    addRepresentative$ = createEffect(() => this.actions$.pipe(
        ofType(addRepresentativeRequest),
        mergeMap((action) => {
            return this.representativesService
                .AddRepresentatives({ PersonId: action.personId, RepresentativeId: action.representativeOfDTO.Id })
                .pipe(
                    map(() => {
                        return addRepresentativeDone({
                            addedRepresentativeId: action.representativeOfDTO.Id
                        });
                    }),
                    catchError(((errorDetails: ErrorDetails) => {
                        return of(addRepresentativeError({
                            addError: errorDetails,
                            RepresentativeId: action.representativeOfDTO.Id //rollback purposes
                        }));
                    }))
                );
        })
    ));

    removeRepresentative$ = createEffect(() => this.actions$.pipe(
        ofType(removeRepresentativeRequest),
        mergeMap((action) => {
            return this.representativesService
                .RemoveRepresentatives({ PersonId: action.personId, RepresentativeId: action.representativeOfDTO.Id })
                .pipe(
                    map(() => {
                        return removeRepresentativeDone({
                            removedRepresentativeId: action.representativeOfDTO.Id
                        });
                    }),
                    catchError(((errorDetails: ErrorDetails) => {
                        return of(removeRepresentativeError({
                            removeError: errorDetails,
                            representativeOfDTO: action.representativeOfDTO //rollback purposes
                        }));
                    }))
                );
        })
    ));

    constructor(
        private actions$: Actions,
        private userService: UserHttpService,
        private representativesService: RepresentativesHttpService
    ) { }
}
