import { HttpServiceBase } from './http-service-base';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';
import { Observable, Subscriber } from 'rxjs';
import { PaginationModel } from 'src/app/models/pagination-models';
import { ToastNotificationSetting } from '../../models/toast-notfication-specfication';
import { HttpStatusCode } from '../../enums/http-status-codes';
import { HttpFieldModel } from '../../models/field-list-model';

export class AuthenticatedHttpServiceBase extends HttpServiceBase {
    /*
     * TODO: We can further improve our services by also giving a feedback mechanism for successful operations
     */

    constructor(
        httpClient: HttpClient,
        _errorManager: ErrorManagerService,
        _toastService: ToastService
    ) {
        super(httpClient, _errorManager, _toastService);
    }

    protected objectToFormData<TModel>(data: TModel): FormData {
        const formData = new FormData();

        this.buildFormData(formData, data, null);

        return formData;
    }

    protected getFormData<TModel>(model: TModel): FormData {
        const formData = new FormData();

        for (const prop in model) {
            if (!model.hasOwnProperty(prop) || !model[prop]) {
                continue;
            }
            
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formData.append(prop, model[prop] as any);
        }

        return formData;
    }

    protected DeleteAuthenticated<TModel>(path: string, headers: HttpHeaders): Observable<TModel>;
    protected DeleteAuthenticated<TModel>(path: string, headers: HttpHeaders, notify: ToastNotificationSetting[]): Observable<TModel>;
    protected DeleteAuthenticated<TModel>(path: string, headers: HttpHeaders, notify: ToastNotificationSetting[], ignoreHttpStatusCode: HttpStatusCode[]): Observable<TModel>;
    protected DeleteAuthenticated<TModel>(path: string, httpHeaders: HttpHeaders, notify: ToastNotificationSetting[], ignoreHttpStatusCode: HttpStatusCode[]): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected DeleteAuthenticated<TModel>(path: string, httpHeaders: HttpHeaders, notify: ToastNotificationSetting[], ignoreHttpStatusCode: HttpStatusCode[], body?: any): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected DeleteAuthenticated<TModel>(path: string, httpHeaders: HttpHeaders, notify: ToastNotificationSetting[] = [], ignoreHttpStatusCode: HttpStatusCode[] = [], body: any = undefined): Observable<TModel> {
        return new Observable<TModel>((observer: Subscriber<TModel>) => {
            super
                .Delete<TModel>(path, httpHeaders, notify, ignoreHttpStatusCode, body)
                .subscribe(
                    (model: TModel) => {
                        observer.next(model);
                        observer.complete();
                    },
                    (error) => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    protected GetAuthenticated<TModel>(path: string, headers: HttpHeaders): Observable<TModel>;
    protected GetAuthenticated<TModel>(path: string, headers: HttpHeaders, notify: ToastNotificationSetting[]): Observable<TModel>;
    protected GetAuthenticated<TModel>(path: string, headers: HttpHeaders, notify: ToastNotificationSetting[], paginationModel: PaginationModel): Observable<TModel>;
    protected GetAuthenticated<TModel>(path: string, headers: HttpHeaders, notify: ToastNotificationSetting[], paginationModel: PaginationModel, ignoreHttpStatusCode: HttpStatusCode[], fieldModel: HttpFieldModel): Observable<TModel>;
    protected GetAuthenticated<TModel>(path: string, headers: HttpHeaders, notify: ToastNotificationSetting[] = [], paginationModel: PaginationModel = null, ignoreHttpStatusCode: HttpStatusCode[] = [], fieldModel: HttpFieldModel = null): Observable<TModel> {
        return new Observable<TModel>((observer: Subscriber<TModel>) => {
            super
                .Get<TModel>(
                    path,
                    headers,
                    notify,
                    paginationModel,
                    ignoreHttpStatusCode,
                    fieldModel
                )
                .subscribe(
                    (model: TModel) => {
                        observer.next(model);
                        observer.complete();
                    },
                    (error) => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected PostAuthenticated<TModel>(path: string, body: any, headers: HttpHeaders): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected PostAuthenticated<TModel>(path: string, body: any, headers: HttpHeaders, notify: ToastNotificationSetting[]): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected PostAuthenticated<TModel>(path: string, body: any, headers: HttpHeaders, notify: ToastNotificationSetting[], paginationModel: PaginationModel): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected PostAuthenticated<TModel>(path: string, body: any, headers: HttpHeaders, notify: ToastNotificationSetting[], paginationModel: PaginationModel, ignoreHttpStatusCodes: HttpStatusCode[]): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected PostAuthenticated<TModel>(path: string, body: any, headers: HttpHeaders, notify: ToastNotificationSetting[], paginationModel: PaginationModel, ignoreHttpStatusCodes: HttpStatusCode[], FieldModel: HttpFieldModel): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected PostAuthenticated<TModel>(path: string, body: any, httpHeaders: HttpHeaders, notify: ToastNotificationSetting[] = [], paginationModel: PaginationModel = null, ignoreHttpStatusCodes: HttpStatusCode[] = [], FieldModel: HttpFieldModel = null): Observable<TModel> {
        return new Observable<TModel>((observer: Subscriber<TModel>) => {
            super
                .Post<TModel>(
                    path,
                    body,
                    httpHeaders,
                    notify,
                    paginationModel,
                    ignoreHttpStatusCodes,
                    FieldModel
                )
                .subscribe(
                    (model: TModel) => {
                        observer.next(model);
                        observer.complete();
                    },
                    (error) => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected PutAuthenticated<TModel>(path: string, body: any, headers: HttpHeaders): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected PutAuthenticated<TModel>(path: string, body: any, headers: HttpHeaders, notify: ToastNotificationSetting[]): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected PutAuthenticated<TModel>(path: string, body: any, headers: HttpHeaders, notify: ToastNotificationSetting[], ignoreHttpStatusCodes: HttpStatusCode[]): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected PutAuthenticated<TModel>(path: string, body: any, headers: HttpHeaders, notify: ToastNotificationSetting[], ignoreHttpStatusCodes: HttpStatusCode[], paginationModel: PaginationModel): Observable<TModel>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected PutAuthenticated<TModel>(path: string, body: any, httpHeaders: HttpHeaders, notify: ToastNotificationSetting[] = [], ignoreHttpStatusCodes: HttpStatusCode[] = [], paginationModel: PaginationModel = null): Observable<TModel> {
        return new Observable<TModel>((observer: Subscriber<TModel>) => {
            super
                .Put<TModel>(path, body, httpHeaders, notify, ignoreHttpStatusCodes, paginationModel)
                .subscribe(
                    (model: TModel) => {
                        observer.next(model);
                        observer.complete();
                    },
                    (error) => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    private buildFormData<TModel>(formData: FormData, data: TModel, parentKey: string) {
        if (data && typeof data === 'object' && !(data instanceof File)) {
            Object.keys(data).forEach((key) => {
                this.buildFormData(
                    formData,
                    data[key],
                    parentKey ? `${parentKey}[${key}]` : key
                );
            });
        } else {
            const value = data == null ? '' : data;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formData.append(parentKey, value as any);
        }
    }
}
