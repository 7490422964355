<div>
    <form>
        <div class="mb-20" [innerHTML]="'OneTimePin.Form.TwoFactorAuthenticationDescription' | transloco "></div>

        <portal-text-field id="onetimepin" name="onetimepin" #onetimepin="ngModel" (keydown.enter)="proceedClicked()"
                           [(ngModel)]="model.onetimepin"
                           required
                           [is-valid]="!onetimepin.touched || onetimepin.valid"
                           [is-disabled]="_disabled"
                           label-text="{{'OneTimePin.Form.Otp' | transloco }}"
                           [invalid-text]="'OneTimePin.Form.OTPRequired' | transloco">
        </portal-text-field>

        <p-checkbox id="rememberclient" name="rememberclient"
                    [(ngModel)]="rememberClient"
                    binary="true"
                    inputId="binary"
                    [disabled]="_disabled"
                    label="{{'OneTimePin.Form.RememberClient' | transloco: { days: 14 } }}">
        </p-checkbox>
    </form>
</div>

<div class="p-dialog-footer justify-content-end">
    <p-button styleClass="btn btn-lg" type="button" label="{{'OneTimePin.Form.SendOneTimePin' | transloco }}" [disabled]="_disabled" (onClick)="sendOneTimePin()"></p-button>
    <p-button styleClass="btn btn-primary btn-lg" type="button" label="{{'OneTimePin.Form.Proceed' | transloco }}" [disabled]="_disabled" (onClick)="proceedClicked()"></p-button>
</div>
