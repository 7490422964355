import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getStructuredDataNodeConfigurationState } from '../../state/configuration/configuration.selectors';
import { loadStructuredDataNodesRequest, resetStructuredDataNodes } from '../../state/configuration/structured-data/node/structured-data-node.actions';
import { AppState, ListState, StructuredDataNodeConfigurationState } from '../../AppState';

@Injectable({
    providedIn: 'root'
})
export class StructuredDataNodeFacade {

    constructor(private store: Store<AppState>) {
    }

    public LoadStructuredDataNodes(pageId: string, entityTypeId: string): void {
        this.store.dispatch(loadStructuredDataNodesRequest({ pageId: pageId, entityTypeId: entityTypeId  }));
    }

    public LoadStructuredDataNodesSelector(): Observable<ListState<StructuredDataNodeConfigurationState>[]> {
        const structuredDataNodes = this.store.pipe(select(getStructuredDataNodeConfigurationState));
        return structuredDataNodes;
    }

    public ResetState(): void {
        this.store.dispatch(resetStructuredDataNodes());
    }
}
