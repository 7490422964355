<fw-inline-edit-checkbox *ngIf="FormEditStyle === FormEditStyles.Inline || FormEditStyle === FormEditStyles.ReadOnly"
                         [CustomFormGroup]="CustomFormGroup"
                         [PropertyName]="Property.PropertyName"
                         [Label]="Property.LabelResourceId"
                         [LabelStyle]="Property.LabelStyle"
                         [Checkbox]="Property.Checkbox"
                         [AcceptChanges]="AcceptChanges"
                         [FormEditStyle]="FormEditStyle">
</fw-inline-edit-checkbox>
<fw-form-edit-checkbox *ngIf="FormEditStyle === FormEditStyles.Form"
                         [CustomFormGroup]="CustomFormGroup"
                         [PropertyName]="Property.PropertyName"
                         [Label]="Property.LabelResourceId"
                         [LabelStyle]="Property.LabelStyle"
                         [Checkbox]="Property.Checkbox"
                         [AcceptChanges]="AcceptChanges">
</fw-form-edit-checkbox>
