import * as FileSaver from 'file-saver';

export class FileProcessingHelper {
    public static DownloadFile(file: File, fileName: string): void {
        FileSaver.saveAs(file, fileName);
    }

    public static ConvertBlobToFile(blob: Blob, fileName: string): File {
        return new File([blob], fileName);
    }

    public static ConvertBase64ToBlob(base64String): Blob {
        const byteString = window.atob(base64String);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array]);
        return blob;
    }
}