/* eslint-disable @angular-eslint/component-selector */
import {
    AfterViewInit,
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { NgMapComponent } from '@landadmin/ng-mapping-library';
import { ShapeUpdateDTO } from '@landadmin/ng-mapping-library/lib/data-transfer-objects/shape-update-dto';
import { ShapeAndSymbology } from '@landadmin/ng-mapping-library/lib/models/shape-and-symbology';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { fromEvent, interval, Observable, Subscription } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { severity_error } from '../../../../../../consts/severity-options';
import { ShapeViewDTO } from '../../../../../../data-transfer-objects/shape/shape-view-dto';
import { ShapeHelper } from '../../../../../../helpers/shape-helper';
import { ModalMapDataModel } from '../../../../../../models/modal-map-data-model';
import { ValidationResult } from '../../../../../../models/validation/validation-result';
import { DialogComponentBase } from '../../../../../../services/application/dialog-application.service';
import { ToastApplicationService } from '../../../../../../services/application/toast-application.service';
import { MapWidgetComponent } from '../map-widget.component';

@Component({
    selector: 'app-modal-map',
    templateUrl: './modal-map.component.html',
})
export class ModalMapComponent extends DialogComponentBase<ModalMapDataModel> implements OnInit, AfterViewInit, OnDestroy {
    public MapLoaded: boolean;
    public ModalMapData: ModalMapDataModel;
    public ResizeObservable: Observable<unknown>;
    public Shape: ShapeViewDTO;

    private _factory: ComponentFactory<NgMapComponent>;
    private _mapRef: ComponentRef<NgMapComponent>;
    private _subscriptions: Subscription[] = [];

    @ViewChild('mapContent', { read: ViewContainerRef })
    private _mapContent: ViewContainerRef;

    constructor(
        private _elementRef: ElementRef,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private toastApplicationService: ToastApplicationService,
        private dynamicDialogRef: DynamicDialogRef,
        dynamicDialogConfig: DynamicDialogConfig
    ) {
        super(dynamicDialogRef, dynamicDialogConfig);
        this.ResizeObservable = fromEvent(this._elementRef.nativeElement, 'resize');
        this.ResizeObservable = this.ResizeObservable.pipe(
            debounce(() => interval(500))
        );
    }

    public OnAcceptChanges(): void {
        if (this.MapLoaded) {
            this._subscriptions.push(
                ShapeHelper.ValidateMapWidgetData(
                    this._mapRef,
                    this._subscriptions,
                    this.ModalMapData.MapWidgetConfigurationViewDTO
                ).subscribe((validationResult: ValidationResult<ShapeUpdateDTO>) => {
                    if (validationResult.Valid) {
                        this.dynamicDialogRef.close(validationResult.Data);
                    } else {
                        this.toastApplicationService.showToast(null, validationResult.ValidationMessages[0], severity_error);
                    }
                })
            );
        }
    }

    public OnCancel() {
        this.dynamicDialogRef.close();
        this.dynamicDialogRef.destroy();
    }

    ngAfterViewInit(): void {
        this._mapRef = MapWidgetComponent.CreateMap(
            this._factory,
            this._mapRef,
            this._componentFactoryResolver,
            this._mapContent,
            this.ResizeObservable,
            this.ModalMapData.MapConfiguration,
            this.ModalMapData.MapStateId
        );
        this._mapRef.instance.MapLoaded.subscribe(() => {
            this.MapLoaded = true;

            if (this.ModalMapData.MapConfiguration.ReadonlyShapes && this.ModalMapData.MapConfiguration.ReadonlyShapes.length > 0) {
                const oldShape: ShapeViewDTO = this.ModalMapData.MapConfiguration.ReadonlyShapes[0];
                const oldShapeWithSymbology: ShapeAndSymbology[] = [{
                    ShapeID: oldShape.ID,
                    Shape: oldShape,
                    DisplaySymbol: null,
                    HighlightSymbol: null
                }];

                ShapeHelper.addSymbologyToShapes(this._mapRef, oldShapeWithSymbology, this.ModalMapData.MapWidgetConfigurationViewDTO.OldShapeSymbology, this.ModalMapData.MapWidgetConfigurationViewDTO.OldShapeSymbology);
                const shape: ShapeViewDTO = this.ModalMapData.MapConfiguration.Shape;

                if (!shape || !shape.Parts || shape.Parts.length < 1) {
                    this._mapRef.instance.HighlightShapesWithSymbology(oldShapeWithSymbology, true);
                } else {
                    this._mapRef.instance.AddShapesWithSymbology(oldShapeWithSymbology);
                }
            }
        });
    }

    ngOnDestroy() {
        this._subscriptions.forEach((subcription) => {
            if (subcription) {
                subcription.unsubscribe();
            }
        });
    }

    ngOnInit(): void {
        this.ModalMapData = this.dialogOptions.dataModel;

        this.Shape = this.ModalMapData.MapConfiguration.Shape;
        this.MapLoaded = false;
    }
}
