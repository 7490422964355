/* eslint-disable */

import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'portal-loading',
  templateUrl: './portal-loading.component.html',
})
export class PortalLoadingComponent {
  @HostBinding('class.small')
  public Small: boolean = true;

  @HostBinding('class.mono')
  public Mono: boolean = false;

  @Input('hide-text')
  public HideText: boolean = false;

  @Input('is-small')
  public set IsSmall(val: boolean) {
    this.Small = val;
  }

  constructor() {}
}
