<ng-container *ngFor="let link of _navigationLinks; let i = index">
    <ng-container *ngIf="link.SubMenu.length >= 1; then dropdown; else single">

    </ng-container>
    <ng-template #dropdown>
        <li class="dropdown d-desktop">
            <a data-display="static" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{{link.Name}}<span class="pi pi-chevron-down"></span></a>
            <ul class="dropdown-menu left">
                <li *ngFor="let submenu of link.SubMenu">
                    <a [routerLinkActive]="'active'" routerLink="/{{submenu.Link}}" [queryParams]="submenu.QueryParams">{{submenu.Name}}</a>
                </li>
            </ul>
        </li>
        <li class="has-children d-mobile expand">
            <div pStyleClass="@parent" toggleClass="expand" class="collapser">{{link.Name}}</div>
            <ul #collapseTarget class="sub-menu collapse">
                <li *ngFor="let submenu of link.SubMenu">
                    <a pStyleClass=".mobile-main-nav" toggleClass="mobile-nav-open" [routerLinkActive]="'active'" routerLink="/{{submenu.Link}}">{{submenu.Name}}</a>
                </li>
            </ul>
        </li>
    </ng-template>
    <ng-template #single>
        <li class="d-desktop">
            <a [routerLinkActive]="'active'" routerLink="/{{link.Link}}">{{link.Name}}</a>
        </li>
        <li class="d-mobile">
            <a pStyleClass=".mobile-main-nav" toggleClass="mobile-nav-open" [routerLinkActive]="'active'" routerLink="/{{link.Link}}">{{link.Name}}</a>
        </li>
    </ng-template>
</ng-container>