
<table structured-data-table class="{{structuredDataClass}}"
       [instance-id]="instanceId"
       [structured-data]="structuredDataModel"
       [show-headers]="true"
       [add-enabled]="addEnabled"
       [delete-enabled]="deleteEnabled"
       [is-edit-mode]="editMode"
       [edit-enabled]="editEnabled"
       (structured-data-entry-change)='Update($event)'
       (structured-data-entry-delete)='Delete($event)'
       (structured-data-entry-add)='Add($event)'
       (on-page-change)="Paginate($event)"
       [total-records]="100"
       [pagination-enabled]="paginationEnabled">
</table>
<div class="p-dialog-footer justify-content-end">
    <p-button styleClass="btn btn-lg" type="button" (onClick)="close()" label="{{cancelButtonText | transloco }}"></p-button>
    <p-button [disabled]="isEntryEmpty" styleClass="btn btn-primary btn-lg" type="button" (onClick)="proceedClicked()" label="{{saveButtonText | transloco }}"></p-button>
</div>