import { Input, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { DiscussionViewDTO } from '../../../../data-transfer-objects/discussion/discussion-view-dto';
import { AbstractDiscussionTopicCommentFacade } from '../../../../facade/abstract/abstract-discussion-topic-comment.facade';
import { AttachmentCreateDTO, DiscussionCommentCreateDTO } from '../../../../data-transfer-objects/discussion-comment/discussion-comment-create-dto';
import { AbstractDiscussionTopicListFacade } from '../../../../facade/abstract/abstract-discussion-topic-list.facade';
import { AbstractDiscussionCommentsListFacade } from '../../../../facade/abstract/abstract-discussion-comments-list.facade';

@Component({
    selector: 'fw-discussion-topic-comment',
    templateUrl: './discussion-topic-comment.component.html'
})
export class DiscussionTopicCommentComponent implements OnInit, OnDestroy {
    public formattedParticipants: string;
    public discussion: DiscussionViewDTO;
    public discussionComment: DiscussionCommentCreateDTO = { Comment: null, Attachments: null };
    public UploadedOutputFiles: File[] = [];

    @Input()
    public DataSourceId: string;
    @Input()
    public AddDiscussionCommentButtonResourceId: string;
    @Input()
    public IsReadOnly: boolean = false;



    private subscriptions: Subscription[] = [];

    constructor(
        private topicCommentFacade: AbstractDiscussionTopicCommentFacade,
        public abstractDiscussionTopicListFacade: AbstractDiscussionTopicListFacade,
        private commentsListFacade: AbstractDiscussionCommentsListFacade,
        public transloco: TranslocoService) {
    }

    addComment() {

        this.mapAttachments();

        this.topicCommentFacade.AddDiscussionComment(this.discussionComment);

        this.commentsListFacade._addCommentToUi.next(this.discussionComment);

        this.abstractDiscussionTopicListFacade.UpdateDiscussion(this.DataSourceId, this.discussion.Id, this.discussionComment.Comment, new Date());
        this.discussionComment = { Comment: null, Attachments: null };
        this.UploadedOutputFiles = []; //clear files after adding a comment
    }

    commentExists(): boolean {
        return this.discussionComment.Comment && this.discussionComment.Comment.length > 0;
    }

    ngOnInit(): void {
        const setLoadSub = this.topicCommentFacade._setLoadSubject.subscribe(readyToLoad => {
            if (readyToLoad === true) {

                this.discussion = this.topicCommentFacade._discussion;

                this.topicCommentFacade.LoadDiscussionParticipants();
            }
        });

        this.subscriptions.push(setLoadSub);

        const selectorSub = this.topicCommentFacade.LoadDiscussionParticipantsSelector().subscribe((participants) => {
            if (participants.DiscussionParticipants !== null) {
                this.formattedParticipants = participants.DiscussionParticipants.map((d) => d.Name).join(', ');
            } else {
                this.formattedParticipants = null;
            }
        });

        this.subscriptions.push(selectorSub);
    }

    ngOnDestroy(): void {

        if (this.subscriptions) {
            this.subscriptions.forEach(subscription => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            })
        }

        this.topicCommentFacade.ResetState();
    }
    private mapAttachments(): void {

        const attachments: AttachmentCreateDTO[] = [];

        this.UploadedOutputFiles.forEach((file) => { attachments.push({ DocumentBlob: file, FileName: file.name }) });

        this.discussionComment.Attachments = attachments;
    }

}
