<ng-template [ngIf]="loaded === true">
    <div class="sd-container {{structuredDataClass}}">
        <table structured-data-table class="{{structuredDataClass}}"
               [instance-id]="instanceId"
               [structured-data]="structuredDataModel"
               [show-headers]="true"
               [add-enabled]="canAddEntries"
               [delete-enabled]="canDeleteEntries"
               [is-edit-mode]="canEditEntriesInline"
               [edit-enabled]="isDialogEditMode"
               (structured-data-entry-change)='Update($event)'
               (structured-data-entry-delete)='Delete($event)'
               (structured-data-entry-edit)='Edit($event,true)'
               (structured-data-entry-add)='Add($event)'
               (on-page-change)="Paginate($event)"
               [total-records]="100"
               [pagination-enabled]="isPaginationEnabled"
               [empty-message]="emptyListMessage">
        </table>
    </div>
</ng-template>