import { createReducer, on } from '@ngrx/store';
import { ListState, MapReferenceListWidgetState, ReferenceCodeListWidgetState } from '../../AppState';
import { ListStateReducerHelper } from '../../helpers/list-state-reducer-helper';
import { getAssetMapReferencesDone, getAssetMapReferencesError, resetMapReferenceList } from './map-reference.actions';

export const intialMapReferenceListState: ListState<MapReferenceListWidgetState>[] = [];

export const MapReferenceListReducer = createReducer<ListState<MapReferenceListWidgetState>[]>(intialMapReferenceListState,
    on(getAssetMapReferencesDone, (state, action) => ListStateReducerHelper.GetUpdatedListState<MapReferenceListWidgetState>(state, action.dataSourceId, action.mapReferenceListWidgetState)),
    on(getAssetMapReferencesError, (state, action) => ListStateReducerHelper.GetUpdatedListState<MapReferenceListWidgetState>(state, action.dataSourceId, action.mapReferenceListWidgetState)),

    on(resetMapReferenceList, () => intialMapReferenceListState)
);
