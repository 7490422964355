import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of, Subject, Subscriber } from 'rxjs';
import { LanguageHelper } from '../../helpers/language-helper';
import { TranslationHelper } from '../../helpers/translation-helper';
import { TranslationViewDTO } from '../../data-transfer-objects/translations/translation-view-dto';
import { BASE_URL } from '../../helpers/base-url';
import { HttpServiceBase } from '../class-definitions/http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { LanguageHttpService } from './language-http.service';
import { ToastService } from '../deprecated/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from "primeng/api";

@Injectable({
    providedIn: 'root'
})
export class TranslationHttpService extends HttpServiceBase {

    private Ready: boolean = false;
    private ReadyObservable: Subject<void> = new Subject();

    constructor(
        httpClient: HttpClient,
        @Inject(BASE_URL) private baseUrl: string,
        errorManager: ErrorManagerService,
        toastService: ToastService,
        private _translocoService: TranslocoService,
        private _languageService: LanguageHttpService,
        private translateService: TranslateService,
        private primeNGConfig: PrimeNGConfig
    ) {
        super(httpClient, errorManager, toastService);
    }

    public LoadTranslations(): Observable<null> {
        return new Observable<null>((subscriber: Subscriber<null>) => {
            this.Get<Array<TranslationViewDTO>>(`${this.baseUrl}api/Translation`, this.GetStandardHeaders())
                .subscribe((translations: Array<TranslationViewDTO>) => {
                    this._languageService.GetCurrentLanguageId()
                        .subscribe((languageId: string) => {
                            TranslationHelper.PopulateTranslations(languageId, translations, this._translocoService);
                            TranslationHelper.setUpDateTranslations(this.translateService, translations)
                            LanguageHelper.UpdateContentDirection(this._languageService, Number(languageId));
                            this._languageService.GetById(Number(languageId)).subscribe(lang => {
                                const defaultLocale = 'en-US';
                                const locale = lang?.Locale || defaultLocale;

                                if (locale !== defaultLocale) {
                                    // Dynamically import the correct locale from the chunk 
                                    // specified through the webpack magic comments.
                                    import(
                                        /* webpackExclude: /\.d\.ts$/ */
                                        /* webpackMode: "lazy-once" */
                                        /* webpackChunkName: 'i18n-base' */
                                        `@/../@angular/common/locales/global/${locale}`);
                                }

                                this.translateService.use(`language-${languageId}`);
                                this.translateService.get('primeng').subscribe(res => this.primeNGConfig.setTranslation(res));
                                this.Ready = true;
                                this.ReadyObservable.next();
                                subscriber.next();
                            });
                        }, error => {
                            subscriber.error(error);
                        });
                }, error => {
                    subscriber.error(error);
                });
        });
    }

    public Translate(key: string): Observable<string> {
        if (this.Ready) {
            const translation: string = this._translocoService.translate(key);
            return of(translation);
        } else {
            return new Observable<string>((subscriber: Subscriber<string>) => {
                this.ReadyObservable
                    .subscribe(() => {
                        const translation: string = this._translocoService.translate(key);
                        subscriber.next(translation);
                    });
            });
        }
    }
}
