import { Injectable, ComponentRef, NgZone } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { StyleVariants } from '../../enums/bootstrap/style-variants';
import { ErrorDetails } from '../../models/error-details';
import { ErrorModel } from '../../models/error-model';
import { FieldHelper } from '../../helpers/field-helper';
import { ToastService } from './toast.service';
import { ToastComponent } from 'src/app/modules/static/generics/components/toast/toast.component';
import { ErrorManagerService } from './error-manager.service';
import { AsynchronousProcessResultDTO } from 'src/app/data-transfer-objects/signal-r/asynchronous-process-result-dto';

@Injectable({
    providedIn: 'root'
})
export class AsynchronousProcessNotificationService {

    constructor(private _toastService: ToastService, private _errorManager: ErrorManagerService, private zone: NgZone) { }

    public EntitiesProcessing: { [toastId: string]: ComponentRef<ToastComponent> } = {};

    public InitialiseNotification<T>(subscriptions: Subscription[],
        request: Observable<AsynchronousProcessResultDTO<T>>,
        getLoadingText: (result: AsynchronousProcessResultDTO<T>) => string,
        getStyleVariant: (result: AsynchronousProcessResultDTO<T>) => StyleVariants = null,
        continuationSubject: Subject<AsynchronousProcessResultDTO<T>> = null
    ): void {

        subscriptions.push(request.subscribe((result: AsynchronousProcessResultDTO<T>) => {

            const loadingText: string = (getLoadingText) ? getLoadingText(result) : '';
            const styleVariant: StyleVariants = (getStyleVariant) ? getStyleVariant(result) : StyleVariants.Info;

            const toastRef: ComponentRef<ToastComponent> = this._toastService.ShowToast([], styleVariant, false, true, loadingText);

            this.EntitiesProcessing[result.entityId] = toastRef;

            this.setLoadingToast(toastRef, loadingText);

            subscriptions.push(toastRef.instance.OnCloseSubject.subscribe(() => {
                this.removeEntityFromProcessing(result.entityId);
            }));

            if (continuationSubject) {
                continuationSubject.next(result);
            }

        }));
    }

    public SuccessNotification<T>(subscriptions: Subscription[],
        request: Observable<AsynchronousProcessResultDTO<T>>,
        getSuccessText: (result: AsynchronousProcessResultDTO<T>) => string,
        getRouterTextTranslationResourceId: (result: AsynchronousProcessResultDTO<T>) => string = null,
        onClose: (result: AsynchronousProcessResultDTO<T>) => void = null,
        getSuccessLink: (result: AsynchronousProcessResultDTO<T>) => string = null,
        getSuccessLinkText: (result: AsynchronousProcessResultDTO<T>) => string = null,
        getStyleVariant: (result: AsynchronousProcessResultDTO<T>) => StyleVariants = null,
        continuationSubject: Subject<AsynchronousProcessResultDTO<T>> = null
    ): void {

        subscriptions.push(request.subscribe((result: AsynchronousProcessResultDTO<T>) => {

            const successText: string = (getSuccessText) ? getSuccessText(result) : '';
            const routerTextTranslationResourceId: string = (getRouterTextTranslationResourceId) ? getRouterTextTranslationResourceId(result) : '';
            const successLink: string = (getSuccessLink) ? getSuccessLink(result) : '.';
            const successLinkText: string = (getSuccessLinkText) ? getSuccessLinkText(result) : '';
            const styleVariant: StyleVariants = (getStyleVariant) ? getStyleVariant(result) : StyleVariants.Success;

            let toastRef: ComponentRef<ToastComponent> = this.removeEntityFromProcessing(result.entityId);

            if (!toastRef) {
                toastRef = this._toastService.ShowToast([], styleVariant, false, true);
            }

            this.setToastSuccess(toastRef, successText, successLink, successLinkText, routerTextTranslationResourceId, styleVariant);

            if (onClose) {
                subscriptions.push(toastRef.instance.OnCloseSubject.subscribe(() => onClose(result)));
            }

            if (continuationSubject) {
                continuationSubject.next(result);
            }
        }));
    }

    public FailureNotification<T>(subscriptions: Subscription[],
        request: Observable<AsynchronousProcessResultDTO<T>>,
        getFailureText: (result: AsynchronousProcessResultDTO<T>) => string,
        getFailureLink: (result: AsynchronousProcessResultDTO<T>) => string = null,
        getFailureLinkText: (result: AsynchronousProcessResultDTO<T>) => string = null,
        getStyleVariant: (result: AsynchronousProcessResultDTO<T>) => StyleVariants = null,
        continuationSubject: Subject<AsynchronousProcessResultDTO<T>> = null
    ): void {

        subscriptions.push(request.subscribe((result: AsynchronousProcessResultDTO<T>) => {

            const failureText: string = (getFailureText) ? getFailureText(result) : '';
            const failureLink: string = (getFailureLink) ? getFailureLink(result) : '';
            const failureLinkText: string = (getFailureLinkText) ? getFailureLinkText(result) : '';
            const styleVariant: StyleVariants = (getStyleVariant) ? getStyleVariant(result) : StyleVariants.Danger;

            let toastRef: ComponentRef<ToastComponent> = this.removeEntityFromProcessing(result.entityId);

            if (!toastRef) {
                toastRef = this._toastService.ShowToast([], styleVariant, false, true);
            }

            this.setToastError(toastRef, failureText, failureLink, failureLinkText, styleVariant);

            if (continuationSubject) {
                continuationSubject.next(result);
            }
        }));
    }

    private setLoadingToast(toastRef: ComponentRef<ToastComponent>, loadingText: string = '', autoClose: boolean = false): void {

        toastRef.instance.IsLoading = true;
        toastRef.instance.LoadingText = loadingText;
        toastRef.instance.ShowCloseButton = !autoClose;
        toastRef.instance.ToastVariant = StyleVariants.Info;
    }

    private setToastError(toastRef: ComponentRef<ToastComponent>, failureText: string, failureLink: string = "/error", failureLinkText: string = "View Error", styleVariant: StyleVariants = StyleVariants.Danger): void {

        toastRef.instance.LoadingText = "";
        toastRef.instance.IsLoading = false;
        toastRef.instance.ShowCloseButton = true;
        toastRef.instance.Messages = [{
            Message: failureText,
            RouterLink: failureLink,
            RouterText: failureLinkText,
            MessageParameters: [],
            QueryParameters: []
        }];
        toastRef.instance.ToastVariant = styleVariant;

    }

    private setToastSuccess(toastRef: ComponentRef<ToastComponent>, successText: string, successLink: string, successLinkText: string, routerTextTranslationResourceId: string, successVariant: StyleVariants): void {

        toastRef.instance.LoadingText = "";
        toastRef.instance.IsLoading = false;
        toastRef.instance.Messages = [{
            Message: successText,
            RouterLink: successLink,
            RouterText: successLinkText,
            MessageParameters: [],
            QueryParameters: [],
            PreventNavigate: true,
            RouterTextTranslationResourceId: routerTextTranslationResourceId
        }];

        toastRef.instance.ToastVariant = successVariant
        toastRef.instance.ShowCloseButton = true;
    }

    private removeEntityFromProcessing(entityId: string): ComponentRef<ToastComponent> {

        let toastRef: ComponentRef<ToastComponent> = this.EntitiesProcessing[entityId];

        if (toastRef) {
            delete this.EntitiesProcessing[entityId];
        }

        return toastRef;
    }
}