import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorDetails } from 'src/app/models/error-details';
import { ConfigurationHttpService } from 'src/app/services/http/configuration-http.service';
import { getGoogleAnalyticsConfigurationDone, getGoogleAnalyticsConfigurationError, getGoogleAnalyticsConfigurationRequest } from './google-analytics.actions';

@Injectable()
export class GoogleAnalyticsConfigurationEffects {
    getGoogleAnalyticsConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getGoogleAnalyticsConfigurationRequest),
            switchMap((action) =>
                this.configHttpService
                    .GetGoogleAnalyticsKey()
                    .pipe(
                        map(configuration => getGoogleAnalyticsConfigurationDone({
                            configuration: {
                                GoogleAnalyticsConfiguration: configuration,
                                GetGoogleAnalyticsConfigurationError: null
                            }
                        })),
                        catchError((errorDetails: ErrorDetails) => of(getGoogleAnalyticsConfigurationError({
                            configuration: {
                                GoogleAnalyticsConfiguration: null,
                                 GetGoogleAnalyticsConfigurationError: errorDetails
                            }
                        })))
                    ))
        ));

    constructor(
        private actions$: Actions,
        public configHttpService: ConfigurationHttpService) {
    }
}
