import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpStatusCode } from '../../enums/http-status-codes';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DiscussionCommentCreateDTO } from '../../data-transfer-objects/discussion-comment/discussion-comment-create-dto';
import { DiscussionCommentViewDTO } from '../../data-transfer-objects/discussion-comment/discussion-comment-view-dto';
import { BASE_URL } from '../../helpers/base-url';
import { PaginationModel } from '../../models/pagination-models';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class DiscussionCommentsHttpService extends AuthenticatedHttpServiceBase {
    constructor(httpClient: HttpClient, @Inject(BASE_URL) private _baseUrl: string, errorManager: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorManager, toastService);
    }

    public getDiscussionComments(discussionId: string, entityId: string, pageId: string, paginationModel: PaginationModel): Observable<DiscussionCommentViewDTO[]> {
        return this.GetAuthenticated<DiscussionCommentViewDTO[]>(`${this._baseUrl}api/discussionComment/entityId/${entityId}/discussionId/${discussionId}/pageId/${pageId}`, this.GetStandardHeaders(), [], paginationModel);
    }

    public addDiscussionComment(entityId: string, pageId: string, discussionId: string, discussionComment: DiscussionCommentCreateDTO): Observable<DiscussionCommentViewDTO> {
        const multiPartHeaders: HttpHeaders = this.GetMultipartFormDataHeaders();

        return this.Post<DiscussionCommentViewDTO>(`${this._baseUrl}api/discussionComment/entityId/${entityId}/discussionId/${discussionId}/pageId/${pageId}`, this.objectToFormData(discussionComment), multiPartHeaders, [], null, [HttpStatusCode.NOT_FOUND, HttpStatusCode.BAD_REQUEST]);
    }
}
