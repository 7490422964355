import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[fwAutoFocus]',
})
export class AutoFocusDirective implements OnInit {
    constructor(private elementRef: ElementRef) {
        
    }

    ngOnInit(): void {
        const htmlElement = this.elementRef.nativeElement as HTMLElement;

        if (htmlElement) {
            setTimeout(() => {
                switch (htmlElement.tagName) {
                    case 'P-DROPDOWN':
                    case 'P-INPUTNUMBER':
                    case 'P-CALENDAR':
                        htmlElement
                            .getElementsByTagName('input')
                            .item(0)
                            ?.focus();
                        break;
                    case 'P-TOGGLEBUTTON':
                        htmlElement
                            .getElementsByTagName('div')
                            .item(0)
                            ?.focus();
                        break;
                    default:
                        htmlElement
                            .focus();
                        break;
                }
            });
        }
    }
}
