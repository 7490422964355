import { createReducer, on } from '@ngrx/store';
import { CompanyListState } from '../../AppState';
import { resetCompanies, getCompaniesError, getCompaniesDone } from './company.actions'

export const initialCompaniesState: CompanyListState = { Companies: null, GetError: null };

export const CompanyReducer = createReducer<CompanyListState>(initialCompaniesState,

    on(getCompaniesDone, (state, action) => action.CompanyListState),
    on(getCompaniesError, (state, action) => action.CompanyListState),
    on(resetCompanies, () => initialCompaniesState)
);
