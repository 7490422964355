import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TextFieldConfiguration } from '../../../../../models/configuration/widgets/properties/controls/textfield/text-field-configuration';
import { ValidatableInputControlBase } from '../../input-controls/validatable-input-control-base';

@Component({
    selector: 'fw-inline-edit-text-field',
    templateUrl: './inline-edit-text-field.component.html',
    host: { 'class': 'inline-edit' }
})
export class InlineEditTextFieldComponent extends ValidatableInputControlBase<string> implements OnInit {

    @Input()
    public TextField: TextFieldConfiguration;  

    constructor(protected transloco: TranslocoService) {
        super(transloco);
    }

    ngOnInit(): void {

        if (this.TextField) {
            this.ConfigureTextFieldValidation(this.TextField.Validation);
        }
    }      
}
