import { createReducer, on } from '@ngrx/store';
import { WidgetStateCache } from '../../AppState';
import { resetStateCache, setStateCache } from './state-cache.actions';

export const intialMapReferenceListState: WidgetStateCache = {};

export const StateCacheReducer = createReducer<WidgetStateCache>(intialMapReferenceListState,
    on(setStateCache, (state, action) => {
        const currentData = state[action.key] || {};
        const newData = action.data;
        return Object.assign({}, state, { [action.key]: Object.assign({}, currentData, newData) });
    }),

    on(resetStateCache, () => intialMapReferenceListState)
);
