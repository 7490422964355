<p-dropdown [(ngModel)]="selectedValue" optionValue="value" optionLabel="label" [options]="dateMatchModeOptions" (onChange)="ClearInputDates()" class="d-block mb-10"></p-dropdown>
<div *ngIf="selectedValue !== 'null' && selectedValue !== 'notNull'">
    <ng-container *ngIf="selectedValue === 'between'; else dteBeforeAfter">
        <p-calendar [(ngModel)]="startDate" [dateFormat]="mapDateFormat(dateFormat)" class="d-block mb-10" showIcon="true"></p-calendar>
        <p-calendar [(ngModel)]="endDate" [dateFormat]="mapDateFormat(dateFormat)" showIcon="true"></p-calendar>
    </ng-container>
    <ng-template #dteBeforeAfter>
        <p-calendar [(ngModel)]="startDate" [dateFormat]="mapDateFormat(dateFormat)" showIcon="true"></p-calendar>
    </ng-template>
</div>
<div class="p-column-filter-buttonbar p-0 pt-40">
    <button pbutton pripple (click)="ClearFilter()" class="p-button-outlined p-button-sm p-button">
        <span class="p-button-label">{{'List.Filter.Clear'| transloco}}</span>
    </button>
    <button pbutton pripple (click)="ApplyFilter()" class="p-button-sm p-button">
        <span class="p-button-label">{{'List.Filter.Apply'| transloco}}</span>
    </button>
</div>