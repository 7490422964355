import { createAction, props } from '@ngrx/store'
import { StructuredDataEntryCreateDTO } from '../../data-transfer-objects/structured-data/entry/structured-data-entry-create-dto';
import { PaginationModel, PaginationResultModel } from '../../models/pagination-models';
import { ErrorDetails } from '../../models/error-details';
import { StructuredDataEntryModel } from '@landadmin/structured-data/lib/models/structured-data-entry-model';

//LOAD STRUCTURED DATA
export const loadStructuredDataEntryRequest = createAction('[StructuredData] Load Structured Data Entry Request',
    props<{
        pageId: string,
        rootNodeId: string,
        entityId: string,
        paginationModel: PaginationModel
    }>());

export const loadStructuredDataEntryDone = createAction('[StructuredData] Load Structured Data Entry Done',
    props<{
        rootNodeId: string,
        data: PaginationResultModel<StructuredDataEntryModel>
    }>());

//UPDATES
export const updateStructuredDataEntryRequest = createAction('[StructuredData] Update Structured Data Entry Request',
    props<{
        pageId: string,
        rootNodeId: string,
        entityId: string,
        structuredDataUpdateModel: StructuredDataEntryCreateDTO
    }>());

//ADDS
export const createStructuredDataEntryRequest = createAction('[StructuredData] Create Structured Data Entry Request',
    props<{
        pageId: string,
        rootNodeId: string,
        entityId: string,
        structuredDataCreateModel: StructuredDataEntryCreateDTO
    }>());

//DELETES
export const deleteStructuredDataEntryRequest = createAction('[StructuredData] Delete Structured Data Entry Request',
    props<{
        pageId: string,
        rootNodeId: string,
        entityId: string,
        parentEntryId: string
    }>());

export const endStructuredDataOperationSuccess = createAction('[StructuredData] Operation end', props<{ rootNodeId: string }>());

export const endStructuredDataOperationFail = createAction('[StructuredData] Operation end', props<{ rootNodeId: string, errorDetails: ErrorDetails }>());

//RESET STATE
export const resetStructuredDataEntry = createAction('[StructuredData] Reset Structured Data Entry');
