import { createReducer, on } from '@ngrx/store';
import { ActionWidgetState, DeleteJobState, DocumentGroupWidgetState, RunShapeValidationState, ShapeValidationResultsState, ShapeWidgetState } from '../../../AppState';
import { ListStateReducerHelper } from '../../../helpers/list-state-reducer-helper';
import { addActionFormDocumentDraftDone, addActionFormDocumentDraftError, createActionDocumentDraftResetState, deleteJobDone, deleteJobError, getActionFormDocumentDraftsDone, getActionFormDocumentDraftsError, getShapeDraftDone, getShapeDraftError, getShapeValidationResultsDone, getShapeValidationResultsError, runShapeValidationDone, runShapeValidationError, shapeDraftResetState, shapeValidationResetState, shapeValidationResultsResetState, updateActionFormDocumentDraftDone, updateActionFormDocumentDraftError, updateShapeDraftDone, updateShapeDraftError } from './create-action-details-form.actions';

export const initialCreateActionActionWidgetState: ActionWidgetState = {
    ActionViewDTO: {
        Id: '', TypeId: '', AccountingCode: '', AdditionalFieldDate: null,
        DueDate: null, ReferenceNumber: '', PeriodFrom: null,
        PeriodTo: null, Code: '', Comments: '', Name: '', AssetId: null
    },
    GetActionError: null,
    UpdateActionError: null
};


export const initialCreateActionDocumentDraftsDTO: DocumentGroupWidgetState =
{
    triggeredDataSourceId: null,
    listState: [],
    widgetConfigurationId: null
};

export const DocumentDraftsReducer = createReducer<DocumentGroupWidgetState>(initialCreateActionDocumentDraftsDTO,
    on(getActionFormDocumentDraftsDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),

    on(getActionFormDocumentDraftsError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),

    on(addActionFormDocumentDraftDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(addActionFormDocumentDraftError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),

    on(updateActionFormDocumentDraftDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(updateActionFormDocumentDraftError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),

    on(createActionDocumentDraftResetState, () => initialCreateActionDocumentDraftsDTO)
);

export const initialShapeDraftDTO: ShapeWidgetState = {
    GetShapeError: null,
    ListState: null,
    UpdateShapeError: null
}

export const ShapeDraftReducer = createReducer<ShapeWidgetState>(initialShapeDraftDTO,
    on(updateShapeDraftDone, (_, action) => action.shapeDraftWidgetState),
    on(updateShapeDraftError, (_, action) => action.shapeDraftWidgetState),
    on(getShapeDraftDone, (_, action) => action.shapeDraftWidgetState),
    on(getShapeDraftError, (_, action) => action.shapeDraftWidgetState),
    on(shapeDraftResetState, () => initialShapeDraftDTO)
);

export const initialShapeValidationState: RunShapeValidationState = {
    JobSchedulerViewDTO: null,
    RunShapeValidationError: null
};

export const RunShapeValidationReducer = createReducer<RunShapeValidationState>(initialShapeValidationState,
    on(runShapeValidationDone, (_, action) => action.runShapeValidationState),
    on(runShapeValidationError, (_, action) => action.runShapeValidationState),
    on(shapeValidationResetState, () => initialShapeValidationState)
);

export const initialShapeValidationResultsState: ShapeValidationResultsState = {
    JobQueryResultViewDTO: null,
    GetShapeValidationResultsError: null
};

export const GetShapeValidationResultsReducer = createReducer<ShapeValidationResultsState>(initialShapeValidationResultsState,
    on(getShapeValidationResultsDone, (_, action) => action.getShapeValidationResultsState),
    on(getShapeValidationResultsError, (_, action) => action.getShapeValidationResultsState),
    on(shapeValidationResultsResetState, () => initialShapeValidationResultsState)
);

export const initialDeleteJobState: DeleteJobState = {
    DeleteJobError: null
};

export const DeleteJobReducer = createReducer<DeleteJobState>(initialDeleteJobState,
    on(deleteJobDone, (_, action) => action.deleteJobState),
    on(deleteJobError, (_, action) => action.deleteJobState)
);
