import { Component, DoCheck, ElementRef, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { AssetDetailsWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/asset-details-widget-configuration-view-dto';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { ConfigurableControlsHelper } from '../../../../../helpers/configurable-controls-helper';
import { AssetViewDTO } from '../../../../../data-transfer-objects/asset/asset-view-dto';
import { AbstractAssetDetailsFacade } from '../../../../../facade/abstract/abstract-asset-details.facade';
import { UIStylingHelper } from 'src/app/helpers/ui-styling-helper';
import { BaseDetailsWidget } from '../base-details-widget';

@Component({
    selector: 'fw-asset-details-widget',
    templateUrl: './asset-details-widget.component.html'

})
export class AssetDetailsWidgetComponent extends BaseDetailsWidget<AssetViewDTO> implements OnInit, OnDestroy, DoCheck {
    public loaded: boolean = false;

    @Input()
    public AcceptChanges: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public AssetDetailsWidgetConfiguration: AssetDetailsWidgetConfigurationViewDTO;

    @Input()
    public FormEditStyle: FormEditStyle;

    constructor(
        private assetDetailsFacade: AbstractAssetDetailsFacade,
        public elementRef: ElementRef) {
        super(elementRef);
    }
    ngDoCheck(): void {
        this.assetDetailsFacade.SetValidity(this.dataSourceId, this.FormGroup.valid);
    }

    ngOnInit(): void {

        super.ngOnInit();

        this.assetDetailsFacade.LoadAssetDetails();

        const getAssetDetailsSubscription = this.assetDetailsFacade.AssetSelector().subscribe((assetWidgetState) => {
            if (assetWidgetState.AssetViewDTO) {
                this.loaded = true;
                this.assetDetailsFacade.PrimaryWidgetCompletedLoading.next(assetWidgetState.AssetViewDTO);
                this.DataModel = assetWidgetState.AssetViewDTO;
                ConfigurableControlsHelper.PopulateFormControl(this.DataModel, this.AssetDetailsWidgetConfiguration.Properties, this.FormGroup);
            }
        });

        const acceptChangesSubscription = this.AcceptChanges.subscribe(() => {
            const clonedAsset = { ...this.DataModel, ...this.FormGroup.value };
            this.DataModel = clonedAsset;
            this.convertHijriDatesToGregorian();
            this.assetDetailsFacade.UpdateAsset(this.DataModel);
        });

        this.Subscriptions.push(getAssetDetailsSubscription);
        this.Subscriptions.push(acceptChangesSubscription);
    }   

    public getLayoutMode(): string {
        return UIStylingHelper.getLayoutMode(this.AssetDetailsWidgetConfiguration.LayoutMode)
    }

    public isReadOnly(): boolean {
        return this.isWidgetReadOnly(this.AssetDetailsWidgetConfiguration);
     }
}
