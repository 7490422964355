import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigurationState, ConfigurationStateConst } from '../../AppState';

export const getWidgetState = createFeatureSelector<ConfigurationState>(ConfigurationStateConst);

export const getStructuredDataLookupConfigurationState = createSelector(
    getWidgetState,
    (state: ConfigurationState) => state.structuredDataLookupConfigurationState
);

export const getStructuredDataNodeConfigurationState = createSelector(
    getWidgetState,
    (state: ConfigurationState) => state.structuredDataNodeConfigurationState
);

export const getPageConfigurationState = createSelector(
    getWidgetState,
    (state: ConfigurationState) => state.pageConfigurationState
);

