import { Observable, Subject, Subscriber } from 'rxjs';


export class BootstrapHelper {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static CleanTooltip(jquery: any): void {

        jquery('.tooltip').remove();


    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static HideModal(element: HTMLElement, jquery: any): void {
        jquery(`#${element.id}`).modal('hide');

    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static InitializeTooltip(element: HTMLElement, jquery: any, tooltip: string): void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static InitializeTooltip(element: HTMLElement, jquery: any, tooltip: string, placement: string): void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static InitializeTooltip(element: HTMLElement, jquery: any, tooltip: string, placement: string = 'auto'): void {

        jquery(element).tooltip('dispose');

        jquery(element).tooltip({
            html: true,
            placement: placement,
            title: tooltip,
            boundary: 'window',
            container: 'body'
        });

    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static ShowModal(element: HTMLElement, jquery: any, closeable: any): Observable<boolean> {

        jquery(`#${element.id}`).modal({
            backdrop: 'static',
            keyboard: false
        });

        jquery(`#${element.id}`).on('hidden.bs.modal', function (e) {

            closeable.onClose();

        });

        return new Observable((subscriber: Subscriber<null>) => {

            jquery(`#${element.id}`).on('shown.bs.modal', function (e) {
                subscriber.next();
            });

        });


    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static ToggleCollapse(element: HTMLElement, jquery: any): void {

        jquery(element).collapse('toggle');

    }
}
