
export enum LandfolioLookupTables {
    AbbreviationFullName,
    AccountAttribute,
    AccountParentType,
    AccountStatus,
    AccountTransactionAttribute,
    AccountTransactionStatus,
    AccountType,
    Action_ApprovalStatus,
    ActionApproval_Status,
    ActionApproval_Type,
    ActionApprovalAttribute,
    ActionApprovalAttribute_AttributeType,
    ActionAssociatedObjectType,
    ActionAttribute,
    ActionAttribute_ActionTypeGroup,
    ActionAttribute_AttributeType,
    ActionDate,
    ActionDue,
    ActionReferenceCodeType,
    ActionReferenceCodeTypeAbbreviation,
    ActionRelatedAction,
    ActionStatus,
    ActionType,
    ActionType_ActionTypeGroup,
    ActionType_Descriptive,
    ActionType_MayContainChildActions,
    ActionTypeDecisionResult,
    ActionTypeGenericActionResult,
    ActionTypeGroup,
    ActionTypeGroup_WithActionTypes,
    ActionTypeReferenceNumberContext,
    ActiveInactive,
    AddressAttribute,
    AgreementAttribute,
    AgreementAttribute_AttributeType,
    AgreementAttribute_Dates,
    AgreementAttribute_Related,
    AgreementPartyType,
    AgreementPartyType_PartyUnitFieldSetting,
    AgreementPartyType_Unit,
    AgreementStatus,
    AgreementStatus_AgreementStatusGroup,
    AgreementType,
    AgreementTypeAbbr,
    AgreementWizard,
    AliasProvince,
    AreaUnit,
    AreaUnitAbbreviation,
    AreaUnitDecimals,
    AreaUnitToDeg2Conversion,
    AreaUnitToKm2Conversion,
    AreaUnitType,
    AssetClass,
    AssetClassDate,
    AssetClassPlural,
    AssetInterest,
    AssetInterest_AssetType,
    AssetReferenceCodeType,
    AssetReferenceCodeTypeAbbreviation,
    AssetStatusGroup,
    AssetStatusGroupAbbreviation,
    AssetSubType,
    AssetSubType_Type,
    AssetSubTypeAbbr,
    AssetType,
    AssetType_Group,
    AssetTypeGroup_AssetClass,
    AssetTypeGroup_MenuPosition,
    AttributeType,
    AuthenticationLogType,
    AuthenticationModules,
    AuthenticationType,
    Authorisation,
    AuthorisationAccessLevel,
    AuthorisationModule,
    BankAccountAttribute,
    BeforeAfter,
    BusinessRuleAttribute,
    BusinessRuleAttribute_AttributeType,
    BusinessRuleOfficialAreaOptions,
    BusinessRuleType,
    CDEXUnitType,
    ChileanLandManagementArticleApprovalStatus,
    ChileanLandManagementArticleDataStatus,
    ChileanLandManagementArticleType,
    ChileanLandManagementArticleTypeAbbr,
    ChileanLandManagementBulletinApprovalStatus,
    ChileanLandManagementBulletinDataStatus,
    ChileanLandManagementOverlapStatus,
    ChileanLandManagementTriggerOn,
    Commodity,
    CommodityCode,
    CommodityGroup,
    CommunicationDate,
    CommunicationDirection,
    CommunicationMedium,
    CommunicationResult,
    CommunicationType,
    CompanyAttribute,
    CompanyPosition,
    CompanyPositionAbb,
    CompanyPositionStatus,
    CompanyReferenceCodeType,
    CompanyReferenceCodeTypeAbbr,
    CompanyStatus,
    CompanyType,
    CompanyTypeAbb,
    CompassPoint,
    ConditionType,
    ConditionType_Agreement,
    ConditionType_AllowEdit,
    ConditionType_License,
    ConditionType_UnitType,
    ContactType,
    ContentDirection,
    CoordinateFormat,
    Counter,
    CounterType,
    Country,
    Culture,
    Currency,
    CurrencyCode,
    CurrencyExchangeRate,
    CurrencyExchangeRateUpdated,
    CustomUnit,
    CustomUnitDecimal,
    DataFilterType,
    DataFilterType_FilterGroup,
    DateRoundingType,
    DateTimeComparison,
    DecisionResult_ActionType,
    DisplayMode,
    DistanceUnit,
    DistanceUnitAbbreviation,
    DistanceUnitDecimals,
    DistanceUnitToKmConversion,
    DMSType,
    DocumentAddMethod,
    DocumentAttribute,
    DocumentExtensions,
    DocumentStorageType,
    DocumentType,
    DocumentType_DefaultRepository,
    DocumentViewStyle,
    DynamicShapeOfficialAreaOptions,
    EastWest,
    ErrorMessage,
    FeatureClass_GeodatabaseID,
    FeatureClass_SpatialReferenceID,
    FeatureClassAlias,
    FeatureClassName,
    FeatureClassObjectType,
    FileType,
    FlexiSchedulerLastRunStatus,
    FoundNotFound,
    Gender,
    GenericActionAttribute,
    GeodatabaseType,
    GeographicSpatialReference,
    GeometryType,
    GeoTransformation,
    GeoTransformationType,
    GrantingAttribute,
    GrantingDate,
    GrantingExpiryCalculationType,
    GridSplitOrigin,
    GroupStatus_GroupStatusGroup,
    HistoryContextType,
    HistoryType,
    HoldAttribute,
    HoldDate,
    Holiday,
    Holiday_Dates,
    Holiday_Jurisdictions,
    ImportMessageType,
    ImportType,
    IndirectFromObjectRelationship,
    Individual_Company,
    Individual_Person,
    Individual_User,
    InnerSearchLegalEntityActionFilter,
    InnerSearchRelationshipAction,
    InnerSearchRelationshipActionInputType,
    InnerSearchRelationshipAgreement,
    InnerSearchRelationshipAgreementInputType,
    InnerSearchRelationshipCompany,
    InnerSearchRelationshipCompanyInputType,
    InnerSearchRelationshipDocument,
    InnerSearchRelationshipDocumentInputType,
    InnerSearchRelationshipGroup,
    InnerSearchRelationshipGroupInputType,
    InnerSearchRelationshipHistory,
    InnerSearchRelationshipHistoryInputType,
    InnerSearchRelationshipLicense,
    InnerSearchRelationshipLicenseInputType,
    InnerSearchRelationshipPerson,
    InnerSearchRelationshipPersonInputType,
    InterfaceCulture,
    InterfaceFieldSetting,
    InterfaceLanguage,
    InterfaceLanguageCulture,
    Jurisdiction,
    JurisdictionAttribute,
    Language,
    Language_ContentDirection,
    LanguageAbbr,
    LawType,
    LegalEntityAttribute,
    LegalEntityType,
    LicenseAttribute,
    LicenseAttribute_Active,
    LicenseAttribute_ActiveDates,
    LicenseAttribute_ActiveDatesNextAnniversaryOf,
    LicenseAttribute_All,
    LicenseAttribute_AttributeType,
    LicenseAttribute_Related,
    LicenseGroupActionTypeGroups,
    LicenseGroupAttribute,
    LicenseGroupAttribute_AttributeType,
    LicenseGroupAttribute_Dates,
    LicenseGroupAttribute_History,
    LicenseGroupAttribute_Related,
    LicenseGroupPartyType,
    LicenseGroupPartyType_PartyInterestUnit,
    LicenseGroupPartyType_PartyUnitFieldSetting,
    LicenseGroupStatus,
    LicenseGroupType,
    LicensePartyType,
    LicensePartyType_PartyInterestUnit,
    LicensePartyType_PartyUnitFieldSetting,
    LicenseStatus,
    LicenseStatus_LicenseStatusGroup,
    LicenseStatusGUID,
    LicenseStatusGUID_LicenseStatus,
    LicenseStatusGUID_LicenseStatusGroup,
    LicenseType,
    LicenseType_Abbreviation,
    LicenseType_AreaExclusionSpatialDataset,
    LicenseType_Comment,
    LicenseType_Descriptive,
    LicenseType_GeometryType,
    LicenseType_Jurisdiction,
    LicenseType_LicenseTypeGroup,
    LicenseType_NonSpatial,
    LicenseType_OfficialAreaUnit,
    LicenseTypeGroup,
    LicenseTypeGroup_VisibleInMenu,
    LicenseWizard,
    LookupCategory,
    LookupTable_Category,
    LookupTableName,
    Map,
    MapExportFormat,
    MapLayerName,
    MapLayerObjectType,
    MapLayerVisibility,
    MapLayoutDocument,
    MapReferenceCategory,
    MapReferenceCategory_ChildSeperator,
    MapReferenceCategory_IncludeInString,
    MapReferenceCategory_MapReferenceCategoryParent,
    MapReferenceCategory_MapReferenceCategoryParentLevel,
    MapReferenceCategory_UseAbbreviationInString,
    MapReferenceOverlapTopology,
    MapReferenceOverlapTopologyFailure,
    MapServiceName,
    MapServiceType,
    MapSize,
    MapTemplateDocument,
    MaritalStatus,
    MathOperation,
    MenuLayout,
    MenuSearch_Name,
    MenuSearch_SearchID,
    Month,
    MultipleResultSelectionMethod,
    NorthSouth,
    NotificationMedium,
    NotificationStatus,
    NotificationType,
    NumberingOrientation,
    ObjectType,
    ObjectTypeDocumentLinkedTo,
    Office,
    OfficeAbbreviation,
    OperatorNumeric,
    OperatorString,
    OptionList,
    OptionListItems,
    ParameterValue,
    ParameterValue_WithLegalEntityInputOrOutput,
    ParameterValueInputTypes,
    ParameterValueInputTypes_AttributeTypeID,
    PartyType,
    PartyTypeUnit,
    PaymentAttribute,
    PaymentDirection,
    PaymentItemQuantityType,
    PaymentMethod,
    PaymentResult,
    PaymentType,
    PersonAttribute,
    PersonReferenceCodeType,
    PersonReferenceCodeTypeAbbr,
    PersonStatus,
    Profession,
    RectilinearCheckType,
    ReferencePointCalculation,
    ReferencePointType,
    ReferencePointType_CalculationMethod,
    Region,
    RegionAttribute,
    RelationshipAmount,
    RelationshipConstraint,
    RelationshipType,
    RenewalAttribute,
    RenewalDate,
    RenewalShape,
    ReportChartStyle,
    ReportChartType,
    ReportingGroup,
    ReportListFilters,
    ReportListItems,
    ReportObject,
    ReportOutputFormat,
    ReportSchema,
    ReportSchema_DisplayTrue,
    ReportSchema_ReportType,
    ReportType,
    Role,
    RoleAbbreviation,
    RoundingDecimals,
    RoundingStyle,
    Rule_ActionType,
    Rule_LicenseType,
    Rule_WorkflowId,
    RuleAbbreviation,
    RuleExecutionContext,
    RuleExecutionContext_Agreement,
    RuleExecutionContext_Group,
    RuleExecutionContext_License,
    RuleName,
    RuleOperator,
    RuleParameter,
    Salutation,
    ScheduleCriterionType,
    ScheduleOffsetDirection,
    SchedulePaymentProportionType,
    ScheduleSeedDateTypeAgreement,
    ScheduleSeedDateTypeLicense,
    ScheduleSeedDateTypeLicenseGroup,
    SearchDateRanges,
    SearchFilters,
    SearchOptions,
    SearchSummaryWidgetRowFilterType,
    SearchType,
    SeedAction,
    SeedAgreement,
    SeedLicense,
    SeedLicenseGroup,
    SeedObject,
    SeedObjectAttribute,
    SeedObjectFromObjectType,
    SeedOwnershipType,
    SeedShapeType,
    Sensitivity,
    SeverityType,
    ShapeChangeAttribute,
    ShapeChangeShape,
    ShapeCopyOptions,
    ShapeEditorTool,
    ShapeIsValid,
    ShapeSearchObject,
    ShapeValidationAttribute,
    ShapeValidationCheckFlag,
    ShapeValidationShape,
    SharePointAuthentication,
    SpatialDataObjectType,
    SpatialDataset,
    SpatialDataset_ObjectType,
    SpatialFilterType,
    SpatialReference,
    SpatialReference_SpatialReferenceType,
    SpatialReferenceType,
    StatisticType,
    StatisticType_IncludeInString,
    StatisticType_ValueOption,
    SupportedGeometryTypes,
    SurveyAttribute,
    SurveyShape,
    TaskType,
    TaxType,
    TaxTypeValue,
    TimeInterval,
    TimeInterval_TimeIntervalUnit,
    TimeIntervalUnit,
    ToFrom,
    TransferAttribute,
    TransferStage,
    TranslationArea,
    TranslationStatus,
    TrueFalse,
    UnitSystem,
    UnitType,
    UpdateableLicenseAttribute,
    UpdateBehaviour,
    UrlAlias,
    UrlAliasUrl,
    User_UserType,
    UserAttribute,
    UsersName,
    UsersName_Active,
    UsersOrUsersAndUserGroups,
    UsersUserName,
    UserType,
    ValuationMethod,
    ValuationType,
    ValueOption_DefaultUnit,
    ValueOption_ValueType,
    ValueType,
    VolumeUnit,
    VolumeUnitAbbreviation,
    VolumeUnitDecimal,
    Website,
    WeightUnit,
    WeightUnitAbbreviation,
    WeightUnitDecimal,
    WizardFirstPage,
    WizardType,
    WorkCommitmentAttribute,
    WorkCommitmentCategory,
    WorkCommitmentDate,
    WorkCommitmentEntryLevel,
    WorkCommitmentExemptionStatus,
    WorkCommitmentSubCategory,
    WorkFlow,
    WorkFlow_Jurisdiction,
    WorkflowAssociation_Agreement,
    WorkflowAttribute,
    WorkFlowType,
    YesNo
}

export enum LookupTables {

    Roles = 1,
    AuditLogType = 2,
    UserAccountStatus = 3
}
