import { ActionReducerMap } from '@ngrx/store';
import { AssetDraftPageState } from '../../../AppState';
import { DocumentDraftsReducer, ShapeDraftReducer, RunShapeValidationReducer, GetShapeValidationResultsReducer, DeleteJobReducer } from './create-asset-details-form.reducers';

export const AssetDraftPageReducerMapper: ActionReducerMap<AssetDraftPageState> = {
    ShapeDraftWidgetState: ShapeDraftReducer,
    DocumentGroupWidgetState: DocumentDraftsReducer,    
    RunShapeValidationState: RunShapeValidationReducer,
    ShapeValidationResultsState: GetShapeValidationResultsReducer,
    DeleteJobState: DeleteJobReducer
};
