import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { HttpFieldModel } from 'src/app/models/field-list-model';
import { RelatedAssetHttpService } from 'src/app/services/http/related-asset-http-service';
import { addRelatedAssets, endOperation, getRelatedAssetsBySearchDone, getRelatedAssetsBySearchRequest, removeRelatedAssets, startOperation } from './related-assets.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/AppState';

@Injectable()
export class RelatedAssetsEffects {

    getRelatedAssetsBySearch$ = createEffect(() => this.actions$.pipe(
        ofType(getRelatedAssetsBySearchRequest),
        mergeMap((action) => {
            const httpFieldModel = new HttpFieldModel();
            httpFieldModel.Fields = new Array<string>();

            action.fields.forEach(field => {
                httpFieldModel.Fields.push(field.PropertyName);
            });

            return this.relatedAssetHttpService.getRelatedAssetsBySearch(action.paginationModel, action.relatedAssetSearchDTO, httpFieldModel, action.pageId).pipe(
                map(paginationResultModel => getRelatedAssetsBySearchDone({
                    dataSourceId: action.dataSourceId,
                    result: paginationResultModel
                }))
            );
        })
    ));

    addRelatedAssets$ = createEffect(() => this.actions$.pipe(
        ofType(addRelatedAssets),
        pipe(mergeMap((action) => {
                this.store.dispatch(startOperation({dataSourceId: action.dataSourceId}));

                return this.relatedAssetHttpService
                    .addRelatedAssets(action.assetId, action.relatedAssets, action.pageId)
                    .pipe(map(() => action.dataSourceId));
            }),
            map(dataSourceId => endOperation({dataSourceId: dataSourceId})))
    ));

    removeRelatedAssets$ = createEffect(() => this.actions$.pipe(
        ofType(removeRelatedAssets),
        pipe(mergeMap((action) => {
                this.store.dispatch(startOperation({dataSourceId: action.dataSourceId}));

                return this.relatedAssetHttpService
                    .removeRelatedAssets(action.assetId, action.relatedAssetIds, action.pageId)
                    .pipe(map(() => action.dataSourceId));
            }),
            map(dataSourceId => endOperation({dataSourceId: dataSourceId})))
    ));

    constructor(private actions$: Actions, private relatedAssetHttpService: RelatedAssetHttpService, private store: Store<AppState> ) {
    }
}
