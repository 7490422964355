import { Injectable } from "@angular/core";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";
import { LanguageHttpService } from "../services/http/language-http.service";
import uq from '@umalqura/core';

@Injectable()
export class HijriCalendar18n extends NgbDatepickerI18n {

    constructor(
        protected transloco: TranslocoService,
        private languageHttpService: LanguageHttpService

    ) {
        super();
        uq.$.setLocale(this.languageHttpService.getCurrentLanguageLocale());
    }

    getWeekdayShortName(weekday: number): string {
        return uq.daysShort()[weekday - 1];
    }

    getWeekdayLabel(weekday: number) {
        return uq.daysShort()[weekday - 1];
    }

    getMonthShortName(month: number) {
        return uq.months()[month - 1];
    }

    getMonthFullName(month: number) {
        return uq.months()[month - 1];
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }

    getDayNumerals(date: NgbDateStruct): string {
        return uq.localizeNum(date.day);
    }

    getYearNumerals(year: number): string {
        return uq.localizeNum(year);
    }
}
