import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgMappingLibraryModule } from '@landadmin/ng-mapping-library';
import { StructuredDataModule } from '@landadmin/structured-data';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { QuillModule } from 'ngx-quill';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { MessagesModule } from 'primeng/messages';
import { RadioButtonModule } from 'primeng/radiobutton';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { PipeModule } from 'src/app/pipes/pipe-module';
import { TranslocoRootModule } from '../../transloco/transloco-root.module';
import { DirectivesModule } from '../directives/directives.module';
import { SelectFromListBoxComponent } from './components/dialogs/select-from-listbox.component';
import { AddNewDiscussionTopicComponent } from './components/discussion-add-new/discussion-add-new.component';
import { DiscussionCommentsListComponent } from './components/discussion-comments-list/discussion-comments-list.component';
import { DiscussionTopicCommentComponent } from './components/discussion-topic-comment/discussion-topic-comment.component';
import { DiscussionTopicListComponent } from './components/discussion-topic-list/discussion-topic-list.component';
import { DocumentRowComponent } from './components/document-row/document-row.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ModalContainerComponent } from './components/form-modal-container/modal-container.component';
import { SelectableListItemHeaderComponent } from './components/list-items/selectable-list-item-header/selectable-list-item-header.component';
import { SelectableListItemComponent } from './components/list-items/selectable-list-item/selectable-list-item.component';
import { AuditListComponent } from './components/lists/audit-list/audit-list.component';
import { CompanyListComponent } from './components/lists/company-list/company-list.component';
import { ListComponent } from './components/lists/list.component';
import { LookupListComponent } from './components/lists/lookup-list/lookup-list.component';
import { PersonListComponent } from './components/lists/person-list/person-list.component';
import { PortalSearchListComponent } from './components/lists/portal-search-list/portal-search-list.component';
import { RepresentationListComponent } from './components/lists/representation-list/representation-list.component';
import { RepresentativesOfListComponent } from './components/lists/representatives-of/representatives-of.component';
import { UserListComponent } from './components/lists/user-list/user-list.component';
import { MessageComponent } from './components/message/message.component';
import { PortalMapComponent } from './components/portal-map/portal-map.component';
import { SelectDocumentTypeComponent } from './components/select-document-type/select-document-type/select-document-type.component';
import { StructuredDataComponent } from './components/structured-data/structured-data.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { AddRepresentationComponent } from './forms/add-representation/add-representation.component';
import { AuthenticationSettingsFormComponent } from './forms/authentication-settings-form/authentication-settings-form.component';
import { ForgotPasswordFormComponent } from './forms/forgot-password-form/forgot-password-form.component';
import { IdpSelectionFormComponent } from './forms/idp-selection-form/idp-selection-form.component';
import { LoginFormComponent } from './forms/login-form/login-form.component';
import { NewEmailFormComponent } from './forms/new-email-form/new-email-form.component';
import { OneTimePinFormComponent } from './forms/one-time-pin-form/one-time-pin-form.component';
import { PortalSearchFormComponent } from './forms/portal-search-form/portal-search-form.component';
import { RegisterUserFormComponent } from './forms/register-user-form/register-user-form.component';
import { ResetPasswordFormComponent } from './forms/reset-password-form/reset-password-form.component';
import { RoleSwitchFormComponent } from './forms/role-switch-form/role-switch-form.component';
import { SdEditFormComponent } from './forms/sd-edit-form/sd-edit-form.component';
import { SearchPhraseFormComponent } from './forms/search-phrase-form/search-phrase-form.component';
import { SetPasswordFormComponent } from './forms/set-password-form/set-password-form.component';
import { SwitchRepresentationComponent } from './forms/switch-representation/switch-representation.component';
import { GenericControlsModule } from './generics/generic-controls.module';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { EditorModule } from 'primeng/editor';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { DateColumnFilterComponent } from './components/lists/custom-filters/date-filter/date-column-filter.component';
import { LookupColumnFilterComponent } from './components/lists/custom-filters/lookup-filter/lookup-column-filter.component';
import { StringColumnFilterComponent } from './components/lists/custom-filters/string-filter/string-column-filter.component';
import { NumberColumnFilterComponent } from './components/lists/custom-filters/number-filter/number-column-filter.component';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { ConfigurationHttpService } from '../../services/http/configuration-http.service';
import { ListCalendarWrapperComponent } from '../dynamic/controls/wrappers/list-calendar-wrapper/list-calendar-wrapper.component';

@NgModule({
    declarations: [
        ModalContainerComponent,
        SetPasswordFormComponent,
        LoginFormComponent,
        SearchPhraseFormComponent,
        PortalSearchListComponent,
        UserListComponent,
        PersonListComponent,
        CompanyListComponent,
        LookupListComponent,
        ForgotPasswordFormComponent,
        RoleSwitchFormComponent,
        RepresentationListComponent,
        AuditListComponent,
        PortalSearchFormComponent,
        RepresentativesOfListComponent,
        StructuredDataComponent,
        SdEditFormComponent,
        PortalMapComponent,
        ResetPasswordFormComponent,
        NewEmailFormComponent,
        UserMenuComponent,
        RegisterUserFormComponent,
        IdpSelectionFormComponent,
        OneTimePinFormComponent,
        AuthenticationSettingsFormComponent,
        SelectFromListBoxComponent,
        AddNewDiscussionTopicComponent,
        DiscussionCommentsListComponent,
        DiscussionTopicCommentComponent,
        DiscussionTopicListComponent,
        DocumentRowComponent,
        FileUploadComponent,
        SelectableListItemComponent,
        SelectableListItemHeaderComponent,
        ListComponent,
        SelectDocumentTypeComponent,
        MessageComponent,
        SwitchRepresentationComponent,
        AddRepresentationComponent,
        DateColumnFilterComponent,
        LookupColumnFilterComponent,
        NumberColumnFilterComponent,
        ListCalendarWrapperComponent,
        StringColumnFilterComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        PipeModule,
        ReactiveFormsModule,
        NgbDatepickerModule,
        RouterModule,
        NgxPaginationModule,
        NgMappingLibraryModule,
        DirectivesModule,
        TranslocoRootModule,
        QuillModule.forRoot(),
        DialogModule,
        DynamicDialogModule,
        RadioButtonModule,
        CheckboxModule,
        ButtonModule,
        TableModule,
        StyleClassModule,
        StructuredDataModule,
        CalendarModule,
        GenericControlsModule,
        DataViewModule,
        MessagesModule,
        DropdownModule,
        InputTextModule,
        InputNumberModule,
        InputTextareaModule,
        EditorModule,
        ProgressSpinnerModule,
        ToggleButtonModule,
        MultiSelectModule,
        RecaptchaModule        
    ],
    exports: [
        ModalContainerComponent,
        SetPasswordFormComponent,
        LoginFormComponent,
        SearchPhraseFormComponent,
        PortalSearchListComponent,
        UserListComponent,
        PersonListComponent,
        CompanyListComponent,
        LookupListComponent,
        ForgotPasswordFormComponent,
        RoleSwitchFormComponent,
        RepresentationListComponent,
        AuditListComponent,
        PortalSearchFormComponent,
        RepresentativesOfListComponent,
        StructuredDataComponent,
        SdEditFormComponent,
        PortalMapComponent,
        ResetPasswordFormComponent,
        NewEmailFormComponent,
        UserMenuComponent,
        RegisterUserFormComponent,
        IdpSelectionFormComponent,
        OneTimePinFormComponent,
        AuthenticationSettingsFormComponent,
        SelectFromListBoxComponent,
        AddNewDiscussionTopicComponent,
        DiscussionCommentsListComponent,
        DiscussionTopicCommentComponent,
        DiscussionTopicListComponent,
        DocumentRowComponent,
        FileUploadComponent,
        SelectableListItemComponent,
        SelectableListItemHeaderComponent,
        ListComponent,
        SelectDocumentTypeComponent,
        MessageComponent,
        SwitchRepresentationComponent,
        AddRepresentationComponent,
        DateColumnFilterComponent,
        LookupColumnFilterComponent,
        NumberColumnFilterComponent,
        ListCalendarWrapperComponent,
        StringColumnFilterComponent
    ],
    providers: [{
        provide: RECAPTCHA_SETTINGS,
        useFactory: (configurationHttpService: ConfigurationHttpService) => {
            return { siteKey: configurationHttpService.GetReCaptchaSiteKey } as RecaptchaSettings;
        },
        deps: [ConfigurationHttpService]
    }],
})
export class StaticModule { }
