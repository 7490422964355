import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { CommodityViewDTO } from '../../../../../data-transfer-objects/commodity/commodity-view-dto';
import { CommoditiesWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/commodities-widget-configuration-view-dto';
import {
  DialogApplicationService,
  DialogOptions,
} from '../../../../../services/application/dialog-application.service';
import { LookupCacheService } from '../../../../../services/deprecated/lookup-cache.service';
import { AbstractCommoditiesFacade } from '../../../../../facade/abstract/abstract-commodities-facade';
import { EditCommodityBaseComponent } from '../../edit-commodity-base';
import { SelectCommoditiesComponent } from '../../select-commodities/select-commodities.component';

@Component({
  selector: 'fw-inline-edit-commodity',
  templateUrl: './inline-edit-commodity.component.html',
  host: { class: 'inline-edit' },
})
export class InlineEditCommodityComponent extends EditCommodityBaseComponent {
  constructor(
    protected translationService: TranslocoService,
    private lookupService: LookupCacheService,
    private dialogApplicationService: DialogApplicationService,
    protected commoditiesFacade: AbstractCommoditiesFacade
  ) {
    super(translationService, lookupService, commoditiesFacade);
  }

  OpenCommodityModal(): void {
    const dialogOptions: DialogOptions<{
      Configuration: CommoditiesWidgetConfigurationViewDTO;
      FormGroup: FormGroup;
      Commodities: CommodityViewDTO[];
    }> = {
      closable: true,
      footer: '',
      showHeader: true,
      header: this.translocoService.translate('SelectCommodities.HeaderLabel'),
      dataModel: {
        Configuration: this.Configuration,
        FormGroup: this.CustomFormGroup,
        Commodities: this.Commodities,
      },
      styleClass: 'commodities-dialog',
    };

    const dialogRef = this.dialogApplicationService.showFormDialog(
      SelectCommoditiesComponent,
      dialogOptions
    );
    dialogRef.onClose.subscribe((commodities: CommodityViewDTO[]) => {
      if (commodities) {
        this.onAcceptChanges();
      } else {
        this.onCancelChanges();
      }
    });
  }
}
