import { LanguageDetailViewDTO } from '../data-transfer-objects/language/language-detail-view-dto';
import { ContentDirection } from '../enums/content-direction-enum';
import { LanguageHttpService } from '../services/http/language-http.service';

export class LanguageHelper {
    public static UpdateContentDirection(languageService: LanguageHttpService, languageId: number): void {
        languageService.GetById(languageId).subscribe((language: LanguageDetailViewDTO) => {
            let dir : string = 'ltr';
            
            if (language.ContentDirection == ContentDirection.RightToLeft) {
                dir = 'rtl';
            }
            
            document.getElementsByTagName('html').item(0).setAttribute('dir', dir);
        });
    }
}
