import { UserPersonViewDTO } from './data-transfer-objects/user/user-person-view-dto';
import { ShapeViewDTO } from './data-transfer-objects/shape/shape-view-dto';
import { AssetListViewDTO } from './data-transfer-objects/asset/asset-list-view-dto';
import { PaginationResultModel } from './models/pagination-models';
import { AssetViewDTO } from './data-transfer-objects/asset/asset-view-dto';
import { DocumentListViewDTO } from './data-transfer-objects/document/document-list-view-dto';
import { CommodityViewDTO } from './data-transfer-objects/commodity/commodity-view-dto';
import { ActionViewDTO } from './data-transfer-objects/action/action-view-dto';
import { ActionListViewDTO } from './data-transfer-objects/action/action-list-view-dto';
import { JobSchedulerViewDTO } from './data-transfer-objects/job/job-scheduler-view-dto';
import { ShapeValidationResultsViewDTO } from './data-transfer-objects/shape/shape-validation-results-view-dto';
import { JobQueryResultViewDTO } from './data-transfer-objects/job/job-query-result-view-dto';
import { FormInputStyle } from './enums/configuration/form-input-style';
import { UserListViewDTO } from './data-transfer-objects/users/user-list-view-dto';
import { LookupConfigurationModel } from '@landadmin/structured-data/lib/models/lookup-configuration-model';
import { StructuredDataNodeModel } from '@landadmin/structured-data/lib/models/structured-data-node-model';
import { StructuredDataEntryModel } from '@landadmin/structured-data/lib/models/structured-data-entry-model';
import { DiscussionViewDTO } from './data-transfer-objects/discussion/discussion-view-dto';
import { DiscussionCommentViewDTO } from './data-transfer-objects/discussion-comment/discussion-comment-view-dto';
import { DiscussionParticipantViewDTO } from './data-transfer-objects/discussion-participant/discussion-participant-view-dto';
import { DiscussionLoadMode } from './data-transfer-objects/discussion/discussion-load-mode';
import { PageConfigurationViewDTO } from './data-transfer-objects/configuration/page-configuration-view.dto';
import { RelatedAssetListViewDTO } from './data-transfer-objects/related-asset/related-asset-list-view-dto';
import { RoleViewDTO } from './data-transfer-objects/role/role-view-dto';
import { IdPConfigurationViewDTO } from './data-transfer-objects/configuration/idp-configuration-view-dto';
import { PartyViewDTO } from './data-transfer-objects/party/party-view-dto';
import { RepresentativeListWidgetDataModel } from './data-transfer-objects/representative/representative-list-widget-data-model';
import { GoogleAnalyticsConfigurationViewDTO } from './data-transfer-objects/configuration/google-analytics-configuration-view-dto';
import { EmbedAccessTokenViewDTO } from './data-transfer-objects/power-bi/embed-access-token-view-dto';
import { ErrorDetails } from './models/error-details';
import { ReferenceCodeViewDTO } from './data-transfer-objects/reference-code/reference-code-view-dto';
import { MapReferenceViewDTO } from './data-transfer-objects/map-reference/map-reference-view-dto';
import { Calendars } from './data-transfer-objects/configuration/site-settings-configuration-view-dto';

export const BasicPageStateConst = 'basicPageState';
export const AssetDraftPageStateConst = 'assetDraftPageState';
export const ActionDraftPageStateConst = 'actionDraftPageState';
export const AssetDetailsPageStateConst = 'assetDetailsPageState';
export const ActionDetailsPageStateConst = 'actionDetailsPageState';
export const ActionDetailsFormPageStateConst = 'actionDetailsFormPageState';
export const UserDetailsPageStateConst = 'userDetailsPageState';
export const UIStyleConfigurationStateConst = 'uiStyleConfigurationState';
export const WidgetStateConst = 'widgetState';
export const ConfigurationStateConst = 'configurationState';
export const SiteSettingsConfigurationStateConst = 'siteSettingsConfigurationState';
export const IdPsConfigurationStateConst = 'idPsConfigurationState';
export const GoogleAnalyticsConfigurationStateConst = 'GoogleAnalyticsConfigurationState';

export interface AppState {
    updateUserError: SavedSuccessfullyModel,
    assetDraftPageState: AssetDraftPageState,
    actionDraftPageState: ActionDraftPageState,
    assetListWidgetState: ListState<AssetListWidgetStateModel>[],
    actionListWidgetState: ListState<ActionListWidgetStateModel>[],
    userListWidgetState: ListState<UserListWidgetStateModel>[],
    basicPageState: BasicPageState,
    assetDetailsPageState: AssetDetailsPageState,
    uiStyleConfigurationState: UIStyleConfigurationState,
    widgetState: WidgetState,
    configurationState: ConfigurationState,
    siteSettingsConfigurationState: SiteSettingsConfigurationState,
    idPsConfigurationState: IdPsConfigurationState
}

export interface WidgetStateCache {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [Key: string]: any;
}

export interface ConfigurationState {
    structuredDataLookupConfigurationState: StructuredDataLookupConfigurationState,
    structuredDataNodeConfigurationState: ListState<StructuredDataNodeConfigurationState>[],
    pageConfigurationState: ListState<PageConfigurationState>[]
}

export interface WidgetState {
    structuredDataListWidgetState: ListState<StructuredDataListWidgetState>[],
    discussionTopicListWidgetState: ListState<DiscussionsWidgetStateModel>[],
    discussionCommentsWidgetState: DiscussionCommentsWidgetState,
    relatedAssetListWidgetState: ListState<RelatedAssetListWidgetStateModel>[]
    discussionParticipantsWidgetState: DiscussionParticipantsWidgetState,
    assetWidgetState: AssetWidgetState,
    actionWidgetState: ActionWidgetState,
    userRoleWidgetState: UserRoleWidgetState,
    partyListWidgetState: ListState<PartyListWidgetStateModel>[],
    commodityWidgetState: CommodityWidgetState,
    representativeWidgetState: RepresentativeWidgetState,
    companyListState: CompanyListState,
    powerBIWidgetState: ListState<PowerBIWidgetState>[],
    referenceCodeListWidgetState: ListState<ReferenceCodeListWidgetState>[]
    mapReferenceListWidgetState: ListState<MapReferenceListWidgetState>[],
    widgetStateCache: WidgetStateCache
}

export interface PageConfigurationState extends GetState<PageConfigurationViewDTO> {

}

export interface StructuredDataLookupConfigurationState extends GetState<LookupConfigurationModel> {

}

export interface StructuredDataNodeConfigurationState extends GetState<StructuredDataNodeModel[]> {

}

export interface GetState<TModel> {
    viewModel: TModel,
    getError: ErrorDetails,
}

export interface UpdateState {
    updateError: ErrorDetails,
}


export interface StructuredDataListWidgetState extends GetState<PaginationResultModel<StructuredDataEntryModel>>, UpdateState {
}

export interface BasicPageState {
    ShapeDraftWidgetState: ShapeWidgetState
}

export interface SavedSuccessfullyModel {
    error: ErrorDetails,
    successfull: boolean
}

export interface Saved {
    error: ErrorDetails
}

export interface AssetListWidgetStateModel {
    paginationResult: PaginationResultModel<AssetListViewDTO>
    error: ErrorDetails
}

export interface ActionListWidgetStateModel {
    paginationResult: PaginationResultModel<ActionListViewDTO>
    error: ErrorDetails
}

export interface UserListWidgetStateModel {
    paginationResult: PaginationResultModel<UserListViewDTO>
    error: ErrorDetails
}

export interface DiscussionsWidgetStateModel {
    paginationResult: PaginationResultModel<DiscussionViewDTO>
    discussionIdToBeUpdated?: string
    latestComment?: string,
    lastUpdated?: Date,
    loadMode: DiscussionLoadMode,
    error: ErrorDetails
}

export interface RelatedAssetListWidgetStateModel {
    paginationResult: PaginationResultModel<RelatedAssetListViewDTO>;
    reloadList: boolean;
    error: ErrorDetails;
    operations?: number;
}

export interface ListState<TModel> {
    dataSourceId: string,
    StateModel: TModel
}

export interface DocumentGroupWidgetState {
    listState: ListState<DocumentWidgetState>[],
    triggeredDataSourceId: string,
    widgetConfigurationId: string
}



export interface ActionDraftPageState {
    DocumentGroupWidgetState: DocumentGroupWidgetState,
    ShapeDraftWidgetState: ShapeWidgetState,
    RunShapeValidationState: RunShapeValidationState,
    ShapeValidationResultsState: ShapeValidationResultsState,
    DeleteJobState: DeleteJobState
}

export interface ActionDetailsPageState {
    ActionDraftLoaded: boolean,
    IsSaving: boolean,
    DocumentGroupWidgetState: DocumentGroupWidgetState,
    ShapeWidgetState: ShapeWidgetState,
    RunShapeValidationState: RunShapeValidationState,
    ShapeValidationResultsState: ShapeValidationResultsState,
    DeleteJobState: DeleteJobState
}

export interface ShapeWidgetState {
    ListState: ListState<ShapeViewDTO>[],
    GetShapeError: ErrorDetails,
    UpdateShapeError: ErrorDetails
}

export interface RunShapeValidationState {
    JobSchedulerViewDTO: JobSchedulerViewDTO
    RunShapeValidationError: ErrorDetails
}

export interface ShapeValidationResultsState {
    JobQueryResultViewDTO: JobQueryResultViewDTO<ShapeValidationResultsViewDTO>
    GetShapeValidationResultsError: ErrorDetails
}

export interface DeleteJobState {
    DeleteJobError: ErrorDetails
}

export interface AssetDraftPageState {
    DocumentGroupWidgetState: DocumentGroupWidgetState,
    ShapeDraftWidgetState: ShapeWidgetState,
    RunShapeValidationState: RunShapeValidationState,
    ShapeValidationResultsState: ShapeValidationResultsState,
    DeleteJobState: DeleteJobState
}

export interface AssetDetailsPageState {
    DocumentGroupWidgetState: DocumentGroupWidgetState,
    ShapeWidgetState: ShapeWidgetState,
    ActionListWidgetState: ListState<ActionListWidgetStateModel>[]
}

export interface AssetWidgetState {
    AssetViewDTO: AssetViewDTO,
    GetAssetError: ErrorDetails,
    UpdateAssetError: ErrorDetails,
}

export interface ActionWidgetState {
    ActionViewDTO: ActionViewDTO,
    GetActionError: ErrorDetails,
    UpdateActionError: ErrorDetails,
}

export interface DocumentWidgetState {
    DocumentDrafts: DocumentListViewDTO[],
    GetDocumentsError?: ErrorDetails,
    UpdateDocumentsError?: ErrorDetails,
    AddDocumentsError?: ErrorDetails,
    RemoveDocumentsError?: ErrorDetails,
    DocumentAdded: boolean,
    DocumentReplaced: boolean,
    DocumentRemoved: boolean
}

export interface CommodityWidgetState {
    UpdateError: ErrorDetails,
    GetError: ErrorDetails,
    Commodities: CommodityViewDTO[];
}

export interface RepresentativeWidgetState {
    AddError: ErrorDetails,
    RemoveError: ErrorDetails,
    GetError: ErrorDetails,
    Representatives: RepresentativeListWidgetDataModel
}

export interface CompanyListState {
    GetError: ErrorDetails,
    Companies: RepresentativeListWidgetDataModel
}

export interface UserDetailsPageState {
    UserDetailsWidgetState: UserDetailsWidgetState,
    DocumentGroupWidgetState: DocumentGroupWidgetState
}

export interface UserDetailsWidgetState {
    UserDetailsViewDTO: UserPersonViewDTO,
    GetUserDetailsError: ErrorDetails,
    UpdateUserDetailsError: ErrorDetails,
}

export interface UIStyleConfigurationState {
    FormInputStyle: FormInputStyle
}

export interface SiteSettingsConfigurationState {
    dateFormat: string,
    calendars:Calendars
}

export interface LanguageState {
    languageLocale: string
}

export interface DiscussionCommentsWidgetState {
    DiscussionComments: DiscussionCommentViewDTO[],
    GetDiscussionCommentsError: ErrorDetails,
    loadMode?: DiscussionLoadMode
}

export interface DiscussionParticipantsWidgetState {
    DiscussionParticipants: DiscussionParticipantViewDTO[],
    GetDiscussionParticipantsError: ErrorDetails
}

export interface UserRoleWidgetState {
    Roles: RoleViewDTO[],
    Error: ErrorDetails
}

export interface IdPsConfigurationState {
    IdPsConfiguration: IdPConfigurationViewDTO,
    GetIdPsConfigurationError: ErrorDetails
}

export interface PartyListWidgetStateModel {
    paginationResult: PaginationResultModel<PartyViewDTO>
    error: ErrorDetails
}

export interface GoogleAnalyticsConfigurationState {
    GoogleAnalyticsConfiguration: GoogleAnalyticsConfigurationViewDTO,
    GetGoogleAnalyticsConfigurationError: ErrorDetails
}

export interface PowerBIWidgetState {
    GetError: ErrorDetails,
    EmbedAccessToken: EmbedAccessTokenViewDTO
}

export interface ReferenceCodeListWidgetState {
    paginationResult: PaginationResultModel<ReferenceCodeViewDTO>
    GetError: ErrorDetails
}

export interface MapReferenceListWidgetState {
    paginationResult: PaginationResultModel<MapReferenceViewDTO>
    GetError: ErrorDetails
}