import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../AppState';
import { FormInputStyle } from '../../enums/configuration/form-input-style';
import { getUIStyleConfigurationRequest } from '../../state/deprecated/ui-style/ui-style.actions';
import { getUIStyleConfigurationFormInputStyleState } from '../../state/deprecated/ui-style/ui-style.selectors';

@Injectable()
export class UIStyleFacade {

    constructor(private store: Store<AppState>) {
    }

    public GetFormInputStyle(): Observable<FormInputStyle> {
        return this.store.pipe(select(getUIStyleConfigurationFormInputStyleState));
    }

    public LoadConfiguration(): void {
        this.store.dispatch(getUIStyleConfigurationRequest());
    }
}
