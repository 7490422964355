import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PowerBIWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/widgets/power-bi-widget-configuration-view-dto';
import * as pbi from 'powerbi-client'
import { AbstractPowerBIFacade } from '../../../../../facade/abstract/abstract-power-bi.facade';
import { Subscription } from 'rxjs';
import { ListState, PowerBIWidgetState } from '../../../../../AppState';
import { Router } from '@angular/router';
import { PowerBIEventType } from '../../../../../enums/power-bi-event-type';
import { EmbedAccessTokenViewDTO } from '../../../../../data-transfer-objects/power-bi/embed-access-token-view-dto';
import { HttpStatusCode } from '../../../../../enums/http-status-codes';
import { EntityType } from '../../../../../enums/entity-type';
import { GuidHelper } from '../../../../../helpers/guid-helper';

@Component({
    selector: 'fw-power-bi-widget',
    templateUrl: './power-bi-widget.component.html'
})
export class PowerBIWidgetComponent implements OnInit, OnDestroy {

    public getError: boolean = false;

    @Input()
    public PowerBIWidgetConfiguration: PowerBIWidgetConfigurationViewDTO;
    @ViewChild('reportContainer', { static: false }) reportContainer: ElementRef;

    private subcriptions: Subscription[] = [];
    private stateEntry: ListState<PowerBIWidgetState>;
    private dataSourceId: string;

    constructor(private powerBIFacade: AbstractPowerBIFacade,
        private router: Router) {
    }

    ngOnInit(): void {

        this.dataSourceId = GuidHelper.NewGuid();

        this.powerBIFacade.LoadEmbedAccessToken(this.PowerBIWidgetConfiguration.Embed, this.dataSourceId);

        const getAccessTokenSubscription = this.powerBIFacade.GetEmbedAccessToken().subscribe((state: ListState<PowerBIWidgetState>[]) => {

            if (state && state.length > 0) {

                this.stateEntry = state.find((listState) => listState.dataSourceId === this.dataSourceId);

                if (!this.stateEntry) {
                    return;
                }

                if (this.stateEntry.StateModel.GetError && this.stateEntry.StateModel.GetError.status !== HttpStatusCode.NOT_FOUND) {
                    this.getError = true;

                } else {

                    if (!this.stateEntry.StateModel.EmbedAccessToken) {
                        return;
                    }

                    this.setEmbedConfiguration(this.stateEntry.StateModel.EmbedAccessToken);
                }
            }
        });

        this.subcriptions.push(getAccessTokenSubscription);
    }

    ngOnDestroy(): void {
        if (this.subcriptions) {
            this.subcriptions.forEach((subcription) => {
                if (subcription) {
                    subcription.unsubscribe();
                }
            });
        }

        this.powerBIFacade.ResetState();
    }

    private setEmbedConfiguration(embedAccessToken: EmbedAccessTokenViewDTO): void {
        const embedConfig: pbi.IEmbedConfiguration = {
            type: 'report',
            tokenType: pbi.models.TokenType.Embed,
            accessToken: embedAccessToken.AccessToken,
            embedUrl: embedAccessToken.Url,
            groupId: this.PowerBIWidgetConfiguration.Embed.GroupId,
            id: this.PowerBIWidgetConfiguration.Embed.ReportId,
            settings: {
                filterPaneEnabled: this.PowerBIWidgetConfiguration.Settings.ShowFilterPane,
                navContentPaneEnabled: this.PowerBIWidgetConfiguration.Settings.ShowNavContentPane,
                hyperlinkClickBehavior: pbi.models.HyperlinkClickBehavior.RaiseEvent
            }
        };

        const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        powerbi.reset(this.reportContainer.nativeElement);
        const report = powerbi.embed(this.reportContainer.nativeElement, embedConfig);

        this.RegisterEventHandlers(report);
    }

    private RegisterEventHandlers(report: pbi.Embed): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        report.on('dataSelected', (selected: any) => {
            
            const entityId: string = selected.detail.dataPoints[0].identity.
                find(identity => identity.target.column === this.PowerBIWidgetConfiguration.DataRow.IdColumnName).
                equals;

            if (this.PowerBIWidgetConfiguration.DataRow.EntityType === EntityType.Asset) {
                this.NavigateByContext(entityId, null, PowerBIEventType.DataSelected);
            }
            else if (this.PowerBIWidgetConfiguration.DataRow.EntityType === EntityType.Action) {
                this.NavigateByContext(null, entityId, PowerBIEventType.DataSelected);
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        report.on('dataHyperlinkClicked', (clicked: any) => {

            const regex = /https?:\/\/(?<hyperlinkId>.+):(?<entityId>.+)/gm;
            const match = regex.exec(clicked.detail.url);
            const hyperlinkId = match.groups.hyperlinkId;
            const entityId = match.groups.entityId;
            const onHyperLinkClicked = this.PowerBIWidgetConfiguration.Hyperlinks.find(x => x.Id === hyperlinkId);

            if (onHyperLinkClicked.EntityType === EntityType.Asset) {
                this.NavigateByContext(entityId, null, PowerBIEventType.HyperlinkClicked, hyperlinkId);
            }
            else if (onHyperLinkClicked.EntityType === EntityType.Action) {
                this.NavigateByContext(null, entityId, PowerBIEventType.HyperlinkClicked, hyperlinkId);
            }

        });
    }

    private NavigateByContext(assetId: string, actionId: string, eventType: PowerBIEventType, hyperlinkId?: string): void {
        this.powerBIFacade.ExecuteBehaviour(eventType, this.PowerBIWidgetConfiguration.Id, assetId, actionId, hyperlinkId);
    }
}