import { BaseSectionConfigurationViewDTO } from '../data-transfer-objects/configuration/base-section-configuration-view-dto';
import { BaseWidgetConfigurationViewDTO } from '../data-transfer-objects/configuration/base-widget-configuration-view-dto';
import { SectionConfigurationViewDTO } from '../data-transfer-objects/configuration/section-configuration-view-dto';
import { WidgetConfigurationViewDTO } from '../data-transfer-objects/configuration/widget-configuration-view-dto';
import { SectionStyle } from '../enums/configuration/section-style';
import { WidgetType } from '../enums/configuration/widget-type';

export class ConfigurationHelper {

    public static GetBaseWidgetFromConfiguration(widgetConfiguration: WidgetConfigurationViewDTO): BaseWidgetConfigurationViewDTO {
        switch (widgetConfiguration.WidgetType)
        {
            case WidgetType.AssetDetails:
                return widgetConfiguration.AssetDetailsWidget;
            case WidgetType.ContentBlock:
                return widgetConfiguration.HtmlWidget;
            case WidgetType.DocumentList:
                return widgetConfiguration.DocumentListWidget;
            case WidgetType.Commodity:
                return widgetConfiguration.CommoditiesWidget;
            case WidgetType.Map:
                return widgetConfiguration.MapWidget;
            case WidgetType.RepresentativeList:
                return widgetConfiguration.RepresentativeListWidget;
            case WidgetType.Task:
                return widgetConfiguration.TaskWidget;
            case WidgetType.Tile:
                return widgetConfiguration.TileWidget;
            case WidgetType.UserDetails:
                return widgetConfiguration.UserDetailsWidget;
            case WidgetType.UserList:
                return widgetConfiguration.UserListWidget;
            case WidgetType.AssetList:
                return widgetConfiguration.AssetListWidget;
            case WidgetType.ActionDetails:
                return widgetConfiguration.ActionDetailsWidget;
            case WidgetType.ActionList:
                return widgetConfiguration.ActionListWidget;
            case WidgetType.StructuredDataList:
                return widgetConfiguration.StructuredDataListWidget;
            case WidgetType.Discussions:
                return widgetConfiguration.DiscussionListWidget;
            case WidgetType.UserRoles:
                return widgetConfiguration.UserRolesWidget;
            case WidgetType.RelatedAssetList:
                return widgetConfiguration.RelatedAssetListWidget;
            case WidgetType.SessionDetails:
                return widgetConfiguration.SessionDetailsWidget;
            case WidgetType.PartyList:
                return widgetConfiguration.PartyListWidget;
            case WidgetType.PowerBI:
                return widgetConfiguration.PowerBIWidget;
            case WidgetType.ReferenceCodeList:
                return widgetConfiguration.ReferenceCodeListWidget;
            case WidgetType.MapReferenceList:
                return widgetConfiguration.MapReferenceListWidget;
            default:
                throw Error(`The widget type '${widgetConfiguration.WidgetType}' has not been mapped.`);
        }
    }

    public static GetBaseSectionFromConfiguration(sectionConfiguration: SectionConfigurationViewDTO): BaseSectionConfigurationViewDTO {
        switch (sectionConfiguration.SectionStyle)
        {
            case SectionStyle.Carousel:
                return sectionConfiguration.CarouselSection;
            case SectionStyle.Tabbed:
                return sectionConfiguration.TabSection;
            case SectionStyle.Column:
                return sectionConfiguration.ColumnSection;
            case SectionStyle.Tiled:
            default:
                throw Error(`The section style '${sectionConfiguration.SectionStyle}' has not been mapped.`);
        }
}
}
