import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListState, PowerBIWidgetState } from '../../AppState';
import { EmbedConfigurationViewDTO } from '../../data-transfer-objects/power-bi/embed-configuration-view-dto';
import { PowerBIEventType } from '../../enums/power-bi-event-type';
import { SignalRApplicationService } from '../../services/application/signalr-base-application-service';

@Injectable()
export abstract class AbstractPowerBIFacade {
    public _signalRLoadedObservable: Observable<SignalRApplicationService>;
    public _pageId: string;

    public abstract GetEmbedAccessToken(): Observable<ListState<PowerBIWidgetState>[]>;
    public abstract LoadEmbedAccessToken(embedConfiguration: EmbedConfigurationViewDTO, dataSourceId: string): void;
    public abstract ExecuteBehaviour(eventType: PowerBIEventType, widgetId: string, assetId: string, actionId: string, hyperlinkId?: string): void;
    public abstract ResetState(): void;
}