<div class="modal-dialog modal-dialog-centered" [ngClass]="_modalSize" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="dynamicModalTitle">{{_modalTitle}}</h5>
            <button *ngIf="_showClose" type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div #modalBody></div>
        </div>
        <div class="modal-footer">
            <div #modalFooter></div>
        </div>
    </div>
</div>
