import { Component, OnDestroy, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { RepresentativeListFacade } from '../../../../facade/representative/representative-list.facade';
import { severity_error, severity_success } from '../../../../consts/severity-options';
import { RepresentativeListWidgetConfigurationViewDTO } from '../../../../data-transfer-objects/configuration/representative-list-widget-configuration-view-dto';
import { UserRepresentationListWidetConfigurationViewDTO } from '../../../../data-transfer-objects/configuration/user-representation-list-widget-configuration-view-dto';
import { RepresentativeOfListViewDTO } from '../../../../data-transfer-objects/representative/representative-list-view-dto';
import { RepresentativeListWidgetDataModel } from '../../../../data-transfer-objects/representative/representative-list-widget-data-model';
import { OrderByDirection } from '../../../../enums/comparator-enum';
import { PaginationModel } from '../../../../models/pagination-models';
import { DialogComponentBase } from '../../../../services/application/dialog-application.service';
import { SessionApplicationService } from '../../../../services/application/session-application.service';
import { ToastApplicationService } from '../../../../services/application/toast-application.service';
import { AbstractRepresentativeListFacade } from '../../../../facade/abstract/abstract-representative-list.facade';
import { AbstractCompanyListFacade } from '../../../../facade/abstract/abstract-company-list.facade';
import { FormEditStyle } from '../../../../enums/configuration/form-edit-style';
import { CompanyListState } from '../../../../AppState';
import { CompanyListFacade } from '../../../../facade/company/company-list.facade';
import { TranslocoService } from '@ngneat/transloco';
import { TranslationHelper } from 'src/app/helpers/translation-helper';
import { UIStylingHelper } from 'src/app/helpers/ui-styling-helper';

@Component({
    selector: 'fw-add-representation',
    templateUrl: './add-representation.component.html',
    providers: [
        { provide: AbstractRepresentativeListFacade, useClass: RepresentativeListFacade },
        { provide: AbstractCompanyListFacade, useClass: CompanyListFacade }
    ]
})
export class AddRepresentationComponent extends DialogComponentBase<{ RepresentativeListWidgetConfiguration: RepresentativeListWidgetConfigurationViewDTO, PageId: string }>
    implements OnInit, OnDestroy {
    CompanyRequestSubscription: Subscription;
    RepresentativeListWidgetConfiguration: RepresentativeListWidgetConfigurationViewDTO;
    RepresentativeListWidgetDataModel: RepresentativeListWidgetDataModel;
    SelectedRepresentative: RepresentativeOfListViewDTO = null;

    first: number = 0;
    loading: boolean;
    currentPageReportTemplate: string;

    constructor(
        private dynamicDialogRef: DynamicDialogRef,
        private dynamicDialogConfig: DynamicDialogConfig,
        private sessionApplicationService: SessionApplicationService,
        private representativeListFacade: AbstractRepresentativeListFacade,
        private toastApplicationService: ToastApplicationService,
        private companyListFacade: AbstractCompanyListFacade,
        private translocoService: TranslocoService
    ) {
        super(dynamicDialogRef, dynamicDialogConfig);
        this.currentPageReportTemplate = this.translocoService.translate(TranslationHelper.CurrentPageReportTemplateResourceId);
    }

    cancelClicked() {
        this.dynamicDialogRef.close();
        this.dynamicDialogRef.destroy();
    }

    confirmClicked() {
        this.dynamicDialogRef.close();
        this.dynamicDialogRef.destroy();

        this.representativeListFacade.AddRepresentative(
            this.sessionApplicationService.currentUser.PersonId,
            this.SelectedRepresentative);

        this.toastApplicationService.showToast('AddRepresentation.Form.RepresentationAddedSummary', 'AddRepresentation.Form.RepresentationAddedSuccessfully', severity_success);
    }

    loadRepresentatives(event?: LazyLoadEvent) {
        this.loading = true;

        this.companyListFacade.UserRepresentationListWidgetConfiguration = this.buildCompanyConfiguration(event);
        this.companyListFacade.LoadRepresentativesBySearch();
    }

    getTableStyleClass(): string {
        let tableStyleClass = '';

        if (this.RepresentativeListWidgetDataModel?.totalRecords <= this.RepresentativeListWidgetConfiguration?.PageSize) {
            tableStyleClass += UIStylingHelper.hidePagingStyleClass;
        }

        return tableStyleClass;
    }
    
    ngOnDestroy() {
        this.CompanyRequestSubscription.unsubscribe();
        this.companyListFacade.ResetState();
    }

    ngOnInit(): void {

        this.companyListFacade.FormEditStyle = FormEditStyle.Inline;

        if (this.RepresentativeListWidgetDataModel == null) {
            this.RepresentativeListWidgetDataModel =
                new RepresentativeListWidgetDataModel();
        }

        this.RepresentativeListWidgetConfiguration =
            this.dialogOptions.dataModel.RepresentativeListWidgetConfiguration;

        if (this.RepresentativeListWidgetDataModel == null) {
            this.RepresentativeListWidgetDataModel =
                new RepresentativeListWidgetDataModel();
            this.RepresentativeListWidgetDataModel.representatives = [];
            this.RepresentativeListWidgetDataModel.totalRecords = 0;
            this.RepresentativeListWidgetDataModel.FormEditStyle = FormEditStyle.Inline;
        }

        this.CompanyRequestSubscription =
            this.companyListFacade
                .GetRepresentativesBySearch()
                .subscribe((companyListState: CompanyListState) => {
                    if (companyListState.GetError) {
                        this.toastApplicationService.showToast('Company.List.GetError', 'Company.List.GetError.Detail', severity_error);
                    } else {
                        this.RepresentativeListWidgetDataModel = companyListState.Companies;
                        this.loading = false;
                    }
                })
    }

    private buildCompanyConfiguration(event?: LazyLoadEvent): UserRepresentationListWidetConfigurationViewDTO {
        const paginationModel: PaginationModel = new PaginationModel();
        paginationModel.Limit = this.RepresentativeListWidgetConfiguration.PageSize;
        paginationModel.Page = event.first / event.rows + 1;
        paginationModel.OrderByList = [
            {
                Field:
                    this.RepresentativeListWidgetConfiguration.Fields[0].PropertyName,
                OrderByDirection: OrderByDirection.Ascending,
            },
        ];

        const fieldList: Array<string> = new Array<string>('Name', 'Code', 'Email');

        const userRepresentationListWidgetConfiguration: UserRepresentationListWidetConfigurationViewDTO =
        {
            PersonId: this.sessionApplicationService.currentUser.PersonId,
            PageId: this.dialogOptions.dataModel.PageId,
            WidgetId: this.RepresentativeListWidgetConfiguration.Id,
            Fields: fieldList,
            PaginationModel: paginationModel,
        };

        return userRepresentationListWidgetConfiguration;
    }
}
