import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { HijriDateHelper } from 'src/app/helpers/date-helper';
import { CalendarConfiguration } from '../../../../../models/configuration/widgets/properties/controls/calendar/calendar-configuration';
import { ValidatableInputControlBase } from '../../input-controls/validatable-input-control-base';
import { SiteSettingsService } from 'src/app/services/deprecated/site-settings.service';
import { NgbCalendar, NgbCalendarIslamicUmalqura, NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { LanguageHttpService } from '../../../../../services/http/language-http.service';
import { HijriCalendar18n } from '../../../../../helpers/hijiri-calendar-I18n';
import { HijriCalendareDateFormatter } from '../../../../../helpers/hijiri-calendar-date-formatter';

@Component({
    selector: 'fw-inline-edit-uaq-calendar',
    templateUrl: './inline-edit-uaq-calendar.component.html',
    host: { 'class': 'inline-edit' },
    providers: [
        {
            provide: NgbCalendar,
            useClass: NgbCalendarIslamicUmalqura
        },
        {
            provide: NgbDatepickerI18n,
            useClass: HijriCalendar18n
        },
        {
            provide: NgbDateParserFormatter,
            useClass: HijriCalendareDateFormatter
        }
    ],
})
export class InlineEditUAQCalendarComponent extends ValidatableInputControlBase<NgbDate> implements OnInit {

    @Input()
    public Calendar: CalendarConfiguration;

    @ViewChild('datePicker') datePicker: NgbInputDatepicker;

    public get dateFormat(): string {
        return this.siteSettingsService.dateFormat;
    }

    constructor(
        protected transloco: TranslocoService,
        private siteSettingsService: SiteSettingsService,
        private languageHttpService: LanguageHttpService
    ) {
        super(transloco);
    }

    ngOnInit(): void {
        if (this.Calendar) {
            this.ConfigureDateTimeValidation(this.Calendar.Validation);
        }
    }

    toggleDatePicker() {

        this.datePicker.toggle();

        if (this.datePicker.isOpen()) {
            const datepicker = document.querySelector('ngb-datepicker');
            if (datepicker) {


                const buttonBarDiv = document.createElement('div');
                buttonBarDiv.id = 'buttonBar';

                const todayButton = document.createElement('button');
                todayButton.textContent = this.transloco.translate('Today');
                todayButton.className = 'today-btn';
                todayButton.addEventListener('click', () => {
                    this.selectToday();
                    this.datePicker.toggle();
                });

                buttonBarDiv.appendChild(todayButton);


                const clearButton = document.createElement('button');
                clearButton.textContent = this.transloco.translate('Clear');
                clearButton.className = 'clear-btn';
                clearButton.addEventListener('click', () => {
                    this.clearDate();
                    this.datePicker.toggle();
                });

                buttonBarDiv.appendChild(clearButton);
                datepicker.appendChild(buttonBarDiv);
            }
        }
    }

    clearDate() {
        this.CustomFormGroup.controls[this.PropertyName].setValue('');
    }

    selectToday() {
        this.CustomFormGroup.controls[this.PropertyName].setValue(HijriDateHelper.GetToday());
    }

    public onAcceptChanges() {

        this.editMode = false;
        this.Data = this.CustomFormGroup.controls[this.PropertyName].value;
        this.CustomFormGroup.controls[this.PropertyName].markAsPristine();
        this.AcceptChanges.emit();
        this.CustomFormGroup.controls[this.PropertyName].setValue(this.Data); //update UI immediately
    }

    public displayValue(): string {
        if (this.CustomFormGroup.controls[this.PropertyName]?.value && this.CustomFormGroup.controls[this.PropertyName]?.status !== 'INVALID') {

            return HijriDateHelper.formatDate(this.CustomFormGroup.controls[this.PropertyName].value.day, this.CustomFormGroup.controls[this.PropertyName].value.month, this.CustomFormGroup.controls[this.PropertyName].value.year, this.siteSettingsService.dateFormat, this.languageHttpService.getCurrentLanguageLocale());
        }

        return '';
    }

    public onCancelChanges() {
        this.editMode = false;

        if (this.Data) {
            this.CustomFormGroup.controls[this.PropertyName].setValue(this.Data);
        }
    }

    //override functionality
    onFocusOut(event) {

        if (!event.sourceCapabilities &&
            (event.target && (event.target.nodeName === 'SPAN' || event.target.nodeName === 'BUTTON'))
        ) {
            return;
        }

        if (!event.relatedTarget || (event.relatedTarget.className && event.relatedTarget.className === 'view-mode')) {
            this.onCancelChanges();
        }
    }
}
